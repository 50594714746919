import React, { useEffect, useState } from 'react'

import { useFormikContext } from 'formik'

import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import Chip from 'presentation/shared/components/Chip'
import { AddSurgicalOrderFormValues } from '../..'

import OpmeTable from 'presentation/hospital/components/OpmeTable'
import Heading from 'presentation/shared/components/Heading'
import * as FormS from '../styles'
import { Profile } from 'common/enum/profile'

type SelectOpmeProps = {
  defaultOpme?: boolean
  onlyVisualization?: boolean
  profile?: Profile
}

export function SelectOpme({
  defaultOpme,
  onlyVisualization,
  profile
}: SelectOpmeProps) {
  const [showOpme, setShowOpme] = useState<boolean | undefined>(defaultOpme)

  const { values, setFieldValue } =
    useFormikContext<AddSurgicalOrderFormValues>()

  useEffect(() => {
    setShowOpme(defaultOpme)
  }, [defaultOpme])

  const handleOpmeSelected = (value: boolean) => {
    setShowOpme(value)
    setFieldValue('use_opme', value)
  }

  const handleFavoriteOpme = (
    values: AddSurgicalOrderFormValues[] | string[],
    type: string
  ) => {
    const favoriteOrder = makeLocalStorageAdapter().get('favorite')
    if (favoriteOrder && Object.keys(favoriteOrder).length > 0) {
      if (type === 'opme') {
        return values.length > 0 ? values : favoriteOrder?.opme.solicitations
      }

      if (type === 'providers') {
        return favoriteOrder?.opme.providers
      }
    }
    return values
  }

  useEffect(() => {
    const favoriteOpme = makeLocalStorageAdapter().get('favorite')
    if (favoriteOpme && Object.keys(favoriteOpme).length > 0) {
      setFieldValue('opmes', favoriteOpme?.opme.solicitations)
      setFieldValue('providers', favoriteOpme?.opme.providers)
      handleOpmeSelected(true)
    }
  }, [])

  return (
    <>
      <FormS.SurgeryWrapper>
        <Heading
          size="large"
          color="primary"
          style={{ marginTop: '60px', marginBottom: '24px' }}
        >
          OPME
        </Heading>
      </FormS.SurgeryWrapper>
      <FormS.ChipContainer>
        <label>
          Procedimento irá precisar de OPME?
          <span>*</span>
        </label>
        <div>
          {options.map((option) => (
            <Chip
              key={option.label}
              label={option.label}
              customValue={option.value}
              disabled={onlyVisualization}
              name="opme"
              checked={option.value === showOpme}
              onCheck={() => handleOpmeSelected(option.value)}
              dataTestId={`use-opme-chip-${option.value}`}
            />
          ))}
        </div>
      </FormS.ChipContainer>
      {showOpme && (
        <FormS.OpmeWrapper>
          <OpmeTable
            selectedOpme={handleFavoriteOpme(values.opmes, 'opme')}
            setSelectedOpme={(opme) => setFieldValue('opmes', opme)}
            handleChange={(val) => setFieldValue('providers', val)}
            onlyVisualization={onlyVisualization}
            defaultProviders={values.providers}
            favoriteProviders={handleFavoriteOpme(
              values.providers,
              'providers'
            )}
            profile={profile}
          />
        </FormS.OpmeWrapper>
      )}
    </>
  )
}

const options = [
  {
    label: 'Sim',
    value: true
  },
  {
    label: 'Não',
    value: false
  }
]
