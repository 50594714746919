import React, { Dispatch, SetStateAction, useState } from 'react'
import { toast } from 'react-toastify'
import * as yup from 'yup'

import { cpfMask, dateMask } from 'presentation/utils/masks'
import Heading from 'presentation/shared/components/Heading'
import SupportText from 'presentation/shared/components/SupportText'
import TextField from 'presentation/shared/components/TextField'
import Button from 'presentation/shared/components/Button'
import NotFountPacientModal from 'presentation/secretary/components/NotFoundPacientModal'
import * as S from './styles'
import ActualPage from 'presentation/shared/components/ActualPage'
import { useFormik, useFormikContext } from 'formik'
import { AddSurgicalOrderFormValues } from '../index'
import { validateCpf } from 'presentation/utils/validators/cpf-validator'
import { ReactComponent as SearchIcon } from 'presentation/assets/icons/search.svg'
import SearchPatientByNameModal from 'presentation/hospital/components/SearchPatientByName'
import { Patient } from 'domain/entities/patient-model'
import { SearchPatient } from 'domain/usecases/patient/search-patient'
import moment from 'moment-timezone'

type Props = {
  findPatient?: (cpf: string) => void
  goNext?: () => void
  goBack?: () => void
  handleNotFoundPatientModal?: boolean
  setCreatePatientMode: Dispatch<SetStateAction<boolean>>
  searchPatient: SearchPatient
  handleClosePacientModal: () => void
}

//add patient modal
export default function SelectSurgicalOrderPatient({
  findPatient,
  goNext,
  goBack,
  setCreatePatientMode,
  handleClosePacientModal,
  searchPatient,
  handleNotFoundPatientModal
}: Props) {
  const [showFindModal, setShowFindModal] = useState<boolean>(false)
  const [findedPatients, setFindedPatients] = useState<Patient[]>([])

  const searchPatientByCpf = () => {
    if (validateCpf(formik.values.cpf)) {
      !!findPatient && findPatient(formik.values.cpf.replace(/\D+/g, ''))
    } else {
      toast.error('CPF inválido')
    }
  }

  const searchPatientByNameAndBday = async () => {
    const date = moment(formik.values.birthday, 'DD/MM/YYYY')
    date.subtract(date.parseZone().utcOffset(), 'minutes')

    try {
      setFindedPatients(
        await searchPatient
          .load({
            name: formik.values.name,
            bday: date.toISOString(),
            params: [
              'data{',
              'patient_id',
              'name',
              'gender',
              'cpf',
              'phone',
              'landlinePhone',
              'email',
              'birthday',
              'maritalStatus',
              'healthInsurance {healthInsuranceCode, healthInsuranceName, health_insurance_id, healthCard',
              'healthPlanCode, healthPlanName, validThru, company}}'
            ],
            pagination: { pageNumber: 1, pageSize: 10 }
          })
          .then((res) => res.data)
      )
      setShowFindModal(true)
    } catch (e) {
      toast.error('Paciente não encontrado')
    }
  }

  const { values, setFieldValue } =
    useFormikContext<AddSurgicalOrderFormValues>()

  const addPatient = () => {
    setFieldValue('patient', undefined)
    setCreatePatientMode(true)
    goNext?.()
  }

  const formik = useFormik({
    initialValues: {
      cpf: '',
      birthday: '',
      name: '',
      searchType: 'cpf'
    },
    validateOnMount: true,
    validationSchema: () =>
      yup.lazy((values) => {
        const isCPF = values.searchType === 'cpf'

        return isCPF ? cpfValidationSchema : nameValidationSchema
      }),
    onSubmit: () => undefined
  })

  const changeSearchMode = () => {
    formik.setFieldValue(
      'searchType',
      formik.values.searchType === 'cpf' ? 'name' : 'cpf'
    )
  }

  return (
    <S.Wrapper>
      <ActualPage text="Voltar" onClick={goBack} />
      <Heading size="xlarge" color="primary" as="h1">
        Novo pedido cirúrgico
      </Heading>
      <SupportText style={{ marginBottom: '3.125em' }}>
        Busque um paciente pelo CPF ou nome
      </SupportText>
      <S.AddPatient>
        <Heading as="h5" color="primary" size="medium">
          Paciente
        </Heading>
      </S.AddPatient>
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <S.Inputs>
          {formik.values.searchType === 'cpf' ? (
            <>
              <S.SearchInputWrapper>
                <TextField
                  label="Informe o CPF do paciente"
                  labelColor="lightGray"
                  bgColor="mainBg"
                  name="cpf"
                  id="cpf"
                  mask={cpfMask}
                  onChange={formik.handleChange('cpf')}
                  defaultValue={values.patient?.cpf}
                  placeholder="Inserir CPF..."
                  required
                  data-testid={`patient-cpf-input`}
                />
                <Button
                  onClick={searchPatientByCpf}
                  disabled={!!formik.errors.cpf}
                  data-testid={`patient-cpf-button`}
                >
                  <SearchIcon style={{ width: '30px', height: '20px' }} />
                </Button>
              </S.SearchInputWrapper>
              <TextField
                label="Paciente"
                disabled
                bgColor="mainBg"
                value={values.patient?.name}
                style={{ marginLeft: '10px' }}
                placeholder="Nome do paciente"
                data-testid={`patient-name-disabled-input`}
              />
            </>
          ) : (
            <>
              <S.SearchInputWrapper>
                <TextField
                  label="Informe o nome do paciente"
                  labelColor="lightGray"
                  bgColor="mainBg"
                  defaultValue={values.patient?.name}
                  placeholder="Nome do paciente"
                  onChange={formik.handleChange('name')}
                  data-testid={`patient-name-input`}
                  required
                />
              </S.SearchInputWrapper>
              <S.SearchInputWrapper style={{ marginLeft: '10px' }}>
                <TextField
                  label="Informe a data de nascimento do paciente"
                  labelColor="lightGray"
                  bgColor="mainBg"
                  name="birthday"
                  id="birthday"
                  mask={dateMask}
                  onChange={formik.handleChange('birthday')}
                  defaultValue={values.patient?.birthday}
                  placeholder="Data de nascimento"
                  required
                  data-testid={`patient-birthday-input`}
                />

                <Button
                  onClick={searchPatientByNameAndBday}
                  disabled={!!(formik.errors.name || formik.errors.birthday)}
                  data-testid={`patient-birthday-button`}
                >
                  <SearchIcon width="29px" height="29px" viewBox="0 0 18 18" />
                </Button>
              </S.SearchInputWrapper>
              <TextField
                disabled
                bgColor="mainBg"
                value={values.patient?.name}
                style={{ marginLeft: '10px' }}
                placeholder="CPF do paciente"
                data-testid={`patient-name-disabled-input`}
              />
            </>
          )}
        </S.Inputs>
        <SupportText
          color="primary"
          style={{
            cursor: 'pointer',
            alignSelf: 'flex-start',
            fontWeight: 500
          }}
          onClick={changeSearchMode}
          data-testid={`change-search-patient-mode-button`}
        >
          {formik.values.searchType === 'cpf'
            ? 'Buscar por nome'
            : 'Buscar por CPF'}
        </SupportText>
      </div>
      <S.Buttons>
        {/* <Button
          variant="outlined"
          onClick={addPatient}
          data-testid={`invite-patient-button`}
        >
          Cadastrar paciente
        </Button> */}
        <Button
          disabled={!values.patient?.patient_id}
          onClick={() => {
            setCreatePatientMode(false)
            goNext?.()
          }}
          type="button"
          data-testid={`select-patient-next-button`}
        >
          Próximo
        </Button>
      </S.Buttons>
      {showFindModal && (
        <SearchPatientByNameModal
          close={() => setShowFindModal(false)}
          patients={findedPatients}
          selectPatient={(patient) => setFieldValue('patient', patient)}
        />
      )}

      {handleNotFoundPatientModal && (
        <NotFountPacientModal
          show={true}
          action={addPatient}
          close={handleClosePacientModal}
        />
      )}
    </S.Wrapper>
  )
}

const cpfValidationSchema = yup.object().shape({
  cpf: yup.string().cpf().required()
})

const nameValidationSchema = yup.object().shape({
  birthday: yup.date().format('DD/MM/YYYY', true).required(),
  name: yup.string().required()
})
