import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: 14px 36px 36px 36px;
    display: flex;
    flex-direction: column;
    border-radius: ${theme.border.radius.small};
    background-color: ${theme.colors.white};
    height: 100%;

    @media (max-width: 1024px) {
      padding: 14px 14px 36px 14px;
    }
  `}
`
