import { LoadSecretaryRegisteredOrdersCount } from 'domain/usecases/secretary/load-secretary-registered-orders-count'
import { SecretaryRepository } from 'repository/interfaces/secretary-repository'

export class RemoteLoadSecretaryRegisteredOrdersCount
  implements LoadSecretaryRegisteredOrdersCount
{
  constructor(private readonly secretaryRepository: SecretaryRepository) {}

  count(
    params?: LoadSecretaryRegisteredOrdersCount.Params
  ): Promise<LoadSecretaryRegisteredOrdersCount.Model[]> {
    return this.secretaryRepository.countRegisteredOrders(params)
  }
}

export type LoadSecretaryRegisteredOrdersCountModel =
  LoadSecretaryRegisteredOrdersCount.Model
