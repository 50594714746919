import { cleanObject } from 'common/utils/cleanObject'
import { LoadSecretaryPendencies } from 'domain/usecases/secretary/load-secretary-pendencies'
import { useFormik } from 'formik'
import { ReactComponent as SearchIcon } from 'presentation/assets/icons/search.svg'
import Button from 'presentation/shared/components/Button'
import TextField from 'presentation/shared/components/TextField'
import React from 'react'
import * as S from './styles'

type FilterPendenciesProps = {
  onSubmit: (filter: LoadSecretaryPendencies.Params['filters']) => void
}

export default function FilterPendencies({ onSubmit }: FilterPendenciesProps) {
  const { handleChange, values, handleSubmit } = useFormik({
    initialValues: {} as Exclude<
      LoadSecretaryPendencies.Params['filters'],
      undefined
    >,
    onSubmit: async (values) => {
      onSubmit(cleanObject(values))
    }
  })

  return (
    <S.FiltersContainer role="form" onSubmit={handleSubmit}>
      <S.FiltersInput>
        <TextField
          name="patientName"
          id="patientName"
          bgColor="mainBg"
          placeholder="Busca por paciente"
          iconPosition="left"
          icon={<SearchIcon />}
          iconMargin="50px"
          iconLocale="inside"
          onChange={handleChange}
          value={values.patientName || ''}
        />
        <TextField
          name="doctorName"
          id="doctorName"
          bgColor="mainBg"
          placeholder="Busca por médico"
          iconPosition="left"
          icon={<SearchIcon />}
          iconMargin="50px"
          iconLocale="inside"
          onChange={handleChange}
          value={values.doctorName || ''}
        />
        <TextField
          name="surgicalOrderId"
          id="surgicalOrderId"
          bgColor="mainBg"
          placeholder="Busca por Número do aviso"
          iconPosition="left"
          icon={<SearchIcon />}
          iconMargin="50px"
          iconLocale="inside"
          type="number"
          min={0}
          onChange={handleChange}
          value={values.surgicalOrderId || ''}
        />
        <Button type="submit" fullWidth>
          Buscar
        </Button>
      </S.FiltersInput>
    </S.FiltersContainer>
  )
}
