import { SurgicalPendencyStatus } from 'domain/entities/surgical-pendency'
import PendencyStatusChip from 'presentation/secretary/components/Pendencies/PendencyStatusChip'
import { DateMinWidth } from 'presentation/shared/components/Table/DateColumn'
import React from 'react'

export const typedPendenciesColumns = [
  {
    name: 'surgical_pendency_id',
    label: ' ',
    options: { filter: false, sort: false, display: false }
  },
  {
    name: 'surgical_order_id',
    label: 'N° aviso',
    options: { filter: false, sort: false }
  },
  {
    name: 'patientName',
    label: 'Paciente',
    options: { filter: true, sort: false }
  },
  {
    name: 'surgery',
    label: 'Cirurgia',
    options: { filter: false, sort: false }
  },
  {
    name: 'doctorName',
    label: 'Médico',
    options: { filter: false, sort: false }
  },
  {
    name: 'createdAt',
    label: 'Data da Pendência',
    options: {
      filter: true,
      sort: false, // eslint-disable-next-line react/display-name
      customBodyRender: (value: string) => {
        return <DateMinWidth value={value} />
      }
    }
  },
  {
    name: 'requestedByName',
    label: 'Responsável CRMO',
    options: { filter: false, sort: false }
  },
  {
    name: 'answeredAt',
    label: 'Data da Resposta',
    options: {
      filter: true,
      sort: false, // eslint-disable-next-line react/display-name
      customBodyRender: (value: string) => {
        return <DateMinWidth value={value} />
      }
    }
  },
  {
    name: 'status',
    label: ' ',
    options: {
      filter: false,
      sort: false,
      // eslint-disable-next-line react/display-name
      customBodyRender: (value: SurgicalPendencyStatus) => {
        return <PendencyStatusChip status={value} />
      }
    }
  }
]
