import styled from 'styled-components'
import { lighten } from 'polished'

export const Wrapper = styled.div`
  height: 90vh;
  max-height: 90vh;
  width: 720px;
  max-width: 80vw;
  background-color: ${({ theme }) => theme.colors.mainBg};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 60px;
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 24px;
  }
`

export const Content = styled.section`
  flex: 1;
  overflow-y: auto;
  margin-bottom: 10px;
`

export const DocumentList = styled.div`
  background-color: white;
  width: 100%;
  border-radius: 10px;
  margin-top: 20px;

  li {
    height: 80px;
    align-items: center;
    flex-direction: row-reverse;

    p {
      font-size: 18px;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.gray};
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg {
        cursor: pointer;
      }

      button {
        margin-left: 40px;
        height: 40px;
        width: 155px;
      }
    }
  }
`

export const AddOtherReports = styled.div`
  height: 80px;
  width: 100%;
  background-color: #fdfdfd;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  border-radius: 10px;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => lighten(0.7, theme.colors.primary)};
  }
`

export const AddOtherReportsLabel = styled.label`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 500;
  cursor: pointer;
`

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    width: 200px;
  }
`
