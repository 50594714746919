import React from 'react'
import { useHistory } from 'react-router'

import * as S from './styles'

import { Patient } from 'domain/entities/patient-model'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import Container from 'presentation/hospital/components/Container'
import SurgicalOrderReview from 'presentation/secretary/components/Forms/AddSurgicalOrder/SurgicalOrderReview'
import { FormikProvider, useFormik } from 'formik'
import { useStores } from 'presentation/hooks/use-stores'
import { LoadSurgicalOrderDocument } from 'domain/usecases/surgical-order/load-surgical-order-document'
import { UpdateSurgicalOrderRegenerateGuides } from 'domain/usecases/surgical-order/update-surgical-order-regenerate-guides'
import { LoadHealthInsurancePlans } from 'domain/usecases/health-insurance/load-health-insurance-plans'

type Props = {
  patient?: Patient
  surgicalOrder?: SurgicalOrderModel
  loadSurgicalOrderDocument?: LoadSurgicalOrderDocument
  updateSurgicalOrderRegenerateGuides?: UpdateSurgicalOrderRegenerateGuides
  loadHealthInsurance?: LoadHealthInsurancePlans
}

export default function SurgicalOrderResumeLayout({
  patient,
  surgicalOrder,
  loadSurgicalOrderDocument,
  updateSurgicalOrderRegenerateGuides,
  loadHealthInsurance
}: Props) {
  const history = useHistory()
  const profile = useStores().currentAccount.getCurrentAccount().user.role
  const allergy =
    surgicalOrder?.allergy?.[0] === 'Látex'
      ? 'Latéx'
      : surgicalOrder?.allergy?.[0] === 'latex-not-informed'
      ? 'latex-not-informed'
      : 'false'
  const formik = useFormik({
    initialValues: {
      laterality: surgicalOrder?.laterality,
      freeze: surgicalOrder?.freeze,
      allergy: allergy,
      hospitalizationType: surgicalOrder?.hospitalizationType,
      hospitalizationMode: surgicalOrder?.hospitalizationMode,
      clinicalHistory: surgicalOrder?.clinicalHistory,
      providers: surgicalOrder?.opme?.providers,
      hospital: surgicalOrder?.hospital,
      patient: surgicalOrder?.patient,
      observations: surgicalOrder?.observation,
      surgery_center: { description: surgicalOrder?.surgicalCenter },
      procedures: surgicalOrder?.procedure?.map((procedure) => ({
        description: procedure.description,
        quantity: procedure.quantity.toString(),
        tuss_id: procedure.code
      })),
      opmes: surgicalOrder?.opme?.solicitations.map((solicitation) => ({
        description: solicitation.description,
        quantity: solicitation.quantity.toString()
      })),
      cids: surgicalOrder?.cid?.map((cid) => ({
        cid_id: cid.code,
        description: cid.description
      })),
      healthInsurance: surgicalOrder?.healthInsurance,
      doctor: surgicalOrder?.doctor,
      createdAt: surgicalOrder?.createdAt,
      documents_attached: surgicalOrder?.documents?.map((document) => ({
        ...document,
        label: document.name
      })),
      patientHospitalized: surgicalOrder?.patientHospitalized,
      expectedDate: surgicalOrder?.expectedDate,
      cmes: surgicalOrder?.cme,
      medicalTeam: surgicalOrder?.medicalTeam,
      anesthetistTeam: surgicalOrder?.anesthetistTeam,
      hospitalizationAvgTimeInDays: surgicalOrder?.hospitalizationAvgTimeInDays,
      equipments: surgicalOrder?.equipment?.reduce((acc, current) => {
        acc[current.name] = { ...current, enabled: true }
        return acc
      }, {}),
      patientOrigin: {
        id: surgicalOrder?.patientOriginId,
        description: surgicalOrder?.patientOriginDescription
      },
      patientClassificationType: {
        id: surgicalOrder?.patientClassificationTypeId,
        description: surgicalOrder?.patientClassificationTypeDescription
      },
      agendaClassification: {
        id: surgicalOrder?.agendaClassificationId,
        description: surgicalOrder?.agendaClassificationDescription
      }
    },
    onSubmit: () => history.goBack()
  })

  return (
    <Container>
      <S.Wrapper>
        <FormikProvider value={formik}>
          <SurgicalOrderReview
            patient={patient}
            goBack={history.goBack}
            onlyVisualization
            profile={profile}
            surgical_order_id={surgicalOrder?.surgical_order_id}
            loadSurgicalOrderDocument={loadSurgicalOrderDocument}
            surgicalOrder={surgicalOrder?.status}
            updateSurgicalOrderRegenerateGuides={
              updateSurgicalOrderRegenerateGuides
            }
            loadHealthInsurance={loadHealthInsurance}
          />
        </FormikProvider>
      </S.Wrapper>
    </Container>
  )
}
