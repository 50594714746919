import React from 'react'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { SurgicalOrderResume } from 'presentation/secretary/pages/SurgicalOrderResume'
import { ApiRepository } from 'repository/api-repository'
import { SurgicalOrderRepository } from 'repository/repositories/surgical-order/surgical-order-repository'
import { RemoteLoadSurgicalOrder } from 'service/usecases/load-surgical-order/remote-load-surgical-order'
import { RemoteLoadSurgicalOrderDocument } from 'service/usecases/load-surgical-order-document/load-upload-surgical-order-document'

export default function makeSecretaryOrderAbstract() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )
  const surgicalOrderRepository = new SurgicalOrderRepository(apiRepository)
  const loadSurgicalOrder = new RemoteLoadSurgicalOrder(surgicalOrderRepository)
  const loadSurgicalOrderDocument = new RemoteLoadSurgicalOrderDocument(
    surgicalOrderRepository
  )

  return (
    <SurgicalOrderResume
      loadOrder={loadSurgicalOrder}
      loadSurgicalOrderDocument={loadSurgicalOrderDocument}
    />
  )
}
