import styled, { css } from 'styled-components'

interface ContentProps {
  disableScroll?: boolean
}
export const Content = styled.main<ContentProps>`
  ${({ theme, disableScroll }) => css`
    padding: 1rem 1.5rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    width: 100%;
    height: 100%;
    overflow-y: ${disableScroll ? 'none' : 'auto'};
    max-height: 100%;
    background-color: ${theme.colors?.mainBg};
    margin-left: 80px;
  `}
`
