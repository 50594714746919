export class SurgeryCenter {
  private _codeCenter: number
  private _description: string
  private _hospital_id: number
  private _rooms: SurgeryCenterRooms[] = []

  constructor(
    codeCenter: number,
    description: string,
    hospital_id: number,
    rooms?: SurgeryCenterRooms[]
  ) {
    this._codeCenter = codeCenter
    this._description = description
    this._hospital_id = hospital_id
    this._rooms = rooms ?? []
  }

  public get codeCenter(): number {
    return this._codeCenter
  }

  public set codeCenter(value: number) {
    this._codeCenter = value
  }

  public get description(): string {
    return this._description
  }

  public set description(value: string) {
    this._description = value
  }

  public get hospital_id(): number {
    return this._hospital_id
  }

  public set hospital_id(value: number) {
    this._hospital_id = value
  }

  public get rooms(): SurgeryCenterRooms[] {
    return this._rooms
  }
  public set rooms(value: SurgeryCenterRooms[]) {
    this._rooms = value
  }

  toJSON() {
    return {
      codeCenter: this._codeCenter,
      description: this._description,
      rooms: this._rooms
    }
  }
}

type SurgeryCenterRooms = {
  id: number
  description: string
}
