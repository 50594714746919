import React, { useState } from 'react'

import Modal from 'presentation/hospital/components/Modal'
import Heading from 'presentation/shared/components/Heading'
import * as S from './styles'
import Button from 'presentation/shared/components/Button'
import Table from 'presentation/shared/components/Table'
import { Patient } from 'domain/entities/patient-model'
import { Radio } from '@material-ui/core'
import moment from 'moment-timezone'
import theme from 'presentation/styles/theme'

type Props = {
  close: () => void
  patients: Patient[]
  selectPatient: (patient: Patient) => void
}

export default function SearchPatientByNameModal({
  close,
  patients,
  selectPatient
}: Props) {
  const [selectedPatient, setSelectedPatient] = useState<Patient>({} as Patient)
  return (
    <Modal close={close}>
      <S.Wrapper>
        <Heading size="large" as="h1" color="primary">
          Pacientes
        </Heading>
        <Table
          columns={columns}
          pagination={false}
          data={patients.map((patient) => ({
            ...patient,
            birthday: moment(patient.birthday).utc().format('DD/MM/YYYY'),
            add: (
              <Radio
                value={patient.patient_id}
                name="procedure"
                checked={selectedPatient?.patient_id === patient.patient_id}
                onChange={() => setSelectedPatient(patient)}
                data-testid={`select-patient-radio`}
                style={{ color: theme.colors.primary }}
              />
            )
          }))}
        />
        <S.Buttons>
          <Button
            variant="outlined"
            type="button"
            onClick={close}
            data-testid={`cancel-button`}
          >
            Cancelar
          </Button>
          <Button
            type="button"
            disabled={!selectedPatient?.patient_id}
            onClick={() => {
              selectPatient(selectedPatient)
              close()
            }}
            data-testid={`add-button`}
          >
            Adicionar
          </Button>
        </S.Buttons>
      </S.Wrapper>
    </Modal>
  )
}

const columns = [
  {
    name: 'name',
    label: 'Paciente',
    options: {
      sort: false
    }
  },
  {
    name: 'birthday',
    label: 'Dt. de nascimento',
    options: {
      sort: false
    }
  },
  {
    name: 'email',
    label: 'E-mail',
    options: {
      sort: false
    }
  },
  {
    name: 'phone',
    label: 'Telefone',
    options: {
      sort: false
    }
  },
  {
    name: 'add',
    label: 'Add',
    options: {
      sort: false
    }
  }
]
