import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 90vh;
  max-height: 90vh;
  width: 720px;
  max-width: 80vw;
  padding: 60px;
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 36px;
  }
`

export const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  margin-bottom: 10px;

  textarea {
    background-color: #f0f6ef;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
`

export const Complement = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
  color: ${({ theme }) => theme.colors.primary};

  h2 {
    font-size: ${({ theme }) => theme.font.sizes.medium};
    text-transform: uppercase;
  }

  h3 {
    text-transform: uppercase;
    font-size: ${({ theme }) => theme.font.sizes.small};
  }

  div {
    display: flex;
  }
`

export const Item = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.font.sizes.xsmall};
  margin-right: 12px;

  span {
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 4px;
  }

  p {
    color: ${({ theme }) => theme.colors.gray};
    font-weight: 600;
  }
`

export const InputsContainer = styled.div``

export const Documents = styled.div``

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    width: 200px;
  }
`
