import styled, { css } from 'styled-components'

import { ReactComponent as Dots } from 'presentation/assets/icons/three-dots.svg'
import { MenuItem } from '@material-ui/core'
import { Tooltip } from '../../../shared/components/Tooltip'
import { lighten } from 'polished'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .MuiTableCell-root {
    padding: 10px;
  }
`

type FiltersContainerProps = {
  initialSurgeryStatus?: string
}

type WarningCellProps = {
  isChecked?: boolean
}

type TecCellProps = {
  type: 'robótica' | 'convencional' | 'vídeo' | 'INT' | string
}

export const FiltersContainer = styled.form<FiltersContainerProps>`
  display: grid;
  grid-template-columns: 1.2fr 1fr 125px;

  ${(props) =>
    props.initialSurgeryStatus &&
    css`
      grid-template-columns: 6fr 1fr;
      grid-gap: 0 25px;
    `}
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 6px;
  padding: 1rem;

  @media (max-width: 1400px) {
    grid-template-columns: 1fr;
    grid-gap: 12px;
  }
`

export const FiltersInput = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;
  place-content: center;
  gap: 1rem;
`

export const FiltersInputGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0 12px;
  width: 100%;
`

export const FiltersRadio = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 120px;
  overflow-y: auto;
  & label {
    margin: 6px 10px;
  }
`

type FiltersButtoProps = {
  initialSurgeryStatus?: string
}

export const FiltersButton = styled.div<FiltersButtoProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  ${(props) =>
    !props.initialSurgeryStatus &&
    css`
      height: 90%;

      @media (max-width: 1600px) {
        height: 60%;
      }
    `}

  @media(max-width: 1400px) {
    width: 50%;
    align-items: center;
    flex-direction: row;
    height: 60%;

    button:first-child {
      margin-right: 12px;
    }
  }
`

export const TecCell = styled.div<TecCellProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4rem;
  border-radius: 4px;
  color: ${({ type }) => (type === 'convencional' ? '#000' : '#fff')};
  text-transform: uppercase;
  font-weight: ${({ type }) => (type === 'convencional' ? 'normal' : 'bold')};

  background: ${({ type, theme }) => {
    if (type === 'INT') return theme.colors.lightGreen
    if (type === 'ROB') return '#89A7E0'
    if (type) return theme.colors.lightRed
  }};
`

export const WarningCell = styled.div<WarningCellProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isChecked, theme }) =>
    isChecked &&
    css`
      &:before {
        content: '';
        margin-right: 5px;
        height: 10px;
        width: 10px;
        background-color: ${theme.colors.lightGreen};
        border-radius: 50%;
        display: inline-block;
      }
    `};
`
export const StatusContainer = styled.div`
  width: 160px;
  padding: 0.2rem;
  border: 2px solid;
  border-color: ${({ theme }) => theme.colors.primaryDarker};
  border-radius: 20px;
`

export const DotsIcon = styled(Dots)`
  cursor: pointer;
  path {
    fill: #c0c0c0;
  }
`

export const AlertTooltip = styled(Tooltip)`
  background: #000;
`

export const StyledItem = styled(MenuItem)`
  color: ${({ theme }) => theme.colors.primary}!important;
  font-weight: ${({ theme }) => theme.font.bold}!important;
  border-radius: 8px !important;
  padding: 8px !important;
  margin-left: 8px !important;
  margin-right: 8px !important;

  span {
    margin-left: 8px;
  }

  circle {
    display: none;
  }

  &:hover {
    background-color: ${({ theme }) =>
      lighten(0.5, theme.colors.primary)} !important;
  }

  svg {
    width: 30px;
    height: 30px;
  }
`

export const PendencyContainer = styled.div<{ color: string }>`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  margin: auto;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 400;
  font-size: 18px;
`
