import React from 'react'
import { useFormik } from 'formik'
import TextField from 'presentation/shared/components/TextField'
import Button from 'presentation/shared/components/Button'
import * as S from './styles'
import { fields } from './fields'
import Heading from 'presentation/shared/components/Heading'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router'
import { User } from 'domain/entities/user-model'
import { useServices } from 'presentation/hooks/use-services'

export type Props = {
  data: User
  setOpenSuccessModal: React.Dispatch<React.SetStateAction<boolean>>
}

export default function UpdateSecretaryProfileForm({
  data,
  setOpenSuccessModal
}: Props) {
  const [blockEdit, setBlockEdit] = React.useState<boolean>(true)

  const history = useHistory()
  const userService = useServices().user

  const formik = useFormik({
    initialValues: {
      name: data.name,
      email: data.email,
      landlinePhone: data.landlinePhone,
      phone: data.phone,
      cpf: data.cpf
    },
    onSubmit: async (values) => {
      try {
        await userService.updateUser(values)

        setTimeout(() => {
          history.push('/home')
        }, 2000)
        setOpenSuccessModal(true)
      } catch (error: any) {
        toast.error(error.message)
      }
    }
  })

  const changeEnableForm = () => {
    if (blockEdit === false) {
      formik.resetForm()
    }
    setBlockEdit(!blockEdit)
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Heading size="huge" color="primary" style={{ marginBottom: '48px' }}>
        Dados pessoais
      </Heading>
      <S.Wrapper>
        {fields.map(({ name, placeholder, label, mask }) => (
          <TextField
            disabled={blockEdit || name === 'cpf' || name === 'nickname'}
            key={name}
            bgColor="mainBg"
            label={label}
            mask={mask || undefined}
            // @ts-ignore: Unreachable code error
            value={formik.values[name]}
            placeholder={placeholder}
            name={name}
            onInputChange={formik.handleChange(name)}
            onBlur={formik.handleBlur(name)}
          />
        ))}
      </S.Wrapper>

      <S.Actions>
        <Button
          type="button"
          variant={!blockEdit ? 'outlined' : 'white'}
          style={{ marginRight: '6px', maxWidth: '150px' }}
          onClick={() => changeEnableForm()}
        >
          {blockEdit ? 'Editar' : 'Descartar'}
        </Button>
        <Button
          disabled={blockEdit}
          onClick={() => formik.handleSubmit}
          style={{ maxWidth: '150px' }}
        >
          Salvar
        </Button>
      </S.Actions>
    </form>
  )
}
