import { PaginationModel } from 'domain/entities/pagination-model'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import {
  LoadSecretaryScheduleSurgicalOrdersSearch,
  LoadSecretaryScheduleSurgicalOrdersStatus
} from 'domain/usecases/secretary/load-secretary-schedule-surgical-orders'
import getTittleBySearchType from 'presentation/doctor/layouts/SurgeryOrderList/Constants/get-title'
import Container from 'presentation/hospital/components/Container'
import FilterScheduleSurgicalOrders from 'presentation/secretary/components/ScheduleSurgicalOrders/FilterScheduleSurgicalOrders'
import { mapSurgicalOrderColumn } from 'presentation/secretary/utils/SurgicalOrderTable/adaptersSurgicalOrderColumn'
import { surgicalOrdersColumns } from 'presentation/secretary/utils/SurgicalOrderTable/surgicalOrdersColumns'
import ActualPage from 'presentation/shared/components/ActualPage'
import Heading from 'presentation/shared/components/Heading'
import Table from 'presentation/shared/components/Table'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

type SecretarySurgicalOrdersLayoutProps = {
  surgicalOrders: SurgicalOrderModel[]
  fetchSurgicalOrder: (
    currentPage: number,
    filter?: LoadSecretaryScheduleSurgicalOrdersSearch,
    itemsPerPage?: number
  ) => void
  loadingSurgicalOrder?: boolean
  pagination: PaginationModel
  orderStatus: LoadSecretaryScheduleSurgicalOrdersStatus
}

export default function SecretaryScheduleSurgicalOrdersLayout({
  surgicalOrders,
  fetchSurgicalOrder,
  loadingSurgicalOrder,
  pagination,
  orderStatus
}: SecretarySurgicalOrdersLayoutProps) {
  const history = useHistory()
  const [filter, setFilter] =
    useState<LoadSecretaryScheduleSurgicalOrdersSearch>()

  const onPaginate = async (currentPage: number, itemsPerPage?: number) => {
    fetchSurgicalOrder(currentPage, filter, itemsPerPage)
    return undefined
  }

  const adapterData = mapSurgicalOrderColumn(surgicalOrders)

  const title = getTittleBySearchType(orderStatus)

  const onHospitalChange = () => {
    fetchSurgicalOrder(1, filter)
  }

  return (
    <Container onHospitalChange={onHospitalChange}>
      <ActualPage onClick={() => history.goBack()} text="Voltar" />
      <Heading size="huge" color="primary" style={{ marginBottom: '15px' }}>
        {title}
      </Heading>
      <FilterScheduleSurgicalOrders
        filterSubmit={(values) => {
          setFilter(values)
          fetchSurgicalOrder(1, values)
        }}
      />
      <Table
        title={title}
        columns={surgicalOrdersColumns}
        data={adapterData}
        count={pagination.totalItems}
        rowsPerPage={pagination.itemsPerPage}
        onPaginate={onPaginate}
        loading={!!loadingSurgicalOrder}
      />
    </Container>
  )
}
