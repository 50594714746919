import moment from 'moment'

const getLifeDaysByBirthdayDate = (birthdayDate: string | undefined) => {
  try {
    if (!birthdayDate) {
      return null
    }
    if (birthdayDate?.length === 10) {
      const totalAge = moment().diff(moment(birthdayDate, 'DD/MM/YYYY'), 'days')

      if (totalAge < 0 || isNaN(totalAge)) return 0
      return totalAge
    }
    return moment().diff(
      moment(new Date(birthdayDate).toLocaleDateString('pt-br'), 'DD/MM/YYYY'),
      'days'
    )
  } catch (err) {
    return null
  }
}

export default getLifeDaysByBirthdayDate
