import styled, { css } from 'styled-components'

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0px 24px;
  padding-bottom: 40px;
`

export const Step = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    h1 {
      text-align: center;
      line-height: 28.32px;
      padding-top: 40px;
      strong {
        color: ${theme.colors.primary};
      }
    }

    header p {
      text-align: center;
    }
  `}
`

export const CodeInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 300px;
  & > div {
    width: max(70px, 25%);
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  input {
    width: 100%;
    text-align: center;
    padding: 0;
  }
`

export const CodeInputWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
`
