import React, { useEffect } from 'react'

import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  withRouter,
  useLocation
} from 'react-router-dom'

import { useStores } from 'presentation/hooks/use-stores'
import makeSecretaryLogin from 'main/factories/pages/secretary/login/secretary-login-factory'
import makeSecretaryPasswordRecovery from 'main/factories/pages/secretary/password-recovery/secretary-password-recovery-factory'
import makeSecretarySignup from 'main/factories/pages/secretary/signup/secretary-signup-factory'
import makeSecretaryHome from 'main/factories/pages/secretary/home/secretary-home-factory'
import makeSecretaryAuthorization from 'main/factories/pages/secretary/authorization/secretary-authorization-factory'
import makeSecretaryAddSurgicalOrder from 'main/factories/pages/secretary/add-surgical-order/secretary-add-surgical-order-factory'
import makeSecretaryOrderDetails from 'main/factories/pages/secretary/order-details/secretary-order-details-factory'
import PatientInfo from 'presentation/secretary/pages/PatientInfo'
import ProcedureInfo from 'presentation/secretary/pages/ProcedureInfo'
import PrivateRoute from 'presentation/shared/components/PrivateRoute'
import { Profile } from 'common/enum/profile'
import makeSecretaryOrderAbstract from 'main/factories/pages/secretary/order-abstract/secretary-order-abstract-factory'
import makeSecretaryProfile from 'main/factories/pages/secretary/profile/secretary-profile-factory'
import makeSecretaryPendenciesFactory from 'main/factories/pages/secretary/pendencies/secretary-pendencies-factory'
import { SurgicalOrderDetails } from 'presentation/secretary/pages/SurgicalOrderDetails'
import { SecretaryTypedPendencies } from 'presentation/secretary/pages/TypedPendencies'
import makeSecretaryLoadScheduleSurgicalOrders from 'main/factories/pages/secretary/schedule-surgical-orders/secretary-schedule-surgical-orders-factory'
import makeSecretaryFavoritesFactory from 'main/factories/pages/secretary/favorites/secretary-favorites-factory'
import { Hospital } from 'domain/entities/hospital-model'

export const ScrollToTop = withRouter(() => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
})

export default function SecretaryRouter() {
  const permissions = useStores().permissions
  const stores = useStores()
  const currentUser = stores.currentAccount
  const surgicalstatus = stores.surgicalstatus
  const hospital = stores.currentHospital

  useEffect(() => {
    if (!window.location.pathname.match('login')) {
      permissions.load()
      surgicalstatus.load()
      const account = currentUser.getCurrentAccount()
      const hospitals: Hospital[] =
        account?.user.hospitals?.map(
          (hospital) =>
            new Hospital(hospital.hospital_id, hospital.nickname, hospital.name)
        ) ?? []
      hospital.setHospitalList(hospitals)
    }
  }, [])

  return (
    <BrowserRouter
      basename={process.env.NODE_ENV === 'development' ? '/secretary' : ''}
    >
      <ScrollToTop />
      <Switch>
        <Route
          path="/"
          exact
          component={() => {
            return (
              <>
                <Redirect
                  to={
                    currentUser.getCurrentAccount()?.accessToken
                      ? '/home'
                      : '/login'
                  }
                />
              </>
            )
          }}
        />
        <Route path="/login" exact component={makeSecretaryLogin} />
        <PrivateRoute
          path="/home"
          exact
          component={makeSecretaryHome}
          profile={[Profile.SECRETARY]}
        />
        <Route path="/pedidos" exact component={makeSecretaryAuthorization} />
        <Route
          path="/recuperar-senha/:type"
          exact
          component={makeSecretaryPasswordRecovery}
        />
        <Route path="/cadastro/:hash" exact component={makeSecretarySignup} />
        <Route path="/pedido" exact component={makeSecretaryOrderDetails} />
        <Route
          path="/pedido/novo"
          exact
          component={makeSecretaryAddSurgicalOrder}
        />
        <Route path="/paciente" exact component={PatientInfo} />
        <Route path="/procedimento" exact component={ProcedureInfo} />
        <Route
          path="/pedido/resumo"
          exact
          component={makeSecretaryOrderAbstract}
        />
        <Route path="/perfil/:id" exact component={makeSecretaryProfile} />
        <Route
          path="/pendencies"
          exact
          component={makeSecretaryPendenciesFactory}
        />
        <Route path="/pedido/detalhes" exact component={SurgicalOrderDetails} />
        <Route
          path="/pendencias/:type"
          exact
          component={SecretaryTypedPendencies}
        />
        <Route
          path="/pedidos/agendamentos"
          exact
          component={makeSecretaryLoadScheduleSurgicalOrders}
        />
        <Route
          path="/favoritos"
          exact
          component={makeSecretaryFavoritesFactory}
        />
      </Switch>
    </BrowserRouter>
  )
}
