import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  padding: 0 1em 1em 1em;
  height: 100%;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1320px;
  margin: 0 auto;
  margin-top: 22px;

  h1 {
    font-size: 36px;
    text-transform: capitalize;
  }

  h2 {
    font-size: 36px;
  }
`

export const Item = styled.div`
  display: flex;
  align-items: center;
`

export const ItemLabel = styled.label`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 600;
  margin-right: 4px;
`

export const ItemContent = styled.p`
  color: ${({ theme }) => theme.colors.primary};
`

export const Details = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 24px;
`

export const UploadModal = styled.div`
  text-align: left;
  position: relative;
  height: 100%;
  width: 100%;
`

export const UploadHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  justify-content: space-between;
`

export const DownloadAllDocumens = styled.button`
  display: flex;
  align-items: center;
  border: 0;
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;

  img {
    height: 22px;
    margin-right: 8px;
  }

  label {
    font-size: ${({ theme }) => theme.font.sizes.small};
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;

    :hover {
      text-decoration: underline;
    }
  }
`

export const UploadOverview = styled.div`
  display: flex;

  section {
    display: flex;
    flex-direction: column;
  }

  section:last-child {
    margin-left: 48px;
  }
`

export const UploadDocuments = styled.div`
  margin-top: 24px;
`

export const UploadActions = styled.div`
  position: absolute;
  display: flex;
  right: 0;
  bottom: 31px;

  button {
    width: 200px;
  }

  button + button {
    margin-left: 1em;
  }
`

export const DocumentsArea = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  max-height: 350px;
  overflow-x: auto;

  div {
    margin-right: 20px;
    margin-bottom: 20px;
  }
`

type DocumentItemProps = {
  bgImg?: string
}

export const DocumentItem = styled.div<DocumentItemProps>`
  height: 140px;
  width: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: 4px solid ${({ theme }) => theme.colors.gray};

  ${(props) =>
    props.bgImg &&
    css`
      background-image: ${`url(${props.bgImg})`};
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: center;
      box-shadow: inset 0 0 0 2000px rgba(128, 128, 128, 0.6);
    `}

  border-radius: 10px;
  position: relative;

  section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  label {
    font-size: ${({ theme }) => theme.font.sizes.xxsmall};
    color: ${({ theme }) => theme.colors.white};
    font-weight: 600;
    text-align: center;
    max-width: 70%;
    margin-left: 4px;
  }

  p {
    font-size: ${({ theme }) => theme.font.sizes.xxsmall};
    color: ${({ theme }) => theme.colors.white};
    font-weight: 600;
    text-align: center;
    position: absolute;
    bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 138px;
    padding: 4px 2px;
    background-color: ${({ theme }) => theme.colors.gray};
  }

  input {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`

export const DocumentActions = styled.div`
  display: flex;
  flex-direction: column;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background: ${({ theme }) => theme.colors.white};
    cursor: pointer;

    img {
      height: 22px;
      margin-right: 4px;
    }

    label {
      font-size: ${({ theme }) => theme.font.sizes.small};
      font-weight: 500;
      color: ${({ theme }) => theme.colors.primary};
      width: 65px;
      cursor: pointer;

      :hover {
        text-decoration: underline;
      }
    }
  }

  button + button {
    margin-top: 6px;
  }
`
