import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  padding: 1rem 2.2rem;

  p {
    font-size: ${({ theme }) => theme.font.sizes.small};
  }
`

export const DocumentsList = styled.div`
  ${({ theme }) => css`
    background-color: white;
    margin-top: 24px;
    border-radius: ${theme.border.radius.small};

    li {
      flex-direction: row-reverse;
      padding-left: 0;
      font-size: ${({ theme }) => theme.font.sizes.small};
      font-weight: 500;
    }

    img.icon {
      width: 28px;
    }
  `}
`
