import React, { useState, useEffect } from 'react'

import { useFormik } from 'formik'
import { isEqual } from 'lodash'

import * as S from './styles'
import TextField from 'presentation/shared/components/TextField'
import Button from 'presentation/shared/components/Button'
import Table from 'presentation/shared/components/Table'
import { Cid } from 'domain/entities/cid'
import { Tuss } from 'domain/entities/tuss'
import { toast } from 'react-toastify'
import AutoComplete from 'presentation/shared/components/AutoComplete'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { useAddSurgicalOrderFunctions } from 'presentation/hospital/pages/AddSurgicalOrder/load-surgical-order'
import { ReactComponent as SearchIcon } from 'presentation/assets/icons/search.svg'
import { ReactComponent as PlusIcon } from 'presentation/assets/icons/plus.svg'
import TextArea from 'presentation/shared/components/TextArea'
import { Backspace } from '@material-ui/icons'
import { Profile } from 'common/enum/profile'

type Props = {
  selectedProcedures: any[]
  setSelectedProcedures?: (procedure: any) => void
  selectedCids: any[]
  setSelectedCids?: (cid: any) => void
  cid?: any[]
  setCid: (procedure: any) => void
  cidList?: Cid[]
  tussList?: Tuss[]
  doctorName?: string
  onlyVisualization?: boolean
  clinicalHistory?: string
  setClinicalHistory: (clinicalHistory: string) => void
  profile: Profile
}

export const TussTable = ({
  cidList,
  tussList,
  doctorName,
  onlyVisualization,
  selectedProcedures,
  setSelectedProcedures,
  selectedCids,
  setSelectedCids,
  clinicalHistory,
  setClinicalHistory,
  profile
}: Props) => {
  const [procedures, setProcedure] = useState({} as any)
  const [selectedCid, setSelectedCid] = useState({} as any)
  const [tussQuery, setTussQuery] = useState<string>()
  const [cidQuery, setCidQuery] = useState<string>()

  const context = useAddSurgicalOrderFunctions()

  useEffect(() => {
    const favorite: {
      procedure: {
        doctor_name: string
        procedure_code: string
        isMain: string
        quantity: string
        pro_fat_id: string
        description: string
        surgery_id: string
      }[]

      cid: {
        code: string
        description: string
      }[]
    } = makeLocalStorageAdapter().get('favorite')

    if (favorite && Object.keys(favorite).length > 0) {
      const formattedFavorite: {
        doctor: string
        tuss_id: string
        principalProcedure: string
        quantity: string
        pro_fat_id: string
        description: string
        surgery_id: string
      }[] = favorite.procedure.map(
        ({
          doctor_name: doctor,
          procedure_code: tuss_id,
          isMain: principalProcedure,
          ...favorite
        }) => ({
          doctor,
          tuss_id,
          principalProcedure,
          ...favorite
        })
      )

      formattedFavorite.forEach((favorite) => {
        favorite.principalProcedure = favorite.principalProcedure
          ? 'Procedimento Principal'
          : ''
      })

      setSelectedProcedures && setSelectedProcedures(formattedFavorite)

      const formattedCid: {
        cid_id: string
        description: string
      }[] = favorite.cid.map(({ code: cid_id, ...cid }) => ({
        cid_id,
        ...cid
      }))

      setSelectedCids && setSelectedCids(formattedCid)
    }
  }, [])

  const formik = useFormik({
    initialValues: {
      quantity: 1
    },
    onSubmit: (values) => {
      if (procedures?.tuss_id) {
        setSelectedProcedures &&
          setSelectedProcedures([
            ...(selectedProcedures ?? []),
            {
              tuss_id: procedures.tuss_id,
              description: procedures.description,
              principalProcedure: procedures.principalProcedure,
              quantity: Number(values.quantity),
              surgery_id: procedures.surgery_id,
              doctor: doctorName,
              pro_fat_id: procedures.pro_fat_id
            }
          ])
      }
      setProcedure(null)
      setTussQuery('')
      formik.resetForm()
    }
  })

  const addProcedure = (procedure: any) => {
    const newProcedure = Object.assign({}, procedure)
    if (selectedProcedures.length === 0) {
      newProcedure.principalProcedure = 'Procedimento Principal'
    }
    if (selectedProcedures.find((val) => val.tuss_id === procedure.tuss_id)) {
      toast.error('Procedimento já adicionado')
    } else {
      setTussQuery(procedure.tuss_id)
      setProcedure({ ...newProcedure, description: procedure.description })
    }
  }

  const addCid = (procedure: any) => {
    if (selectedCids?.find((val) => val.cid_id === procedure.cid_id)) {
      return toast.error('CID já inserido')
    }
    setCidQuery(procedure.cid_id)
    setSelectedCid(procedure)
  }

  const submitCid = () => {
    if (selectedCid?.cid_id) {
      setSelectedCids &&
        setSelectedCids([
          ...(selectedCids ?? []),
          {
            cid_id: selectedCid.cid_id,
            description: selectedCid.description
          }
        ])
    }
    setSelectedCid(null)
    setCidQuery('')
  }

  const onDeleteRow = (index: number[]) => {
    setSelectedProcedures &&
      setSelectedProcedures(
        selectedProcedures
          ?.filter(
            (procedure, procedureIndex) => !index.includes(procedureIndex)
          )
          .map((procedures, index) => {
            if (index === 0) {
              return {
                ...procedures,
                principalProcedure: 'Procedimento Principal'
              }
            } else {
              return { ...procedures }
            }
          })
      )
  }

  const onDeleteCid = (index: number[]) => {
    setSelectedCids &&
      setSelectedCids(
        selectedCids?.filter(
          (procedure, procedureIndex) => !index.includes(procedureIndex)
        )
      )
  }

  const isValid = () => {
    return !!(
      formik.values.quantity > 0 &&
      procedures?.tuss_id &&
      procedures?.description
    )
  }

  return (
    <S.Wrapper>
      {!onlyVisualization && (
        <S.Inputs>
          <S.InputButtonContainer>
            <AutoComplete
              label="Código Tuss"
              labelColor="gray"
              placeholder="TUSS"
              value={tussQuery}
              onSuggestionClick={addProcedure}
              style={{ width: '100%' }}
              onType={(val) => context?.loadSurgeries?.(1, 5, val)}
              onInputChange={setTussQuery}
              suggestions={tussList?.map((tuss) => ({
                label: tuss.tuss_id + ' - ' + tuss.description,
                value: tuss
              }))}
              disabled={!!procedures?.tuss_id}
              bgColor="mainBg"
              icon={<SearchIcon />}
              iconPosition="right"
              iconLocale="inside"
              required
              data-testid="select-tuss-autocomplete"
            />
            {!!procedures?.tuss_id && (
              <Button
                backgroundColor="lightGray"
                onClick={() => {
                  setProcedure(undefined)
                  setTussQuery('')
                }}
                title="Limpar procedimento selecionado"
              >
                <Backspace style={{ width: '30px', height: '24px' }} />
              </Button>
            )}
          </S.InputButtonContainer>
          <TextField
            label="Descrição"
            labelColor="gray"
            value={procedures?.description || ''}
            placeholder="Nome do procedimento"
            style={{ width: '120%', marginLeft: '12px' }}
            disabled
            data-testid={`tuss-description-disabled-input`}
          />
          <S.QuantityContainer>
            <S.Title>Quantidade</S.Title>
            <TextField
              onInputChange={formik.handleChange('quantity')}
              value={formik.values.quantity}
              type="number"
              bgColor="mainBg"
              inputSize="6rem"
              min={1}
              data-testid={`tuss-quantity-input`}
              required
            />
          </S.QuantityContainer>
          <Button
            type="button"
            disabled={!isValid()}
            onClick={formik.submitForm}
            style={{ maxWidth: '120px' }}
            data-testid={`add-tuss-button`}
          >
            <PlusIcon width="12px" height="12px" />
            Adicionar
          </Button>
        </S.Inputs>
      )}
      <Table
        checkbox={!onlyVisualization}
        data={selectedProcedures}
        columns={procedureColumns}
        rowsDelete={onDeleteRow}
        pagination={false}
        noItemsDescription="Nenhum procedimento adicionado"
      />
      <div style={{ marginTop: '20px' }}>
        <label>
          História Clínica
          {profile !== 'RECEPTIONIST' && <span>*</span>}
        </label>
        <div>
          <TextArea
            bgColor="mainBg"
            style={{ width: '100%' }}
            rows={5}
            onChange={(e) => setClinicalHistory(e.currentTarget.value)}
            value={clinicalHistory}
            disabled={onlyVisualization}
            dataTestId={`clinical-history-input`}
          />
        </div>
      </div>
      <br />
      {!onlyVisualization && (
        <S.Inputs>
          <S.InputButtonContainer>
            <AutoComplete
              label="CID"
              labelColor="gray"
              placeholder="CID"
              value={cidQuery}
              onSuggestionClick={addCid}
              style={{ width: '100%' }}
              onType={(val) => context?.loadCid?.(1, 5, val)}
              onInputChange={setCidQuery}
              suggestions={cidList?.map((cid) => ({
                label: cid.cid_id + ' - ' + cid.description,
                value: cid
              }))}
              disabled={!!selectedCid?.cid_id}
              bgColor="mainBg"
              icon={<SearchIcon />}
              iconPosition="right"
              iconLocale="inside"
              required={profile !== 'RECEPTIONIST'}
              data-testid="select-cid-autocomplete"
            />
            {!!selectedCid?.cid_id && (
              <Button
                backgroundColor="lightGray"
                onClick={() => {
                  setSelectedCid(undefined)
                  setCidQuery('')
                }}
                title="Limpar CID selecionado"
              >
                <Backspace style={{ width: '30px', height: '24px' }} />
              </Button>
            )}
          </S.InputButtonContainer>
          <TextField
            value={selectedCid?.description || ''}
            placeholder="Descrição"
            style={{ width: '160%', margin: '0 12px' }}
            disabled
            data-testid={`cid-description-disabled-input`}
          />
          <Button
            type="button"
            disabled={!selectedCid?.cid_id}
            onClick={submitCid}
            style={{ maxWidth: '120px' }}
            data-testid={`add-cid-button`}
          >
            <PlusIcon width="12px" height="12px" style={{ color: 'white' }} />
            Adicionar
          </Button>
        </S.Inputs>
      )}
      <Table
        checkbox={!onlyVisualization}
        data={selectedCids}
        columns={cidColumns}
        rowsDelete={onDeleteCid}
        pagination={false}
        noItemsDescription="Nenhum CID adicionado"
      />
    </S.Wrapper>
  )
}

function dataCompare(prevData: Props, nextData: Props) {
  const prev = {
    cids: prevData.cidList,
    tuss: prevData.tussList,
    selectedTuss: prevData.selectedProcedures,
    selectedCids: prevData.cid
  }

  const next = {
    cids: nextData.cidList,
    tuss: nextData.tussList,
    selectedTuss: nextData.selectedProcedures,
    selectedCids: nextData.cid
  }
  return isEqual(prev, next)
}

export default React.memo(TussTable, dataCompare)

export const procedureColumns = [
  {
    label: 'Procedimento principal',
    name: 'principalProcedure',
    options: {
      sort: false
    }
  },
  {
    label: 'Código',
    name: 'tuss_id',
    options: {
      sort: false
    }
  },
  {
    label: 'Descrição',
    name: 'description',
    options: {
      sort: false
    }
  },
  {
    label: 'QT',
    name: 'quantity',
    options: {
      sort: false
    }
  },
  {
    label: 'Médico',
    name: 'doctor',
    options: {
      sort: false
    }
  }
]

export const cidColumns = [
  {
    label: 'Código',
    name: 'cid_id',
    options: {
      sort: false
    }
  },
  {
    label: 'Descrição',
    name: 'description',
    options: {
      sort: false
    }
  }
]
