import styled, { css } from 'styled-components'

type WrapperProps = {
  showAsPanel?: boolean
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${(props) =>
    props.showAsPanel &&
    css`
      min-height: 180px;
      background-color: ${({ theme }) => theme.colors.white};
      border-radius: 10px;
      padding: 31px 73px;
    `}
`

export const OverviewItems = styled.div`
  margin-top: 13px;
  display: flex;
  align-items: center;

  section {
    display: flex;
    flex-direction: column;
  }

  section:last-child {
    margin-left: 48px;
  }
`

export const Item = styled.div`
  display: flex;
  align-items: center;
`

export const ItemLabel = styled.label`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 600;
  margin-right: 4px;
`

export const ItemContent = styled.p`
  color: ${({ theme }) => theme.colors.primary};
`
