import translateRole from 'common/utils/getTranslatedRole'
import { Hospital } from 'domain/entities/hospital-model'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import moment from 'moment-timezone'
import { getCrmoTableStatus } from 'presentation/hospital/pages/Authorization/Functions/getCrmoTableStatus'

export function adapterSurgicalOrderColumn(data: SurgicalOrderModel) {
  const formatDate = (date?: string | Date) => {
    if (date) {
      return moment(date).utc(true).format('DD/MM/YYYY - HH[h]')
    }
    return ''
  }

  return {
    surgical_order_id: data.isSolicitation ? '' : data.surgical_order_id,
    status: data.status ? getCrmoTableStatus(data.status) : '',
    hospitalName: new Hospital(
      0,
      data.hospital?.nickname ?? '',
      data.hospital?.name ?? ''
    ).name,
    createdAt: formatDate(data.createdAt),
    patientName: data.patientName,
    healthInsuranceName: data.healthInsurance?.healthInsuranceName,
    surgery: data.procedure?.[0]?.description,
    doctorName: data.doctorName,
    createdByRole: translateRole(data.createdBy?.role)
  }
}

export function mapSurgicalOrderColumn(data: SurgicalOrderModel[]) {
  return data.map((unformattedData) =>
    adapterSurgicalOrderColumn(unformattedData)
  )
}
