import React, { useState } from 'react'
import SecretaryHomeLayout, {
  SecretaryHomeLayoutProps
} from 'presentation/secretary/layouts/Home'
import { LoadSecretary } from 'domain/usecases/secretary/load-secretary'
import moment from 'moment'
import { SurgicalOrderList } from 'presentation/hospital/components/SurgicalOrderTable'
import { toast } from 'react-toastify'
import { LoadSecretarySurgicalPendenciesCount } from 'domain/usecases/secretary/load-secretary-surgical-pendencies-count'
import { LoadSecretaryRegisteredOrdersCount } from 'domain/usecases/secretary/load-secretary-registered-orders-count'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { getSecretaryTableStatus } from './functions/getSecretaryTableStatus'
import { Hospital } from 'domain/entities/hospital-model'
import translateRole from 'common/utils/getTranslatedRole'

type HomeProps = {
  loadSecretary: LoadSecretary
  countRegisteredOrders: LoadSecretaryRegisteredOrdersCount
  countSurgicalPendencies: LoadSecretarySurgicalPendenciesCount
} & WithLoadingProps

const SecretaryHome = WithLoading(
  ({
    loadSecretary,
    countSurgicalPendencies,
    countRegisteredOrders,
    setIsLoading
  }: HomeProps) => {
    const [layoutData, setLayoutData] =
      useState<Omit<SecretaryHomeLayoutProps, 'refetchData'>>()
    const [tableData, setTableData] = useState<SurgicalOrderList[]>()

    const loadSurgicalOrdersData = async () => {
      setIsLoading(true)
      try {
        const res = await loadSecretary.load([
          'surgicalOrder{surgical_order_id, procedure {description}, doctor{name}, hospital{name}, expectedDate, healthInsurance {healthInsuranceName} status {status isActive isConcluded}' +
            'patient { name, gender, birthday}, createdBy {name, role}, createdAt, status {status} }' +
            'pendencies{surgical_pendency_id, status, answer}'
        ])

        setTableData(
          res?.surgicalOrder?.map((surgicalOrder) => ({
            surgical_order_id: surgicalOrder.surgical_order_id?.toString(),
            patient: surgicalOrder.patient?.name,
            hospital: new Hospital(
              surgicalOrder.hospital?.hospital_id ?? 0,
              surgicalOrder.hospital?.nickname ?? '',
              surgicalOrder.hospital?.name ?? ''
            ).name,
            surgery: surgicalOrder?.procedure
              ? surgicalOrder?.procedure[0].description
              : '',
            doctor: surgicalOrder.doctor?.name,
            insurance: surgicalOrder?.healthInsurance?.healthInsuranceName,
            createdBy:
              surgicalOrder.createdBy?.name +
              ' * ' +
              translateRole(surgicalOrder.createdBy?.role),
            createdAt: surgicalOrder.createdAt
              ? moment(new Date(surgicalOrder.createdAt))
                  .utc(true)
                  .format('DD/MM/YYYY HH:mm')
              : '',
            status: surgicalOrder?.status?.find((status) => status.isActive)
              .status
          }))
        )
      } catch (error) {
        toast.error(error?.message)
      } finally {
        setIsLoading(false)
      }
    }
    const loadCountData = async () => {
      setIsLoading(true)
      try {
        const res = await loadSecretary.load([
          'surgicalOrderCount {scheduled {total, approved, disapproved}, unscheduled {total, approved, disapproved}}',
          'surgicalOrderMonthlyCount {total, totalDoctor, totalSecretary}'
        ])

        const count = await countSurgicalPendencies.count([
          'assistence',
          'authorization'
        ])
        const registeredOrdersCount = await countRegisteredOrders.count([
          'doctorName',
          'count'
        ])
        setLayoutData({
          total: res.surgicalOrderMonthlyCount?.total,
          totalDoctor: res.surgicalOrderMonthlyCount?.totalDoctor,
          totalSecretary: res.surgicalOrderMonthlyCount?.totalSecretary,
          unscheduled: res.surgicalOrderCount?.unscheduled,
          scheduled: res.surgicalOrderCount?.scheduled,
          chartData: res.doctors,
          pendencies: res.pendencies ?? [],
          assistancePendencies: count.assistence,
          authorizationPendencies: count.authorization,
          registeredOrders: registeredOrdersCount
        })
      } catch (error) {
        toast.error(error?.message)
      } finally {
        setIsLoading(false)
      }
    }

    const loadData = async () => {
      await loadSurgicalOrdersData()
      await loadCountData()
    }

    return (
      <SecretaryHomeLayout
        surgicalOrderList={tableData}
        {...layoutData}
        refetchData={loadData}
      />
    )
  }
)

export default SecretaryHome

export type LoginFormValues = {
  cpf: string
  password: string
  remember: boolean
}
