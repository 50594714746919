import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 25px;
  flex: 1;

  h1 {
    line-height: normal;
    margin: 0;
  }

  button {
    align-self: flex-end;
  }
`

export const SurgeryCenterList = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > div {
    margin-right: 16px;
    margin-bottom: 16px;
  }
`

export const NoAvailableSurgeryCenter = styled.label`
  margin-top: 8px;
  font-size: 1em;
  color: #a4a4a;
  font-weight: 400;
`

export const Inputs = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5%;
  height: 100%;
  align-content: flex-start;
  & > div {
    display: flex;
    flex-direction: column;
  }
`

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;

  button:first-child {
    margin-right: 30px;
  }
`

export const InputButtonContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
  align-self: flex-start;

  #textfield-icon {
    bottom: -19px;
  }

  button {
    position: absolute;
    width: 2.75rem;
    height: 2.75rem;
    display: flex;
    justify-content: center;
    align-content: center;
    border-radius: 0px 4px 4px 0px;

    span {
      width: 30px;
      height: 30px;
    }

    svg {
      height: 30px;
      width: 30px;

      path {
        height: 30px;
        width: 30px;
        fill: white;
        opacity: 1;
      }
    }
  }

  p {
    margin-bottom: 10px;
  }
`

export const ModalButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  button {
    width: 45%;
  }
`

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 48px;
  font-weight: 600;
  height: 370px;
  width: 500px;
  text-align: left;
  color: ${({ theme }) => theme.colors.gray};

  svg {
    width: 90px;
    height: 90px;
    path {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`
