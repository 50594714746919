import { FinishSecretarySignup } from 'domain/usecases/secretary/finish-secretary-signup'
import { SecretaryRepository } from 'repository/interfaces/secretary-repository'

export class RemoteFinishSecretarySignup implements FinishSecretarySignup {
  constructor(private readonly SecretaryRepository: SecretaryRepository) {}

  add(
    params: FinishSecretarySignup.Params
  ): Promise<FinishSecretarySignup.Model> {
    return this.SecretaryRepository.finishSecretarySignup(params)
  }
}

export type FinishSecretarySignupModel = FinishSecretarySignup.Model
