import styled, { css } from 'styled-components'
import { ReactComponent as PlusIcon } from 'presentation/assets/icons/plus.svg'
import { ReactComponent as TrashIcon } from 'presentation/assets/icons/trashIcon.svg'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Inputs = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 20px;
`

export const InputButtonContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: flex-start;
  width: 100%;

  button {
    position: absolute;
    width: 2.75rem;
    height: 2.75rem;
    display: flex;
    justify-content: center;
    align-content: center;
    margin-left: 10px;
    border-radius: ${({ theme }) =>
      `0 ${theme.border.radius.xsmall} ${theme.border.radius.xsmall} 0`};

    span {
      width: 30px;
      height: 30px;
    }

    svg {
      height: 30px;
      width: 30px;

      path {
        height: 30px;
        width: 30px;
        fill: white;
        opacity: 1;
      }
    }
  }
`

export const Providers = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px 5%;
  width: 100%;
  margin-top: 40px;
  position: relative;

  svg:hover {
    path {
      fill: ${({ theme }) => theme.colors.secondary};
    }
  }

  path {
    fill: ${({ theme }) => theme.colors.primary};
    transition: 0.3s;
  }
`

export const RemoveProviderIcon = styled(TrashIcon)`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`

export const AddProviderIcon = styled(PlusIcon)`
  padding: 1px;
  margin-right: 8px;

  path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`

export const AddProviderWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 24px 0px;
`

export const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 20px;

  input {
    width: 58px;
    padding: 0;
    margin: 0;
    text-align: center;
  }

  h5 {
    margin-right: 10px;
  }
`

export const Title = styled.h5`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    font-weight: 400;
    color: ${theme.colors.gray};
    cursor: pointer;
  `}
`
