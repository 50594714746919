import { StatusContainer } from 'presentation/hospital/components/SurgicalOrderTable/styles'
import React from 'react'

export const surgicalOrdersColumns = [
  {
    name: 'surgical_order_id',
    label: 'Nº aviso',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      filter: true,
      sort: false,
      //eslint-disable-next-line
      customBodyRender: (value: string) => (
        <StatusContainer>
          <p>{value}</p>
        </StatusContainer>
      )
    }
  },
  {
    name: 'hospitalName',
    label: 'Unidade',
    options: { filter: true, sort: false }
  },
  {
    name: 'createdAt',
    label: 'Dt. de criação',
    options: { filter: true, sort: false }
  },
  {
    name: 'patientName',
    label: 'Paciente',
    options: { filter: false, sort: false }
  },
  {
    name: 'healthInsuranceName',
    label: 'Convênio',
    options: { filter: true, sort: false }
  },
  {
    name: 'surgery',
    label: 'Cirurgia',
    options: { filter: true, sort: false }
  },
  {
    name: 'doctorName',
    label: 'Médico',
    options: { filter: true, sort: false }
  },
  {
    name: 'createdByRole',
    label: 'Responsável',
    options: {
      filter: true,
      sort: false
    }
  }
]
