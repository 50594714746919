import { ApiClientImplementation } from 'infra/api-client/api-client'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import SecretaryLoadSurgicalOrder from 'presentation/secretary/pages/SurgicalOrders'
import React from 'react'
import { ApiRepository } from 'repository/api-repository'
import { SecretaryRepository } from 'repository/repositories/secretary/secretary-repository'
import { RemoteLoadSecretarySurgicalOrders } from 'service/usecases/load-secretary-surgical-orders/load-secretary-surgical-orders'

export default function makeSecretaryLoadSurgicalOrders() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )
  const secretaryRepository = new SecretaryRepository(apiRepository)

  const loadSecretarySurgicalOrder = new RemoteLoadSecretarySurgicalOrders(
    secretaryRepository
  )

  return (
    <SecretaryLoadSurgicalOrder
      loadSecretarySurgicalOrder={loadSecretarySurgicalOrder}
    />
  )
}
