import { LoadSurgicalOrdersByDoctor } from 'domain/usecases/doctor/load-surgical-order'

const getTittleBySearchType = (
  order_status: LoadSurgicalOrdersByDoctor.Params['order_status'],
  unfinishedOrders?: boolean
): string => {
  if (unfinishedOrders) return 'Pedidos não finalizados'
  if (order_status.all) return 'Todos os agendamentos'

  return 'Seus pedidos'
}

export default getTittleBySearchType
