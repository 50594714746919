import styled, { DefaultTheme } from 'styled-components'

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  width: 100%;

  h1 {
    margin-bottom: 0;
  }
`

export const Content = styled.div`
  & > div {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 32px;
    margin-top: 20px;

    section {
      display: flex;
      align-items: center;

      div + div {
        margin-left: 10px;
      }
    }

    @media (max-width: 1600px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  button {
    margin-top: 30px;
    justify-self: flex-end;
    align-self: flex-end;
  }
`

export const NewBornCheckArea = styled.div<{
  bgColor?: keyof DefaultTheme['colors']
}>`
  display: flex;
  align-self: flex-end;
  align-items: center;
  height: 54px;
  max-width: 300px;
  padding: 0 18px;
  background: ${({ theme, bgColor }) =>
    bgColor ? theme.colors[bgColor] : theme.colors.mainBg};
  border-radius: 10px;

  label {
    font-size: ${({ theme }) => theme.font.sizes.small};
  }
`

export const Buttons = styled.div`
  margin-top: 1.75rem;
  display: flex;
  justify-content: right;
  gap: 2rem;

  button {
    max-width: 12.5rem;
  }
`
