import { SurgicalPendency } from 'domain/entities/surgical-pendency'
import Button from 'presentation/shared/components/Button'
import React, { useState } from 'react'
import { InputsContainer } from '../styles'
import * as S from './styles'
import TypedModal from 'presentation/shared/components/Modal'
import TextArea from 'presentation/shared/components/TextArea'
import { useFormikContext } from 'formik'
import { AnswerPendencyForm } from '..'
import { cleanObject } from 'common/utils/cleanObject'
import { isObjectStrictEqual } from 'common/utils/isObjectStrictEqual'

type PendencyReplyProps = {
  changeMode: () => void
  onClose: (shouldRefresh: boolean) => void
  surgicalPendency?: SurgicalPendency
  alreadyHasDocuments: boolean
}

export default function PendencyReply({
  onClose,
  changeMode,
  alreadyHasDocuments,
  surgicalPendency
}: PendencyReplyProps) {
  const [showCancel, setShowCancel] = useState<boolean>(false)
  const { handleChange, values, initialValues, submitForm } =
    useFormikContext<AnswerPendencyForm>()

  const onCloseModal = () => {
    if (!isObjectStrictEqual(cleanObject(initialValues), cleanObject(values)))
      setShowCancel(true)
    else onClose(false)
  }
  const onConfirmCancel = () => {
    onClose(false)
    setShowCancel(false)
  }
  const onCancel = () => {
    setShowCancel(false)
  }

  const pendencyAlreadyAnswered = !!(
    surgicalPendency?.answer || surgicalPendency?.answeredAt
  )

  return (
    <S.ReplyContainer>
      <S.FieldsContainer>
        <TextArea
          rows={3}
          label="Pendência"
          bgColor="mainBg"
          name="observation"
          id="observation"
          value={surgicalPendency?.observation || ''}
          disabled
        />
        <TextArea
          rows={3}
          label="Retorno"
          bgColor="mainBg"
          name="answer"
          id="answer"
          value={values.answer || ''}
          onChange={handleChange}
          disabled={pendencyAlreadyAnswered}
        />
        {pendencyAlreadyAnswered ? (
          <S.AlreadyAnsweredContainer>
            PENDÊNCIA JÁ RESPONDIDA
          </S.AlreadyAnsweredContainer>
        ) : (
          <S.PendencyAdvancedButton
            label="Anexar documentos"
            onClick={changeMode}
            showAttachmentIcon={alreadyHasDocuments}
          />
        )}
      </S.FieldsContainer>
      <InputsContainer>
        {pendencyAlreadyAnswered ? (
          <Button type="button" onClick={() => onClose(false)}>
            Fechar
          </Button>
        ) : (
          <>
            <Button type="button" variant="outlined" onClick={onCloseModal}>
              Cancelar
            </Button>
            <Button
              type="submit"
              onClick={() => submitForm()}
              disabled={!values.answer}
            >
              Enviar
            </Button>
          </>
        )}
      </InputsContainer>
      <TypedModal
        type="alert"
        show={showCancel}
        actionLabel="Confirmar"
        action={onConfirmCancel}
        title="Deseja realmente cancelar?"
        description="Todas as alterações não salvas serão perdidas."
        hasCloseButton="Cancelar"
        closeButtonProps={{
          variant: 'outlined',
          style: {
            marginTop: '10px'
          }
        }}
        close={onCancel}
        preventAutomateClose
      />
    </S.ReplyContainer>
  )
}
