import {
  pendencyHexColor,
  pendencyTranslator,
  SurgicalPendencyStatus
} from 'domain/entities/surgical-pendency'
import React from 'react'
import * as S from './styles'

type PendencyStatusChipProps = {
  status: SurgicalPendencyStatus
}

export default function PendencyStatusChip({
  status
}: PendencyStatusChipProps) {
  return (
    <>
      <S.Status bgColor={pendencyHexColor[status]}>
        {pendencyTranslator[status]}
      </S.Status>
    </>
  )
}
