import { PaginationModel } from 'domain/entities/pagination-model'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import {
  LoadSecretaryScheduleSurgicalOrders,
  LoadSecretaryScheduleSurgicalOrdersSearch,
  LoadSecretaryScheduleSurgicalOrdersStatus
} from 'domain/usecases/secretary/load-secretary-schedule-surgical-orders'
import SecretaryScheduleSurgicalOrdersLayout from 'presentation/secretary/layouts/ScheduleSurgicalOrders'
import { surgicalOrderFields } from 'presentation/secretary/utils/SurgicalOrderTable/surgicalOrdersFields'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Pagination } from 'service/protocols/api/api-client'

const SURGICAL_ORDERS_PER_PAGE_DEFAULT = 10
const CURRENT_PAGE_DEFAULT = 1
const TOTAL_ITEMS_DEFAULT = 0
const TOTAL_PAGES_DEFAULT = 0

type SecretaryLoadScheduleSurgicalOrderProps = {
  loadSecretaryScheduleSurgicalOrder: LoadSecretaryScheduleSurgicalOrders
}

export default function SecretaryLoadScheduleSurgicalOrder({
  loadSecretaryScheduleSurgicalOrder
}: SecretaryLoadScheduleSurgicalOrderProps) {
  const [loadingSurgicalOrders, setLoadingSurgicalOrders] =
    useState<boolean>(false)
  const [surgicalOrders, setSurgicalOrders] = useState<SurgicalOrderModel[]>([])
  const [pagination, setPagination] = useState<PaginationModel>({
    currentPage: CURRENT_PAGE_DEFAULT,
    itemsPerPage: SURGICAL_ORDERS_PER_PAGE_DEFAULT,
    totalItems: TOTAL_ITEMS_DEFAULT,
    totalPages: TOTAL_PAGES_DEFAULT
  })

  const { state } = useLocation<{
    params: LoadSecretaryScheduleSurgicalOrdersStatus
  }>()

  async function loadData(
    page: number,
    filter?: LoadSecretaryScheduleSurgicalOrdersSearch,
    itemsPerPage?: number
  ) {
    setLoadingSurgicalOrders(true)
    try {
      const newPagination: Pagination = {
        pageNumber: page,
        pageSize: pagination.itemsPerPage
      }
      if (itemsPerPage) {
        if (itemsPerPage !== pagination.itemsPerPage) {
          newPagination.pageNumber = 1
          newPagination.pageSize = itemsPerPage
        }
      }
      const surgicalOrders = await loadSecretaryScheduleSurgicalOrder.load({
        fields: surgicalOrderFields,
        filters: {
          pagination: newPagination,
          search: {
            ...filter
          },
          orderStatus: state.params
        }
      })

      if (surgicalOrders?.data) setSurgicalOrders(surgicalOrders?.data)
      if (surgicalOrders?.pageInfo) setPagination(surgicalOrders?.pageInfo)
    } catch (err: any) {
      toast.error(err.message)
    } finally {
      setLoadingSurgicalOrders(false)
    }
  }

  useEffect(() => {
    loadData(1)
  }, [])

  return (
    <SecretaryScheduleSurgicalOrdersLayout
      loadingSurgicalOrder={loadingSurgicalOrders}
      fetchSurgicalOrder={loadData}
      pagination={pagination}
      surgicalOrders={surgicalOrders}
      orderStatus={state.params}
    />
  )
}
