import React from 'react'
import SecretaryPasswordRecoveryForm, {
  SetPasswordFormValues,
  GetPasswordCodeFormValues
} from 'presentation/secretary/components/Forms/PasswordRecovery'
import { ChangePassword } from 'domain/usecases/authentication/change-password'
import { GetPasswordRecoveryCode } from 'domain/usecases/authentication/get-password-recovery-code'
import { Profile } from 'common/enum/profile'

type SecretaryPasswordRecoveryLayoutProps = {
  getPasswordCodeInitialValues?: GetPasswordCodeFormValues
  setPasswordInitialValues?: SetPasswordFormValues
  getCodeUseCase?: GetPasswordRecoveryCode
  setPasswordUseCase?: ChangePassword
  type: 'phone' | 'email'
}

export default function SecretaryPasswordRecoveryLayout({
  getCodeUseCase,
  setPasswordUseCase,
  getPasswordCodeInitialValues,
  setPasswordInitialValues,
  type
}: SecretaryPasswordRecoveryLayoutProps) {
  return (
    <SecretaryPasswordRecoveryForm
      getCodeUseCase={getCodeUseCase}
      changePasswordUseCase={setPasswordUseCase}
      getCodeValues={getPasswordCodeInitialValues}
      setPasswordValues={setPasswordInitialValues}
      type={type}
      client={Profile.SECRETARY}
    />
  )
}
