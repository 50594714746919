import { PaginationModel } from 'domain/entities/pagination-model'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import {
  LoadSecretarySurgicalOrders,
  LoadSecretarySurgicalOrdersSearch
} from 'domain/usecases/secretary/load-secretary-surgical-order'
import SecretarySurgicalOrdersLayout from 'presentation/secretary/layouts/SurgicalOrders'
import { surgicalOrderFields } from 'presentation/secretary/utils/SurgicalOrderTable/surgicalOrdersFields'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Pagination } from 'service/protocols/api/api-client'

const SURGICAL_ORDERS_PER_PAGE_DEFAULT = 10
const CURRENT_PAGE_DEFAULT = 1
const TOTAL_ITEMS_DEFAULT = 0
const TOTAL_PAGES_DEFAULT = 0
type SecretaryLoadSurgicalOrderProps = {
  loadSecretarySurgicalOrder: LoadSecretarySurgicalOrders
}

export default function SecretaryLoadSurgicalOrder({
  loadSecretarySurgicalOrder
}: SecretaryLoadSurgicalOrderProps) {
  const [loadingSurgicalOrders, setLoadingSurgicalOrders] =
    useState<boolean>(false)
  const [surgicalOrders, setSurgicalOrders] = useState<SurgicalOrderModel[]>([])

  const [pagination, setPagination] = useState<PaginationModel>({
    currentPage: CURRENT_PAGE_DEFAULT,
    itemsPerPage: SURGICAL_ORDERS_PER_PAGE_DEFAULT,
    totalItems: TOTAL_ITEMS_DEFAULT,
    totalPages: TOTAL_PAGES_DEFAULT
  })

  async function loadData(
    page: number,
    filter?: LoadSecretarySurgicalOrdersSearch,
    itemsPerPage?: number
  ) {
    setLoadingSurgicalOrders(true)
    try {
      const newPagination: Pagination = {
        pageNumber: page,
        pageSize: pagination.itemsPerPage
      }
      if (itemsPerPage) {
        if (itemsPerPage !== pagination.itemsPerPage) {
          newPagination.pageNumber = 1
          newPagination.pageSize = itemsPerPage
        }
      }
      const surgicalOrders = await loadSecretarySurgicalOrder.load({
        fields: surgicalOrderFields,
        filters: {
          pagination: newPagination,
          search: {
            ...filter
          }
        }
      })

      if (surgicalOrders?.data) setSurgicalOrders(surgicalOrders?.data)
      if (surgicalOrders?.pageInfo) setPagination(surgicalOrders?.pageInfo)
    } catch (err: any) {
      toast.error(err.message)
    } finally {
      setLoadingSurgicalOrders(false)
    }
  }

  return (
    <SecretarySurgicalOrdersLayout
      loadingSurgicalOrder={loadingSurgicalOrders}
      fetchSurgicalOrder={loadData}
      pagination={pagination}
      surgicalOrders={surgicalOrders}
    />
  )
}
