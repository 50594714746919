import moment from 'moment-timezone'
import theme from 'presentation/styles/theme'
import React from 'react'
import * as S from './styles'
import Divider from '../Divider'

export type TimelineStatusItem = {
  status: string
  color?: string
  isConcluded: boolean
  isActive?: boolean
  createdAt?: string
  updatedAt?: string
}

export type TimelineProps = {
  minified?: boolean
  items?: TimelineStatusItem[]
}

export default function NewTimeline({
  minified = false,
  items
}: TimelineProps) {
  return (
    <S.Wrapper minified={minified}>
      {items?.map((status, index) => (
        <>
          <S.TimelineItem
            done={status.isConcluded}
            minified={minified}
            actual={status.isActive}
            key={status.status}
          >
            <S.Dot
              done={status.isConcluded}
              minified={minified}
              color={status.isActive ? theme.colors.primary : undefined}
            />
            {!minified && (
              <S.ItemContainer>
                <S.DescriptionContainer>
                  <S.StatusLabel isActive={status.isActive}>
                    {status.status}
                  </S.StatusLabel>
                  {status.isConcluded && (
                    <S.DoneDate>
                      {status.updatedAt &&
                        moment(status.updatedAt).format('DD/MM/YYYY - HH:mm')}
                    </S.DoneDate>
                  )}
                </S.DescriptionContainer>
              </S.ItemContainer>
            )}
          </S.TimelineItem>
          <Divider />
        </>
      ))}
    </S.Wrapper>
  )
}
