import React from 'react'

import Container from 'presentation/hospital/components/Container'
import Heading from 'presentation/shared/components/Heading'
import PendenciesTable from 'presentation/secretary/components/Pendencies/PendenciesTable'
import { SurgicalPendency } from 'domain/entities/surgical-pendency'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import { AnswerSurgicalPendency } from 'domain/usecases/surgical-pendency/answer-surgical-pendency'
import { UploadSurgicalPendencyDocument } from 'domain/usecases/surgical-pendency/upload-surgical-pendency-document'
import { LoadSurgicalPendencyDocument } from 'domain/usecases/surgical-pendency/load-surgical-pendency-document'

import * as S from './styles'

type Props = {
  pendencies: SurgicalPendency[]
  surgicalOrders: SurgicalOrderModel[]
  answerSurgicalPendency: AnswerSurgicalPendency
  uploadSurgicalPendencyDocument: UploadSurgicalPendencyDocument
  loadSurgicalPendencyDocument?: LoadSurgicalPendencyDocument
  refreshPendencies(): void
}

const PendenciesLayout = ({
  pendencies,
  surgicalOrders,
  answerSurgicalPendency,
  uploadSurgicalPendencyDocument,
  loadSurgicalPendencyDocument,
  refreshPendencies
}: Props) => {
  return (
    <Container>
      <Heading size="huge" color="primary" style={{ marginBottom: '48px' }}>
        Pendências Cirúrgica
      </Heading>
      <S.Wrapper>
        <PendenciesTable
          data={pendencies}
          surgicalOrders={surgicalOrders}
          answerSurgicalPendency={answerSurgicalPendency}
          uploadSurgicalPendencyDocument={uploadSurgicalPendencyDocument}
          loadSurgicalPendencyDocument={loadSurgicalPendencyDocument}
          refreshPendencies={refreshPendencies}
        />
      </S.Wrapper>
    </Container>
  )
}

export default PendenciesLayout
