import React from 'react'

import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { AuthenticationRepository } from 'repository/repositories/authentication/authentication-repository'
import { ApiRepository } from 'repository/api-repository'
import SecretarySignup from 'presentation/secretary/pages/Signup'
import { RemoteLoadUserByInviteToken } from 'service/usecases/load-user-by-invite-token/remote-load-user-by-invite-token'
import { SecretaryRepository } from 'repository/repositories/secretary/secretary-repository'
import { RemoteFinishSecretarySignup } from 'service/usecases/finish-secretary-signup/remote-finish-secretary-signup'

export default function makeSecretarySignup() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )
  const authenticationRepository = new AuthenticationRepository(apiRepository)
  const secretaryRepository = new SecretaryRepository(apiRepository)

  const loadUserByInviteToken = new RemoteLoadUserByInviteToken(
    authenticationRepository
  )
  const finishSecretarySignup = new RemoteFinishSecretarySignup(
    secretaryRepository
  )

  return (
    <SecretarySignup
      loadUserByInviteToken={loadUserByInviteToken}
      finishSecretarySignup={finishSecretarySignup}
    />
  )
}
