import theme from 'presentation/styles/theme'
import React, { ReactNode, useEffect } from 'react'

import * as S from './styles'

type Props = {
  children: ReactNode
  close?: () => void
  noBackground?: boolean
  bgColor?: keyof typeof theme.colors
  wrapperWidth?: string
}

export default function Modal({
  children,
  close,
  noBackground,
  bgColor,
  wrapperWidth
}: Props) {
  const closeModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target !== e.currentTarget) return
    close && close()
  }

  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  return (
    <S.bg id="bg" onClick={(e) => closeModal(e)} noBackground={!!noBackground}>
      <S.Wrapper bgColor={bgColor} width={wrapperWidth}>
        {children}
      </S.Wrapper>
    </S.bg>
  )
}
