import React, { useEffect, useMemo, useState } from 'react'
import Logo from 'presentation/shared/components/Logo'

import { observer } from 'mobx-react'
import { useHistory } from 'react-router'
import { startCase } from 'lodash'
import * as S from './styles'
import SelectField from 'presentation/shared/components/SelectField'
import { useStores } from 'presentation/hooks/use-stores'
import ProfilePic from 'presentation/shared/components/ProfilePic'
import { Link } from 'react-router-dom'
import { formatProfilePicLink } from 'presentation/utils/format-profile-pic-link'
import { Menu, MenuItem } from '@material-ui/core'
import { toast } from 'react-toastify'
import LoadingModal from 'presentation/shared/components/LoadingModal'
import { User } from 'domain/entities/user-model'
import translateRole from 'common/utils/getTranslatedRole'
import MenuIcon from '@material-ui/icons/Menu'

import { ButtonNavItem, buttonNavItems } from './data'

type Props = {
  hideButton?: boolean
  headerButtonTitle?: string
  onClickHeaderButton?: () => void
  onHospitalChange?: () => void
  onClickMenuIcon?: () => void
}

const HospitalHeader = observer(
  ({
    hideButton,
    onClickHeaderButton,
    headerButtonTitle,
    onHospitalChange,
    onClickMenuIcon
  }: Props) => {
    const [user, setUser] = useState({} as User)

    const history = useHistory()
    const currentHospital = useStores().currentHospital

    const [anchorEl, setAnchorEl] = React.useState(null)
    const [showLoadingModal, setShowLoadingModal] = useState<boolean>(false)

    const currentAccount = useStores().currentAccount.getCurrentAccount()

    useEffect(() => {
      async function setDefaultActualHospital() {
        if (
          currentHospital.hospitalList.length > 0 &&
          !currentHospital.getActualHospital()
        ) {
          const firstHospital = currentHospital.hospitalList[0]
          await currentHospital.setActualHospital(firstHospital.hospital_id)
        }
      }
      setDefaultActualHospital()
      if (currentAccount) {
        setUser(currentAccount.user)
      }
    }, [])

    const hospital = currentHospital.actualHospital
    const hospitalName = hospital?.name
    useEffect(() => {
      if (onHospitalChange) {
        onHospitalChange()
      }
    }, [hospitalName])

    const handleClose = () => {
      setAnchorEl(null)
    }

    const availableMenuUserRoles = ['admin', 'crmo']

    const handleNavButtonClick = (evt: ButtonNavItem) => {
      if (evt.isAdmin) {
        return history.push({
          pathname: evt.path,
          state: {
            isAdmin: true
          }
        })
      }

      history.push(evt.path)
    }

    const resolvedItems = useMemo<ButtonNavItem[]>(() => {
      return buttonNavItems.filter((item) => item.profile.includes(user.role))
    }, [user.role])

    const hospitalOptions = useMemo(() => {
      const hospitals = currentHospital.hospitalList.map((hospital) => ({
        label: hospital.nickname,
        value: hospital.hospital_id
      }))
      return hospitals
    }, [currentHospital.hospitalList])
    const handleChangeHospital = async (
      evt: React.ChangeEvent<HTMLSelectElement>
    ) => {
      const value = evt.target.value
      try {
        setShowLoadingModal(true)
        await currentHospital.setActualHospital(Number(value))
      } catch (error) {
        toast.error('Ocorreu um erro ao tentar trocar para esta unidade!')
      } finally {
        setShowLoadingModal(false)
      }
    }

    return (
      <S.Wrapper>
        <S.IconWrapper>
          <MenuIcon onClick={onClickMenuIcon} />
        </S.IconWrapper>
        <S.LogoWrapper>
          <Logo size="large" onClick={() => history.push('/home')} />
        </S.LogoWrapper>
        <S.Content>
          <SelectField
            items={hospitalOptions}
            onChange={handleChangeHospital}
            value={currentHospital?.getActualHospital()?.hospital_id.toString()}
          />
          <S.Options>
            {availableMenuUserRoles.includes(user.role) && !hideButton && (
              <S.AddButton onClick={(e: any) => setAnchorEl(e.currentTarget)}>
                <span>+</span>
              </S.AddButton>
            )}

            {(user.role === 'crmo' ||
              user.role === 'secretary' ||
              user.role === 'RECEPTIONIST') &&
              !hideButton && (
                <S.NewSurgicalOrder
                  onClick={() => history.push('/pedido/novo')}
                  data-testid="new-surgical-order-button"
                >
                  <span>Novo pedido cirúrgico</span>
                </S.NewSurgicalOrder>
              )}
          </S.Options>

          {onClickHeaderButton && headerButtonTitle ? (
            <S.HeaderButton onClick={() => onClickHeaderButton()}>
              <span>{headerButtonTitle}</span>
            </S.HeaderButton>
          ) : null}

          <S.UserWrapper>
            <S.UserInfo>
              <strong>{startCase(user.name?.toLocaleLowerCase())}</strong>
              <label>{translateRole(user.role)}</label>
            </S.UserInfo>
            {user.role === 'secretary' ? (
              <Link to="/perfil/0101">
                <ProfilePic
                  data-testid="profile pic"
                  size="small"
                  src={
                    user.photoKey &&
                    formatProfilePicLink(user.photoKey, user.user_id)
                  }
                />
              </Link>
            ) : (
              <Link to="/perfil/0101">
                <ProfilePic
                  data-testid="profile pic"
                  size="small"
                  src="https://source.unsplash.com/random"
                />
              </Link>
            )}
          </S.UserWrapper>
        </S.Content>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {resolvedItems.map((item, index) => (
            <MenuItem key={index} onClick={() => handleNavButtonClick(item)}>
              {item.label}
            </MenuItem>
          ))}
        </Menu>
        <LoadingModal show={showLoadingModal} />
      </S.Wrapper>
    )
  }
)

export default HospitalHeader

// const ALL_OPTIONS_KEYS = 'allOptions'
