import React from 'react'

import Heading from 'presentation/shared/components/Heading'
import Checkbox from 'presentation/shared/components/Checkbox'
import TextField from 'presentation/shared/components/TextField'
import { defaultEquipments } from 'presentation/utils/default-equipments'
import { AddSurgicalOrderFormValues } from '../..'

import styled from 'styled-components'
import { useFormikContext } from 'formik'

import * as FormS from '../styles'
import * as S from './styles'

const SpecificationTextField = styled(TextField)`
  height: 37px;

  & > input {
    height: 37px;
  }
`

export function SurgeryEquipments() {
  const { values, setFieldValue } =
    useFormikContext<AddSurgicalOrderFormValues>()

  const equipmentsSelected = values.equipments || []

  const onCheck = (equipment: string) => {
    const oldValue = equipmentsSelected[equipment]
    const newEquipments = {
      ...equipmentsSelected,
      [equipment]: {
        enabled: !oldValue?.enabled,
        value: oldValue?.value || ''
      }
    }
    setFieldValue('equipments', newEquipments)
  }

  const onSpecificationChange = (equipment: string, value: string) => {
    const oldValue = equipmentsSelected[equipment]
    const newEquipments = {
      ...equipmentsSelected,
      [equipment]: {
        enabled: oldValue?.enabled,
        value: value
      }
    }
    setFieldValue('equipments', newEquipments)
  }

  return (
    <>
      <FormS.SurgeryWrapper>
        <Heading
          size="large"
          color="primary"
          style={{ marginTop: '60px', marginBottom: '24px' }}
        >
          Equipamentos
        </Heading>
      </FormS.SurgeryWrapper>
      <S.InputsContainer>
        {defaultEquipments?.map((equipment) => (
          <S.InputContainer key={equipment}>
            <Checkbox
              label={equipment}
              name={equipment}
              labelFor={equipment}
              onCheck={() => onCheck(equipment)}
              isChecked={!!equipmentsSelected[equipment]?.enabled}
            />
            <SpecificationTextField
              placeholder="Especificação"
              bgColor="mainBg"
              disabled={!equipmentsSelected[equipment]?.enabled}
              onChange={(e) => onSpecificationChange(equipment, e.target.value)}
              defaultValue={equipmentsSelected[equipment]?.value || ''}
            />
          </S.InputContainer>
        ))}
      </S.InputsContainer>
    </>
  )
}
