import styled from 'styled-components'

export const Wrapper = styled.main`
  height: 100%;
  font-size: 16px;
  display: flex;
  flex-direction: column;
`

export const PendencyNotification = styled.div`
  height: 60px;
  width: 104%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffaaaa;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 40px;
  margin-top: -24px;
  margin-left: -24px;
  cursor: pointer;
`

export const DataOverview = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1rem;
  gap: 20px;
`

export const RegisteredOrdersTotal = styled.div`
  width: 100%;
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem 2rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius.small};
  font-weight: ${({ theme }) => theme.font.bold};

  div.icon-total {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

export const RegisteredOrders = styled.div`
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius.small};
  font-weight: ${({ theme }) => theme.font.bold};

  & + & {
    margin-top: 0.7rem;
  }
`

export const AttendancesOverview = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 30px;
  height: 183px;

  cursor: pointer;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const ScheduledOrders = styled.div<{ color: 'blue' | 'orange' }>`
  color: ${({ theme, color }) =>
    color === 'blue' ? '#5d86da' : theme.colors.lightOrange};
  height: 102px;
  grid-column: 1/3;
  font-weight: 700;
  border-bottom: 2px solid #f3f3f3;
  text-transform: uppercase;
  cursor: pointer;

  span {
    font-size: ${({ theme }) => theme.font.sizes.xlarge};
    @media only screen and (min-width: 1400px) {
      font-size: ${({ theme }) => theme.font.sizes.xxlarge};
    }
  }

  label {
    font-size: ${({ theme }) => theme.font.sizes.small};
    margin-left: 9px;
    @media only screen and (min-width: 1400px) {
      font-size: ${({ theme }) => theme.font.sizes.medium};
    }
  }
`

export const ApprovedOrders = styled.div`
  height: 79px;
  border-right: 1px solid #f3f3f3;
  display: flex;
  text-transform: uppercase;
  label {
    margin-left: 5px;
    color: ${({ theme }) => theme.colors.secondary};
    font-weight: ${({ theme }) => theme.font.black};
    font-size: ${({ theme }) => theme.font.sizes.xxsmall};

    @media only screen and (min-width: 1400px) {
      font-size: ${({ theme }) => theme.font.sizes.medium};
    }
  }

  span {
    color: ${({ theme }) => theme.colors.secondary};
    font-weight: ${({ theme }) => theme.font.black};
    font-size: ${({ theme }) => theme.font.sizes.xlarge};
  }
`

export const RejectedOrders = styled.div`
  height: 79px;
  border-left: 1px solid #f3f3f3;
  display: flex;
  text-transform: uppercase;
  label {
    margin-left: 5px;
    color: ${({ theme }) => theme.colors.lightGray};
    font-weight: ${({ theme }) => theme.font.black};
    font-size: ${({ theme }) => theme.font.sizes.xxsmall};
    @media only screen and (min-width: 1400px) {
      font-size: ${({ theme }) => theme.font.sizes.medium};
    }
  }

  span {
    color: ${({ theme }) => theme.colors.lightGray};
    font-weight: ${({ theme }) => theme.font.black};
    font-size: ${({ theme }) => theme.font.sizes.xlarge};
  }
`

export const CardSubtitle = styled.div`
  display: flex;
  flex-direction: column;

  abbr {
    text-transform: capitalize;
    color: ${({ theme }) => theme.colors.lightGray};
    font-size: ${({ theme }) => theme.font.sizes.xxsmall};
  }

  label {
    color: ${({ theme }) => theme.colors.secondary};
    font-size: ${({ theme }) => theme.font.sizes.small};
    font-weight: ${({ theme }) => theme.font.bold};
    line-height: 22px;
    @media only screen and (min-width: 1400px) {
      font-size: ${({ theme }) => theme.font.sizes.medium};
    }
  }
`

export const CardNumber = styled.span`
  color: ${({ theme }) => theme.colors.lightGray};
  font-size: ${({ theme }) => theme.font.sizes.xxlarge};
  font-weight: ${({ theme }) => theme.font.bold};

  @media only screen and (min-width: 1400px) {
    font-size: ${({ theme }) => theme.font.sizes.xxlarge};
  }
`

export const ChartContainer = styled.div`
  position: relative;
  padding: 20px 12px;
  grid-column: 1/3;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius.small};
  height: 30rem;
`

export const TableContainer = styled.div`
  grid-column: 1/3;
  white-space: nowrap;
  div {
    margin-top: 0px;
  }
`
export const TableTitle = styled.h2`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 36px;
  margin-top: 20px;
`

export const PendencyContainer = styled.div`
  grid-column: 3/5;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-self: start;
  width: 100%;
`

export const StatusContainer = styled.div`
  min-width: 120px;
  padding: 0.2rem;
  border: 2px solid;
  border-color: ${({ theme }) => theme.colors.black};
  border-radius: 20px;
  font-weight: ${({ theme }) => theme.font.semiBold};
`
