import React, { useEffect, useState } from 'react'

import { useHistory, useParams } from 'react-router'
import { toast } from 'react-toastify'

import SecretarySignupLayout from 'presentation/secretary/layouts/Signup'
import { LoadUserByInviteToken } from 'domain/usecases/authentication/get-user-by-invite-token'
import { SecretarySignupFormValues } from 'presentation/secretary/components/Forms/Signup'
import { FinishSecretarySignup } from 'domain/usecases/secretary/finish-secretary-signup'

type SecretarySignupProps = {
  loadUserByInviteToken: LoadUserByInviteToken
  finishSecretarySignup: FinishSecretarySignup
}

export default function SecretarySignup({
  loadUserByInviteToken,
  finishSecretarySignup
}: SecretarySignupProps) {
  const { hash } = useParams<RouteParams>()
  const history = useHistory()

  const [initialValues, setInitialValues] = useState(
    {} as SecretarySignupFormValues
  )
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    async function loadData() {
      try {
        const userData = await loadUserByInviteToken.load(hash, [
          'cpf',
          'email',
          'phone',
          'name'
        ])
        setInitialValues({
          cpf: userData.cpf,
          name: userData.name,
          email: userData.email,
          phone: userData.phone ?? '',
          password: '',
          passwordConfirmation: '',
          terms: false
        })
      } catch (error) {
        toast.error('Token de acesso inválido')
        return history.push('/login')
      }
      setIsLoading(false)
    }
    loadData()
  }, [hash])

  if (isLoading) {
    return <></>
  }

  return (
    <SecretarySignupLayout
      initialValues={initialValues}
      finishSecretarySignup={finishSecretarySignup}
      token={hash}
    />
  )
}

type RouteParams = {
  hash: string
}
