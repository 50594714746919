import styled from 'styled-components'

export const FiltersContainer = styled.form`
  display: grid;
  grid-template-columns: 1fr 0.2fr;
  gap: 1rem;

  align-items: center;
  justify-content: space-between;
  background-color: white;
  margin-bottom: 20px;
  border-radius: 6px;
  padding: 30px 30px;

  @media (max-width: 1600px) {
    grid-template-columns: 1fr;
    grid-gap: 12px;
  }
`

export const FiltersInput = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  @media (min-width: 1600px) and (max-width: 1700px) {
    input::-webkit-input-placeholder {
      font-size: ${({ theme }) => theme.font.sizes.xsmall};
    }
  }
`

export const FiltersRadio = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 120px;
  overflow-y: auto;

  & label {
    margin: 6px 10px;
  }
`

export const FiltersButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  @media (max-width: 1600px) {
    width: 50%;
    align-items: center;
    flex-direction: row;
    height: 60%;

    button:first-child {
      margin-right: 12px;
    }
  }
`
