import React from 'react'

import { SecretaryPendencies } from 'presentation/secretary/pages/Pendencies'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { ApiRepository } from 'repository/api-repository'
import { SecretaryRepository } from 'repository/repositories/secretary/secretary-repository'
import { RemoteLoadSecretary } from 'service/usecases/load-secretary/remote-load-secretary'
import { RemoteAnswerSurgicalPendency } from 'service/usecases/answer-surgical-pendency/remote-answer-surgical-pendency'
import { SurgicalPendencyRepository } from 'repository/repositories/surgical-pendency/surgical-pendency-repository'
import { RemoteUploadSurgicalPendencyDocument } from 'service/usecases/upload-surgical-pendency-document/remote-upload-surgical-pendency-document'
import { RemoteLoadSurgicalPendencyDocument } from 'service/usecases/load-surgical-pendency-document/remote-load-surgical-pendency-document'

export default function makeSecretaryPendenciesFactory() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )
  const secretaryRepository = new SecretaryRepository(apiRepository)
  const surgicalPendencyRepository = new SurgicalPendencyRepository(
    apiRepository
  )

  const loadSecretary = new RemoteLoadSecretary(secretaryRepository)
  const loadSurgicalPendencyDocument = new RemoteLoadSurgicalPendencyDocument(
    surgicalPendencyRepository
  )
  const answerSurgicalPendency = new RemoteAnswerSurgicalPendency(
    surgicalPendencyRepository
  )
  const uploadSurgicalPendencyDocument =
    new RemoteUploadSurgicalPendencyDocument(surgicalPendencyRepository)

  return (
    <SecretaryPendencies
      loadSecretary={loadSecretary}
      loadSurgicalPendencyDocument={loadSurgicalPendencyDocument}
      answerSurgicalPendency={answerSurgicalPendency}
      uploadSurgicalPendencyDocument={uploadSurgicalPendencyDocument}
    />
  )
}
