import React, { useEffect } from 'react'

import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { ApiRepository } from 'repository/api-repository'
import { SecretaryRepository } from 'repository/repositories/secretary/secretary-repository'
import SecretaryHome from 'presentation/secretary/pages/Home'
import { RemoteLoadSecretary } from 'service/usecases/load-secretary/remote-load-secretary'
import { RemoteLoadSecretarySurgicalPendenciesCount } from 'service/usecases/load-secretary-surgical-pendencies-count'
import { RemoteLoadSecretaryRegisteredOrdersCount } from 'service/usecases/load-secretary-registered-orders-count/remote-load-secretary-registered-orders-count'
import { useStores } from 'presentation/hooks/use-stores'

export default function makeSecretaryHome() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )
  const secretaryRepository = new SecretaryRepository(apiRepository)

  const loadSecretary = new RemoteLoadSecretary(secretaryRepository)

  const countSurgicalPendencies =
    new RemoteLoadSecretarySurgicalPendenciesCount(secretaryRepository)

  const countRegisteredOrders = new RemoteLoadSecretaryRegisteredOrdersCount(
    secretaryRepository
  )

  const surgicalstatus = useStores().surgicalstatus

  useEffect(() => {
    surgicalstatus.load()
  }, [])

  return (
    <SecretaryHome
      loadSecretary={loadSecretary}
      countSurgicalPendencies={countSurgicalPendencies}
      countRegisteredOrders={countRegisteredOrders}
    />
  )
}
