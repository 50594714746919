// @ts-nocheck
export const isObjectStrictEqual = <T>(objectOne: T, objectTwo: T): boolean => {
  const keysObjectOne = Object.keys(objectOne)
  const keysObjectTwo: (string | undefined)[] = Object.keys(objectTwo)
  if (keysObjectOne.length !== keysObjectTwo.length) return false
  const hasDifference = keysObjectOne.some((key) => {
    const indexOfKey = keysObjectTwo.indexOf(key)
    keysObjectTwo[indexOfKey] = undefined
    return (
      objectOne[key as keyof typeof objectOne] !==
      objectTwo[key as keyof typeof objectOne]
    )
  })
  if (hasDifference) return false
  return !keysObjectTwo.some((key) => key !== undefined)
}
