import styled from 'styled-components'

export const FiltersContainer = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr 1.25fr 125px;
  gap: 18px;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  margin-bottom: 20px;
  border-radius: 6px;
  padding: 30px 30px;
  button {
    margin-top: 12px;
  }

  @media (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 850px) {
    grid-template-columns: 1fr;
  }
`
export const FiltersRadio = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 120px;
  overflow-y: auto;

  & label {
    margin: 6px 10px;
  }
`
