import React, { useState } from 'react'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import Heading from 'presentation/shared/components/Heading'
import SupportText from 'presentation/shared/components/SupportText'
import TextField from 'presentation/shared/components/TextField'
import Button from 'presentation/shared/components/Button'
import * as S from './styles'
import ActualPage from 'presentation/shared/components/ActualPage'
import { useFormik, useFormikContext } from 'formik'
import { AddSurgicalOrderFormValues } from '../index'
import { ReactComponent as SearchIcon } from 'presentation/assets/icons/search.svg'
import { ReactComponent as LoadingIcon } from 'presentation/assets/icons/reload-1s.svg'
import { useServices } from 'presentation/hooks/use-services'
import Chip from 'presentation/shared/components/Chip'
import moment from 'moment'
import { AgendaAllocation } from 'domain/entities/agenda-allocation.model'

type Props = {
  goNext?: () => void
  goBack?: () => void
}

export default function SelectSurgicalOrderAllocation({
  goNext,
  goBack
}: Props) {
  const { values, setFieldValue } =
    useFormikContext<AddSurgicalOrderFormValues>()
  const [loading, setLoading] = useState(false)
  const [allocations, setAllocations] = useState({})
  const [rooms, setRooms] = useState([])
  const [reserves, setReserves] = useState([])
  const [selectedRoom, setSelectedRoom] = useState('')
  const surgicalOrderService = useServices().surgicalOrder

  const searchAllocations = async () => {
    setSelectedRoom('')
    setRooms([])
    setReserves([])
    setAllocations([])
    setLoading(true)
    try {
      const allocations = await surgicalOrderService.getAgendaAllocation({
        hospitalId: values.hospital.hospital_id,
        beginDate: formik.values?.beginDate,
        endDate: formik.values?.endDate
      })
      const groupedAllocations = allocations.reduce((acc, item) => {
        const agendaDescription = item.agendaDescription.trim()
        const beginDate = String(item.beginDate).split('T')[0]
        acc[agendaDescription] = acc[agendaDescription] || []
        const existingDateGroup = acc[agendaDescription].find(
          (group) => group.date === beginDate
        )
        if (existingDateGroup) {
          existingDateGroup.allocations.push(item)
        } else {
          acc[agendaDescription].push({
            date: beginDate,
            allocations: [item]
          })
        }
        return acc
      }, {})

      setRooms(Object.keys(groupedAllocations))
      setAllocations(groupedAllocations)
    } catch (error) {
      toast.error('Ocorreu um erro ao buscar as reservas na agenda')
    } finally {
      setLoading(false)
    }
  }

  function handleSelectRoom(room: string) {
    setSelectedRoom(room)
    setReserves(
      allocations[room].map((allocation) => ({
        ...allocation
      }))
    )
  }

  function handleSelectReserve(allocation: AgendaAllocation) {
    setFieldValue('allocation', {
      id: allocation.agendaSequence,
      beginDateTime: allocation.beginDateTime
    })
  }

  const formik = useFormik({
    initialValues: {
      beginDate: '',
      endDate: ''
    },
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: () => undefined
  })

  return (
    <S.Wrapper>
      <ActualPage text="Voltar" onClick={goBack} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Heading size="xlarge" color="primary" as="h1">
          Novo pedido cirúrgico
        </Heading>
        {values?.allocation?.id && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Heading size="large" color="primary" as="h1">
              Reserva selecionada:
            </Heading>
            <Heading
              size="large"
              color="gray"
              as="h1"
              style={{
                marginLeft: '1rem'
              }}
            >
              {moment(values?.allocation?.beginDateTime)
                .utc(false)
                .format('DD/MM/YYYY HH:mm')}
            </Heading>
          </div>
        )}
      </div>
      <SupportText style={{ marginBottom: '1rem' }}>
        Busque uma reserva na agenda por um periodo de datas
      </SupportText>
      {!!values?.expectedDate?.length && (
        <Heading
          size="large"
          color="gray"
          as="h3"
          style={{
            marginBottom: '1rem'
          }}
        >
          Datas sugeridas:
          {values?.expectedDate
            ?.map((date) => moment(date).format('DD/MM/yyyy'))
            .join(', ')}
        </Heading>
      )}
      <S.SectionWrapper>
        <S.Inputs>
          <S.SearchInputWrapper>
            <TextField
              label="Data Inicial"
              labelColor="gray"
              type="date"
              showCalendarIcon
              bgColor="mainBg"
              name="beginDate"
              id="beginDate"
              onChange={formik.handleChange('beginDate')}
              defaultValue={formik.values?.beginDate}
              required
              error={
                formik.touched.beginDate ? formik.errors.beginDate : undefined
              }
              onBlur={formik.handleBlur('beginDate')}
              style={{ width: '100%' }}
            />
            <TextField
              label="Data Final"
              labelColor="gray"
              type="date"
              showCalendarIcon
              bgColor="mainBg"
              name="endDate"
              id="endDate"
              onChange={formik.handleChange('endDate')}
              defaultValue={formik.values?.endDate}
              required
              error={formik.touched.endDate ? formik.errors.endDate : undefined}
              onBlur={formik.handleBlur('endDate')}
              style={{ width: '100%' }}
            />
            <div
              style={{
                padding: '2rem 0'
              }}
            >
              <Button
                onClick={searchAllocations}
                disabled={
                  loading ||
                  !(!formik.errors.beginDate && !formik.errors.endDate)
                }
              >
                {loading ? (
                  <>
                    <LoadingIcon />
                    Buscando
                  </>
                ) : (
                  <>
                    <SearchIcon style={{ marginRight: '.5rem' }} />
                    Pesquisar
                  </>
                )}
              </Button>
            </div>
          </S.SearchInputWrapper>
        </S.Inputs>
        {!!rooms.length && (
          <>
            <SupportText style={{ margin: '0 0 1rem' }}>
              Selecione uma sala
            </SupportText>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {rooms.map((room) => (
                <Chip
                  key={room}
                  label={room}
                  customValue={room}
                  onCheck={() => handleSelectRoom(room)}
                  checked={selectedRoom === room}
                  style={{ margin: '.2rem' }}
                />
              ))}
            </div>
          </>
        )}

        {!!reserves.length && (
          <>
            <SupportText style={{ margin: '2.5rem 0 0' }}>
              Selecione uma reserva
            </SupportText>
            {reserves.map((reserve, index) => (
              <div key={index}>
                <SupportText
                  color="primaryDarker"
                  style={{
                    fontWeight: '600',
                    margin: '1rem 0'
                  }}
                >
                  {moment(reserve.date).format('DD/MM/YYYY')}
                </SupportText>
                <S.ChipsWrapper>
                  {reserve.allocations.map((allocation) => (
                    <Chip
                      key={allocation.agendaSequence}
                      label={moment(allocation?.beginDateTime)
                        .utc(false)
                        .format('HH:mm')}
                      customValue={allocation.agendaSequence}
                      onCheck={() => handleSelectReserve(allocation)}
                      checked={
                        values?.allocation?.id === allocation.agendaSequence
                      }
                    />
                  ))}
                </S.ChipsWrapper>
              </div>
            ))}
          </>
        )}
      </S.SectionWrapper>

      <S.Buttons>
        <Button
          disabled={!values?.allocation?.id}
          onClick={() => {
            goNext?.()
          }}
          type="button"
        >
          Próximo
        </Button>
      </S.Buttons>
    </S.Wrapper>
  )
}

const validationSchema = yup.object().shape({
  beginDate: yup.date().required(),
  endDate: yup
    .date()
    .required()
    .test(
      'greater_then_begin_date',
      'A data informada não pode ser menor que a data inicial.',
      (value, context) => {
        if (value) {
          const beginDateTime = Number(context.parent.beginDate)
          return beginDateTime <= Number(value)
        }
        return true
      }
    )
})
