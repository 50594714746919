import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 70px;
  display: flex;
  flex-direction: column;
  max-height: 90vh;

  div {
    justify-self: center;
    margin: auto 0;
    flex: 0;
  }
`

export const Buttons = styled.footer`
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
  margin-top: 10px;
  button {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  button:last-child {
    margin-left: 10px;
  }
`
