import React from 'react'

import { useFormik } from 'formik'
import * as yup from 'yup'

import * as S from './styles'
import TextField from 'presentation/shared/components/TextField'
import Button from 'presentation/shared/components/Button'
import Table from 'presentation/shared/components/Table'
import { ReactComponent as PlusIcon } from 'presentation/assets/icons/plus.svg'
import { crmMask, phoneMaskWithoutDDI } from 'presentation/utils/masks'

type Props = {
  selectedAnesthetistTeam: any[]
  setSelectedAnesthetistTeam?: (procedure: any) => void
  doctorName?: string
  onlyVisualization?: boolean
}

export const AnesthetistTeam = ({
  onlyVisualization,
  selectedAnesthetistTeam,
  setSelectedAnesthetistTeam
}: Props) => {
  const validationSchema = yup.object().shape({
    name: yup.string().required(),
    crm: yup.string().required(),
    phone: yup
      .string()
      .matches(/^\(\d{2}\) \d{9}$/gi, 'Telefone inválido')
      .required(),
    anesthesiaType: yup.string().required()
  })

  const formik = useFormik({
    initialValues: {
      name: '',
      crm: '',
      phone: '',
      anesthesiaType: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setSelectedAnesthetistTeam &&
        setSelectedAnesthetistTeam([
          ...(selectedAnesthetistTeam ?? []),
          {
            anesthesiaType: values.anesthesiaType,
            name: values.name,
            crm: values.crm,
            phone: values.phone
          }
        ])
      formik.resetForm()
    }
  })

  const onDeleteRow = (index: number[]) => {
    setSelectedAnesthetistTeam &&
      setSelectedAnesthetistTeam(
        selectedAnesthetistTeam
          ?.filter(
            (anesthetistTeam, anesthetistTeamIndex) =>
              !index.includes(anesthetistTeamIndex)
          )
          .map((anesthetistTeams) => {
            return { ...anesthetistTeams }
          })
      )
  }

  const isValid = () => {
    return formik.isValid
  }

  return (
    <S.Wrapper>
      {!onlyVisualization && (
        <S.Inputs>
          <TextField
            label="Médico Anestesista"
            labelColor="gray"
            name="name"
            id="name"
            bgColor="mainBg"
            value={formik.values.name}
            onChange={formik.handleChange('name')}
          />
          <TextField
            label="CRM"
            labelColor="gray"
            name="crm"
            id="crm"
            mask={crmMask}
            bgColor="mainBg"
            value={formik.values.crm}
            onChange={formik.handleChange('crm')}
          />
          <TextField
            label="Telefone"
            labelColor="gray"
            name="crm"
            id="crm"
            mask={phoneMaskWithoutDDI}
            bgColor="mainBg"
            value={formik.values.phone}
            onChange={formik.handleChange('phone')}
          />
          <TextField
            label="Tipo de anestesia"
            labelColor="gray"
            name="anesthesiaType"
            id="anesthesiaType"
            bgColor="mainBg"
            value={formik.values.anesthesiaType}
            onChange={formik.handleChange('anesthesiaType')}
          />
          <Button
            type="button"
            disabled={!isValid()}
            onClick={formik.submitForm}
          >
            <PlusIcon width="12px" height="12px" />
            Adicionar
          </Button>
        </S.Inputs>
      )}
      <Table
        checkbox={!onlyVisualization}
        data={selectedAnesthetistTeam}
        columns={anesthetistColumns}
        rowsDelete={onDeleteRow}
        pagination={false}
        noItemsDescription="Nenhum médico anestesista adicionado"
      />
    </S.Wrapper>
  )
}

export default React.memo(AnesthetistTeam)

export const anesthetistColumns = [
  {
    label: 'Médico Anestesista',
    name: 'name',
    options: {
      sort: false
    }
  },
  {
    label: 'CRM',
    name: 'crm',
    options: {
      sort: false
    }
  },
  {
    label: 'Telefone',
    name: 'phone',
    options: {
      sort: false
    }
  },
  {
    label: 'Tipo de anestesia',
    name: 'anesthesiaType',
    options: {
      sort: false
    }
  }
]
