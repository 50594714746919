import theme from 'presentation/styles/theme'
import styled from 'styled-components'

export const bg = styled.div<{ noBackground: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ noBackground }) =>
    noBackground ? 'transparent' : 'rgba(0, 0, 0, 0.6)'};
  z-index: ${({ theme }) => theme.layers.modal};
  display: flex;
  justify-content: center;
  align-items: center;
`

type Props = {
  bgColor?: keyof typeof theme.colors
  width?: string
}

export const Wrapper = styled.div<Props>`
  width: ${({ width }) => width};
  box-shadow: 0px 4px 9px 2px rgba(0, 0, 0, 0.25);
  background: ${({ bgColor }) =>
    bgColor ? theme.colors[bgColor] : theme.colors.white};
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 999;
  border-radius: ${({ theme }) => theme.border.radius.small};
`
