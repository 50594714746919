import React from 'react'
import Modal from 'presentation/shared/components/Modal'

type Props = {
  show: boolean
  action?: () => void
  addPatient?: () => void
  close: () => void
}

export default function NotFountPacientModal({ show, action, close }: Props) {
  return (
    <Modal
      // actionLabel="Adicionar paciente"
      // action={action}
      type="alert"
      preventAutomateClose
      title="Paciente não encontrado"
      show={show}
      hasCloseButton="Cancelar"
      close={close}
    ></Modal>
  )
}
