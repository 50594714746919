import React from 'react'

import Heading from 'presentation/shared/components/Heading'
import NewTimeline, {
  TimelineStatusItem
} from 'presentation/shared/components/NewTimeline'

import * as S from './styles'

type TimelineStatusProps = {
  timelineItems: TimelineStatusItem[]
}

export const TimelineStatus = ({ timelineItems }: TimelineStatusProps) => {
  return (
    <S.Wrapper>
      <Heading size="large" color="primary" as="h2">
        Status
      </Heading>
      <section>
        <NewTimeline items={timelineItems} />
      </section>
    </S.Wrapper>
  )
}
