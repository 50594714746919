import styled from 'styled-components'

export const Wrapper = styled.div``

export const FiltersContainer = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 0.5fr;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
  margin-bottom: 20px;
  border-radius: 6px;
  padding: 50px 30px;

  @media (max-width: 1400px) {
    grid-template-columns: 1fr;
    grid-gap: 12px;
  }
`

export const FiltersInput = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;
  place-content: center;

  div:first-child {
    grid-column: span 2;
  }
`

export const FiltersButton = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  padding: 0 1rem;

  button {
    width: 50%;
    margin-left: 12px;
  }

  @media (max-width: 1400px) {
    padding: 0;

    button {
      width: 50%;
      margin-left: 0;
    }
  }
`
export const PendenciesTable = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px 40px;
  margin-top: 40px;

  @media (max-width: 1400px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`

export const PendencyItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  cursor: pointer;
`

export const PendencyHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  span {
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 700;
    width: 60%;
  }
`

export const PendencyInfo = styled.div``

export const InfoItem = styled.div`
  display: flex;
  align-items: center;

  span {
    font-size: ${({ theme }) => theme.font.sizes.xxsmall};
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 4px;
  }

  p {
    font-size: ${({ theme }) => theme.font.sizes.xxsmall};
    font-weight: 500;
  }
`
export const NoPendencies = styled.h2`
  color: ${({ theme }) => theme.colors.lightGray};
  font-size: ${({ theme }) => theme.font.sizes.medium};
  font-weight: 600;
  text-align: center;
`
