import React from 'react'

import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { ApiRepository } from 'repository/api-repository'
import { SecretaryRepository } from 'repository/repositories/secretary/secretary-repository'
import { RemoteLoadSecretary } from 'service/usecases/load-secretary/remote-load-secretary'
import { HospitalRepository } from 'repository/repositories/hospital/hospital-repository'
import { RemoteLoadHospitals } from 'service/usecases/load-hospitals/remote-load-hospitals'
import { RemoteLoadPatientByCpf } from 'service/usecases/load-patient-by-cpf/remote-load-patient-by-cpf'
import { PatientRepository } from 'repository/repositories/patient/patient-repository'
import { HealthInsuranceRepository } from 'repository/repositories/health-insurance/health-insurance-repository'
import { RemoteLoadTuss } from 'service/usecases/load-tuss/remote-load-tuss'
import { RemoteLoadCid } from 'service/usecases/load-cid/remote-load-cid'
import { SurgicalOrderRepository } from 'repository/repositories/surgical-order/surgical-order-repository'
import { RemoteInvitePatient } from 'service/usecases/invite-patient/remote-invite-patient'
import { RemoteRegisterSurgicalOrder } from 'service/usecases/register-surgical-order/remote-register-surgical-order'
import { RemoteAddFavorite } from 'service/usecases/add-favorite/remote-add-favorite'
import { SurgeryCenterRepository } from 'repository/repositories/surgery-center/surgery-center-repository'
import { RemoteLoadSurgeryCenter } from 'service/usecases/load-surgery-center/remote-load-surgery-center'
import HospitalAddSurgicalOrder from 'presentation/hospital/pages/AddSurgicalOrder'
import { RemoteAddPatient } from 'service/usecases/add-patient/remote-add-patient'
import { RemoteLoadSurgeries } from 'service/usecases/load-surgeries/remote-load-surgeries'
import { RemoteLoadHealthInsurancePlans } from 'service/usecases/load-health-insurance-plans/remote-load-health-insurance-plans'
import { RemoteSearchPatient } from 'service/usecases/search-patient/remote-search-patient'
import { RemoteUploadSurgicalOrderDocument } from 'service/usecases/upload-surgical-order-document/remote-upload-surgical-order-document'

export default function makeSecretaryAddSurgicalOrder() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )
  const secretaryRepository = new SecretaryRepository(apiRepository)
  const hospitalRepository = new HospitalRepository(apiRepository)
  const patientRepository = new PatientRepository(apiRepository)
  const healthInsuranceRepository = new HealthInsuranceRepository(apiRepository)
  const surgicalOrderRepository = new SurgicalOrderRepository(apiRepository)
  const surgeryCenterRepository = new SurgeryCenterRepository(apiRepository)

  const loadSecretary = new RemoteLoadSecretary(secretaryRepository)
  const loadHospitals = new RemoteLoadHospitals(hospitalRepository)
  const loadPatient = new RemoteLoadPatientByCpf(patientRepository)
  const invitePatient = new RemoteInvitePatient(patientRepository)
  const loadInsurances = new RemoteLoadHealthInsurancePlans(
    healthInsuranceRepository
  )
  const loadTuss = new RemoteLoadTuss(surgicalOrderRepository)
  const loadCid = new RemoteLoadCid(surgicalOrderRepository)
  const registerOrder = new RemoteRegisterSurgicalOrder(surgicalOrderRepository)
  const addFavorite = new RemoteAddFavorite(surgicalOrderRepository)
  const loadSurgeryCenters = new RemoteLoadSurgeryCenter(
    surgeryCenterRepository
  )
  const addPatient = new RemoteAddPatient(patientRepository)
  const loadSurgeries = new RemoteLoadSurgeries(surgicalOrderRepository)
  const searchPatient = new RemoteSearchPatient(patientRepository)
  const uploadSurgicalOrderDocument = new RemoteUploadSurgicalOrderDocument(
    surgicalOrderRepository
  )

  return (
    <HospitalAddSurgicalOrder
      searchPatient={searchPatient}
      loadHospitals={loadHospitals}
      loadSecretary={loadSecretary}
      loadPatient={loadPatient}
      loadInsurances={loadInsurances}
      loadCid={loadCid}
      loadTuss={loadTuss}
      invitePatient={invitePatient}
      addOrder={registerOrder}
      addFavorite={addFavorite}
      loadSurgeryCenters={loadSurgeryCenters}
      addPatient={addPatient}
      loadSurgeries={loadSurgeries}
      uploadSurgicalOrderDocument={uploadSurgicalOrderDocument}
    />
  )
}
