import { SurgicalPendency } from 'domain/entities/surgical-pendency'
import moment from 'moment-timezone'
import Heading from 'presentation/shared/components/Heading'
import React from 'react'
import * as S from './styles'
type PendencyHeaderInfoProps = {
  surgicalPendency?: SurgicalPendency
}

export default function PendencyHeaderInfo({
  surgicalPendency
}: PendencyHeaderInfoProps) {
  const formatDate = (date?: string) => {
    if (date) {
      return moment(date).utc(true).format('DD/MM/YYYY - HH:mm')
    }
    return ''
  }

  return (
    <S.HeaderContainer>
      <Heading size="large" color="primary">
        {surgicalPendency?.patient?.name}
      </Heading>
      <Heading size="medium" color="primary">
        {surgicalPendency?.surgicalOrder?.procedure?.[0]?.description}
      </Heading>
      <div>
        <div>
          <label>Responsável CRMO:</label>
          <strong>{surgicalPendency?.requestedBy?.name || '-'}</strong>
          <label>Pendência gerada:</label>
          <strong>{formatDate(surgicalPendency?.createdAt) || '-'}</strong>
        </div>
        <div>
          <label>Retorno Pendência:</label>{' '}
          <strong>{formatDate(surgicalPendency?.answeredAt) || '-'}</strong>
          <label>Data da validação:</label>
          <strong>{formatDate(surgicalPendency?.approvedAt) || '-'}</strong>
        </div>
      </div>
    </S.HeaderContainer>
  )
}
