import React from 'react'

type DateMinWidthProps = {
  value: string | number
}

export const DateMinWidth = ({ value }: DateMinWidthProps) => {
  return (
    <div
      style={{
        minWidth: '125px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {value}
    </div>
  )
}
