import styled from 'styled-components'

export const InputsContainer = styled.div`
  column-count: 2;
  column-gap: 24px;
  height: auto;

  margin-bottom: 24px;

  @media (max-width: 1100px) {
    column-count: 1;
  }
`

export const InputContainer = styled.div`
  display: flex;
  width: 450px;
  justify-content: space-between;
  margin-bottom: 12px;

  @media (max-width: 1100px) {
    width: 600px;
  }

  @media (min-width: 1365px) {
    width: 550px;
  }
`
