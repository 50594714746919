import { lighten } from 'polished'
import styled from 'styled-components'

type ListWrapperProps = {
  hasMoreButton: boolean
}

export const ListWrapper = styled.div<ListWrapperProps>`
  border-radius: ${({ theme }) => theme.border.radius.small};
  background-color: ${({ theme }) => theme.colors.lightestGray};

  li {
    font-weight: ${({ theme }) => theme.font.semiBold};
    font-size: ${({ theme }) => theme.font.sizes.xsmall};
    line-height: ${({ theme }) => theme.font.sizes.xlarge};
    flex-direction: row-reverse;
  }
  margin-bottom: ${({ hasMoreButton }) => (hasMoreButton ? '14px' : '28px')};

  max-height: 300px;
  overflow-y: auto;
`

export const DocumentsListContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const MoreExamReportButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 54px;
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.colors.lightestGray};
  border-radius: ${({ theme }) => theme.border.radius.small};
  font-weight: ${({ theme }) => theme.font.semiBold};
  color: ${({ theme }) => theme.colors.gray};

  &:hover {
    background-color: ${({ theme }) => lighten(0.68, theme.colors.gray)};
  }
`
