import React from 'react'
import PatientInfoLayout from 'presentation/secretary/layouts/PatientInfo'
import { useLocation } from 'react-router-dom'
import { Patient } from 'domain/entities/patient-model'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'

export default function PatientInfo() {
  const { state } = useLocation<{
    patient?: Patient
    surgicalOrder: SurgicalOrderModel
  }>()
  return (
    <PatientInfoLayout
      patient={state?.patient}
      surgicalOrder={state?.surgicalOrder}
    />
  )
}
