import {
  PendencyType,
  SurgicalPendency
} from 'domain/entities/surgical-pendency'
import { FormikProvider, useFormik } from 'formik'
import Modal from 'presentation/hospital/components/Modal'
import Heading from 'presentation/shared/components/Heading'
import React, { useEffect, useState } from 'react'
import PendencyHeaderInfo from './PendencyHeaderInfo'
import PendencyReply from './PendencyReply'
import PendencyDocuments from './PendencyDocuments'
import * as S from './styles'
import { PatientDocument } from 'common/enum/patient-document'
import { useServices } from 'presentation/hooks/use-services'
import { toast } from 'react-toastify'
import TypedModal from 'presentation/shared/components/Modal'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { ListFile } from 'presentation/shared/components/UploadSection/UploadListFiles/UploadListFile'
import { generateHash } from 'common/utils/generateHash'
import { renameFile } from 'common/utils/file/renameFile'

type TypedPendencyModalProps = {
  onClose: (shouldRefresh: boolean) => void
  show: boolean
  surgicalPendency?: SurgicalPendency
  type?: PendencyType
} & WithLoadingProps

export type PendencyDocument = {
  type: PatientDocument
  label: string
  file: File
} & ListFile

export type AnswerPendencyForm = {
  answer?: string
  documents: PendencyDocument[]
}

const TypedPendencyModal = WithLoading(
  ({
    surgicalPendency,
    onClose,
    show,
    type,
    setIsLoading
  }: TypedPendencyModalProps) => {
    const [title, setTitle] = useState<string>('')
    const [showSuccess, setShowSuccess] = useState<boolean>(false)
    const [mode, setMode] = useState<'documents' | 'reply'>('reply')
    const defaultTitle = `Pendência Cirúrgica de ${type ? langType[type] : ''}`

    const surgicalPendencyService = useServices().surgicalPendency

    const changeTitle = (newTitle?: string) => {
      setTitle(newTitle ? newTitle : defaultTitle)
    }
    const changeMode = () => {
      setMode(mode === 'documents' ? 'reply' : 'documents')
    }

    useEffect(() => {
      if (show && type) {
        changeTitle()
      }
    }, [show, type])

    const initialValues = {
      documents: [],
      answer: surgicalPendency?.answer
    }
    const formik = useFormik<AnswerPendencyForm>({
      initialValues,
      onSubmit: async (values) => {
        setIsLoading(true)
        try {
          const group_id = generateHash()
          await Promise.all(
            values.documents.map(async (document) => {
              if (document.file) {
                const file = renameFile(document.file, document.label)
                const form = new FormData()
                form.append('file', file)
                form.append(
                  'surgical_pendency_id',
                  String(surgicalPendency?.surgical_pendency_id)
                )
                form.append('type', document.type)
                form.append('group_id', group_id)
                const uploadedFile =
                  surgicalPendencyService.uploadSurgicalPendencyDocument({
                    type: document.type,
                    form: form,
                    file
                  })
                const filename = document.file.name
                return await toast.promise(uploadedFile, {
                  pending: `Fazendo o envio do arquivo ${filename}`,
                  success: `Arquivo ${filename} enviado com sucesso`,
                  error: `Falha ao enviar o arquivo ${filename}`
                })
              }
            })
          )
          await surgicalPendencyService.answerSurgicalPendency({
            surgical_pendency_id: Number(
              surgicalPendency?.surgical_pendency_id
            ),
            answer: String(values.answer),
            group_id
          })
          setShowSuccess(true)
        } catch (e: any) {
          toast.error(e.message)
        } finally {
          setIsLoading(false)
        }
      }
    })
    useEffect(() => {
      formik.resetForm({
        values: initialValues
      })
    }, [surgicalPendency])

    const onFinish = () => {
      onClose(true)
      setShowSuccess(false)
    }

    return (
      <>
        {show ? (
          <Modal>
            <S.Wrapper>
              <Heading size="large" color="gray">
                {title}
              </Heading>
              <PendencyHeaderInfo surgicalPendency={surgicalPendency} />
              <FormikProvider value={formik}>
                {mode === 'reply' ? (
                  <PendencyReply
                    onClose={onClose}
                    changeMode={changeMode}
                    surgicalPendency={surgicalPendency}
                    alreadyHasDocuments={formik.values.documents.length > 0}
                  />
                ) : null}
                {mode === 'documents' && type ? (
                  <PendencyDocuments
                    changeMode={changeMode}
                    type={type}
                    changeTitle={changeTitle}
                  />
                ) : null}
              </FormikProvider>
            </S.Wrapper>
          </Modal>
        ) : null}
        <TypedModal
          type="check"
          show={showSuccess}
          actionLabel="Fechar"
          action={onFinish}
          close={onFinish}
          title="Pendência respondida com sucesso"
        />
      </>
    )
  }
)

export default TypedPendencyModal

const langType = {
  [PendencyType.ASSISTANCE]: 'Assistencial',
  [PendencyType.AUTHORIZATION]: 'Autorização'
}
