import { AddPatient } from 'domain/usecases/patient/add-patient'
import { PatientRepository } from 'repository/interfaces/patient-repository'

export class RemoteAddPatient implements AddPatient {
  constructor(private readonly patientRepository: PatientRepository) {}

  add(params: AddPatient.Params): Promise<AddPatient.Model> {
    return this.patientRepository.addPatient(params)
  }
}
