import styled from 'styled-components'

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  margin-bottom: 20px;

  div {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 4px;
    div {
      margin-top: 0;
      display: flex;
      flex-direction: row;
      label {
        color: ${({ theme }) => theme.colors.primary};
      }
      strong {
        color: ${({ theme }) => theme.colors.gray};
        font-weight: ${({ theme }) => theme.font.semiBold};
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }

  @media (max-width: 900px) {
    div {
      display: grid;
      grid-template-columns: 1fr;
      div {
        display: grid;
        grid-template-columns: auto 1fr;
      }
    }
  }
  @media (max-width: 600px) {
    div {
      display: grid;
      grid-template-columns: 1fr;
      div {
        display: grid;
        grid-template-columns: 1fr;
      }
    }
  }
`
