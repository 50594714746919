import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transform-origin: center;
    z-index: ${theme.layers.alwaysOnTop};
  `}
`

export const Container = styled.form`
  ${({ theme }) => css`
    transform-origin: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: ${theme.colors.mainBg};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 25px;
    width: 40%;
    min-width: min(700px, 100%);
    overflow-y: auto;
    border-radius: ${theme.border.radius.large};
    & > div {
      width: 100%;
    }
    z-index: ${theme.layers.alwaysOnTop};
  `}
`

export const CheckboxWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    margin-top: 30px;

    & > div {
      margin-right: 20px;
    }

    p {
      margin-right: 30px;
      font-weight: ${theme.font.semiBold};
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.gray};
    }
  `}
`

export const Persona = styled.div`
  display: grid;
  grid-template-columns: 100px 80px 80px 1fr 1fr;
  align-items: center;
  margin-top: 10px;

  & > div + div {
    margin-left: 10px;
  }

  & > div {
    p {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.red};
    }
  }

  input[type='text'] {
    margin: 0;
    font-size: 16px;
  }
`
export const PersonaLabel = styled.label`
  margin-right: 10px;
`

export const Footer = styled.footer`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  button {
    max-width: 180px;
    & + button {
      margin-left: 10px;
    }
  }
`
