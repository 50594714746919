import { SurgicalPendency } from 'domain/entities/surgical-pendency'
import moment from 'moment-timezone'

export function adapterSurgicalPendencyColumn(data: SurgicalPendency) {
  const formatDate = (date?: string) => {
    if (date) {
      return moment(date).utc(true).format('DD/MM/YYYY - HH[h]')
    }
    return ''
  }

  return {
    surgical_pendency_id: data.surgical_pendency_id,
    surgical_order_id: data.surgical_order_id,
    createdAt: formatDate(data.createdAt),
    patientName: data.patient?.name,
    doctorName: data.doctorName,
    surgery: data.surgicalOrder?.procedure?.[0]?.description,
    status: data.status,
    answeredAt: formatDate(data.answeredAt),
    requestedByName: data.requestedBy?.name
  }
}

export function mapSurgicalPendencyColumn(data: SurgicalPendency[]) {
  return data.map((unformattedData) =>
    adapterSurgicalPendencyColumn(unformattedData)
  )
}
