import { PatientDocument } from 'common/enum/patient-document'
import { DocumentsType } from 'domain/entities/document-types'
import { useUploadSection } from 'presentation/hooks/use-upload-section'
import { SurgicalDocumentListFile } from 'presentation/secretary/layouts/SurgicalOrderDetails'
import Modal from 'presentation/shared/components/Modal'
import { UploadSection } from 'presentation/shared/components/UploadSection'
import React, { useState } from 'react'
import TypedModal from 'presentation/shared/components/Modal'
import { generateHash } from 'common/utils/generateHash'
import { useServices } from 'presentation/hooks/use-services'
import { toast } from 'react-toastify'
import LoadingModal from 'presentation/shared/components/LoadingModal'
import { renameFile } from 'common/utils/file/renameFile'

type UploadSurgicalDocumentsModalProps = {
  initialValues: SurgicalDocumentListFile[]
  type: PatientDocument
  onClose: () => void
  refreshData: () => void
  surgical_order_id: number
}

export function UploadSurgicalDocumentsModal({
  onClose,
  type,
  initialValues,
  refreshData,
  surgical_order_id
}: UploadSurgicalDocumentsModalProps) {
  const [showConfirmUnify, setShowConfirmUnify] = useState<boolean>(false)
  const [handleSuccessModal, setHandleSuccessModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [cancelModal, setCancelModal] = useState<boolean>(false)

  const alreadySaved = initialValues.length !== 0
  const documentType = DocumentsType.getDocumentTypeByType(type)

  const services = useServices().surgicalOrder

  const onSubmit = async () => {
    try {
      setIsLoading(true)
      setShowConfirmUnify(false)
      const group_id = generateHash()
      for (const { file } of uploadSection.files) {
        const renamedFile = renameFile(file, documentType?.label, {
          forceOriginalExtension: true
        })
        await services.uploadToMergeDocument({
          file: renamedFile,
          type,
          group_id
        })
      }
      await services.confirmMergeDocuments({
        group_id,
        surgical_order_id
      })
      refreshData()
      setHandleSuccessModal(true)
    } catch (error) {
      toast.error(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const uploadSection = useUploadSection({
    ...documentType,
    initialFiles: initialValues,
    verifyUniqueFile: alreadySaved ? false : true
  })

  const canUpload = {
    canAdd: uploadSection.filesInfo.canAdd && !alreadySaved,
    canDelete: !alreadySaved,
    canDownload: true,
    canUpdate: !alreadySaved
  }

  const downloadAllDocuments = () => {
    uploadSection.files.forEach((file) => {
      uploadSection.onDownload(file)
    })
  }

  const onConfirm = () => {
    if (uploadSection.files.length === 0) return onClose()
    return setShowConfirmUnify(true)
  }

  const onCancel = () => {
    if (uploadSection.files.length === 0 || alreadySaved) return onClose()
    return setCancelModal(true)
  }

  const titleProps = {
    titleButtonProps: {
      showTitleButton: true,
      onClickTitleButton: downloadAllDocuments
    },
    title: documentType?.title
  }
  const actionProps = {
    primaryButtonProps: {
      text: 'Enviar',
      show: !alreadySaved
    },
    secondaryButtonProps: {
      text: alreadySaved ? 'Fechar' : 'Cancelar'
    },
    onPrimaryButtonClick: onConfirm,
    onSecondaryButtonClick: onCancel
  }
  return (
    <>
      <Modal
        title="Deseja incluir algum documento?"
        preventAutomateClose
        close={onClose}
        style={{
          height: 'max(600px,60%)',
          width: 'min(100%, 900px)',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          padding: '31px 73px'
        }}
        show
      >
        <UploadSection
          {...uploadSection}
          titleProps={titleProps}
          actionProps={actionProps}
          can={canUpload}
          listContainerProps={{
            height: '100%'
          }}
          errors={uploadSection.filesInfo.errorMessage}
          acceptFiles={documentType?.acceptFiles}
          showActions
          showTitle
        />
      </Modal>
      <Modal
        title="Documentos anexados com sucesso!"
        show={handleSuccessModal}
        close={onClose}
      />
      <TypedModal
        type="check"
        show={showConfirmUnify}
        actionLabel="Confirmar"
        action={() => onSubmit()}
        title="Deseja realmente confirmar?"
        description="Após confirmação, os documentos serão unificados, esta ação não poderá ser desfeita."
        hasCloseButton="Cancelar"
        closeButtonProps={{
          variant: 'outlined',
          style: {
            marginTop: '10px'
          }
        }}
        close={() => setShowConfirmUnify(false)}
        subtitleProps={{
          colorFont: 'gray'
        }}
        preventAutomateClose
      />
      <TypedModal
        type="alert"
        show={cancelModal}
        actionLabel="Confirmar"
        action={onClose}
        title="Você possui documentos não enviados, deseja realmente cancelar?"
        description="Documentos não enviados serão perdidos."
        hasCloseButton="Cancelar"
        closeButtonProps={{
          variant: 'outlined',
          style: {
            marginTop: '10px'
          }
        }}
        close={() => setCancelModal(false)}
        preventAutomateClose
      />
      <LoadingModal show={isLoading} />
    </>
  )
}
