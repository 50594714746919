import styled from 'styled-components'

export const ListWrapper = styled.div`
  border-radius: ${({ theme }) => theme.border.radius.small};
  background-color: ${({ theme }) => theme.colors.lightestGray};

  li {
    font-weight: ${({ theme }) => theme.font.semiBold};
    font-size: ${({ theme }) => theme.font.sizes.xsmall};
    line-height: ${({ theme }) => theme.font.sizes.xlarge};
  }
  margin-bottom: 24px;
`

export const DocumentsContainer = styled.div`
  display: flex;
  flex-direction: column;
`
