import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import ProcedureInfoLayout from 'presentation/secretary/layouts/ProcedureInfo'

export default function ProcedureInfo() {
  const { state } = useLocation<{ surgicalOrder?: SurgicalOrderModel }>()

  const history = useHistory()
  if (!state?.surgicalOrder) {
    history.goBack()
  }
  return (
    <ProcedureInfoLayout
      procedures={state?.surgicalOrder?.procedure?.map((procedure) => ({
        tuss_id: procedure.code,
        description: procedure.description,
        principalProcedure: procedure.isMain ? 'Procedimento Principal' : '',
        quantity: procedure.quantity,
        doctor: procedure.doctor_name
      }))}
      cids={state?.surgicalOrder?.cid?.map((cid) => ({
        description: cid.description,
        cid_id: cid.code
      }))}
      opmes={state?.surgicalOrder?.opme?.solicitations.map((solicitation) => ({
        description: solicitation.description,
        quantity: solicitation.quantity,
        observation: solicitation.observation
      }))}
      surgeryInfo={state?.surgicalOrder}
      providers={state?.surgicalOrder?.opme?.providers}
    />
  )
}
