import React from 'react'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { ApiRepository } from 'repository/api-repository'
import { SurgicalOrderRepository } from 'repository/repositories/surgical-order/surgical-order-repository'
import { RemoteLoadSurgicalOrder } from 'service/usecases/load-surgical-order/remote-load-surgical-order'
import SecretaryOrderDetails from 'presentation/secretary/pages/OrderDetails'
import { RemoteUploadSurgicalOrderDocument } from 'service/usecases/upload-surgical-order-document/remote-upload-surgical-order-document'
import { HospitalRepository } from 'repository/repositories/hospital/hospital-repository'
import { RemoteLoadHospitals } from 'service/usecases/load-hospitals/remote-load-hospitals'
import { RemoteLoadHealthInsurance } from 'service/usecases/load-health-insurance/remote-load-health-insurance'
import { RemoteLoadSurgeryCenter } from 'service/usecases/load-surgery-center/remote-load-surgery-center'
import { SurgeryCenterRepository } from 'repository/repositories/surgery-center/surgery-center-repository'
import { HealthInsuranceRepository } from 'repository/repositories/health-insurance/health-insurance-repository'

export default function makeSecretaryOrderDetails() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )
  const surgicalOrderRepository = new SurgicalOrderRepository(apiRepository)
  const hospitalRepository = new HospitalRepository(apiRepository)
  const surgeryCenterRepository = new SurgeryCenterRepository(apiRepository)
  const healthInsurancesRepository = new HealthInsuranceRepository(
    apiRepository
  )

  const loadSurgicalOrder = new RemoteLoadSurgicalOrder(surgicalOrderRepository)
  const uploadSurgicalOrderDocument = new RemoteUploadSurgicalOrderDocument(
    surgicalOrderRepository
  )
  const loadHospitals = new RemoteLoadHospitals(hospitalRepository)

  const loadHealthInsurances = new RemoteLoadHealthInsurance(
    healthInsurancesRepository
  )
  const loadSurgeryCenters = new RemoteLoadSurgeryCenter(
    surgeryCenterRepository
  )

  return (
    <SecretaryOrderDetails
      loadOrder={loadSurgicalOrder}
      uploadSurgicalOrderDocument={uploadSurgicalOrderDocument}
      loadHospitals={loadHospitals}
      loadHealthInsurance={loadHealthInsurances}
      loadSurgeryCenter={loadSurgeryCenters}
    />
  )
}
