import Heading from 'presentation/shared/components/Heading'
import React from 'react'
import { fields } from './fields'
import * as S from './styles'
import { useFormik } from 'formik'
import Button from 'presentation/shared/components/Button'
import PasswordField from 'presentation/shared/components/PasswordField'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import { useServices } from 'presentation/hooks/use-services'

type Props = {
  setUpdatePasswordSuccess: React.Dispatch<React.SetStateAction<boolean>>
}

export default function UpdatePasswordForm({
  setUpdatePasswordSuccess
}: Props) {
  const userServices = useServices().user
  const formik = useFormik({
    initialValues: {
      actualPassword: '',
      newPassword: '',
      newPasswordConfirmation: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await userServices.updatePassword(values)
        setUpdatePasswordSuccess(true)
      } catch (error) {
        toast.error('Erro ao atualizar senha')
      }
    }
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Heading size="huge" color="primary" style={{ marginBottom: '48px' }}>
        Alterar senha
      </Heading>

      <S.Wrapper>
        {fields.map(({ name, placeholder, label }) => (
          <PasswordField
            key={name}
            label={label}
            // @ts-ignore: Unreachable code error
            error={formik.errors[name]}
            // @ts-ignore: Unreachable code error
            initialValue={formik.values[name]}
            placeholder={placeholder}
            name={name}
            onInputChange={formik.handleChange(name)}
            onBlur={formik.handleBlur(name)}
          />
        ))}
      </S.Wrapper>

      <S.Actions>
        <Button type="submit" style={{ maxWidth: '150px' }}>
          Alterar
        </Button>
      </S.Actions>
    </form>
  )
}

const validationSchema = yup.object().shape({
  actualPassword: yup.string().required(),
  newPassword: yup.string().required(),
  newPasswordConfirmation: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Confirmação diferente da senha')
    .required()
})
