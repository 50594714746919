import React from 'react'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiRepository } from 'repository/api-repository'
import { SecretaryRepository } from 'repository/repositories/secretary/secretary-repository'
import { RemoteLoadSecretaryScheduleSurgicalOrders } from 'service/usecases/load-secretary-schedule-surgical-orders/load-secretary-schedule-surgical-orders'
import SecretaryLoadScheduleSurgicalOrder from 'presentation/secretary/pages/ScheduleSurgicalOrders'

export default function makeSecretaryLoadScheduleSurgicalOrders() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )
  const secretaryRepository = new SecretaryRepository(apiRepository)

  const loadSecretaryScheduleSurgicalOrders =
    new RemoteLoadSecretaryScheduleSurgicalOrders(secretaryRepository)

  return (
    <SecretaryLoadScheduleSurgicalOrder
      loadSecretaryScheduleSurgicalOrder={loadSecretaryScheduleSurgicalOrders}
    />
  )
}
