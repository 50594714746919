import { LoadSurgeries } from 'domain/usecases/surgery/load-surgeries'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteLoadSurgeries implements LoadSurgeries {
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  load(params: LoadSurgeries.Params): Promise<LoadSurgeries.Model> {
    return this.surgicalOrderRepository.loadSurgeries(params)
  }
}
