import React, { useState } from 'react'
import { useHistory } from 'react-router'
import moment from 'moment-timezone'

import * as S from './styles'
import Container from 'presentation/hospital/components/Container'
import Heading from 'presentation/shared/components/Heading'
import ProfilePic from 'presentation/shared/components/ProfilePic'
import Button from 'presentation/shared/components/Button'
import ChangeOrderStatusModal from 'presentation/hospital/components/ChangeOrderStatusModal'
import SurgeryDocumentList from 'presentation/shared/components/SurgeryDocumentList'
import ArrowIcon from 'presentation/assets/icons/listArrow.png'
import List, { ListItem } from 'presentation/shared/components/List'
import { Patient } from 'domain/entities/patient-model'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import { SurgeryStatus } from 'domain/entities/surgery-status'
import { getStatusInfo } from 'presentation/utils/service-status'
import getActualSurgeryStatus from 'common/utils/getActualSurgeryStatus'
import { LoadHospitals } from 'domain/usecases/hospital/load-hospitals'
import { LoadHealthInsurance } from 'domain/usecases/health-insurance/load-health-insurance'
import { LoadSurgeryCenter } from 'domain/usecases/surgery-center/load-surgery-center'
import { ReorderSurgicalOrder } from 'domain/usecases/surgical-order/reorder-surgery'
import { LoadSurgicalOrderDocument } from 'domain/usecases/surgical-order/load-surgical-order-document'

type Props = {
  doctorName?: string
  patient?: Patient
  unit?: string
  id?: string
  surgicalOrder?: SurgicalOrderModel
  documents?: ListItem[]
  surgeryStatus?: SurgeryStatus
  refreshSurgicalOrderStatus?: () => void
  loadHospitals: LoadHospitals
  loadHealthInsurances: LoadHealthInsurance
  loadSurgeryCenter: LoadSurgeryCenter
  reorderSurgicalOrder?: ReorderSurgicalOrder
  loadSurgicalOrderDocument?: LoadSurgicalOrderDocument
}

export default function SecretaryOrderDetailsLayout({
  doctorName,
  patient,
  unit,
  id,
  surgicalOrder,
  documents,
  surgeryStatus,
  refreshSurgicalOrderStatus
}: Props) {
  const history = useHistory()
  const [showModal, setShowModal] = useState<boolean>(false)
  const getActualStatus = getActualSurgeryStatus(
    surgeryStatus?.allSurgeryStatus ?? []
  )

  return (
    <Container>
      <S.Wrapper>
        <S.Actions>
          {getActualStatus?.status?.name === 'Em análise no convênio' && (
            <Button type="button" onClick={() => setShowModal(true)}>
              Trocar fase
            </Button>
          )}
          <Button type="button">Refazer pedido</Button>
        </S.Actions>
        <S.OrderInfo>
          <Heading as="h1" size="huge" color="primary">
            Pedido cirúrgico
          </Heading>
          <div>
            <S.InfoContainer>
              <div>
                <h6>Unidade: </h6>
                <span>{unit}</span>
              </div>
              <div>
                <h6>N aviso: </h6>
                <span>{id}</span>
              </div>
              <div>
                <h6>Data do procedimento: </h6>
                <span>-</span>
              </div>
            </S.InfoContainer>
            <S.Profile>
              <ProfilePic src="https://source.unsplash.com/random" />
              <div>
                <h6>Paciente</h6>
                <span>{patient?.name}</span>
              </div>
            </S.Profile>
            <S.Profile>
              <ProfilePic src="https://source.unsplash.com/random" />
              <div>
                <h6>Médico responsável</h6>
                <span>{doctorName}</span>
              </div>
            </S.Profile>
          </div>
        </S.OrderInfo>
        <S.SurgicalPlanning>
          <Heading color="primary" as="h2">
            Planejamento Cirúrgico
          </Heading>
          <List
            arrow
            size="medium"
            padding={false}
            items={[
              {
                title: <S.ListTitle>Dados do paciente</S.ListTitle>,
                icon: '',
                click: () =>
                  history.push('/paciente', {
                    patient: patient,
                    surgicalOrder: surgicalOrder
                  })
              },
              {
                title: <S.ListTitle>Procedimento</S.ListTitle>,
                icon: '',
                click: () =>
                  history.push('/procedimento', {
                    surgicalOrder: surgicalOrder
                  })
              }
            ]}
          />
        </S.SurgicalPlanning>
        <S.Documents>
          <Heading color="primary" as="h2">
            Documentos {'&'} exames
          </Heading>
          <SurgeryDocumentList
            title={false}
            supportText={false}
            padding={false}
            documents={documents ?? []}
          />
        </S.Documents>
        <S.Resume
          onClick={() =>
            history.push('/pedido/resumo', {
              surgicalOrder: surgicalOrder,
              patient: patient
            })
          }
        >
          <Heading color="primary" as="h2">
            Resumo
          </Heading>
          <div>
            <img src={ArrowIcon} />
          </div>
        </S.Resume>
      </S.Wrapper>
      {showModal && (
        <ChangeOrderStatusModal
          surgery={{
            title: surgicalOrder?.procedure
              ? surgicalOrder?.procedure[0].description.replaceAll('"', '')
              : '',
            type: 'Cirurgia',
            unit: surgicalOrder?.hospital?.name,
            color: getStatusInfo(getActualStatus?.status?.name)?.color ?? '',
            status: getActualStatus?.status?.name,
            date: surgicalOrder?.expectedDate
              ? moment(new Date(surgicalOrder?.expectedDate[0]))
                  .utc()
                  .format('DD/MM/YYYY - HH:mm')
              : 'Aguardando agendamento'
          }}
          close={() => {
            refreshSurgicalOrderStatus && refreshSurgicalOrderStatus()
            setShowModal(false)
          }}
          surgicalOrderId={Number(id)}
        />
      )}
    </Container>
  )
}
