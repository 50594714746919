import styled, { css } from 'styled-components'
import { ReactComponent as PencilIcon } from 'presentation/assets/icons/pencil.svg'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  label {
    span {
      color: ${({ theme }) => theme.colors.red};
    }
  }
`

export const Inputs = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  > button {
    border-radius: ${({ theme }) => theme.border.radius.xsmall};
    font-size: ${({ theme }) => theme.font.sizes.small};

    svg {
      margin-right: 10px;

      path {
        fill: white;
      }
    }
  }
`

export const CidContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 32px;
  gap: 40px 5%;
`

export const QuantityContainer = styled.div`
  align-items: center;
  padding: 0px 12px;

  input {
    margin: 0;
    text-align: center;
  }
`

export const Title = styled.h5`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    font-weight: 400;
    color: ${theme.colors.gray};
    cursor: pointer;
    margin-bottom: 12px;
  `}
`

export const Pencil = styled(PencilIcon)`
  rect {
    fill-opacity: 1;
    fill: #e2ede3;
  }
`

export const InputButtonContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: flex-start;
  width: 100%;

  button {
    position: absolute;
    width: 2.75rem;
    height: 2.75rem;
    display: flex;
    justify-content: center;
    align-content: center;
    margin-left: 10px;
    border-radius: ${({ theme }) =>
      `0 ${theme.border.radius.xsmall} ${theme.border.radius.xsmall} 0`};

    span {
      width: 30px;
      height: 30px;
    }

    svg {
      height: 30px;
      width: 30px;

      path {
        height: 30px;
        width: 30px;
        fill: white;
        opacity: 1;
      }
    }
  }
`
