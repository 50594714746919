import { PaginationModel } from 'domain/entities/pagination-model'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import { LoadSecretarySurgicalOrdersSearch } from 'domain/usecases/secretary/load-secretary-surgical-order'
import Container from 'presentation/hospital/components/Container'
import FilterSurgicalOrders from 'presentation/secretary/components/SurgicalOrders/FilterSurgicalOrders'
import { mapSurgicalOrderColumn } from 'presentation/secretary/utils/SurgicalOrderTable/adaptersSurgicalOrderColumn'
import { surgicalOrdersColumns } from 'presentation/secretary/utils/SurgicalOrderTable/surgicalOrdersColumns'
import ActualPage from 'presentation/shared/components/ActualPage'
import Heading from 'presentation/shared/components/Heading'
import Table from 'presentation/shared/components/Table'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

type SecretarySurgicalOrdersLayoutProps = {
  surgicalOrders: SurgicalOrderModel[]
  fetchSurgicalOrder: (
    currentPage: number,
    filter?: LoadSecretarySurgicalOrdersSearch,
    itemsPerPage?: number
  ) => void
  loadingSurgicalOrder?: boolean
  pagination: PaginationModel
}

export default function SecretarySurgicalOrdersLayout({
  surgicalOrders,
  fetchSurgicalOrder,
  loadingSurgicalOrder,
  pagination
}: SecretarySurgicalOrdersLayoutProps) {
  const history = useHistory()
  const [filter, setFilter] = useState<LoadSecretarySurgicalOrdersSearch>()

  const onPaginate = async (currentPage: number, itemsPerPage?: number) => {
    fetchSurgicalOrder(currentPage, filter, itemsPerPage)
    return undefined
  }

  const onHospitalChange = () => {
    fetchSurgicalOrder(1, filter)
  }

  const adapterData = mapSurgicalOrderColumn(surgicalOrders)

  return (
    <Container onHospitalChange={onHospitalChange}>
      <ActualPage onClick={() => history.goBack()} text="Voltar" />
      <Heading size="huge" color="primary" style={{ marginBottom: '15px' }}>
        Pedidos
      </Heading>
      <FilterSurgicalOrders
        filterSubmit={(values) => {
          setFilter(values)
          fetchSurgicalOrder(1, values)
        }}
        onCleanFilter={() => {
          setFilter(undefined)
          fetchSurgicalOrder(1, undefined)
        }}
      />
      <Table
        title={'Pedidos cirúrgicos'}
        columns={surgicalOrdersColumns}
        data={adapterData}
        page={pagination.currentPage}
        count={pagination.totalItems}
        rowsPerPage={pagination.itemsPerPage}
        rowsPerPageOptions={[10]}
        loading={!!loadingSurgicalOrder}
        onPaginate={(pageNumber, pageSize) => {
          fetchSurgicalOrder(pageNumber, filter, pageSize)
          return Promise.resolve(undefined)
        }}
        pagination={true}
      />
    </Container>
  )
}
