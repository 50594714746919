import React from 'react'

import * as S from './styles'
import Container from 'presentation/hospital/components/Container'
import { useHistory } from 'react-router'
import SelectSurgeryInfo from 'presentation/secretary/components/Forms/AddSurgicalOrder/SelectSurgeryInfo'
import { FormikProvider, useFormik } from 'formik'
import { useStores } from 'presentation/hooks/use-stores'

type Props = {
  procedures?: any[]
  cids?: any[]
  opmes?: any[]
  surgeryInfo?: {
    freeze?: boolean
    clinicalHistory?: string
    laterality?: string
    patientHospitalized?: boolean
    allergy?: string
    hospitalizationType?: string
    hospitalizationMode?: string
    observation?: string
  }
  providers?: string[]
}

export default function ProcedureInfoLayout({
  surgeryInfo,
  cids,
  procedures,
  opmes,
  providers
}: Props) {
  const history = useHistory()
  const profile = useStores().currentAccount.getCurrentAccount().user.role
  const allergy =
    surgeryInfo?.allergy?.[0] === 'Látex'
      ? 'Latéx'
      : surgeryInfo?.allergy?.[0] === 'latex-not-informed'
      ? 'latex-not-informed'
      : 'false'
  const otherAllergies = surgeryInfo?.allergy
  const formik = useFormik({
    initialValues: {
      freeze: surgeryInfo?.freeze,
      clinicalHistory: surgeryInfo?.clinicalHistory,
      laterality: surgeryInfo?.laterality,
      allergy: allergy,
      cids,
      procedures,
      isHospitalized: surgeryInfo?.patientHospitalized,
      hospitalizationType: surgeryInfo?.hospitalizationType,
      hospitalizationMode: surgeryInfo?.hospitalizationMode,
      observations: surgeryInfo?.observation,
      opmes,
      provider1: providers?.[0],
      provider2: providers?.[1],
      provider3: providers?.[2],
      otherAllergies: otherAllergies
    },
    onSubmit: () => undefined
  })

  return (
    <Container>
      <S.Wrapper>
        <FormikProvider value={formik}>
          <SelectSurgeryInfo
            goBack={history.goBack}
            submit={history.goBack}
            onlyVisualization
            profile={profile}
            updateOrder={false}
            defaultOpme={opmes && opmes?.length > 0}
          />
        </FormikProvider>
      </S.Wrapper>
    </Container>
  )
}
