import React from 'react'

import * as S from './styles'
import ReviewPatient from 'presentation/secretary/components/Forms/AddSurgicalOrder/ReviewPatient'
import Container from 'presentation/hospital/components/Container'
import { useHistory } from 'react-router'
import { Patient, PatientHealthInsurance } from 'domain/entities/patient-model'
import { FormikProvider, useFormik } from 'formik'
import { Profile } from 'common/enum/profile'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'

type Props = {
  patient?: Patient
  surgicalOrder: SurgicalOrderModel
}

export default function PatientInfoLayout({ patient, surgicalOrder }: Props) {
  const history = useHistory()
  const formik = useFormik({
    initialValues: { patient },
    onSubmit: async () => null
  })

  const patientInsurance = {
    healthPlanCode: surgicalOrder?.healthInsurance?.healthPlanCode ?? 0,
    healthPlanName: surgicalOrder?.healthInsurance?.healthPlanName ?? '',
    healthInsuranceCode:
      surgicalOrder?.healthInsurance?.healthInsuranceCode ?? 0,
    healthInsuranceName:
      surgicalOrder?.healthInsurance?.healthInsuranceName ?? '',
    company: surgicalOrder?.healthInsurance?.company,
    healthCard: surgicalOrder?.healthInsurance?.healthCard,
    validThru: surgicalOrder?.healthInsurance?.validThru
  } as PatientHealthInsurance

  if (patient) {
    patient.healthInsurance = patientInsurance
  }

  return (
    <Container>
      <S.Wrapper>
        <FormikProvider value={formik}>
          <ReviewPatient
            goNext={history.goBack}
            goBack={history.goBack}
            createPatientMode={false}
            onlyVisualization
            insurances={[
              {
                code: patientInsurance?.healthInsuranceCode ?? 0,
                description: patientInsurance?.healthInsuranceName ?? '',
                plans: [],
                healthCard: patientInsurance?.healthCard ?? ''
              }
            ]}
            plans={[
              {
                code: patientInsurance?.healthPlanCode ?? 0,
                description: patientInsurance?.healthPlanName ?? '',
                subPlan: []
              }
            ]}
            profile={Profile.CRMO}
          />
        </FormikProvider>
      </S.Wrapper>
    </Container>
  )
}
