import styled from 'styled-components'

export const PendencyCard = styled.span`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius.small};
  height: 110px;

  cursor: pointer;

  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-right: 36px;

  justify-content: space-between;

  div {
    display: flex;
  }
  color: ${({ theme }) => theme.colors.lightGray};
  font-weight: ${({ theme }) => theme.font.semiBold};
  strong {
    font-weight: ${({ theme }) => theme.font.bold};
    font-size: ${({ theme }) => theme.font.sizes.xxlarge};
  }
`
