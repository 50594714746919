import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  padding: 1rem 2.2rem;

  section {
    margin-top: 20px;
  }

  @media (max-width: 1400px) {
    strong {
      font-size: 0.9rem;
    }
  }
`
