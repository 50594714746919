import { SurgicalStatusModel } from 'domain/entities/surgical-status-model'
import { SurgicalOrderStatus } from 'presentation/shared/components/Timeline/status'

export function getCrmoTableStatus(surgeryStatusEnum: SurgicalStatusModel[]) {
  let actualStatus = surgeryStatusEnum.slice(-1)[0].status
  if (actualStatus === SurgicalOrderStatus.SCHEDULED) {
    actualStatus = surgeryStatusEnum.slice(-2)[0].status
  }
  return actualStatus
}
