import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: 0 1em 1em 1em;
    border-radius: ${theme.border.radius.small};
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.white};
    height: 100%;
  `}
`
