import { InputsContainer } from '../../styles'
import React, { useState } from 'react'
import * as S from './styles'
import Button from 'presentation/shared/components/Button'
import TypedModal from 'presentation/shared/components/Modal'
import { PendencyDocument } from '../..'
import { PendencyType } from 'domain/entities/surgical-pendency'
import { UploadSection } from 'presentation/shared/components/UploadSection'
import { useUploadSection } from 'presentation/hooks/use-upload-section'
import { ListFile } from 'presentation/shared/components/UploadSection/UploadListFiles/UploadListFile'
import { DocumentsType, DocumentType } from 'domain/entities/document-types'

type DocumentsUploadProps = {
  selectedDocumentLabel: string
  onGoBack: () => void
  defaultDocuments: PendencyDocument[]
  onSave: (newDocuments: PendencyDocument[], label: string) => void
  type: PendencyType
}

export default function DocumentsUpload({
  selectedDocumentLabel,
  defaultDocuments,
  onGoBack,
  onSave,
  type
}: DocumentsUploadProps) {
  const [showCancel, setShowCancel] = useState<boolean>(false)
  const [showSuccess, setShowSuccess] = useState<boolean>(false)

  const getDocumentType = (): DocumentType => {
    const documentType = DocumentsType.getPendenciesDocuments()[type].find(
      (document) => document.label === selectedDocumentLabel
    )
    if (documentType) return documentType
    return {
      ...DocumentsType.examReportDocumentType,
      label: selectedDocumentLabel
    }
  }

  const documentType = getDocumentType()

  const compareArrayOfDocuments = (
    firstArray: ListFile[],
    secondArray: ListFile[]
  ) => {
    const filesSize: Record<string, number> = {}
    firstArray.forEach(({ file }) => {
      filesSize[`${file.name}-${file.size}`] = file.size
    })
    return !secondArray.every(
      ({ file }) => filesSize[`${file.name}-${file.size}`]
    )
  }

  const { filesInfo, files, onAdd, onDelete, onDownload, onUpdate } =
    useUploadSection({
      maxFileSizeInMB: documentType.maxFileSizeInMB,
      maxFiles: documentType.maxFiles,
      initialFiles: defaultDocuments,
      extensionsAllowed: ['pdf', 'jpeg', 'jpg']
    })

  const onCancel = () => {
    const hasFilesNotSaved =
      compareArrayOfDocuments(files, defaultDocuments) ||
      compareArrayOfDocuments(defaultDocuments, files)

    if (hasFilesNotSaved) setShowCancel(true)
    else onGoBack()
  }
  const onConfirm = () => {
    setShowSuccess(true)
    onSave(
      files.map((file) => ({
        ...file,
        label: selectedDocumentLabel,
        type: documentType.type[0]
      })),
      selectedDocumentLabel
    )
  }

  const canUpload = {
    canAdd: filesInfo.canAdd,
    canDelete: true,
    canDownload: true,
    canUpdate: true
  }

  return (
    <S.DocumentsUploadContainer>
      <UploadSection
        can={canUpload}
        files={files}
        onAdd={onAdd}
        onDelete={onDelete}
        onDownload={onDownload}
        onUpdate={onUpdate}
        errors={filesInfo.errorMessage}
        listContainerProps={{
          maxHeight: '400px'
        }}
        acceptFiles={'application/pdf, image/jpeg'}
      />
      <InputsContainer>
        <Button variant="outlined" type="button" onClick={onCancel}>
          Cancelar
        </Button>
        <Button type="button" onClick={onConfirm}>
          Salvar
        </Button>
      </InputsContainer>
      <TypedModal
        type="alert"
        show={showCancel}
        actionLabel="Confirmar"
        action={onGoBack}
        title="Você possui documentos não anexados, deseja realmente cancelar?"
        description="Documentos não salvos serão perdidas."
        hasCloseButton="Cancelar"
        closeButtonProps={{
          variant: 'outlined',
          style: {
            marginTop: '10px'
          }
        }}
        close={() => setShowCancel(false)}
        preventAutomateClose
      />
      <TypedModal
        type="check"
        show={showSuccess}
        actionLabel="Fechar"
        action={onGoBack}
        close={onGoBack}
        title="Documento anexado com sucesso"
      />
    </S.DocumentsUploadContainer>
  )
}
