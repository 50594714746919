import styled, { css } from 'styled-components'

export const Wrapper = styled.main`
  padding: 0px 24px;
  width: 100%;
  height: 100%;
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & > span {
    align-self: flex-end;
    justify-self: flex-end;
    margin-bottom: 20px;
  }
`

export const Header = styled.div`
  ${({ theme }) => css`
    div ~ svg {
      display: inline-block;
    }
    text-align: center;
    h1 {
      margin-top: 12px;
      margin-bottom: 12px;
      strong {
        color: ${theme.colors.primary};
      }
    }
  `}
`
