import styled from 'styled-components'
import { transparentize } from 'polished'

export const Wrapper = styled.menu<{ open: boolean | undefined }>`
  grid-area: side;

  height: 100%;
  width: ${({ open }) => (open ? '266px' : '85px')};

  position: absolute;
  z-index: 3;

  padding-top: 1%;
  background-color: ${({ theme }) => theme.colors?.white};
  font-size: 16px;

  transition: width 0.7s;
  box-shadow: ${({ open }) => (open ? '7px 0 7px -7px #333' : 'none')};
`

export const MenuItem = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;

  height: 45px;

  margin-bottom: 15px;
  padding-left: 20px;
  margin: 0 12px;
  background-color: ${({ active, theme }) =>
    active ? transparentize(0.85, theme.colors.secondary) : 'none'};
  border-radius: 10px;

  color: ${({ active, theme }) =>
    active ? theme.colors.secondary : theme.colors.mediumGray};

  cursor: pointer;

  > div {
    height: 32px;
    margin-right: 24px;
  }

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  svg {
    min-width: 22px;
    max-width: 22px;

    .colored {
      fill: ${({ active, theme }) =>
        active ? theme.colors.secondary : theme.colors.mediumGray};
    }

    .colored-stroke {
      stroke: ${({ active, theme }) =>
        active ? theme.colors.secondary : theme.colors.mediumGray};
    }
  }

  :hover {
    background-color: ${({ active, theme }) =>
      !active ? transparentize(0.7, theme.colors.lightGray) : ''};
  }
`

export const MenuLink = styled.a<{ open: boolean | undefined }>`
  overflow: hidden;
  position: relative;
  margin-left: 25px;
  text-decoration: none;
  white-space: nowrap;
  animation: ${({ open }) => (open ? 'fadeIn 0.7s' : 'fadeOut 0.7s')};
  font-size: ${({ theme }) => theme.font.sizes.xsmall};

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    100% {
      opacity: 0;
    }
    0% {
      opacity: 1;
    }
  }
`
