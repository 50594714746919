import { PatientDocument } from 'common/enum/patient-document'
import { DocumentsType } from 'domain/entities/document-types'

import { PendencyType } from 'domain/entities/surgical-pendency'
import { useFormikContext } from 'formik'
import Button from 'presentation/shared/components/Button'
import List, { ListItem } from 'presentation/shared/components/List'
import TypedModal from 'presentation/shared/components/Modal'
import React, { useEffect, useRef, useState } from 'react'
import { AnswerPendencyForm, PendencyDocument } from '../..'
import { InputsContainer } from '../../styles'
import { adaptDocumentToList, getItemsAdapted } from './Functions'
import * as S from './styles'

type DocumentsListProps = {
  changeMode: () => void
  documents: PendencyDocument[]
  setSelectedDocumentLabel: (label: string) => void
  type: PendencyType
  changeTitle: (newTitle?: string) => void
}

export default function DocumentsList({
  changeMode,
  documents,
  setSelectedDocumentLabel,
  type,
  changeTitle
}: DocumentsListProps) {
  const { values, setFieldValue } = useFormikContext<AnswerPendencyForm>()
  const [showCancel, setShowCancel] = useState<boolean>(false)
  const [scroll, setScroll] = useState<boolean>(false)
  const [showSuccess, setShowSuccess] = useState<boolean>(false)

  const onSelectDocument = (label: string) => {
    changeTitle(label)
    setSelectedDocumentLabel(label)
  }

  const [items, setItems] = useState<ListItem[]>(
    getItemsAdapted(type, documents, onSelectDocument)
  )

  const onCancel = () => {
    if (values.documents.length !== documents?.length) setShowCancel(true)
    else changeMode()
  }
  const onConfirm = () => {
    if (values.documents.length === documents?.length) changeMode()
    else {
      setFieldValue('documents', documents)
      setShowSuccess(true)
    }
  }

  const getLastItem = (): {
    value: number
    label: string
  } => {
    const lastExamReport = items[items.length - 1].title
    const splittedString = String(items[items.length - 1].title).split(' ')
    return {
      value: Number(splittedString[splittedString.length - 1]),
      label: String(lastExamReport)
    }
  }

  const canAddMoreExamReport = () => {
    if (type === PendencyType.ASSISTANCE) return false
    const lastExamReport = getLastItem()

    return documents.some((document) => document.label === lastExamReport.label)
  }

  const hasMoreButton = canAddMoreExamReport()

  const listEndRef = useRef<HTMLDivElement>(null)

  const scrollToBottom = () => {
    listEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    if (scroll) scrollToBottom()
  }, [scroll])

  return (
    <S.DocumentsListContainer>
      <S.ListWrapper hasMoreButton={hasMoreButton}>
        <List size="medium" items={items} />
        <div ref={listEndRef}></div>
      </S.ListWrapper>
      {hasMoreButton ? (
        <S.MoreExamReportButton
          onClick={() => {
            setItems([
              ...items,
              adaptDocumentToList(
                {
                  ...DocumentsType.examReportDocumentType,
                  label: `Laudos ${getLastItem().value + 1}`,
                  type: [PatientDocument.EXAM_REPORT]
                },
                documents,
                setSelectedDocumentLabel
              )
            ])
            setScroll(true)
          }}
        >
          Adicionar Laudos
        </S.MoreExamReportButton>
      ) : null}

      <InputsContainer>
        <Button variant="outlined" type="button" onClick={onCancel}>
          Cancelar
        </Button>
        <Button type="button" onClick={onConfirm}>
          Salvar
        </Button>
      </InputsContainer>
      <TypedModal
        type="alert"
        show={showCancel}
        actionLabel="Confirmar"
        action={changeMode}
        title="Você possui documentos não anexados, deseja realmente cancelar?"
        description="Documentos não salvos serão perdidas."
        hasCloseButton="Cancelar"
        closeButtonProps={{
          variant: 'outlined',
          style: {
            marginTop: '10px'
          }
        }}
        close={() => setShowCancel(false)}
        preventAutomateClose
      />
      <TypedModal
        type="check"
        show={showSuccess}
        actionLabel="Fechar"
        action={changeMode}
        close={changeMode}
        title="Documento anexado com sucesso"
      />
    </S.DocumentsListContainer>
  )
}
