import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transform-origin: center;
    z-index: ${theme.layers.alwaysOnTop};
  `}
`

export const Container = styled.div`
  ${({ theme }) => css`
    transform-origin: center;
    box-shadow: 0px 4px 9px 2px rgba(0, 0, 0, 0.25);
    background: ${theme.colors.white};
    display: flex;
    flex-direction: column;
    padding: 25px 25px;
    z-index: ${theme.layers.alwaysOnTop};
    max-height: 700px;
    justify-content: center;
    align-items: center;
    min-width: 480px;
    border-radius: ${theme.border.radius.small};
    gap: 20px;
  `}
`

export const ButtonsContainer = styled.footer`
  display: flex;
  justify-content: space-between;
  width: 100%;

  button {
    width: 160px;
  }
`

export const InvalidAttachmentSize = styled.span`
  text-align: center;
  margin-bottom: 30px;
  color: #e31a12;
`
