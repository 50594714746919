import styled, { css } from 'styled-components'

type WrapperProps = {
  cellRelative?: boolean
}

export const Wrapper = styled.div<WrapperProps>`
  margin-top: 24px;
  flex: 1;
  overflow-y: auto;
  z-index: 1;

  .MuiPaper-root {
    width: 100%;
    /* max-width: 1530px; */
    * {
      font-family: Inter !important;
    }
  }

  .MuiTableHead-root,
  .MuiTableCell-head {
    max-height: 500px;
    background-color: #efe9f7 !important;
    z-index: 1;
  }

  .MuiTableCell-head,
  .MuiButton-label {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.primary};
  }

  tr {
    &:nth-child(even) {
      background-color: #f7f2fc;
    }
  }

  ${(props) =>
    props.cellRelative &&
    css`
      .MuiTableCell-body {
        position: relative;
      }
    `}

  @media (max-width: 959.95px) {
    .MuiTableCell-body :first-child {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`

export const LoadingMore = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;

  img {
    height: 40px;
  }
`
