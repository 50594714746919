import { PaginationModel } from 'domain/entities/pagination-model'
import {
  PendencyType,
  SurgicalPendency
} from 'domain/entities/surgical-pendency'
import { LoadSecretaryPendencies } from 'domain/usecases/secretary/load-secretary-pendencies'
import Container from 'presentation/hospital/components/Container'
import FilterPendencies from 'presentation/secretary/components/TypedPendencies/FilterPendencies'
import { mapSurgicalPendencyColumn } from 'presentation/secretary/utils/TypedPendenciesTable/adaptersTypedPendencies'
import { typedPendenciesColumns } from 'presentation/secretary/utils/TypedPendenciesTable/typedPendenciesColumns'
import { typedPendenciesContent } from 'presentation/secretary/utils/TypedPendenciesTable/typedPendenciesContent'
import ActualPage from 'presentation/shared/components/ActualPage'
import Heading from 'presentation/shared/components/Heading'
import Table from 'presentation/shared/components/Table'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import TypedPendencyModal from 'presentation/secretary/components/TypedPendencies/TypedPendencyModal'

type Props = {
  pendencies?: SurgicalPendency[]
  pagination: PaginationModel
  refreshPendencies(
    page?: number,
    filter?: {
      patientName?: string
      doctorName?: string
      surgical_order_id?: number
    },
    itemsPerPage?: number
  ): void
  type?: PendencyType
  loadingPendencies?: boolean
}

const TypedPendenciesLayout = ({
  refreshPendencies,
  pagination,
  type,
  pendencies = [],
  loadingPendencies = false
}: Props) => {
  const history = useHistory()
  const [filter, setFilter] =
    useState<LoadSecretaryPendencies.Params['filters']>()
  const [selectedSurgicalPendency, setSelectedSurgicalPendency] =
    useState<SurgicalPendency>()
  const title = `Pendências ${type ? typedPendenciesContent[type] : '-'}`

  const adapterData = mapSurgicalPendencyColumn(pendencies)

  const onPaginate = async (currentPage: number, itemsPerPage?: number) => {
    refreshPendencies(currentPage, filter, itemsPerPage)
    return undefined
  }

  const onHospitalChange = () => {
    refreshPendencies(1, filter)
  }

  const onRowClick = (dataInArray: any[]) => {
    const surgicalPendencyId = dataInArray[0]
    const surgicalPendency = pendencies.find(
      (pendency) => pendency.surgical_pendency_id === surgicalPendencyId
    )
    setSelectedSurgicalPendency(surgicalPendency)
  }

  const onCloseModal = (shouldRefresh: boolean) => {
    if (shouldRefresh) {
      refreshPendencies(1, filter, pagination.itemsPerPage)
    }
    setSelectedSurgicalPendency(undefined)
  }

  return (
    <Container onHospitalChange={onHospitalChange}>
      <ActualPage onClick={() => history.goBack()} text="Voltar" />
      <Heading size="huge" color="primary" style={{ marginBottom: '48px' }}>
        {title}
      </Heading>
      <div>
        <FilterPendencies
          onSubmit={(newFilter) => {
            setFilter(newFilter)
            refreshPendencies(1, newFilter)
          }}
        />
        <Table
          title={title}
          columns={typedPendenciesColumns}
          data={adapterData}
          count={pagination.totalItems}
          rowsPerPage={pagination.itemsPerPage}
          onPaginate={onPaginate}
          loading={loadingPendencies}
          onClick={onRowClick}
        />
      </div>
      <TypedPendencyModal
        onClose={onCloseModal}
        show={!!selectedSurgicalPendency}
        surgicalPendency={selectedSurgicalPendency}
        type={type}
      />
    </Container>
  )
}

export default TypedPendenciesLayout
