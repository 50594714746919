import { darken } from 'polished'
import styled, { css } from 'styled-components'

type StatusProps = {
  bgColor: string
}

export const Status = styled.div<StatusProps>`
  font-size: ${({ theme }) => theme.font.sizes.xxsmall};
  font-weight: 600;
  text-transform: uppercase;
  padding: 2px 6px;
  text-align: center;
  background-color: #c7c7c7;
  border-radius: 20px;
  ${(props) =>
    props.bgColor &&
    css`
      background-color: ${props.bgColor};
      color: ${darken(0.5, props.bgColor)};
    `}
`
