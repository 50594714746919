import { Profile } from 'common/enum/profile'
import { RegisterSurgicalOrder } from 'domain/usecases/surgical-order/register-surgical-order'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteRegisterSurgicalOrder implements RegisterSurgicalOrder {
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  add(
    params: RegisterSurgicalOrder.Params,
    profile?: Profile
  ): Promise<RegisterSurgicalOrder.Model> {
    if (profile === 'crmo' || profile === 'RECEPTIONIST') {
      return this.surgicalOrderRepository.registerCrmoSurgicalOrder(params)
    } else if (profile === 'secretary') {
      return this.surgicalOrderRepository.registerSecretarySurgicalOrder(params)
    }
  }
}

export type RegisterSurgicalOrderModel = RegisterSurgicalOrder.Model
