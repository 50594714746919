import { LoadTuss } from 'domain/usecases/surgical-order/load-tuss'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteLoadTuss implements LoadTuss {
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  load(params?: LoadTuss.Params): Promise<LoadTuss.Model> {
    return this.surgicalOrderRepository.loadTuss(params)
  }
}

export type LoadTussModel = LoadTuss.Model
