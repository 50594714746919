import normalizeText from 'common/utils/getNormalizedText'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import {
  SurgicalPendency,
  SurgicalPendencyStatus
} from 'domain/entities/surgical-pendency'
import { AnswerSurgicalPendency } from 'domain/usecases/surgical-pendency/answer-surgical-pendency'
import { LoadSurgicalPendencyDocument } from 'domain/usecases/surgical-pendency/load-surgical-pendency-document'
import { UploadSurgicalPendencyDocument } from 'domain/usecases/surgical-pendency/upload-surgical-pendency-document'
import moment from 'moment'
import { ReactComponent as SearchIcon } from 'presentation/assets/icons/search.svg'
import Button from 'presentation/shared/components/Button'
import TextField from 'presentation/shared/components/TextField'
import React, { useEffect, useState } from 'react'
import PendencyModal from '../PendencyModal'
import PendencyStatusChip from '../PendencyStatusChip'
import * as S from './styles'

type PendencyProps = {
  pendency: {
    id: number
    patientName: string
    status: SurgicalPendencyStatus
    responsible: string
    createdAt: string
    crmoResponsible: string
  }
  click(): void
}

const PendencyCard = ({ pendency, click }: PendencyProps) => {
  return (
    <S.PendencyItem onClick={() => click()}>
      <S.PendencyHeader>
        <span>{pendency.patientName}</span>
        <PendencyStatusChip status={pendency.status} />
      </S.PendencyHeader>
      <S.PendencyInfo>
        {pendency.responsible && (
          <S.InfoItem>
            <span>Responsável pedido:</span>
            <p>{pendency.responsible}</p>
          </S.InfoItem>
        )}
        <S.InfoItem>
          <span>Pendência gerada em:</span>
          <p>{pendency.createdAt}</p>
        </S.InfoItem>
        {pendency.crmoResponsible && (
          <S.InfoItem>
            <span>Responsável CRMO:</span>
            <p>{pendency.crmoResponsible}</p>
          </S.InfoItem>
        )}
      </S.PendencyInfo>
    </S.PendencyItem>
  )
}

type PendenciesTableProps = {
  data: SurgicalPendency[]
  surgicalOrders: SurgicalOrderModel[]
  loadSurgicalPendencyDocument?: LoadSurgicalPendencyDocument
  answerSurgicalPendency: AnswerSurgicalPendency
  uploadSurgicalPendencyDocument: UploadSurgicalPendencyDocument
  refreshPendencies(): void
}

const PendenciesTable = ({
  data,
  loadSurgicalPendencyDocument,
  surgicalOrders,
  answerSurgicalPendency,
  uploadSurgicalPendencyDocument,
  refreshPendencies
}: PendenciesTableProps) => {
  const [showPendencyModal, setShowPendencyModal] = useState(false)
  const [activePendency, setActivePendency] = useState<SurgicalPendency>(
    {} as SurgicalPendency
  )
  const [patientNameQuery, setPatientNameQuery] = useState<string>('')
  const [pendenciesData, setPendenciesData] = useState<SurgicalPendency[]>(
    () => data
  )

  const handleClickPendencyCard = (pendency: SurgicalPendency) => {
    setActivePendency(pendency)
    setShowPendencyModal(true)
  }

  const getProceduresInSurgicalOrder = () => {
    const surgicalOrder = surgicalOrders.find(
      (surgicalOrder) =>
        surgicalOrder.surgical_order_id === activePendency.surgical_order_id
    )
    if (surgicalOrder && surgicalOrder.procedure) {
      return surgicalOrder.procedure
    }
    return []
  }

  function handleFilterPendencies() {
    if (patientNameQuery) {
      setPendenciesData(
        pendenciesData.filter((pendency) =>
          normalizeText(pendency.patient?.name)
            .toLowerCase()
            .includes(normalizeText(patientNameQuery).toLowerCase())
        )
      )
    } else {
      setPendenciesData(data)
    }
  }

  function cleanFilters() {
    setPatientNameQuery('')
    setPendenciesData(data)
  }

  function handleClosePendencyModal(dataChanged: boolean) {
    setShowPendencyModal(false)
    if (dataChanged) refreshPendencies()
  }

  useEffect(() => {
    setPendenciesData(data)
  }, [data])

  return (
    <S.Wrapper>
      <S.FiltersContainer>
        <S.FiltersInput>
          <TextField
            name="patientName"
            id="patientName"
            bgColor="mainBg"
            placeholder="Busca por paciente"
            iconPosition="left"
            icon={<SearchIcon />}
            iconMargin="50px"
            iconLocale="inside"
            onChange={(e) => setPatientNameQuery(e.target.value)}
            value={patientNameQuery}
          />
        </S.FiltersInput>
        <S.FiltersButton>
          <Button type="button" onClick={() => handleFilterPendencies()}>
            Buscar
          </Button>
          <Button
            type="button"
            onClick={() => cleanFilters()}
            variant="outlined"
          >
            Limpar
          </Button>
        </S.FiltersButton>
      </S.FiltersContainer>
      <S.PendenciesTable>
        {pendenciesData &&
          pendenciesData.map((pendency) => (
            <PendencyCard
              click={() => handleClickPendencyCard(pendency)}
              key={pendency.surgical_pendency_id}
              pendency={{
                id: pendency.surgical_pendency_id,
                patientName: pendency.patient?.name ?? '-',
                status: pendency.status,
                responsible: pendency.requestedBy?.name ?? '',
                createdAt: moment(pendency.createdAt).format('DD/MM/YYYY'),
                crmoResponsible: ''
              }}
            />
          ))}
        {showPendencyModal && (
          <PendencyModal
            surgicalPendecy={{
              crmResponsible: activePendency.requestedBy?.name ?? '',
              patientName: activePendency.patient?.name ?? '',
              surgeonReturn: '',
              validateDate: activePendency.answeredAt
                ? moment(activePendency.answeredAt).format('DD/MM/YYYY')
                : '',
              pendency: activePendency,
              procedures: getProceduresInSurgicalOrder()
            }}
            close={(dataChanged) => handleClosePendencyModal(dataChanged)}
            loadSurgicalPendencyDocument={loadSurgicalPendencyDocument}
            answerSurgicalPendency={answerSurgicalPendency}
            uploadSurgicalPendencyDocument={uploadSurgicalPendencyDocument}
          />
        )}
      </S.PendenciesTable>
      {pendenciesData.length === 0 && (
        <S.NoPendencies data-testid="no-pendencies">
          Nenhum resultado encontrado
        </S.NoPendencies>
      )}
    </S.Wrapper>
  )
}

export default PendenciesTable
