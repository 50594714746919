import { PatientDocument } from 'common/enum/patient-document'
import { DocumentType } from 'domain/entities/document-types'
import { useUploadSection } from 'presentation/hooks/use-upload-section'
import Button from 'presentation/shared/components/Button'
import Heading from 'presentation/shared/components/Heading'
import Modal from 'presentation/shared/components/Modal'
import { UploadSection } from 'presentation/shared/components/UploadSection'
import { ListFile } from 'presentation/shared/components/UploadSection/UploadListFiles/UploadListFile'
import React, { useState } from 'react'
import * as S from './styles'

export type ListFileType = {
  document_id?: number
  label?: string
  type?: PatientDocument
}

type Props = {
  close?: () => void
  submit?: (values: any) => void
  defaultFiles: ListFile<ListFileType>[]
  documentType?: DocumentType
}

export default function UploadModal({
  close,
  submit,
  defaultFiles,
  documentType
}: Props) {
  const [success, setSuccess] = useState(false)

  const onSubmit = () => {
    submit?.(uploadHook.files)
    setSuccess(true)
  }

  const { filesInfo, ...uploadHook } = useUploadSection({
    initialFiles: defaultFiles,
    extensionsAllowed: documentType?.extensionsAllowed,
    maxFileSizeInMB: documentType?.maxFileSizeInMB,
    maxFiles: documentType?.maxFiles,
    verifyUniqueFile: true
  })

  const canUpload = {
    canAdd: filesInfo.canAdd,
    canDelete: true,
    canDownload: true,
    canUpdate: true
  }

  const onAdd = (files: ListFile<ListFileType>[]) => {
    uploadHook.onAdd(
      files.map((file) => ({
        ...file,
        identifier: {
          type: documentType?.type?.[0]
        }
      }))
    )
  }

  const getModalTitle = () => {
    if (defaultFiles.length !== 0 && uploadHook.files.length === 0)
      return 'Documentos excluídos com sucesso'
    if (defaultFiles.length === 0 && uploadHook.files.length !== 0)
      return 'Documentos enviados com sucesso'
    if (defaultFiles.length !== 0 && uploadHook.files.length !== 0)
      return 'Documentos alterados com sucesso'
  }

  return (
    <S.Wrapper>
      <S.Container>
        <Heading>{documentType?.type?.[0] || ''}</Heading>
        <UploadSection
          {...uploadHook}
          can={canUpload}
          errors={filesInfo.errorMessage}
          onAdd={onAdd}
          acceptFiles={documentType?.acceptFiles}
          listContainerProps={{ maxWidth: '480px', maxHeight: '480px' }}
        />
        <S.ButtonsContainer>
          <Button type="button" variant="outlined" onClick={close}>
            Cancelar
          </Button>
          <Button
            type="button"
            onClick={onSubmit}
            disabled={
              uploadHook.files.length === 0 && defaultFiles.length === 0
            }
          >
            Enviar
          </Button>
        </S.ButtonsContainer>
      </S.Container>
      <Modal show={success} close={close} title={getModalTitle()} />
    </S.Wrapper>
  )
}
