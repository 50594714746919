import { LoadSecretary } from 'domain/usecases/secretary/load-secretary'
import { SecretaryRepository } from 'repository/interfaces/secretary-repository'

export class RemoteLoadSecretary implements LoadSecretary {
  constructor(private readonly secretaryRepository: SecretaryRepository) {}

  load(params?: LoadSecretary.Params): Promise<LoadSecretary.Model> {
    return this.secretaryRepository.loadSecretary(params)
  }
}

export type LoadPatientSurgeryModel = LoadSecretary.Model
