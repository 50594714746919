import React from 'react'
import { Authentication } from 'domain/usecases/authentication/authentication'
import LoginLayout from 'presentation/shared/layouts/Login'
import { Profile } from 'common/enum/profile'

type LoginProps = {
  useCase: Authentication
}

export default function SecretaryLogin({ useCase }: LoginProps) {
  return <LoginLayout useCase={useCase} client={Profile.SECRETARY} />
}

export type LoginFormValues = {
  cpf: string
  password: string
  remember: boolean
}
