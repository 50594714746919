import React from 'react'
import { Patient } from 'domain/entities/patient-model'
import { LoadTuss } from 'domain/usecases/surgical-order/load-tuss'
import { LoadCid } from 'domain/usecases/surgical-order/load-cid'
import { LoadSurgeries } from 'domain/usecases/surgery/load-surgeries'

type surgicalOrderContext = {
  loadHealthInsurances: (hospital_id: number) => void
  loadPatientByCpf?: (cpf: string) => Promise<Patient>
  loadTuss?: (
    pageNumber: number,
    pageSize: number,
    name?: string
  ) => Promise<LoadTuss.Model | undefined>
  loadCid?: (
    pageNumber: number,
    pageSize: number,
    name?: string
  ) => Promise<LoadCid.Model | undefined>
  loadSurgeries?: (
    pageNumber: number,
    pageSize: number,
    name?: string
  ) => Promise<LoadSurgeries.Model | undefined>
  loadHealthPlans: (insurance_id: number) => void
  loadHealthSubPlans: (insurance_id: number) => void
  loadSurgeryCenter: (hospital_id: number) => void
  loadDoctor?: (name: string) => void
}

// type SurgicalOrderUseCases = {
//   loadInsurances: LoadHealthInsurance
//   loadTuss: LoadTuss
//   loadCid: LoadCid
//   loadHospitals: LoadHospitals
//   loadDoctors: SearchDoctors
//   loadPatient: LoadPatientByCpf
//   loadSurgeryCenters: LoadSurgeryCenter
// }

let context: React.Context<surgicalOrderContext>
export const createAddSurgicalOrderFunctions = ({
  loadHealthInsurances,
  loadTuss,
  loadCid,
  loadSurgeryCenter,
  loadPatientByCpf,
  loadHealthPlans,
  loadSurgeries,
  loadHealthSubPlans,
  loadDoctor
}: surgicalOrderContext) => {
  // const loadHealthInsurances = (hospital_id: number) => {
  //   return loadInsurances.load({
  //     search: { multiCompanyHealthInsuranceCode: hospital_id },
  //     pagination: { pageNumber: 1, pageSize: 9999 },
  //     params: [
  //       'data{',
  //       'healthInsuranceCode',
  //       'healthInsuranceName',
  //       'healthPlanCode',
  //       'healthPlanName',
  //       '}'
  //     ]
  //   })
  // }
  //
  // const loadHealthPlans = (
  //   insurance_id: number,
  //   insurances: HealthInsurance[]
  // ): { value: number; name: string }[] => {
  //   const healthPlans = insurances
  //     .filter((insurance) => {
  //       return insurance.healthInsuranceCode === insurance_id
  //     })
  //     .map((insurance) => {
  //       return {
  //         value: insurance.healthPlanCode,
  //         name: insurance.healthPlanName
  //       }
  //     })
  //   return uniqBy(healthPlans, function (p) {
  //     return p.value
  //   })
  // }
  //
  // const loadTussTable = () => {
  //   return loadTuss.load({
  //     pagination: { pageNumber: 1, pageSize: 1 },
  //     fields: ['data{description', 'tuss_id}']
  //   })
  // }
  //
  // const loadCidTable = () => {
  //   return loadCid.load({
  //     pagination: { pageNumber: 1, pageSize: 1 },
  //     fields: ['data{description', 'cid_id}']
  //   })
  // }
  //
  // const loadHospitalList = () => {
  //   return loadHospitals.load(['name', 'hospital_id'])
  // }
  //
  // const loadDoctorList = () => {
  //   return loadDoctors.load({
  //     doctorName: '',
  //     params: ['name', 'doctor_id']
  //   })
  // }
  //
  // const loadPatientByCpf = (cpf: string) => {
  //   return loadPatient.load(cpf, [
  //     'gender',
  //     'name',
  //     'cpf',
  //     'phone',
  //     'landlinePhone',
  //     'email',
  //     'birthday',
  //     'patient_id',
  //     'company',
  //     'healthInsurance {healthInsuranceCode, healthInsuranceName, patient_has_health_insurance_id, healthCard',
  //     'healthPlanCode, healthPlanName, validThru}'
  //   ])
  // }
  //
  // const loadSurgeryCentersByHospital = (hospital_id: number) => {
  //   return loadSurgeryCenters.load({
  //     hospital_id,
  //     fields: ['codeCenter', 'description']
  //   })
  // }
  context = React.createContext<surgicalOrderContext>({
    loadTuss,
    loadHealthPlans,
    loadPatientByCpf,
    loadHealthInsurances,
    loadSurgeryCenter,
    loadCid,
    loadSurgeries,
    loadHealthSubPlans,
    loadDoctor
  })
}

export const useAddSurgicalOrderFunctions = () => {
  if (context) {
    return React.useContext(context)
  }
}
