import React, { useEffect, useState } from 'react'
import * as S from './styles'

import Modal from 'presentation/hospital/components/Modal'
import SuccesModal from 'presentation/shared/components/Modal'
import Heading from '../../Heading'
import Button from 'presentation/shared/components/Button'
import { ReactComponent as DownloadSvgIcon } from 'presentation/assets/icons/download.svg'
import UploadModal, {
  ListFileType
} from 'presentation/hospital/components/UploadModal'
import UploadIcon from 'presentation/assets/icons/upload.svg'
import CheckIcon from 'presentation/assets/icons/big-check.svg'
import AlertIcon from 'presentation/assets/icons/alert.svg'
import { PatientDocument } from 'common/enum/patient-document'
import { toast } from 'react-toastify'

import List, { ListItem } from 'presentation/shared/components/List'
import {
  documentList,
  DocumentsList
} from 'presentation/shared/pages/SurgeryDetails/documents'
import { getDocumentsLabelLocationByEnum } from 'presentation/utils/document-types-location'
import downloadFileFromBlob from 'common/utils/downloadFileFromBlob'
import { ListFile } from '../../UploadSection/UploadListFiles/UploadListFile'
import { DocumentsType, DocumentType } from 'domain/entities/document-types'
import { sortReports } from 'presentation/utils/reports/sort-reports'

export type DocumentItem = {
  file: File
  type: string
  document_id?: number
  label?: string
  thumbnail?: string
}

type DocumentsAreaModalProps = {
  defaultDocuments: DocumentItem[]
  handleChangeDocuments?(documents: DocumentItem[]): void
  handleClose(): void
  handleDownloadDocument?(document_id: number): Promise<File>
  isReviewOnly?: boolean
  defaultDocsOnly?: boolean
  defaultDocumentsListType?: DocumentsList[]
  canSee?: {
    hasExams?: boolean
    hasMedicalReport?: boolean
  }
  preventCreateExam?: boolean
}

type UploadType = {
  label: string
  files: {
    file: File
    document_id?: number
    thumbnail?: string
  }[]
  type: PatientDocument
  documentType?: DocumentType
}

export const DocumentsAreaModal = ({
  defaultDocuments,
  handleChangeDocuments,
  handleClose,
  handleDownloadDocument,
  isReviewOnly,
  defaultDocsOnly,
  defaultDocumentsListType,
  canSee,
  preventCreateExam = true
}: DocumentsAreaModalProps) => {
  const [documents, setDocuments] = useState(defaultDocuments)
  const [otherDocuments, setOtherDocuments] = useState([] as DocumentItem[])
  const [patientDocuments, setPatientDocuments] = useState([] as ListItem[])
  const [patientOtherDocuments, setPatientOtherDocuments] = useState(
    [] as ListItem[]
  )
  const [handleUploadModal, setHandleUploadModal] = useState(false)
  const [uploadType, setUploadType] = useState<UploadType>()
  const [showSucessModal, setShowSucessModal] = useState(false)

  const documentsListType = defaultDocumentsListType ?? documentList

  const handleSubmit = () => {
    setShowSucessModal(true)
    setTimeout(() => {
      setShowSucessModal(false)
      handleClose()
    }, 2000)
  }

  const downloadFile = async (documents?: DocumentItem[]) => {
    documents?.map(async (documentItem) => {
      if (documentItem.file && documentItem.file.type) {
        return downloadFileFromBlob(
          documentItem.file,
          documentItem.file.type,
          getDocumentsLabelLocationByEnum(documentItem.type)
        )
      }
      if (documentItem.document_id && handleDownloadDocument) {
        const documentFile = await handleDownloadDocument(
          documentItem.document_id
        )
        return downloadFileFromBlob(
          documentFile,
          documentFile.type,
          getDocumentsLabelLocationByEnum(documentItem.type)
        )
      }
    })
  }

  const setIcon = (documentType: string[], isOtherDocument?: boolean) => {
    let hasFileUploaded: DocumentItem[] = []
    if (isOtherDocument) {
      hasFileUploaded = otherDocuments?.filter((document) =>
        documentType.includes(document.type)
      )
    } else {
      hasFileUploaded = documents?.filter((document) =>
        documentType.includes(document.type)
      )
    }
    return hasFileUploaded && hasFileUploaded.length > 0 ? CheckIcon : AlertIcon
  }

  const openUploadModal = (type: UploadType) => {
    setUploadType(type)
    setHandleUploadModal(true)
  }

  const renameExams = (files: DocumentItem[]) => {
    const exams = files.filter(
      (file) => file.type === PatientDocument.EXAM_REPORT
    )
    const examsIndexes = exams
      .map((file) => Number(file.label?.split(' ')[1]))
      .sort((a, b) => a - b)
    const uniqueIndexes = [...new Set(examsIndexes)]
    const missingIndex = uniqueIndexes.findIndex(
      (index, arrayIndex) => index - 1 !== arrayIndex
    )
    if (missingIndex === -1) return files
    const newExams = exams.map((exam) => {
      const index = Number(exam.label?.split(' ')[1])
      if (index - 1 < missingIndex) return exam
      return {
        ...exam,
        label: `Laudos ${index - 1}`
      }
    })
    const filesWithoutExams = files.filter(
      (file) => file.type !== PatientDocument.EXAM_REPORT
    )
    return [...filesWithoutExams, ...newExams]
  }

  const uploadSubmit = (files: ListFile<ListFileType>[]) => {
    const updatedFiles: DocumentItem[] = files.map(
      ({ file, identifier, thumbnail }) => {
        return {
          ...identifier,
          type: String(identifier?.type),
          file,
          label: uploadType?.label,
          thumbnail
        }
      }
    )

    const uploadFilesCleaned = documents.filter(
      (document) => document.label !== uploadType?.label
    )
    const newUploadedFiles = uploadFilesCleaned.concat(updatedFiles)
    const renameFiles =
      files.length === 0 && uploadType?.type === PatientDocument.EXAM_REPORT
        ? renameExams(newUploadedFiles)
        : newUploadedFiles

    setDocuments(renameFiles)
    handleChangeDocuments && handleChangeDocuments(renameFiles)
  }

  const handleAddOtherReports = () => {
    if (otherDocuments.length >= 9) {
      toast.error('Limite de 10 laudos atingido')
      return
    }
    const newOtherReport: DocumentItem = {
      file: new File([''], ''),
      type: PatientDocument.EXAM_REPORT,
      label: `Laudos ${otherDocuments.length + 1}`
    }
    setOtherDocuments([...otherDocuments, newOtherReport])
  }

  const filterFiles = (doc: typeof documentsListType[0]) => {
    if (defaultDocsOnly) {
      if (defaultDocuments.length === 0) {
        return false
      }
      return (
        defaultDocuments.filter((defaultDoc) => {
          const types = defaultDoc.type
          return doc.type.includes(types)
        }).length > 0
      )
    } else {
      return doc.upload
    }
  }

  const filterCanSee = (doc: DocumentsList) => {
    if (canSee) {
      if (canSee.hasExams && doc.type.includes(PatientDocument.EXAM_REPORT)) {
        return false
      }
      if (
        canSee.hasMedicalReport &&
        doc.type.includes(PatientDocument.MEDICAL_REPORT)
      ) {
        return false
      }
    }
    return true
  }

  // patient documents
  useEffect(() => {
    const handleSetPatientsDocuments = () => {
      const formatedDocumentList: ListItem[] = documentsListType
        .filter((doc) => filterFiles(doc))
        .filter(filterCanSee)
        .map((item, index) => {
          const typeDocuments =
            documents?.filter((document: any) =>
              item.type.includes(document.type)
            ) || []
          const files = typeDocuments.map((document) => ({
            file: document.file,
            document_id: document.document_id,
            thumbnail: document.thumbnail
          }))
          const documentType = DocumentsType.getDocumentTypeByType(
            item.type[0] as PatientDocument
          )
          const hasAlreadyUploaded = files.some((file) => file.document_id)
          return {
            title: item.name,
            icon: setIcon(item.type),
            download: (
              <DownloadSvgIcon
                onClick={() =>
                  downloadFile(
                    documents?.filter((document: any) =>
                      item.type.includes(document.type)
                    )
                  )
                }
                style={{ margin: '0 12px', display: 'block' }}
                data-testid={`download-button-${index}`}
              />
            ),
            upload: !isReviewOnly && !hasAlreadyUploaded && (
              <img
                src={UploadIcon}
                onClick={() =>
                  openUploadModal({
                    files,
                    label: item.type[0],
                    type: item.type[0] as PatientDocument,
                    documentType
                  })
                }
                data-testid={`upload-button-${index}`}
              />
            )
          }
        })
      setPatientDocuments(formatedDocumentList)
    }
    handleSetPatientsDocuments()
  }, [documents])

  // other documents (exams)
  useEffect(() => {
    const handleSetPatientsOtherDocuments = () => {
      if (canSee?.hasExams) return
      const formatedOtherDocumentList: ListItem[] = otherDocuments.map(
        (item, idx) => {
          const typeDocuments: DocumentItem[] =
            documents?.filter((document) => document.label === item.label) || []
          const files = typeDocuments.map((document) => ({
            file: document.file,
            document_id: document.document_id,
            thumbnail: document.thumbnail
          }))
          const hasAlreadyUploaded = files.some((file) => file.document_id)
          return {
            title: item.label || '',
            icon: files.length === 0 ? AlertIcon : setIcon([item.type], true),
            download: (
              <DownloadSvgIcon
                onClick={() =>
                  downloadFile(
                    documents?.filter(
                      (document) => item.label === document.label
                    )
                  )
                }
                style={{ margin: '0 12px', display: 'block' }}
                data-testid={`download-other-documents-${idx}`}
              />
            ),
            upload: !isReviewOnly && !hasAlreadyUploaded && (
              <img
                src={UploadIcon}
                onClick={() =>
                  openUploadModal({
                    files,
                    label: item.label || '',
                    type: item.type as PatientDocument,
                    documentType: DocumentsType.getDocumentTypeByType(
                      item.type as PatientDocument
                    )
                  })
                }
                data-testid={`upload-other-documents-${idx}`}
              />
            )
          }
        }
      )
      setPatientOtherDocuments(formatedOtherDocumentList)
    }
    handleSetPatientsOtherDocuments()
  }, [otherDocuments, documents])

  useEffect(() => {
    setDocuments(defaultDocuments)
    const examsDocuments = defaultDocuments
      .filter((doc) => doc.label?.startsWith?.('Laudos'))
      .map((doc) => doc.label)
    const uniqueExamsDocuments = [...new Set(examsDocuments)]
    const oldOtherDocuments = uniqueExamsDocuments.map((doc) => ({
      file: new File([''], ''),
      type: PatientDocument.EXAM_REPORT,
      label: doc
    }))
    if (oldOtherDocuments.length === 0 && !preventCreateExam) {
      oldOtherDocuments.push({
        file: new File([''], ''),
        type: PatientDocument.EXAM_REPORT,
        label: 'Laudos 1'
      })
    }
    const documentsWithTitle = oldOtherDocuments.map((document) => ({
      ...document,
      title: document.label || ''
    }))

    setOtherDocuments(sortReports(documentsWithTitle))
  }, [defaultDocuments])

  const canAddMoreReport = otherDocuments.every((otherDocument) => {
    const hasDocument = documents.some(
      (doc) => doc.label === otherDocument.label
    )
    return hasDocument
  })

  return (
    <Modal>
      <S.Wrapper>
        <Heading as="h1" color="primary">
          Documentos, exames, laudos e relatórios
        </Heading>
        <S.Content>
          <S.DocumentList>
            <List padding size="medium" items={patientDocuments} />
            <List padding size="medium" items={patientOtherDocuments} />
            {handleUploadModal && (
              <UploadModal
                close={() => setHandleUploadModal(false)}
                submit={uploadSubmit}
                defaultFiles={
                  uploadType?.files.map(
                    ({ file, document_id, thumbnail }, index) => ({
                      file: file,
                      page: index + 1,
                      identifier: {
                        document_id: document_id,
                        type: uploadType?.type,
                        label: uploadType?.label
                      },
                      thumbnail
                    })
                  ) || []
                }
                documentType={uploadType?.documentType}
              />
            )}
          </S.DocumentList>
          {!isReviewOnly && canAddMoreReport && !canSee?.hasExams && (
            <S.AddOtherReports onClick={() => handleAddOtherReports()}>
              <S.AddOtherReportsLabel>
                Adicionar Outros Laudos
              </S.AddOtherReportsLabel>
            </S.AddOtherReports>
          )}
        </S.Content>
        <S.Buttons>
          <Button
            variant="outlined"
            type="button"
            onClick={() => handleClose()}
            data-testid="cancel-documents-modal-button"
          >
            {!isReviewOnly ? 'Cancelar' : 'Fechar'}
          </Button>
          {!isReviewOnly && (
            <Button
              type="button"
              onClick={() => handleSubmit()}
              data-testid="save-documents-modal-button"
            >
              Salvar
            </Button>
          )}
        </S.Buttons>
        <SuccesModal
          show={showSucessModal}
          description="Arquivos anexados com sucesso"
        />
      </S.Wrapper>
    </Modal>
  )
}
