import { cpfMask, phoneMask } from 'presentation/utils/masks'

type Fields = {
  name: string
  placeholder: string
  label: string
  mask?: any
}

export const fields: Fields[] = [
  {
    name: 'name',
    placeholder: 'Primeiro nome',
    label: 'Nome'
  },

  {
    name: 'cpf',
    placeholder: 'XXXXXXXX-X',
    label: 'CPF',
    mask: cpfMask
  },
  {
    name: 'email',
    placeholder: 'mariaroberta@gmail.com',
    label: 'Email'
  },
  {
    name: 'phone',
    placeholder: '(xx) xxxxx-xxxx',
    label: 'Celular',
    mask: phoneMask
  },
  {
    name: 'landlinePhone',
    placeholder: '(xx) xxxxx-xxxx',
    label: 'Telefone fixo',
    mask: phoneMask
  }
]
