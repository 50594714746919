import { LoadSecretaryScheduleSurgicalOrders } from 'domain/usecases/secretary/load-secretary-schedule-surgical-orders'
import { SecretaryRepository } from 'repository/interfaces/secretary-repository'

export class RemoteLoadSecretaryScheduleSurgicalOrders
  implements LoadSecretaryScheduleSurgicalOrders
{
  constructor(private readonly SecretaryRepository: SecretaryRepository) {}

  load(
    params: LoadSecretaryScheduleSurgicalOrders.Params
  ): Promise<LoadSecretaryScheduleSurgicalOrders.Model> {
    return this.SecretaryRepository.loadScheduleSurgicalOrders(params)
  }
}

export type LoadSecretaryScheduleSurgicalOrdersModel =
  LoadSecretaryScheduleSurgicalOrders.Model
