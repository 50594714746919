export const surgicalOrderFields = [
  'data {',
  ' surgical_order_id',
  ' isSolicitation',
  ' status {',
  '  status',
  ' }',
  ' hospital {',
  '  name',
  ' }',
  ' createdAt',
  ' patientName',
  ' healthInsurance {',
  '  healthInsuranceName',
  ' }',
  ' procedure {',
  '  description',
  ' }',
  ' doctorName',
  ' createdBy {',
  '  role',
  ' }',
  '}',
  'pageInfo {',
  ' currentPage',
  ' itemsPerPage',
  ' totalItems',
  ' totalPages',
  '}'
]
