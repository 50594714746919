import React, { Dispatch, SetStateAction, useEffect } from 'react'

import { useFormik, useFormikContext } from 'formik'
import moment from 'moment'
import * as yup from 'yup'
import { sortBy } from 'lodash'
import { toast } from 'react-toastify'
import 'main/config/yup'

import { HealthInsurance } from 'domain/entities/health-insurance'
import TextField from 'presentation/shared/components/TextField'
import SelectField from 'presentation/shared/components/SelectField'
import Heading from 'presentation/shared/components/Heading'
import Button from 'presentation/shared/components/Button'
import {
  cpfMask,
  dateMask,
  landlinePhoneMask,
  phoneMask
} from 'presentation/utils/masks'
import { Gender } from 'common/enum/gender'
import ActualPage from 'presentation/shared/components/ActualPage'
import { AddSurgicalOrderFormValues } from '../index'
import { useAddSurgicalOrderFunctions } from 'presentation/hospital/pages/AddSurgicalOrder/load-surgical-order'
import { Profile } from 'common/enum/profile'
import { PatientHealthInsurance } from 'domain/entities/patient-model'
import { AddPatient } from 'domain/usecases/patient/add-patient'
import { User } from 'domain/entities/user-model'
import { UploadPatientDocument } from 'domain/usecases/patient/upload-patient-document'
import {
  HealthInsurancePlans,
  Plans,
  SubPlan
} from 'domain/usecases/health-insurance/load-health-insurance-plans'
import { isIsoDate } from 'common/utils/verify-iso-date'
import {
  MaritalStatus,
  maritalStatus
} from 'presentation/utils/default-marital-status'
import getAgeByBirthdayDate from 'common/utils/getAgeByBirthdayDate'
import normalizeText from 'common/utils/getNormalizedText'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { alertInsuranceCardValidity } from 'presentation/utils/alertInsuranceCardValidity'
import { InvitePatient } from 'domain/usecases/patient/invite-patient'
import SupportText from 'presentation/shared/components/SupportText'
import Checkbox from 'presentation/shared/components/Checkbox'
import getLifeDaysByBirthdayDate from 'common/utils/getLifeDaysByBirthdayDate'
import { useServices } from 'presentation/hooks/use-services'

import * as S from './styles'
import DocInput from 'presentation/shared/components/FileInput'
import { PatientDocument } from 'common/enum/patient-document'
import { useStores } from 'presentation/hooks/use-stores'
import { DocumentItem } from 'presentation/shared/components/DocumentsArea/DocumentsAreaModal'
import { DocumentsType } from 'domain/entities/document-types'
import { getThumbnail } from 'presentation/shared/components/UploadSection/Functions'

type Props = {
  insurances?: HealthInsurancePlans[]
  plans: Plans[]
  goNext?: () => void
  goBack?: () => void
  onlyVisualization?: boolean
  findPatient?: (cpf: string) => void
  profile?: Profile
  updateOrder?: boolean
  createPatientMode?: boolean
  addPatient?: AddPatient
  invitePatient?: InvitePatient
  uploadDocument?: UploadPatientDocument
  setCreatePatientMode?: Dispatch<SetStateAction<boolean>>
  subPlans?: SubPlan[]
} & WithLoadingProps

export const ReviewPatient = WithLoading(
  ({
    insurances,
    goNext,
    goBack,
    onlyVisualization,
    plans,
    updateOrder,
    createPatientMode,
    setCreatePatientMode,
    subPlans,
    setIsLoading,
    invitePatient
  }: Props) => {
    const { values, setFieldValue, handleChange } =
      useFormikContext<AddSurgicalOrderFormValues>()

    const formContext = useAddSurgicalOrderFunctions()

    const patientService = useServices().patient

    const initialValues = {
      cpf: values.patient?.cpf,
      name: values.patient?.name,
      gender: values.patient?.gender,
      phone: values.patient?.phone,
      landlinePhone: values.patient?.landlinePhone,
      email: values.patient?.email,
      insurance: {
        healthInsuranceCode:
          values.patient?.healthInsurance?.healthInsuranceCode ||
          values?.healthInsurance?.healthInsuranceCode,
        healthInsuranceName:
          values.patient?.healthInsurance?.healthInsuranceName ||
          values?.healthInsurance?.healthInsuranceName,
        healthPlanCode:
          values.patient?.healthInsurance?.healthPlanCode ||
          values?.healthInsurance?.healthPlanCode,
        healthPlanName:
          values.patient?.healthInsurance?.healthPlanName ||
          values?.healthInsurance?.healthPlanName
      },
      maritalStatus: values.patient?.maritalStatus,
      company:
        values.patient?.healthInsurance?.company ||
        values?.healthInsurance?.company,
      insuranceCardNumber:
        values.patient?.healthInsurance?.healthCard ||
        values?.healthInsurance?.healthCard,
      insurancePlan: {
        code:
          values.patient?.healthInsurance?.healthPlanCode ||
          values?.healthInsurance?.healthPlanCode,
        description:
          values.patient?.healthInsurance?.healthPlanName ||
          values?.healthInsurance?.healthPlanName
      },
      insuranceSubPlan: {
        code: values.patient?.healthInsurance?.healthSubPlanCode,
        description: values.patient?.healthInsurance?.healthSubPlanName
      },
      insuranceCardValidity: values.patient?.healthInsurance?.validThru
        ? moment(values.patient?.healthInsurance.validThru).format('DD/MM/YYYY')
        : values?.healthInsurance?.validThru
        ? moment(values?.healthInsurance?.validThru).format('DD/MM/YYYY')
        : undefined,
      birthday: isIsoDate(values.patient?.birthday)
        ? moment(values.patient?.birthday).format('DD/MM/YYYY')
        : values.patient?.birthday,
      patientHealthCard: values.documents_attached?.filter(
        (document) => document?.type === PatientDocument.HEALTH_CARD
      ),
      newBornPatient: false
    } as ReviewPatientFormValues

    const formik = useFormik({
      initialValues,
      validationSchema: createPatientMode
        ? addPatientValidationSchema
        : validationSchema,
      validateOnMount: true,
      enableReinitialize: true,
      onSubmit: async (formValues) => {
        setIsLoading(true)
        try {
          if (onlyVisualization) {
            return goNext?.()
          }

          const insuranceData: Partial<PatientHealthInsurance> = {
            healthInsuranceName: formValues.insurance?.healthInsuranceName,
            healthInsuranceCode: formValues.insurance?.healthInsuranceCode
              ? Number(formValues.insurance?.healthInsuranceCode)
              : undefined,
            ansRegister: formValues.insurance?.ansRegister,
            healthPlanCode: formValues.insurancePlan?.code
              ? Number(formValues.insurancePlan?.code)
              : undefined,
            healthPlanName: formValues.insurancePlan?.description,
            healthSubPlanCode: formValues.insuranceSubPlan?.code
              ? Number(formValues.insurancePlan?.code)
              : undefined,
            healthSubPlanName: formValues.insuranceSubPlan?.description,
            healthCard: formValues.insuranceCardNumber,
            validThru: moment(
              formValues.insuranceCardValidity,
              'DD/MM/YYYY'
            ).toISOString(),
            company: formValues.company
          }

          if (!insuranceData.ansRegister) {
            insuranceData.ansRegister = insurances?.find(
              (val) => val.code === insuranceData.healthInsuranceCode
            )?.ansRegister
          }

          if (createPatientMode) {
            await handleInvitePatient(formValues, insuranceData)
          } else {
            setFieldValue('patient[healthInsurance]', insuranceData)
          }
          setFieldValue('healthInsurance', insuranceData)
          if (formValues.patientHealthCard) {
            const documentsAttached = [...(values.documents_attached || [])]
            const documentsFiltered = documentsAttached.filter(
              (document) => document.type !== PatientDocument.HEALTH_CARD
            )
            const newDocumentsAttached = documentsFiltered
              .concat(formValues.patientHealthCard)
              .filter((doc) => doc)
            setFieldValue('documents_attached', newDocumentsAttached)
          }
          !!goNext && goNext()
        } catch (err: any) {
          toast.error(err.message)
        } finally {
          setIsLoading(false)
        }
      }
    })

    const isNewbornPatient = (): boolean => {
      return !!formik.values.newBornPatient
    }

    const patienHasMoreThan30Days = (birthday?: string): boolean => {
      return birthday
        ? getLifeDaysByBirthdayDate(birthday) > 30
        : getLifeDaysByBirthdayDate(formik.values.birthday) > 30
    }

    const handleInvitePatient = async (
      formValues: ReviewPatientFormValues,
      insuranceData: Partial<PatientHealthInsurance>
    ) => {
      if (isNewbornPatient() && !patienHasMoreThan30Days()) {
        const newbornPatient = await patientService.inviteNewBornPatient({
          gender: formValues.gender ?? '',
          company: formValues.company ?? '',
          maritalStatus: MaritalStatus.SINGLE,
          birthday: moment(formValues.birthday, 'DD/MM/YYYY').toDate(),
          user: {
            name: normalizeText(formValues.name),
            cpf: formValues.cpf?.replace(/\D+/g, ''),
            phone: formValues.phone?.replace(/\D+/g, ''),
            landlinePhone: formValues.landlinePhone?.replace(/\D+/g, ''),
            email: formValues.email
          } as User,
          healthInsurance: {
            healthInsuranceCode: Number(insuranceData.healthInsuranceCode),
            healthInsuranceName: insuranceData.healthInsuranceName ?? '',
            healthPlanCode: Number(formValues.insurancePlan?.code),
            healthPlanName: formValues.insurancePlan?.description ?? '',
            healthCard: formValues.insuranceCardNumber ?? '',
            validThru: moment(
              formValues.insuranceCardValidity,
              'DD/MM/YYYY'
            ).toISOString(),
            company: insuranceData.company,
            ansRegister: insuranceData.ansRegister
          }
        })
        setFieldValue('patient', newbornPatient)
      } else {
        const patient = await invitePatient?.update({
          gender: formValues.gender ?? '',
          company: formValues.company ?? '',
          maritalStatus: isNewbornPatient()
            ? MaritalStatus.SINGLE
            : formValues.maritalStatus,
          birthday: moment(formValues.birthday, 'DD/MM/YYYY').toISOString(),
          user: {
            name: normalizeText(formValues.name),
            cpf: formValues.cpf?.replace(/\D+/g, ''),
            phone: formValues.phone?.replace(/\D+/g, ''),
            landlinePhone: formValues.landlinePhone?.replace(/\D+/g, ''),
            email: formValues.email
          } as User,
          healthInsurance: {
            healthInsuranceCode: Number(insuranceData.healthInsuranceCode),
            healthInsuranceName: insuranceData.healthInsuranceName ?? '',
            healthPlanCode: Number(formValues.insurancePlan?.code),
            healthPlanName: formValues.insurancePlan?.description ?? '',
            healthCard: formValues.insuranceCardNumber ?? '',
            validThru: moment(
              formValues.insuranceCardValidity,
              'DD/MM/YYYY'
            ).toISOString(),
            company: insuranceData.company,
            ansRegister: insuranceData.ansRegister
          }
        })
        setFieldValue('patient', patient)
      }
      setCreatePatientMode?.(false)
    }

    const handleInsuranceChange = async (id: string) => {
      formContext?.loadHealthPlans(Number(id))
      const insurance = insurances?.find((val) => val.code.toString() === id)
      const formikValue = {
        healthInsuranceCode: insurance?.code,
        healthInsuranceName: insurance?.description,
        ansRegister: insurance?.ansRegister
      }
      await formik.setFieldValue('insurance', formikValue)
      setFieldValue('insurance', formikValue)
      formik.setFieldValue('insurancePlan', undefined)
      setFieldValue('insurancePlan', undefined)
      formik.setFieldValue('insuranceSubPlan', undefined)
      setFieldValue('insuranceSubPlan', undefined)
    }

    const handlePlanChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      formContext?.loadHealthSubPlans(Number(e.currentTarget.value))
      const insurancePlan = plans.find(
        (val) => val.code.toString() === e.currentTarget.value
      )
      formik.setFieldValue('insurancePlan', insurancePlan)
      setFieldValue('insurancePlan', insurancePlan)
      formik.setFieldValue('insuranceSubPlan', undefined)
      setFieldValue('insuranceSubPlan', undefined)
    }

    const handleSubPlanChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const insuranceSubPlan = subPlans?.find(
        (val) => val.code.toString() === e.currentTarget.value
      )
      formik.setFieldValue('insuranceSubPlan', insuranceSubPlan)
      setFieldValue('insuranceSubPlan', insuranceSubPlan)
    }

    const filterPlansWhenPrivateInsurance = (
      healthInsuranceName: string | undefined,
      plans: Plans[]
    ) => {
      const PRIVATE_INSURANCE = 'PARTICULAR'
      if (
        healthInsuranceName &&
        healthInsuranceName.includes(PRIVATE_INSURANCE)
      ) {
        return plans.filter((plan) =>
          plan.description.includes(PRIVATE_INSURANCE)
        )
      }
      return plans
    }

    const handleChangePlanByPrivateInsurance = () => {
      const PRIVATE_INSURANCE = 'PARTICULAR'
      if (
        formik.values.insurance &&
        formik.values.insurance.healthInsuranceName === PRIVATE_INSURANCE
      ) {
        const insurancePrivatePlan = plans.find(
          (val) => val.description === PRIVATE_INSURANCE
        )
        if (insurancePrivatePlan) {
          formik.setFieldValue('insurancePlan', insurancePrivatePlan)
          setFieldValue('insurancePlan', insurancePrivatePlan)
        }
      }
    }

    const handleChangeBirthday = (value: string) => {
      formik.setFieldValue('birthday', value)
      if (patienHasMoreThan30Days(value)) {
        formik.setFieldValue('newBornPatient', false)
      } else {
        formik.setFieldValue('newBornPatient', true)
      }
    }

    useEffect(() => {
      handleChangePlanByPrivateInsurance()
    }, [formik.values.insurance])

    useEffect(() => {
      async function loadData() {
        await formContext?.loadHealthInsurances(
          values?.hospital?.hospital_id ?? 0
        )
      }
      loadData()
    }, [values?.hospital?.hospital_id])

    const stores = useStores()
    const currentUser = stores.currentAccount
    const role = currentUser.getCurrentAccount()?.user?.role

    const canUploadPatientHealthCard = () => {
      const canUpload = ['secretary']
      return canUpload.includes(role)
    }

    const onChangePatientHealthCard = async (
      event: React.ChangeEvent<HTMLInputElement>,
      index: 0 | 1
    ) => {
      if (!event.target.files?.[0]) return
      const documentType = DocumentsType.healthCardDocumentType
      const extension =
        event.target.files?.[0]?.name?.split('.').pop()?.toLocaleLowerCase() ||
        ''
      const hasExtensionNotAllowed =
        !documentType.extensionsAllowed.includes(extension)
      if (hasExtensionNotAllowed) return toast.error('Extensão inválida')
      const newFiles = [...(formik.values.patientHealthCard || [])]
      newFiles[index] = {
        type: PatientDocument.HEALTH_CARD,
        file: event.target.files?.[0],
        label: DocumentsType.healthCardDocumentType.label,
        thumbnail: await getThumbnail(event.target.files?.[0])
      }
      const filesSizeInMB = newFiles.reduce(
        (acc, newFile) => acc + newFile.file.size / 1024 / 1024,
        0
      )
      if (filesSizeInMB >= documentType.maxFileSizeInMB)
        return toast.error(
          `Tamanho máximo excedido: ${documentType.maxFileSizeInMB}mb`
        )
      const uniqueFiles: Record<string, number> = {}
      const hasDuplicated = newFiles.some((newFile) => {
        const nameSize = `${newFile.file.name}-${newFile.file.size}`
        if (uniqueFiles[nameSize]) {
          return true
        }
        uniqueFiles[nameSize] = 1
        return false
      })
      if (hasDuplicated) return toast.error('Arquivos duplicados')
      formik.setFieldValue('patientHealthCard', newFiles)
    }

    return (
      <S.Wrapper>
        {createPatientMode && (
          <>
            <ActualPage text="Voltar" onClick={goBack} />
            <Heading as="h1" size="xlarge" color="primary">
              Cadastro novo paciente
            </Heading>
            <SupportText color="lightGray">
              Insira os dados do paciente
            </SupportText>
          </>
        )}

        {!updateOrder && !createPatientMode && (
          <>
            <ActualPage text="Voltar" onClick={goBack} />
            <Heading as="h1" size="huge" color="primary">
              Novo pedido cirúrgico
            </Heading>
            <SupportText>Confirme os dados do paciente</SupportText>
          </>
        )}

        <Heading style={{ marginTop: '30px' }} as="h2">
          Dados do paciente
        </Heading>
        <S.Content>
          {createPatientMode && (
            <div>
              <S.NewBornCheckArea bgColor="mainBg">
                <Checkbox
                  label={'Paciente Recém-nascido?'}
                  labelFor={'Paciente Recém-nascido?'}
                  name="newBornPatient"
                  onCheck={(value) =>
                    formik.setFieldValue('newBornPatient', value)
                  }
                  checked={isNewbornPatient()}
                  data-testid="checkbox-newborn-patient"
                />
              </S.NewBornCheckArea>
            </div>
          )}
          <div>
            <TextField
              bgColor="mainBg"
              labelColor="gray"
              mask={cpfMask}
              label="CPF"
              placeholder="000.000.000-00"
              defaultValue={formik.values.cpf}
              onInputChange={formik.handleChange('cpf')}
              disabled={
                !createPatientMode ||
                (isNewbornPatient() && !patienHasMoreThan30Days())
              }
              required={!isNewbornPatient() || patienHasMoreThan30Days()}
              data-testid={`patient-cpf-disabled-input`}
            />
            <span
              style={{
                display: 'flex',
                gap: '32px'
              }}
            >
              {createPatientMode &&
                isNewbornPatient() &&
                !patienHasMoreThan30Days() && (
                  <TextField
                    labelColor="gray"
                    bgColor="mainBg"
                    label="RN"
                    placeholder="RN"
                    disabled
                    style={{
                      maxWidth: '65px'
                    }}
                    data-testid="input-rn"
                  />
                )}
              <TextField
                bgColor="mainBg"
                label="Paciente"
                labelColor="gray"
                placeholder={
                  isNewbornPatient() && !patienHasMoreThan30Days()
                    ? 'Nome da mãe'
                    : 'Nome do paciente'
                }
                style={{
                  width: '100%'
                }}
                defaultValue={formik.values.name}
                value={formik.values.name}
                onInputChange={(val) => {
                  const formattedValue = normalizeText(val)
                  formik.handleChange('name')(formattedValue)
                }}
                disabled={!createPatientMode}
                required
                data-testid="input-patient-name"
              />
            </span>
            <TextField
              bgColor="mainBg"
              mask={dateMask}
              label="Data de nascimento"
              labelColor="gray"
              placeholder="dd/mm/aaaa"
              value={formik.values.birthday}
              onInputChange={(value) => handleChangeBirthday(value)}
              disabled={!createPatientMode}
              required
              data-testid={`patient-birthday-disabled-input`}
            />
            <TextField
              bgColor="mainBg"
              labelColor="gray"
              label="Idade"
              value={getAgeByBirthdayDate(formik.values.birthday)}
              style={{ width: '70px' }}
              disabled
              data-testid={`patient-age-disabled-input`}
            />
          </div>
          <div>
            <SelectField
              name="gender"
              label="Gênero"
              items={[
                {
                  label: 'Masculino',
                  value: 'Masculino'
                },
                {
                  label: 'Feminino',
                  value: 'Feminino'
                }
              ]}
              labelColor="gray"
              onInputChange={formik.handleChange('gender')}
              onBlur={formik.handleBlur('gender')}
              value={formik.values.gender || ''}
              bgColor="mainBg"
              disabled={!createPatientMode}
              required
              data-testid={`patient-gender-disabled-input`}
            />
            <SelectField
              bgColor="mainBg"
              label="Estado civil"
              value={formik.values.maritalStatus || ''}
              onInputChange={formik.handleChange('maritalStatus')}
              disabled={!createPatientMode || isNewbornPatient()}
              items={maritalStatus}
              required={!isNewbornPatient()}
              data-testid={`patient-marital-status-disabled-input`}
            />
          </div>
          <div>
            <TextField
              bgColor="mainBg"
              labelColor="gray"
              label="E-mail"
              defaultValue={formik.values.email}
              onInputChange={formik.handleChange('email')}
              disabled={!createPatientMode}
              style={{ margin: 0 }}
              required
              data-testid={`patient-email-disabled-input`}
            />
            <TextField
              bgColor="mainBg"
              labelColor="gray"
              label="Celular"
              mask={phoneMask}
              placeholder="+55 00 0000000000"
              defaultValue={formik.values.phone}
              onInputChange={formik.handleChange('phone')}
              disabled={!createPatientMode}
              required
              data-testid={`patient-phone-disabled-input`}
            />
            <TextField
              bgColor="mainBg"
              labelColor="gray"
              label="Telefone fixo"
              mask={landlinePhoneMask}
              placeholder="+55 00 00000000"
              defaultValue={formik.values.landlinePhone}
              onInputChange={formik.handleChange('landlinePhone')}
              disabled={!createPatientMode}
              style={{ margin: 0 }}
              data-testid={`patient-landline-disabled-input`}
            />
          </div>
          <Heading style={{ marginTop: '30px' }} as="h2">
            Dados do convênio
          </Heading>
          <div>
            <SelectField
              bgColor="mainBg"
              labelColor="gray"
              label="Convênio"
              items={sortBy(
                insurances?.map((insurance) => ({
                  label: insurance.description,
                  value: insurance.code
                })),
                (val) => val.label
              )}
              disabled={onlyVisualization}
              onInputChange={(id) => {
                handleInsuranceChange(id)
              }}
              value={formik.values.insurance?.healthInsuranceCode || ''}
              required
              data-testid={`patient-health-insurance-disabled-input`}
            />
            <SelectField
              bgColor="mainBg"
              label="Plano"
              value={formik.values.insurancePlan?.code ?? ''}
              onChange={handlePlanChange}
              items={sortBy(
                filterPlansWhenPrivateInsurance(
                  formik.values.insurance?.healthInsuranceName,
                  plans
                ).map((plan) => ({
                  label: plan.description,
                  value: plan.code
                })),
                (val) => val.label
              )}
              disabled={
                !formik.values.insurance?.healthInsuranceCode ||
                plans?.length === 0
              }
              data-testid={`patient-health-insurance-plan-disabled-input`}
            />
            <SelectField
              bgColor="mainBg"
              label="Subplano"
              value={formik.values.insuranceSubPlan?.code ?? ''}
              onChange={handleSubPlanChange}
              items={sortBy(
                subPlans?.map((plan) => ({
                  label: plan.description,
                  value: plan.code
                })),
                (val) => val.label
              )}
              disabled={
                !formik.values.insurancePlan?.code || subPlans?.length === 0
              }
              data-testid={`patient-health-insurance-sub-plan-disabled-input`}
            />
            <TextField
              bgColor="mainBg"
              labelColor="gray"
              label="Empresa (titular do plano)"
              disabled={onlyVisualization}
              value={formik.values.company}
              onInputChange={formik.handleChange('company')}
              data-testid={`patient-health-insurance-company-disabled-input`}
            />
            <TextField
              bgColor="mainBg"
              labelColor="gray"
              label="Número da carteira"
              disabled={onlyVisualization}
              defaultValue={formik.values.insuranceCardNumber}
              onInputChange={formik.handleChange('insuranceCardNumber')}
              data-testid={`patient-health-insurance-card-number-disabled-input`}
              required
            />
            <TextField
              bgColor="mainBg"
              labelColor="gray"
              error={
                updateOrder
                  ? alertInsuranceCardValidity({
                      dateCheck: values.insuranceCardValidity
                    })
                  : alertInsuranceCardValidity({
                      dateCheck: formik.values.insuranceCardValidity
                    })
              }
              label="Validade da carteira"
              disabled={onlyVisualization}
              placeholder="dd/mm/aaaa"
              mask={dateMask}
              defaultValue={formik.values.insuranceCardValidity}
              onInputChange={
                updateOrder
                  ? handleChange('insuranceCardValidity')
                  : formik.handleChange('insuranceCardValidity')
              }
              data-testid={`patient-health-insurance-validity-disabled-input`}
            />
          </div>
          {canUploadPatientHealthCard() && (
            <>
              <Heading style={{ marginTop: '30px' }} as="h2">
                Foto da carteira (frente e verso)
              </Heading>
              <div>
                <DocInput
                  name={'patientHealthCard'}
                  onChange={(event) => onChangePatientHealthCard(event, 0)}
                  text="Clique para selecionar a foto da carteira"
                  style={{
                    height: '150px'
                  }}
                  span={<span>Clique aqui para adicionar</span>}
                  fileName={
                    formik.values.patientHealthCard?.[0]?.file?.name || ''
                  }
                  acceptFiles={DocumentsType.healthCardDocumentType.acceptFiles}
                  notUpdateFileName
                  ignoreOnInput
                />
                <DocInput
                  name={'patientHealthCardBack'}
                  text="Clique para selecionar a foto da carteira"
                  onChange={(event) => onChangePatientHealthCard(event, 1)}
                  style={{
                    height: '150px',
                    margin: 0
                  }}
                  span={<span>Clique aqui para adicionar</span>}
                  fileName={
                    formik.values.patientHealthCard?.[1]?.file?.name || ''
                  }
                  acceptFiles={DocumentsType.healthCardDocumentType.acceptFiles}
                  notUpdateFileName
                  ignoreOnInput
                />
              </div>
            </>
          )}
        </S.Content>

        {!updateOrder && (
          <S.Buttons>
            <Button
              variant="outlined"
              type="button"
              onClick={goBack}
              data-testid={`go-back-or-next-button`}
            >
              Voltar
            </Button>
            <Button
              disabled={!formik.isValid}
              type="button"
              onClick={formik.submitForm}
              data-testid={`go-back-or-next-button`}
            >
              Próximo
            </Button>
          </S.Buttons>
        )}
      </S.Wrapper>
    )
  }
)

export type ReviewPatientFormValues = {
  cpf?: string
  name?: string
  birthday?: string
  gender?: Gender
  phone?: string
  landlinePhone?: string
  email?: string
  insurance?: HealthInsurance
  company?: string
  insurancePlan?: { code: number; description: string }
  insuranceSubPlan?: { code: number; description: string }
  insuranceCardNumber?: string
  insuranceCardValidity?: string
  maritalStatus: string
  newBornPatient?: boolean
  patientHealthCard?: DocumentItem[]
}

const defaultSchema = {
  insurance: yup.object().shape({
    healthInsuranceCode: yup.mixed().required(),
    healthInsuranceName: yup.string().required()
  }),
  insuranceCardNumber: yup.mixed().required(),
  insuranceCardValidity: yup.date().format('DD/MM/YYYY', true).optional()
}

const validationSchema = yup.object().shape({
  ...defaultSchema
})

export default ReviewPatient

const addPatientValidationSchema = yup.object().shape({
  cpf: yup.string().when(['newBornPatient', 'birthday'], {
    is: (newBornPatient: boolean, birthday: Date) =>
      !newBornPatient ||
      getLifeDaysByBirthdayDate(moment(birthday).format('DD/MM/YYYY')) > 30,
    then: yup.string().required()
  }),
  name: yup.string().required(),
  gender: yup.string().required(),
  email: yup.string().email().required(),
  maritalStatus: yup.string().when('newBornPatient', {
    is: (value: boolean) => !value,
    then: yup.string().required()
  }),
  phone: yup
    .string()
    .matches(/^(?:\+)[0-9]{2}\s?[0-9]{2}\s?[0-9]{9}$/, 'Telefone inválido')
    .required(),
  landlinePhone: yup
    .string()
    .matches(/^(?:\+)[0-9]{2}\s?[0-9]{2}\s?[0-9]{8}$/, 'Telefone inválido')
    .optional(),
  birthday: yup.date().max(new Date()).format('DD/MM/YYYY', true).required(),
  ...defaultSchema
})
