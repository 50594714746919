export const defaultEquipments = [
  'Apirador Ultrassônico',
  'RX',
  'Radioscopia',
  'Suporte de ombro',
  'Polígrafo',
  'Vídeo Laparoscópico',
  'Vídeo Artroscópio',
  'Bisturi Ultrassônico',
  'USG',
  'Dopleer',
  'Eco Transesofágio',
  'Balão Intraórtico',
  'Fotoforo',
  'Monitorização Neufisiológica',
  'Perfurador',
  'Serra Ortopédica',
  'Drill Neuro',
  'Fonte de Luz',
  'Broconscópio',
  'Mesa de tração',
  'ECO',
  'Neuro Navegador',
  'Microscópio',
  'Bio Pump',
  'Outros'
]
