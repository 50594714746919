import React from 'react'

import Heading from 'presentation/shared/components/Heading'

import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'

import * as S from './styles'
import { useHistory } from 'react-router'
import Button from 'presentation/shared/components/Button'

type OverviewProps = {
  surgicalOrder?: SurgicalOrderModel
  getExpectedDate(): string
  showHeading?: boolean
  showAsPanel?: boolean
}

export const OverviewPanel = ({
  surgicalOrder,
  getExpectedDate,
  showHeading = true,
  showAsPanel = true
}: OverviewProps) => {
  const history = useHistory()
  return (
    <S.Wrapper showAsPanel={showAsPanel}>
      <div style={{ width: '80%' }}>
        {showHeading && (
          <Heading size="huge" color="primary" as="h1">
            {surgicalOrder?.procedure
              ?.find((procedure) => procedure.isMain)
              ?.description.toLowerCase() ?? '-'}
          </Heading>
        )}
        <S.OverviewItems>
          <section>
            <S.Item>
              <S.ItemLabel>Paciente:</S.ItemLabel>
              <S.ItemContent>{surgicalOrder?.patient?.name}</S.ItemContent>
            </S.Item>
            <S.Item>
              <S.ItemLabel>Número do Aviso:</S.ItemLabel>
              <S.ItemContent>{surgicalOrder?.surgical_order_id}</S.ItemContent>
            </S.Item>
          </section>
          <section>
            <S.Item>
              <S.ItemLabel>Unidade:</S.ItemLabel>
              <S.ItemContent>{surgicalOrder?.hospital?.name}</S.ItemContent>
            </S.Item>
            <S.Item>
              <S.ItemLabel>
                {surgicalOrder?.surgeryDate
                  ? 'Data Agendada:'
                  : surgicalOrder?.hospitalizationType === 'urgency'
                  ? 'Data de  Urgência:'
                  : 'Data Prevista:'}
              </S.ItemLabel>
              <S.ItemContent>{getExpectedDate()}</S.ItemContent>
            </S.Item>
          </section>
        </S.OverviewItems>
      </div>
      <div style={{ width: '20%' }}>
        <Button
          type="button"
          onClick={() =>
            history.push('/pedido/resumo', {
              id: surgicalOrder.surgical_order_id
            })
          }
        >
          Ver resumo
        </Button>
      </div>
    </S.Wrapper>
  )
}
