import React from 'react'
import * as S from './styles'
import { ReactComponent as AuthorizationIcon } from 'presentation/assets/icons/authorization-pendencies.svg'
import { ReactComponent as AssistanceIcon } from 'presentation/assets/icons/assistance-pendencies.svg'
import { useHistory } from 'react-router-dom'

type PendencyCardProps = {
  type: 'assistance' | 'authorization'
  quantity?: number
}

type ContentType = {
  [key in PendencyCardProps['type']]: {
    icon: JSX.Element
    content: JSX.Element
    url: string
  }
}

export default function PendencyCard({ type, quantity }: PendencyCardProps) {
  const history = useHistory()

  const content: ContentType = {
    assistance: {
      icon: <AssistanceIcon />,
      content: (
        <>
          Pendências <br /> Assistenciais
        </>
      ),
      url: '/assistencial'
    },
    authorization: {
      icon: <AuthorizationIcon />,
      content: (
        <>
          Pendências <br />
          de Autorização
        </>
      ),
      url: '/autorizacao'
    }
  }

  const realQuantity = typeof quantity === 'number' ? quantity : ''

  return (
    <>
      <S.PendencyCard
        onClick={() => {
          history.push(`/pendencias${content[type].url}`)
        }}
        data-testid={`span-pendency-card-${type}`}
      >
        <div>
          {content[type].icon}
          {content[type].content}
        </div>
        <strong>{realQuantity}</strong>
      </S.PendencyCard>
    </>
  )
}
