import styled from 'styled-components'

export const Wrapper = styled.div``

export const OrderInfo = styled.div`
  padding: 40px 70px;

  h1 {
    line-height: 3.54rem;
    margin-bottom: 20px;
  }

  & h6 {
    font-weight: ${({ theme }) => theme.font.normal};
    font-size: ${({ theme }) => theme.font.sizes.small};
    margin-right: 4px;
  }

  & span {
    font-weight: ${({ theme }) => theme.font.bold};
    font-size: ${({ theme }) => theme.font.sizes.small};
  }

  & div {
    display: flex;
  }

  & > div {
    justify-content: space-between;
  }

  background-color: white;
  color: ${({ theme }) => theme.colors.primary};
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const Profile = styled.div`
  display: flex;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
  }

  img {
    margin-right: 16px;
  }

  span {
    font-weight: ${({ theme }) => theme.font.semiBold};
    color: ${({ theme }) => theme.colors.lightGray};
  }
`

export const ListTitle = styled.span`
  font-weight: ${({ theme }) => theme.font.semiBold};
  font-size: ${({ theme }) => theme.font.sizes.medium};
  color: ${({ theme }) => theme.colors.gray};
`

export const SurgicalPlanning = styled.div`
  margin-top: 40px;
  padding: 40px 70px;
  h2 {
    font-size: ${({ theme }) => theme.font.sizes.xlarge};
    margin-bottom: 30px;
  }
  background-color: ${({ theme }) => theme.colors.white};
`

export const Documents = styled.div`
  margin-top: 40px;
  padding: 40px 70px;
  h2 {
    font-size: ${({ theme }) => theme.font.sizes.xlarge};
    margin-bottom: 30px;
  }
  background-color: ${({ theme }) => theme.colors.white};
`

export const Resume = styled.div`
  margin-top: 40px;
  padding: 40px 70px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  h2 {
    font-size: ${({ theme }) => theme.font.sizes.xlarge};
    margin-bottom: 30px;
  }
  background-color: ${({ theme }) => theme.colors.white};
`

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    max-width: 170px;
    margin-bottom: 20px;
  }
`
