import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-top: 12px;
`

export const PatientInfo = styled.div`
  display: grid;
  grid-template-columns: 212px repeat(3, 90px);
  gap: 20px;
  margin-bottom: 40px;
  margin-top: 40px;ali gatie
`

export const SurgeryWrapper = styled.div`
  section {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  section:not(:first-child) {
    margin-top: 1.75rem;
  }
`

export const OpmeWrapper = styled.div`
  margin-top: 60px;
`

export const HospitalizationWrapper = styled.div``

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem 2rem;
`

export const Headers = styled.div`
  margin-top: 3.75rem;
  margin-bottom: 1.25rem;
  display: grid;
  grid-template-columns: 1.5fr 2fr 1fr;
  gap: 2rem 2rem;
`

export const Congelation = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  label {
    font-size: 1.5em;
  }
`

export const ChipContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-begin;
  min-height: 5rem;

  & > div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    div:not(:last-child) {
      margin-right: 12px;
    }
    & > div {
      margin-top: 10px;
    }
  }

  & > label {
    font-size: ${({ theme }) => theme.font.sizes.small};
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gray};
    cursor: pointer;

    span {
      color: ${({ theme }) => theme.colors.red};
    }
  }
`

export const UtiContainer = styled.div`
  display: flex;
  margin-bottom: 1.75rem;

  label {
    color: ${({ theme }) => theme.colors.gray};
  }
  span {
    color: red;
  }
`

export const BloodTypeContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;

  margin-bottom: 3rem;
`

export const DateContainer = styled.div`
  display: flex;

  input {
    padding: 0 0.5rem;
  }

  > div:not(:first-child) {
    margin-left: 1rem;
  }
`

export const Buttons = styled.div`
  display: flex;
  margin-top: 30px;
  margin-left: auto;
  justify-content: flex-end;
  button + button {
    margin-left: 5%;
  }
`

export const ExpectedDateWrapper = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  input {
    margin: 0;
  }

  & > label {
    font-size: ${({ theme }) => theme.font.sizes.medium};
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gray};
    cursor: pointer;
    margin-right: 10px;
  }
`

export const DiaryWrapper = styled.div`
  padding: 0;
  display: flex;
  align-items: center;

  input {
    padding: 0;
    margin: 0;
    text-align: center;
  }

  & > label {
    font-size: ${({ theme }) => theme.font.sizes.large};
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gray};
    cursor: pointer;
    margin-right: 10px;

    span {
      color: ${({ theme }) => theme.colors.red};
    }
  }
`
export const SurgeryDocumentsContainer = styled.div`
  margin: 60px 0;
`

export const DocumentsButton = styled.div``

export const DateSuportText = styled.div`
  color: ${({ theme }) => theme.colors.darkGreen};
  background: ${({ theme }) => theme.colors.moreLightGreen};
  width: fit-content;
  font-style: italic;
  padding: 0 10px;
  margin-top: 1rem;
`
