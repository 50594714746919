import React, { useState } from 'react'
import getAgeByBirthdayDate from 'common/utils/getAgeByBirthdayDate'
import TextField from 'presentation/shared/components/TextField'
import Modal from 'presentation/shared/components/Modal'
import Heading from 'presentation/shared/components/Heading'
import { cpfMask, dateMask, phoneMask } from 'presentation/utils/masks'
import { maritalStatus } from 'presentation/utils/default-marital-status'
import { useFormik } from 'formik'
import { Patient } from 'domain/entities/patient-model'
import moment from 'moment'
import * as S from './styles'
import SelectField from 'presentation/shared/components/SelectField'
import Button from 'presentation/shared/components/Button'
import * as yup from 'yup'
import { Gender } from 'common/enum/gender'

type Props = {
  showModal(show: boolean): void
  patientData: Patient
  changePatientData: (data: ModifiedPatientData) => void
}

export default function EditPatientDataModal({
  showModal,
  patientData,
  changePatientData
}: Props) {
  const birthDay = moment(patientData.birthday).format('DD/MM/YYYY')

  const formik = useFormik({
    initialValues: {
      patient: patientData,
      cpf: patientData.cpf,
      name: patientData.name,
      gender: patientData.gender,
      phone: patientData.phone,
      email: patientData.email,
      maritalStatus: patientData.maritalStatus,
      landlinePhone: patientData.landlinePhone,
      birthday: birthDay
    },
    validateOnMount: true,
    validationSchema,
    onSubmit: async (values) => {
      const newData = {
        ...values,
        birthday: moment(values.birthday, 'DD/MM/YYYY').toISOString(),
        phone: values.phone?.replace(/\D/g, ''),
        landlinePhone: values.landlinePhone?.replace(/\D/g, '')
      }
      changePatientData(newData)
    }
  })
  const [onlyVisualization] = useState(true)
  const [modalVisible] = useState(true)
  const age = getAgeByBirthdayDate(formik.values.birthday)
  const genderList = [
    { label: 'Masculino', value: 'Masculino' },
    { label: 'Feminino', value: 'Feminino' }
  ]

  return (
    <Modal show={modalVisible} preventAutomateClose style={{ width: '50%' }}>
      <S.Container>
        <Heading
          style={{ textAlign: 'left' }}
          size="large"
          color="gray"
          as="h1"
        >
          Dados do paciente
        </Heading>
        <S.Wrapper>
          <S.InputsContainer>
            <TextField
              key={'cpf'}
              bgColor="mainBg"
              label={'CPF'}
              name={'cpf'}
              mask={cpfMask}
              defaultValue={formik.values.cpf}
              disabled={onlyVisualization}
            />
            <TextField
              key={'name'}
              bgColor="mainBg"
              label={'Paciente'}
              name={'name'}
              onChange={formik.handleChange}
              defaultValue={formik.values.name}
              required
            />
            <SelectField
              style={{ textAlign: 'left' }}
              label="Sexo"
              name="gender"
              bgColor="mainBg"
              items={genderList}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              defaultValue={formik.values.gender}
              required
            />
          </S.InputsContainer>
          <S.InputsContainer>
            <SelectField
              style={{ textAlign: 'left' }}
              label="Estado civil"
              name="maritalStatus"
              bgColor="mainBg"
              items={maritalStatus}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              defaultValue={formik.values.maritalStatus}
              required
              disabled
            />
            <TextField
              key={'birthday'}
              bgColor="mainBg"
              label={'Data de nascimento'}
              name={'birthday'}
              onChange={formik.handleChange}
              defaultValue={birthDay}
              mask={dateMask}
              required
            />
            <TextField
              key={'age'}
              bgColor="mainBg"
              label={'Idade'}
              name={'age'}
              disabled={onlyVisualization}
              value={age || '-'}
              style={{ width: 75 }}
            />
          </S.InputsContainer>
          <S.InputsContainer>
            <TextField
              key={'email'}
              bgColor="mainBg"
              label={'E-mail'}
              name={'email'}
              onChange={formik.handleChange}
              defaultValue={formik.values.email}
              required
            />
            <TextField
              key={'phone'}
              bgColor="mainBg"
              label={'Celular'}
              name={'phone'}
              onChange={formik.handleChange}
              defaultValue={formik.values.phone}
              mask={phoneMask}
              required
            />
            <TextField
              key={'landlinePhone'}
              bgColor="mainBg"
              label={'Telefone fixo'}
              name={'landlinePhone'}
              onChange={formik.handleChange}
              defaultValue={formik.values.landlinePhone}
              mask={phoneMask}
            />
          </S.InputsContainer>
          <S.ActionsContainer>
            <Button
              style={{ width: '30%', marginRight: 20 }}
              variant="outlined"
              onClick={() => showModal(false)}
            >
              Cancelar
            </Button>
            <Button
              style={{ width: '30%' }}
              onClick={() => formik.submitForm()}
              type="submit"
              disabled={!formik.isValid}
            >
              <S.ButtonTitle>Salvar</S.ButtonTitle>
            </Button>
          </S.ActionsContainer>
        </S.Wrapper>
      </S.Container>
    </Modal>
  )
}

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup.string().required(),
  gender: yup.string().required(),
  maritalStatus: yup.string().required(),
  birthday: yup.date().format('DD/MM/YYYY', true).required()
})

export type ModifiedPatientData = {
  patient: Patient
  cpf: string
  name: string
  gender: Gender
  phone?: string
  email: string
  maritalStatus?: string
  landlinePhone?: string
  birthday: string
}
