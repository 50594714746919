import { useFormik, useFormikContext } from 'formik'
import Button from 'presentation/shared/components/Button'
import Heading from 'presentation/shared/components/Heading'
import TextField from 'presentation/shared/components/TextField'
import React, { useMemo } from 'react'
import * as FormS from '../styles'
import * as S from './styles'
import * as yup from 'yup'
import { AddSurgicalOrderFormValues } from '../..'
import Table from 'presentation/shared/components/Table'

type SelectCmeProps = {
  onlyVisualization?: boolean
}

const initialFormValues = {
  description: '',
  quantity: 1
}

export function SelectCme({ onlyVisualization }: SelectCmeProps) {
  const { values: formValues, setFieldValue } =
    useFormikContext<AddSurgicalOrderFormValues>()
  const formik = useFormik<AddCmeForm>({
    initialValues: initialFormValues,
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setFieldValue('cmes', [
        ...formValues.cmes,
        {
          description: values.description,
          quantity: Number(values.quantity)
        }
      ])
      formik.setValues(initialFormValues)
    }
  })

  const removeCme = (index: number[]) => {
    const newCmes = formValues.cmes.filter((_, i) => !index.includes(i))
    setFieldValue('cmes', newCmes)
  }

  const inputs = (
    <S.Inputs>
      <TextField
        placeholder="Descrição"
        onInputChange={formik.handleChange('description')}
        value={formik.values.description}
        bgColor="mainBg"
        style={{ width: '100%' }}
        data-testid={`opme-description-input`}
      />
      <S.QuantityContainer>
        <S.Title>Quantidade</S.Title>
        <TextField
          onInputChange={formik.handleChange('quantity')}
          value={formik.values.quantity}
          type="number"
          bgColor="mainBg"
          data-testid={`opme-quantity-input`}
          min={1}
        />
      </S.QuantityContainer>

      <Button
        type="button"
        onClick={formik.submitForm}
        disabled={!formik.isValid}
        data-testid={`add-opme-button`}
      >
        Adicionar
      </Button>
    </S.Inputs>
  )

  const CmeTable = useMemo(() => {
    return (
      <Table
        checkbox={!onlyVisualization}
        data={formValues.cmes}
        columns={cmeColumns}
        rowsDelete={removeCme}
        pagination={false}
        noItemsDescription="Nenhum CME adicionado"
      />
    )
  }, [formValues.cmes])

  return (
    <>
      <FormS.SurgeryWrapper>
        <Heading
          size="large"
          color="primary"
          style={{ marginTop: '60px', marginBottom: '24px' }}
        >
          CME
        </Heading>
      </FormS.SurgeryWrapper>
      {!onlyVisualization ? inputs : null}
      {CmeTable}
    </>
  )
}

type AddCmeForm = {
  description: string
  quantity: number
}

const validationSchema = yup.object().shape({
  description: yup.string().required('Campo obrigatório'),
  quantity: yup.number().required(' ')
})

export const cmeColumns = [
  {
    label: 'Descrição',
    name: 'description',
    options: {
      sort: false
    }
  },
  {
    label: 'Quantidade',
    name: 'quantity',
    options: {
      sort: false
    }
  }
]
