import React, { useState, useEffect } from 'react'

import PendenciesLayout from 'presentation/secretary/layouts/Pendencies'
import { LoadSecretary } from 'domain/usecases/secretary/load-secretary'
import { SurgicalPendency } from 'domain/entities/surgical-pendency'
import { toast } from 'react-toastify'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import { AnswerSurgicalPendency } from 'domain/usecases/surgical-pendency/answer-surgical-pendency'
import { UploadSurgicalPendencyDocument } from 'domain/usecases/surgical-pendency/upload-surgical-pendency-document'
import { LoadSurgicalPendencyDocument } from 'domain/usecases/surgical-pendency/load-surgical-pendency-document'

type Props = {
  loadSecretary: LoadSecretary
  answerSurgicalPendency: AnswerSurgicalPendency
  uploadSurgicalPendencyDocument: UploadSurgicalPendencyDocument
  loadSurgicalPendencyDocument?: LoadSurgicalPendencyDocument
}

export const SecretaryPendencies = ({
  loadSecretary,
  answerSurgicalPendency,
  uploadSurgicalPendencyDocument,
  loadSurgicalPendencyDocument
}: Props) => {
  const [pendencies, setPendencies] = useState<SurgicalPendency[]>([])
  const [surgicalOrders, setSurgicalOrders] = useState<SurgicalOrderModel[]>([])

  async function loadData() {
    try {
      const res = await loadSecretary.load([
        'pendencies {surgical_pendency_id, surgical_order_id, observation, answer, answeredAt, impediment, status, createdAt,' +
          'requestedBy {name}, documents { document_id, type, name}, patient { name }},' +
          'surgicalOrder {surgical_order_id, procedure{description, procedure_code}}'
      ])
      const { pendencies, surgicalOrder } = res
      if (pendencies && surgicalOrder) {
        setPendencies(pendencies)
        setSurgicalOrders(surgicalOrder)
      }
    } catch (err: any) {
      toast.error(err.message)
    }
  }

  useEffect(() => {
    loadData()
  }, [])
  return (
    <PendenciesLayout
      pendencies={pendencies}
      surgicalOrders={surgicalOrders}
      answerSurgicalPendency={answerSurgicalPendency}
      uploadSurgicalPendencyDocument={uploadSurgicalPendencyDocument}
      loadSurgicalPendencyDocument={loadSurgicalPendencyDocument}
      refreshPendencies={loadData}
    />
  )
}
