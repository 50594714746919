import React from 'react'
import { ReactComponent as LogoutIcon } from 'presentation/assets/icons/exit.svg'
import { observer } from 'mobx-react'
import menuItems from './items'

import * as S from './styles'
import { useStores } from 'presentation/hooks/use-stores'
import { useHistory } from 'react-router'

type Props = {
  menuOpen?: boolean
}

const Menu = observer(({ menuOpen }: Props) => {
  const history = useHistory()
  const user = useStores().currentAccount
  const permissions = useStores().permissions

  const logout = () => {
    user.removeCurrentAccount()
    permissions.clean()
    history.push('/login')
  }

  const redirect = (url: string) => {
    if (url.match('https')) {
      window.open(url, '_blank')
    } else {
      history.push(url)
    }
  }

  return (
    <S.Wrapper open={menuOpen}>
      {menuItems[user.getCurrentAccount()?.user?.role]?.map((link) => (
        <S.MenuItem
          onClick={() => redirect(link.link)}
          key={link.label}
          active={history.location.pathname === link.link}
        >
          {link.icon}
          <S.MenuLink open={menuOpen}>{link.label}</S.MenuLink>
        </S.MenuItem>
      ))}
      <S.MenuItem onClick={logout}>
        <LogoutIcon />
        <S.MenuLink open={menuOpen}>Sair</S.MenuLink>
      </S.MenuItem>
    </S.Wrapper>
  )
})

export default Menu
