import styled from 'styled-components'

export const Wrapper = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(298px, 1fr));
  grid-gap: 24px 44px;

  width: 100%;
  max-width: 720px;
`
export const Actions = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
  justify-content: flex-end;
`
