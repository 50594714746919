import React from 'react'
import MuiTooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from 'styled-components'
import { Zoom } from '@material-ui/core'

const DEFAULT_STYLE = {
  tooltip: {
    maxWidth: '200px',
    fontSize: 14,
    fontWeight: 600
  }
}

const StandardTooltipStyle = makeStyles((theme) => {
  const scTheme = useTheme()
  return {
    tooltip: {
      ...DEFAULT_STYLE.tooltip,
      backgroundColor: theme.palette.common.white,
      color: scTheme.colors.primary,
      boxShadow: theme.shadows[5],
      fontFamily: scTheme.font.family,
      textAlign: 'center'
    },
    arrow: {
      color: theme.palette.common.white
    }
  }
})

const AlertTooltipStyle = makeStyles((theme) => {
  const scTheme = useTheme()
  return {
    tooltip: {
      ...DEFAULT_STYLE.tooltip,
      backgroundColor: scTheme.colors.moreLightRed,
      color: scTheme.colors.red,
      boxShadow: theme.shadows[5],
      fontFamily: scTheme.font.family,
      maxWidth: '300px',
      textAlign: 'center'
    },
    arrow: {
      color: scTheme.colors.moreLightRed
    }
  }
})

type Props = {
  children: React.ReactElement
  text: string
  type?: 'standard' | 'alert'
}

export function Tooltip({ children, text, type = 'standard' }: Props) {
  const classes =
    type === 'standard' ? StandardTooltipStyle() : AlertTooltipStyle()

  return (
    <MuiTooltip
      title={text}
      style={{ cursor: 'pointer' }}
      arrow
      placement="top-start"
      TransitionComponent={Zoom}
      leaveDelay={500}
      classes={classes}
    >
      {children}
    </MuiTooltip>
  )
}
