import React from 'react'

import { useFormik } from 'formik'
import * as yup from 'yup'

import * as S from './styles'
import TextField from 'presentation/shared/components/TextField'
import Heading from 'presentation/shared/components/Heading'
import Button from 'presentation/shared/components/Button'
import Table from 'presentation/shared/components/Table'
import { ReactComponent as PlusIcon } from 'presentation/assets/icons/plus.svg'
import SelectField from 'presentation/shared/components/SelectField'
import {
  medicalTeamType,
  medicalTeamTypeDictionary
} from 'presentation/utils/professional'
import { crmMask, phoneMaskWithoutDDI } from 'presentation/utils/masks'

type Props = {
  selectedMedicalTeam: any[]
  setSelectedMedicalTeam?: (procedure: any) => void
  doctorName?: string
  onlyVisualization?: boolean
}

export const MedicalTeamTable = ({
  onlyVisualization,
  selectedMedicalTeam,
  setSelectedMedicalTeam
}: Props) => {
  const validationSchema = yup.object().shape({
    type: yup.string().required(),
    name: yup.string().required(),
    crm: yup.string().required(),
    phone: yup
      .string()
      .matches(/^\(\d{2}\) \d{9}$/gi, 'Telefone inválido')
      .required()
  })

  const formik = useFormik({
    initialValues: {
      type: '',
      name: '',
      crm: '',
      phone: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setSelectedMedicalTeam &&
        setSelectedMedicalTeam([
          ...(selectedMedicalTeam ?? []),
          {
            type: values.type,
            name: values.name,
            crm: values.crm,
            phone: values.phone
          }
        ])
      formik.resetForm()
    }
  })

  const onDeleteRow = (index: number[]) => {
    setSelectedMedicalTeam &&
      setSelectedMedicalTeam(
        selectedMedicalTeam
          ?.filter(
            (medicalTeam, medicalTeamIndex) => !index.includes(medicalTeamIndex)
          )
          .map((medicalTeams) => {
            return { ...medicalTeams }
          })
      )
  }

  const isValid = () => {
    return formik.isValid
  }

  return (
    <S.Wrapper>
      <Heading
        size="large"
        color="primary"
        style={{ marginTop: '3rem', marginBottom: '1.25rem' }}
      >
        Equipe Médica
      </Heading>
      {!onlyVisualization && (
        <S.Inputs>
          <SelectField
            label="Profissional"
            labelColor="gray"
            bgColor="mainBg"
            name="type"
            id="type"
            items={medicalTeamType}
            value={formik.values.type}
            onChange={formik.handleChange('type')}
          />
          <TextField
            label="Nome"
            labelColor="gray"
            bgColor="mainBg"
            name="name"
            id="name"
            placeholder="Nome do profissional"
            value={formik.values.name}
            onChange={formik.handleChange('name')}
          />
          <TextField
            label="CRM"
            labelColor="gray"
            name="crm"
            id="crm"
            mask={crmMask}
            bgColor="mainBg"
            value={formik.values.crm}
            onChange={formik.handleChange('crm')}
          />
          <TextField
            label="Telefone"
            labelColor="gray"
            name="phone"
            id="phone"
            mask={phoneMaskWithoutDDI}
            bgColor="mainBg"
            value={formik.values.phone}
            onChange={formik.handleChange('phone')}
          />
          <Button
            type="button"
            disabled={!isValid()}
            onClick={formik.submitForm}
          >
            <PlusIcon width="12px" height="12px" />
            Adicionar
          </Button>
        </S.Inputs>
      )}
      <Table
        checkbox={!onlyVisualization}
        data={selectedMedicalTeam?.map((medicalTeam) => ({
          ...medicalTeam,
          type: medicalTeamTypeDictionary[medicalTeam.type]
        }))}
        columns={medicalTeamColumns}
        rowsDelete={onDeleteRow}
        pagination={false}
        noItemsDescription="Nenhum profissional adicionado"
      />
    </S.Wrapper>
  )
}

export default React.memo(MedicalTeamTable)

export const medicalTeamColumns = [
  {
    label: 'Profissional',
    name: 'type',
    options: {
      sort: false
    }
  },
  {
    label: 'Nome',
    name: 'name',
    options: {
      sort: false
    }
  },
  {
    label: 'CRM',
    name: 'crm',
    options: {
      sort: false
    }
  },
  {
    label: 'Telefone',
    name: 'phone',
    options: {
      sort: false
    }
  }
]
