import React, { useState } from 'react'
import { DocumentItem, DocumentsAreaModal } from './DocumentsAreaModal'
import { DocumentsList } from 'presentation/shared/pages/SurgeryDetails/documents'
import AdvancedButton from '../AdvancedButton'

type DocumentsAreaProps = {
  defaultDocuments: DocumentItem[]
  handleChangeDocuments?(documents: DocumentItem[]): void
  handleDownloadDocument?(document_id: number): Promise<File>
  isReviewOnly?: boolean
  defaultDocumentsListType?: DocumentsList[]
  hasDocuments?: boolean
  canSee?: {
    hasExams?: boolean
    hasMedicalReport?: boolean
  }
  preventCreateExam?: boolean
}

export default function DocumentsArea({
  defaultDocuments,
  handleChangeDocuments,
  handleDownloadDocument,
  isReviewOnly,
  defaultDocumentsListType,
  hasDocuments = true,
  canSee,
  preventCreateExam
}: DocumentsAreaProps) {
  const [showDocumentsModal, setShowDocumentsModal] = useState<boolean>(false)

  return (
    <>
      <AdvancedButton
        title="Documentos, Exames, Laudos e Relatórios Anexos"
        onClick={() => setShowDocumentsModal(true)}
        showAttachmentIcon={defaultDocuments?.length > 0 && hasDocuments}
      />
      {showDocumentsModal && (
        <DocumentsAreaModal
          defaultDocuments={defaultDocuments}
          handleChangeDocuments={(documents) =>
            handleChangeDocuments && handleChangeDocuments(documents)
          }
          handleClose={() => setShowDocumentsModal(false)}
          handleDownloadDocument={handleDownloadDocument}
          isReviewOnly={isReviewOnly}
          defaultDocumentsListType={defaultDocumentsListType}
          canSee={canSee}
          preventCreateExam={preventCreateExam}
        />
      )}
    </>
  )
}
