export const typedPendenciesFields = [
  'data {',
  ' surgical_pendency_id',
  ' surgical_order_id',
  ' createdAt',
  ' status',
  ' doctorName',
  ' observation',
  ' answer',
  ' answeredAt',
  ' requestedBy {',
  '  name',
  ' }',
  ' patient {',
  '  name',
  ' }',
  ' surgicalOrder {',
  '  doctorName',
  '  procedure {',
  '   description',
  '  }',
  ' }',
  '}',
  'pageInfo {',
  ' currentPage',
  ' itemsPerPage',
  ' totalItems',
  ' totalPages',
  '}'
]
