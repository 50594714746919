import React, { useState } from 'react'
import SecretaryProfileLayout from 'presentation/secretary/layouts/ScreataryProfile'

import Modal from 'presentation/shared/components/Modal'

export default function Profile() {
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false)
  const [updatePasswordSuccess, setUpdatePasswordSuccess] =
    useState<boolean>(false)

  return (
    <>
      <SecretaryProfileLayout
        setOpenSuccessModal={setOpenSuccessModal}
        setUpdatePasswordSuccess={setUpdatePasswordSuccess}
      />
      <Modal
        title="Dados atualizado com sucesso"
        show={openSuccessModal}
        close={() => setOpenSuccessModal(false)}
      />

      <Modal
        title="Senha Atualizada"
        show={updatePasswordSuccess}
        close={() => setUpdatePasswordSuccess(false)}
      />
    </>
  )
}
