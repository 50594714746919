import React, { useState, useEffect } from 'react'
import Container from 'presentation/hospital/components/Container'
import * as S from './styles'
import List, { ListItem } from 'presentation/shared/components/List'
import UserLogo from 'presentation/assets/icons/user.svg'
import { startCase } from 'lodash'
import UpdateSecretaryProfileForm from 'presentation/secretary/components/Forms/UpdateProfile'
import UpdatePasswordForm from 'presentation/secretary/components/Forms/UpdatePassword'

import { User } from 'domain/entities/user-model'
import { useServices } from 'presentation/hooks/use-services'

import UpdateProfilePic from 'presentation/utils/update-profile-pic'

export type SecretaryProfileProps = {
  setOpenSuccessModal: React.Dispatch<React.SetStateAction<boolean>>
  setUpdatePasswordSuccess: React.Dispatch<React.SetStateAction<boolean>>
}

export default function SecretaryProfileLayout({
  setOpenSuccessModal,
  setUpdatePasswordSuccess
}: SecretaryProfileProps) {
  const [data, setData] = useState<User>()
  const [section, setSection] = useState<0 | 1>(0)

  const selectSection = (sectionNumber: 0 | 1): void =>
    setSection(sectionNumber)

  const LIST_ITEMS: ListItem[] = [
    {
      title: 'Dados Pessoais',
      icon: UserLogo,
      click: () => selectSection(0)
    },
    {
      title: 'Alterar senha',
      icon: UserLogo,
      click: () => selectSection(1)
    }
  ]

  const userService = useServices().user

  const getUserInfo = async () => {
    const res = await userService.loadUser([
      'name',
      'lastName',
      'cpf',
      'phone',
      'landlinePhone',
      'email',
      'role'
    ])
    setData(res)
    return res
  }
  useEffect(() => {
    getUserInfo()
  }, [])

  return (
    <Container>
      <S.Wrapper>
        {data && (
          <>
            <S.ContainerOptions>
              <div>
                <div className="profile-info-div">
                  <UpdateProfilePic />
                  <S.UserInfo>
                    <strong>{startCase(data.name?.toLocaleLowerCase())}</strong>
                    <label>Secretária</label>
                  </S.UserInfo>
                </div>

                <List
                  items={LIST_ITEMS}
                  size="medium"
                  preventIconStyle
                  arrow
                  spacingIcon
                />
              </div>
            </S.ContainerOptions>

            <S.DivForm>
              {section === 0 ? (
                <UpdateSecretaryProfileForm
                  data={data}
                  setOpenSuccessModal={setOpenSuccessModal}
                />
              ) : (
                <UpdatePasswordForm
                  setUpdatePasswordSuccess={setUpdatePasswordSuccess}
                />
              )}
            </S.DivForm>
          </>
        )}
      </S.Wrapper>
    </Container>
  )
}
