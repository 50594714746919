import styled from 'styled-components'

export const InputsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 32px;
  margin-top: 12px;

  @media (max-width: 1360px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
`
export const Wrapper = styled.div`
  flex: 1;
  width: 100%;
`
export const Container = styled.div``
export const ButtonTitle = styled.p`
  color: #fff;
`
export const ActionsContainer = styled.div`
  justify-content: flex-end;
  display: flex;
  margin-top: 30px;
`
