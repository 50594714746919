import styled, { css } from 'styled-components'

export const Inputs = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 20px;
`

export const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 20px;

  input {
    width: 58px;
    padding: 0;
    margin: 0;
    text-align: center;
  }

  h5 {
    margin-right: 10px;
  }
`

export const Title = styled.h5`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    font-weight: 400;
    color: ${theme.colors.gray};
    cursor: pointer;
  `}
`
