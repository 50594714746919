import AdvancedButton from 'presentation/shared/components/AdvancedButton'
import styled from 'styled-components'

export const ReplyContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 27px;
`

export const PendencyAdvancedButton = styled(AdvancedButton)`
  margin: 0px;
  background-color: ${({ theme }) => theme.colors.moreLightGreen};
  .arrow-svg {
    fill: ${({ theme }) => theme.colors.primary};
  }
`

export const AlreadyAnsweredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  color: ${({ theme }) => theme.colors.primary};
`
