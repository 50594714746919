import { LoadSecretarySurgicalOrders } from 'domain/usecases/secretary/load-secretary-surgical-order'
import { SecretaryRepository } from 'repository/interfaces/secretary-repository'

export class RemoteLoadSecretarySurgicalOrders
  implements LoadSecretarySurgicalOrders
{
  constructor(private readonly SecretaryRepository: SecretaryRepository) {}

  load(
    params: LoadSecretarySurgicalOrders.Params
  ): Promise<LoadSecretarySurgicalOrders.Model> {
    return this.SecretaryRepository.loadSurgicalOrders(params)
  }
}

export type LoadSecretarySurgicalOrdersModel = LoadSecretarySurgicalOrders.Model
