import React, { useEffect, useState } from 'react'

import * as yup from 'yup'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router'
import { FormikProvider, useFormik } from 'formik'

import SelectDoctorAndHospital from './SelectDoctorAndHospital'
import SelectSurgicalOrderPatient from './SelectPatient'
import ReviewPatient from './ReviewPatient'
import SelectSurgeryInfo from './SelectSurgeryInfo'
import SurgicalOrderReview from './SurgicalOrderReview'
import { Hospital } from 'domain/entities/hospital-model'
import { Doctor } from 'domain/entities/doctor-model'
import { Patient, PatientHealthInsurance } from 'domain/entities/patient-model'
import { LoadPatientByCpf } from 'domain/usecases/patient/load-patient-by-cpf'
import { Tuss } from 'domain/entities/tuss'
import { Cid } from 'domain/entities/cid'
import { InvitePatient } from 'domain/usecases/patient/invite-patient'
import { dateInputFormat } from 'presentation/utils/date-format'
import { RegisterSurgicalOrder } from 'domain/usecases/surgical-order/register-surgical-order'
import Modal from 'presentation/shared/components/Modal'
import { AddFavorite } from 'domain/usecases/surgical-order/add-favorite'
import { SurgeryCenter } from 'domain/entities/surgery-center-model'
import { useAddSurgicalOrderFunctions } from 'presentation/hospital/pages/AddSurgicalOrder/load-surgical-order'
import ConditionalCarousel, {
  CarouselState
} from 'presentation/shared/components/Carousel/ConditionalCarousel'
import { useStores } from 'presentation/hooks/use-stores'
import { AddPatient } from 'domain/usecases/patient/add-patient'
import { UploadPatientDocument } from 'domain/usecases/patient/upload-patient-document'
import { UploadSurgicalOrderDocument } from 'domain/usecases/surgical-order/upload-surgical-order-document'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import {
  HealthInsurancePlans,
  Plans,
  SubPlan
} from 'domain/usecases/health-insurance/load-health-insurance-plans'
import { SearchPatient } from 'domain/usecases/patient/search-patient'
import { useLocation } from 'react-router-dom'
import normalizeText from 'common/utils/getNormalizedText'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { useServices } from 'presentation/hooks/use-services'
import { generateHash } from 'common/utils/generateHash'
import { renameFile } from 'common/utils/file/renameFile'
import { PatientDocument } from 'common/enum/patient-document'
import { getThumbnail } from 'presentation/shared/components/UploadSection/Functions'
import { DocumentItem } from 'presentation/shared/components/DocumentsArea/DocumentsAreaModal'
import { DocumentsType } from 'domain/entities/document-types'
import { MedicalTeamTypeEnum } from 'common/enum/medical-team-professional'
import SelectSurgicalOrderAllocation from './SelectAllocation'

type Props = {
  loadPatient?: LoadPatientByCpf
  doctors: Doctor[]
  hospitals: Hospital[]
  cids: Cid[]
  tussList: Tuss[]
  insurances: HealthInsurancePlans[]
  plans: Plans[]
  subPlans: SubPlan[]
  invitePatient: InvitePatient
  addOrder: RegisterSurgicalOrder
  addFavorite?: AddFavorite
  surgeryCenter: SurgeryCenter[]
  addPatient: AddPatient
  uploadDocument?: UploadPatientDocument
  searchPatient: SearchPatient
  uploadSurgicalOrderDocument?: UploadSurgicalOrderDocument
} & WithLoadingProps

// const MINIMAL_DOCUMENTS_ATTACHED = 9

export const SecretaryAddSurgicalOrderForm = WithLoading(
  ({
    hospitals,
    doctors,
    insurances,
    cids,
    tussList,
    addOrder,
    addFavorite,
    surgeryCenter,
    plans,
    addPatient,
    uploadDocument,
    setIsLoading,
    subPlans,
    searchPatient,
    invitePatient
  }: Props) => {
    const [carousel, setCarousel] = useState({} as CarouselState)
    const [handleSuccessModal, setHandleSuccessModal] = useState(false)
    const [handleNotFoundPatientModal, setHandleNotFoundPatientModal] =
      useState(false)
    const [handleFavoriteModal, setHandleFavoriteModal] = useState(false)
    const [createPatientMode, setCreatePatientMode] = useState(false)
    const history = useHistory()
    const context = useAddSurgicalOrderFunctions()
    const profile = useStores().currentAccount.getCurrentAccount().user.role
    const location = useLocation<{
      patient?: Patient
      old_solicitation?: number
      startStep?: string
    }>()

    const initialValues = {
      patient: location.state?.patient
    } as AddSurgicalOrderFormValues

    const service = useServices().surgicalOrder

    const getData = async (surgical_order_id: number) => {
      const surgicalOrder = await service.loadSurgicalOrder(
        +surgical_order_id,
        [
          'surgical_order_id',
          'patient { name, gender, birthday, patient_id, email, phone, cpf, maritalStatus, landlinePhone}',
          'patient_id',
          'patientName',
          'procedure {procedure_code, code, description, quantity, doctor_name, isMain, surgery_id, pro_fat_id}',
          'opme {solicitations {description, quantity, opme_id}, providers}',
          'cid {code, description}',
          'doctor {name, doctor_id, email, phone}',
          'hospitalizationMode',
          'hospitalizationType',
          'healthInsurance {healthInsuranceName, healthInsuranceCode}',
          'allergy',
          'hospital {name, hospital_id}',
          'clinicalHistory',
          'surgicalCenter',
          'expectedDate',
          'expectedDuration',
          'hospitalizationAvgTimeInDays',
          'patientHospitalized',
          'requestedUtiDiaries',
          'laterality',
          'preliminaryScintigraphyTime',
          'xrayOfPieceTime',
          'medicalTeamName',
          'medicalTeam { type, name, crm, phone }',
          'anesthetistTeam { anesthesiaType, name, crm, phone }',
          'hasBloodType',
          'hasHemoconcentrates',
          'cellSaverSpec',
          'bloodBagQuantity',
          'equipment { name, specification }',
          'cme { description, quantity }',
          'documents { document_id type name group_id }',
          'createdBy {name, role},',
          'surgical_center_id,',
          'isSolicitation,',
          'isComplete,',
          'createdAt,',
          'status {status}'
        ]
      )

      const documents = surgicalOrder?.documents || []
      const documents_attached: DocumentItem[] = []
      await Promise.all(
        documents.map(async ({ group_id, type, name, document_id }) => {
          if (!group_id) return
          const response = await service.loadSurgicalOrderDocument({
            surgical_order_id: group_id,
            document_id
          })
          const file = new File([response.data], name, {
            type: response.contentType
          })
          const label = DocumentsType.getDocumentTypeByType(
            type as PatientDocument
          )?.label
          documents_attached.push({
            file,
            type,
            document_id,
            label,
            thumbnail: await getThumbnail(file)
          })
        })
      )

      formHandler.setFieldValue('documents_attached', documents_attached)
      formHandler.setFieldValue('doctor', surgicalOrder?.doctor)
      formHandler.setFieldValue(
        'surgery_center',
        new SurgeryCenter(
          surgicalOrder.surgical_center_id,
          surgicalOrder.surgicalCenter,
          surgicalOrder.hospital.hospital_id
        )
      )
      formHandler.setFieldValue('doctorName', surgicalOrder?.doctor?.name)
      formHandler.setFieldValue('hasBloodType', surgicalOrder?.hasBloodType)
      formHandler.setFieldValue(
        'hasHemoconcentrates',
        surgicalOrder?.hasHemoconcentrates
      )
      formHandler.setFieldValue(
        'bloodBagQuantity',
        surgicalOrder?.bloodBagQuantity
      )
      formHandler.setFieldValue('equipment', surgicalOrder?.equipment)
      formHandler.setFieldValue(
        'anesthetistTeam',
        surgicalOrder?.anesthetistTeam
      )
      formHandler.setFieldValue('cellSaverSpec', surgicalOrder?.cellSaverSpec)
      formHandler.setFieldValue(
        'medicalTeamName',
        surgicalOrder?.medicalTeamName
      )
      formHandler.setFieldValue('medicalTeam', surgicalOrder?.medicalTeam)
      formHandler.setFieldValue(
        'xrayOfPieceTime',
        surgicalOrder?.xrayOfPieceTime
      )
      formHandler.setFieldValue('patient', surgicalOrder?.patient)
      formHandler.setFieldValue('patientName', surgicalOrder?.patient?.name)
      formHandler.setFieldValue(
        'patient_id',
        surgicalOrder?.patient?.patient_id
      )
      formHandler.setFieldValue(
        'patientHospitalized',
        surgicalOrder?.patientHospitalized
      )
      formHandler.setFieldValue('hospital', surgicalOrder?.hospital)
      formHandler.setFieldValue('allergy', surgicalOrder?.allergy)
      formHandler.setFieldValue(
        'hospitalizationAvgTimeInDays',
        surgicalOrder?.hospitalizationAvgTimeInDays
      )
      if (surgicalOrder?.expectedDate) {
        formHandler.setFieldValue('expectedDate', surgicalOrder?.expectedDate)
        formHandler.setFieldValue(
          'expectedDate1',
          surgicalOrder?.expectedDate[0]?.split('T')[0]
        )
        formHandler.setFieldValue(
          'expectedDate2',
          surgicalOrder?.expectedDate[1]?.split('T')[0]
        )
        formHandler.setFieldValue(
          'expectedDate3',
          surgicalOrder?.expectedDate[2]?.split('T')[0]
        )
      }
      if (surgicalOrder?.procedure) {
        formHandler.setFieldValue('procedures', surgicalOrder?.procedure)
      }
      if (surgicalOrder?.opme?.solicitations?.length) {
        formHandler.setFieldValue('opmes', surgicalOrder?.opme?.solicitations)
      }
      formHandler.setFieldValue(
        'use_opme',
        !!surgicalOrder?.opme?.solicitations.length
      )
      if (surgicalOrder?.opme?.providers?.length) {
        formHandler.setFieldValue('providers', surgicalOrder?.opme?.providers)
      }
      formHandler.setFieldValue('cids', surgicalOrder?.cid)
      formHandler.setFieldValue(
        'laterality',
        surgicalOrder?.laterality?.toLowerCase()
      )
      formHandler.setFieldValue('needIcu', !!surgicalOrder?.requestedUtiDiaries)
      formHandler.setFieldValue(
        'expectedDuration',
        +surgicalOrder?.expectedDuration?.match(/(\d+)/)[0]
      )
      formHandler.setFieldValue(
        'healthInsurance',
        surgicalOrder?.healthInsurance
      )
      formHandler.setFieldValue(
        'hospital_id',
        surgicalOrder?.hospital.hospital_id
      )
      formHandler.setFieldValue('doctor_id', surgicalOrder?.doctor?.doctor_id)

      formHandler.setFieldValue(
        'hospitalizationMode',
        surgicalOrder?.hospitalizationMode
      )
      formHandler.setFieldValue(
        'hospitalizationType',
        surgicalOrder?.hospitalizationType
      )
      formHandler.setFieldValue(
        'clinicalHistory',
        surgicalOrder?.clinicalHistory
      )
      formHandler.setFieldValue('surgery_center', {
        codeCenter: surgicalOrder?.surgical_order_id,
        description: surgicalOrder?.surgicalCenter,
        hospital_id: surgicalOrder?.hospital?.hospital_id
      })
    }

    useEffect(() => {
      if (location?.state?.old_solicitation) {
        getData(location.state.old_solicitation)
      }
    }, [location?.state?.old_solicitation])

    const validationSchemas = {
      crmo: opmeSchema,
      secretary: secretarySchema,
      RECEPTIONIST: receptionistSchema
    }

    const formHandler = useFormik({
      initialValues: {
        procedures: [],
        cids: [],
        opmes: [],
        documents_attached: [],
        healthInsurance: {},
        equipments: {},
        cmes: [],
        hospitalizationType: '',
        expectedDuration: '',
        ...initialValues
      },
      validationSchema: validationSchemas[profile],
      onSubmit: async (values) => {
        setIsLoading(true)

        const formatedCid = values.cids
          .filter((item) => item)
          .map((item: any) => ({
            code: item?.cid_id || item?.code,
            description: item.description
          }))

        const reqObject: RegisterSurgicalOrder.Params = {
          patient_id: values.patient.patient_id,
          hospital_id: values.hospital.hospital_id,
          doctor_id: Number(values.doctor.doctor_id),
          hospitalizationMode: values.hospitalizationMode,
          hospitalizationType: values.hospitalizationType,
          clinicalHistory: values.clinicalHistory,
          allergy: values.allergy != ' ' ? values.allergy : undefined,
          procedure: formatProcedures(values.procedures),
          cid: formatedCid,
          surgical_center_id: Number(values.surgery_center.codeCenter),
          surgicalCenter: values.surgery_center.description,
          patientName: normalizeText(values.patient.name),
          doctorName: normalizeText(values.doctor.name),
          opme: {
            solicitations: formatOpme(values.opmes),
            providers: values.providers?.filter((val) => !isEmpty(val)) || []
          },
          healthInsurance: values.healthInsurance,
          patientHospitalized: values.patientHospitalized,
          cme: values.cmes || [],
          equipment: formatEquipments(values.equipments),
          hospitalizationAvgTimeInDays: values.hospitalizationAvgTimeInDays,
          medicalTeamName: values.medicalTeamName,
          laterality: values.laterality,
          medicalTeam: values.medicalTeam,
          preliminaryScintigraphyTime: values.preliminaryScintigraphyTime,
          freezeTime: values.freezeTime,
          xrayOfPieceTime: values.xrayOfPieceTime,
          anesthetistTeam: values.anesthetistTeam,
          hasBloodType: values.hasBloodType,
          hasHemoconcentrates: values.hasHemoconcentrates,
          cellSaverSpec: values.cellSaverSpec,
          bloodBagQuantity: values.bloodBagQuantity,
          requestedUtiDiaries: values.requestedUtiDiaries,
          expectedDate: values?.expectedDate || [],
          expectedDuration: String(values?.expectedDuration),
          patientOriginId: values.patientOrigin?.id,
          patientClassificationTypeId: values.patientClassificationType?.id,
          agendaClassificationId: Number(values.agendaClassification?.id),
          patientOriginDescription: values.patientOrigin?.description,
          patientClassificationTypeDescription:
            values.patientClassificationType?.description,
          agendaClassificationDescription:
            values.agendaClassification?.description,
          agendaSequence: values.allocation?.id
        }

        delete reqObject.healthInsurance.healthSubPlanCode
        delete reqObject.healthInsurance.healthSubPlanName

        if (location.state?.old_solicitation) {
          reqObject.old_solicitation = +location.state?.old_solicitation
        }

        Object.keys(values)
          .filter((value) => value.includes('expectedDate'))
          .forEach((key) => {
            reqObject.expectedDate.push(
              moment(values[key], 'YYYY-MM-DDTHH:mm').toISOString()
            )
          })

        try {
          const group_id = generateHash()
          const profilesAllowed = ['secretary', 'crmo', 'RECEPTIONIST']
          if (profilesAllowed.includes(profile)) {
            await uploadDocuments(values.documents_attached, group_id)
            const newSurgicalOrder = await addOrder.add(reqObject, profile)
            if (newSurgicalOrder.surgical_order_id) {
              await service.confirmMergeDocuments({
                group_id,
                surgical_order_id: newSurgicalOrder.surgical_order_id
              })
            }
          }

          setHandleSuccessModal(true)
        } catch (error: any) {
          console.error(error)
          toast.error(error.message)
        }
        setIsLoading(false)
      }
    })

    const findPatient = async (cpf: string) => {
      try {
        const gettedPatient =
          context?.loadPatientByCpf && (await context.loadPatientByCpf(cpf))
        if (gettedPatient) {
          await formHandler.setFieldValue('patient', {
            ...gettedPatient,
            birthday: dateInputFormat(gettedPatient.birthday)
          } as Patient)
          if (gettedPatient.healthInsurance?.healthInsuranceCode) {
            context?.loadHealthPlans(
              gettedPatient.healthInsurance.healthInsuranceCode
            )
          }
        }
      } catch (error) {
        setHandleNotFoundPatientModal(true)
        return toast.error('Paciente não encontrado')
      }
    }

    const submitFavorite = async (value: string) => {
      const providers: string[] =
        formHandler.values?.providers &&
        formHandler.values?.providers.filter((val) => !isEmpty(val))

      const formatedCid = formHandler.values.cids
        .filter((item) => item)
        .map((item: { cid_id: string; description: string }) => ({
          code: item.cid_id,
          description: item.description
        }))

      try {
        await addFavorite?.add({
          name: value,
          cid: formatedCid,
          procedure: formatProcedures(formHandler.values.procedures),
          opme: {
            solicitations: formatOpme(formHandler.values.opmes),
            providers: providers ?? []
          }
        })
        setHandleFavoriteModal(true)
      } catch (e: any) {
        toast.error(e.message)
      }
    }

    function slideNext() {
      if (profile === 'secretary' && carousel.activeIndex === 2) {
        carousel.slideTo(4)
      } else {
        carousel.slideNext()
      }
      window.scrollTo(0, 0)
    }

    function slidePrev() {
      if (+location.state?.startStep === carousel.activeIndex) {
        history.goBack()
      }
      if (profile === 'secretary' && carousel.activeIndex === 4) {
        carousel.slideTo(2)
      } else {
        carousel.slidePrev()
      }
      window.scrollTo(0, 0)
    }

    const uploadDocuments = async (
      documentsAttached: any[],
      group_id: string
    ) => {
      await Promise.all(
        documentsAttached.map(async (file: any) => {
          if (file.file && !file.document_id) {
            const uploadedFile = service.uploadToMergeDocument({
              file: renameFile(file.file, file.label as string, {
                forceOriginalExtension: true
              }),
              type: file.type as string,
              group_id
            })
            return await toast.promise(uploadedFile, {
              pending: `Fazendo o envio do arquivo ${file.file.name}`,
              success: `Arquivo ${file.file.name} enviado com sucesso`,
              error: `Falha ao enviar o arquivo ${file.file.name}`
            })
          }
        })
      )
    }

    const handelClosePacientModal = () => setHandleNotFoundPatientModal(false)

    return (
      <FormikProvider value={formHandler}>
        <ConditionalCarousel
          setState={setCarousel}
          activeIndex={+location.state?.startStep || 0}
        >
          <SelectDoctorAndHospital
            hospitals={hospitals}
            doctors={doctors}
            goNext={slideNext}
            goBack={history.goBack}
            surgeryCenters={surgeryCenter}
          />

          <SelectSurgicalOrderPatient
            goNext={slideNext}
            handleNotFoundPatientModal={handleNotFoundPatientModal}
            handleClosePacientModal={handelClosePacientModal}
            findPatient={findPatient}
            goBack={slidePrev}
            setCreatePatientMode={setCreatePatientMode}
            searchPatient={searchPatient}
          />

          <ReviewPatient
            insurances={insurances}
            profile={profile}
            goNext={slideNext}
            goBack={slidePrev}
            findPatient={findPatient}
            plans={plans}
            createPatientMode={createPatientMode}
            addPatient={addPatient}
            invitePatient={invitePatient}
            uploadDocument={uploadDocument}
            setCreatePatientMode={setCreatePatientMode}
            subPlans={subPlans}
          />

          <SelectSurgicalOrderAllocation
            goNext={slideNext}
            goBack={slidePrev}
          />

          <SelectSurgeryInfo
            submit={slideNext}
            cidList={cids}
            tussList={tussList}
            goBack={slidePrev}
            addFavorite={(value) => submitFavorite(value)}
            profile={profile}
            defaultOpme={formHandler.values.use_opme}
          />

          <SurgicalOrderReview goBack={slidePrev} profile={profile} />
        </ConditionalCarousel>
        <Modal
          close={() => history.push('/home')}
          show={handleSuccessModal}
          title="Planejamento cirúrgico criado com sucesso!"
        />
        <Modal
          close={() => setHandleFavoriteModal(false)}
          show={handleFavoriteModal}
          title="Procedimento favoritado com sucesso!"
        />
      </FormikProvider>
    )
  }
)

export default SecretaryAddSurgicalOrderForm

export type AddSurgicalOrderFormValues = {
  room: string
  freezeTime?: string
  allergy: string
  hospitalizationMode: string
  hospitalizationType: string
  otherAllergies: string
  clinicalHistory: string
  attendanceCode: string
  providers: string[]
  height: string
  weight: string
  front: File
  back: File
  isHospitalized: string
  patient: Patient
  doctor: Doctor
  hospital: Hospital
  procedures: any[]
  cids: any[]
  tussList: any[]
  opmes: any[]
  documents_attached: any[]
  use_opme: boolean
  observations: string
  healthInsurance: PatientHealthInsurance
  surgery_center: SurgeryCenter
  createdAt: string
  patientHospitalized: boolean

  insuranceCardValidity: string
  patientName?: string
  canSee?: {
    hasExams?: boolean
    hasMedicalReport?: boolean
  }
  medicalTeamName?: string
  hospitalizationAvgTimeInDays: number
  requestedUtiDiaries: number
  needIcu: boolean
  equipments: CreateEquipments
  cmes: {
    description: string
    quantity: number
  }[]
  preliminaryScintigraphyTime?: string
  laterality?: string
  xrayOfPieceTime?: string
  medicalTeam?: CreateMedicalTeam[]
  hasHemoconcentrates?: boolean
  hasBloodType?: boolean
  cellSaverSpec?: string
  bloodBagQuantity?: number
  anesthetistTeam?: CreateAnesthetistTeam[]
  expectedDate1: string
  expectedDate2: string
  expectedDate3: string
  expectedDuration?: string
  expectedDate?: string[]

  allocation: {
    id: number
    beginDateTime: Date
  }

  patientOrigin: {
    id: number
    description: string
  }
  patientClassificationType: {
    id: number
    description: string
  }
  agendaClassification: {
    id: number
    description: string
  }
}

type CreateEquipments = Record<
  string,
  { enabled: boolean; value: string; specification?: string }
>

type CreateMedicalTeam = {
  type: MedicalTeamTypeEnum
  name: string
  crm: string
  phone: string
}

type CreateAnesthetistTeam = {
  name: string
  crm: string
  phone: string
  anesthesiaType: string
}

const validationSchema = {
  clinicalHistory: yup
    .string()
    .required('O Campo "História Clínica" é obrigatório'),
  surgery_center: yup.mixed().required(),
  allergy: yup
    .mixed()
    .test('allergy', 'O Campo "Alergia" é obrigatório', (value) => {
      return value !== undefined
    }),
  hospitalizationMode: yup
    .string()
    .required('O Campo "Regime Hospitalar" é obrigatório'),
  hospitalizationType: yup.string().required('O Campo "Caráter" é obrigatório'),
  procedures: yup.array().min(1, 'Selecione ao menos um procedimento'),
  cids: yup.array().min(1, 'Selecione ao menos um CID'),
  use_opme: yup.boolean().required('Informe se o procedimento utiliza OPME'),
  opmes: yup.array().when('use_opme', {
    is: (value: boolean) => value,
    then: yup.array().min(1, 'Selecione ao menos um OPME')
  }),
  providers: yup.array().when('opmes', {
    is: (value: []) => !!value?.length,
    then: yup
      .array()
      .test('opme_size', 'Informe ao menos um fornecedor', (value) => {
        return !!value?.some((val) => val)
      })
  }),
  patientHospitalized: yup
    .boolean()
    .required('O Campo "Paciente Internado" é obrigatório'),
  equipments: yup
    .object()
    .nullable()
    .test('validate-equipments', (value, { createError }) => {
      if (!value) return true
      const entries = Object.entries<{ enabled: boolean; value?: string }>(
        value
      )
      const errors = []
      entries.forEach(([equipment, object]) => {
        if (object.enabled && !object.value) {
          errors.push(equipment)
        }
      })
      return errors.length === 0
        ? true
        : createError({
          path: 'equipments',
          message: `Preencha a especificação dos seguintes equipamentos: ${errors.join(
            ' - '
          )}`
        })
    })
}

const opmeSchema = yup.object().shape({
  ...validationSchema,
  patientOrigin: yup.object().shape({
    description: yup.string().required('Preencha a origem do paciente')
  }),
  patientClassificationType: yup.object().shape({
    description: yup.string().required('Preencha a classificação do paciente')
  }),
  agendaClassification: yup.object().shape({
    description: yup.string().required('Preencha a classificação da agenda')
  })
})

const secretarySchema = yup.object().shape({
  ...validationSchema
})

const receptionistSchema = yup.object().shape({
  ...validationSchema,
  clinicalHistory: yup.string(),
  cids: yup.array(),
  providers: yup.array(),
  patientHospitalized: yup.boolean()
})

const formatOpme = (opmes: any[]) => {
  return opmes.map((item: any) => ({
    description: item.description,
    quantity: Number(item.quantity),
    opme_id: item.opme_id
  }))
}

const formatProcedures = (procedures: any[]) => {
  return procedures.map((item) => ({
    isMain: !!item.principalProcedure,
    code: item?.tuss_id || item?.code,
    description: item.description,
    doctor_name: item.doctor || item.doctor_name,
    quantity: Number(item.quantity),
    procedure_code: item.tuss_id || item?.code,
    surgery_id: item.surgery_id,
    pro_fat_id: item.pro_fat_id
  }))
}

export const formatEquipments = (
  equipments: CreateEquipments,
  getEnabled = false
) => {
  const formatted = []
  Object.entries(equipments).forEach(([key, equip]) => {
    if (!equip.enabled) return
    formatted.push({
      name: key,
      specification: equip?.value || equip?.specification,
      enabled: getEnabled ? equip?.enabled : undefined
    })
  })
  return formatted
}
