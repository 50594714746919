type Fields = {
  name: string
  placeholder: string
  label: string
  mask?: any
}

export const fields: Fields[] = [
  {
    name: 'actualPassword',
    placeholder: 'Senha antiga',
    label: 'Senha antiga'
  },
  {
    name: 'newPassword',
    placeholder: 'Nova senha',
    label: 'Nova senha'
  },
  {
    name: 'newPasswordConfirmation',
    placeholder: 'Confirmar senha',
    label: 'Confirmar senha'
  }
]
