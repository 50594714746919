import React from 'react'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { AuthenticationRepository } from 'repository/repositories/authentication/authentication-repository'
import { ApiRepository } from 'repository/api-repository'
import { RemoteAuthentication } from 'service/usecases/authentication/remote-authentication'
import SecretaryLogin from 'presentation/secretary/pages/Login'

export default function makeSecretaryLogin() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )
  const authenticationRepository = new AuthenticationRepository(apiRepository)
  const authentication = new RemoteAuthentication(authenticationRepository)
  return <SecretaryLogin useCase={authentication} />
}
