import React, { useEffect, useState } from 'react'

import SecretaryOrderDetailsLayout from 'presentation/secretary/layouts/OrderDetails'
import { LoadSurgicalOrder } from 'domain/usecases/surgical-order/load-surgical-order'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import { useLocation } from 'react-router-dom'
import { documentList } from 'presentation/shared/pages/SurgeryDetails/documents'
import { ListItem } from 'presentation/shared/components/List'
import CheckIcon from 'presentation/assets/icons/big-check.svg'
import { ReactComponent as DownloadSvgIcon } from 'presentation/assets/icons/download.svg'
import AlertIcon from 'presentation/assets/icons/alert.svg'
import { UploadSurgicalOrderDocument } from 'domain/usecases/surgical-order/upload-surgical-order-document'
import { LoadSurgicalOrderStatus } from 'domain/usecases/surgical-order/get-surgical-order-status'
import { SurgeryStatus } from 'domain/entities/surgery-status'
import { LoadHospitals } from 'domain/usecases/hospital/load-hospitals'
import { LoadSurgeryCenter } from 'domain/usecases/surgery-center/load-surgery-center'
import { LoadHealthInsurance } from 'domain/usecases/health-insurance/load-health-insurance'
import { ReorderSurgicalOrder } from 'domain/usecases/surgical-order/reorder-surgery'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { LoadSurgicalOrderDocument } from 'domain/usecases/surgical-order/load-surgical-order-document'
import downloadFileFromBlob from 'common/utils/downloadFileFromBlob'
import { getDocumentsLabelLocationByEnum } from 'presentation/utils/document-types-location'

type Props = {
  loadOrder: LoadSurgicalOrder
  loadOrderStatus?: LoadSurgicalOrderStatus
  uploadSurgicalOrderDocument?: UploadSurgicalOrderDocument
  loadHospitals: LoadHospitals
  loadSurgeryCenter: LoadSurgeryCenter
  loadHealthInsurance: LoadHealthInsurance
  reorderSurgicalOrder?: ReorderSurgicalOrder
  loadSurgicalOrderDocument?: LoadSurgicalOrderDocument
} & WithLoadingProps

export const SecretaryOrderDetails = WithLoading(
  ({
    loadOrder,
    loadHospitals,
    loadSurgeryCenter,
    loadHealthInsurance,
    reorderSurgicalOrder,
    loadSurgicalOrderDocument,
    setIsLoading
  }: Props) => {
    const [order, setOrder] = useState({} as SurgicalOrderModel)
    const [surgeryStatus] = useState({} as SurgeryStatus)
    const [patientDocuments, setPatientDocuments] = useState([] as ListItem[])

    const location = useLocation<{ id: string }>()

    const setIcon = (
      documentType: string[],
      uploadable: boolean,
      documents: any[]
    ) => {
      return documentType.every((type) =>
        documents.find((document) => document.type === type)
      )
        ? CheckIcon
        : AlertIcon
    }

    async function loadData() {
      setIsLoading(true)
      try {
        const loadSurgicalOrder = await loadOrder.load(
          Number(location.state.id),
          [
            'doctor{name, doctor_id crm}',
            'patient{patient_id,birthday, gender, company, name, cpf, email, lastName, phone, landlinePhone, maritalStatus,' +
              '}',
            'hospital{name, hospital_id}',
            'surgical_order_id',
            'createdAt',
            'procedure {isMain,procedure_code,code,description,quantity,doctor_name}',
            'healthInsurance {healthInsuranceCode, healthInsuranceName, healthPlanCode, healthPlanName, company, validThru, healthCard}',
            'hospitalizationMode',
            'hospitalizationType',
            'cid {code, description}',
            'clinicalHistory',
            'laterality',
            'surgicalCenter',
            'room',
            'patientHospitalized',
            'allergy',
            'opme {solicitations {description, quantity}, providers}',
            'documents {document_id, type, name, documentUri}'
          ]
        )

        setOrder(loadSurgicalOrder)
        const formatedDocumentList: ListItem[] = documentList.map((item) => {
          return {
            title: item.name,
            icon: setIcon(
              item.type,
              item.upload,
              loadSurgicalOrder.documents ?? []
            ),
            download: (
              <DownloadSvgIcon
                onClick={() =>
                  downloadFile(
                    loadSurgicalOrder.documents?.filter((document) =>
                      item.type.includes(document.type)
                    )
                  )
                }
                style={{ margin: '0 12px', display: 'block' }}
              />
            )
          }
        })
        setPatientDocuments(formatedDocumentList)
      } catch (e) {
        return
      } finally {
        setIsLoading(false)
      }
    }

    const downloadFile = async (
      files?: { document_id: number; type: string }[]
    ) => {
      try {
        files?.map(async (file) => {
          const surgicalOrderId = order.surgical_order_id
            ? order.surgical_order_id
            : 0
          const surgicalOrderDocument = await loadSurgicalOrderDocument?.load(
            surgicalOrderId,
            file.document_id
          )
          if (surgicalOrderDocument) {
            downloadFileFromBlob(
              surgicalOrderDocument.data,
              surgicalOrderDocument.contentType,
              getDocumentsLabelLocationByEnum(file.type)
            )
          }
        })
      } catch (err) {
        return
      }
    }

    useEffect(() => {
      loadData()
    }, [])

    return (
      <>
        <SecretaryOrderDetailsLayout
          doctorName={order.doctor?.name}
          patient={order.patient}
          unit={order.hospital?.name}
          id={order.surgical_order_id?.toString()}
          surgicalOrder={order}
          documents={patientDocuments}
          surgeryStatus={surgeryStatus}
          refreshSurgicalOrderStatus={() => undefined}
          loadHospitals={loadHospitals}
          loadSurgeryCenter={loadSurgeryCenter}
          loadHealthInsurances={loadHealthInsurance}
          reorderSurgicalOrder={reorderSurgicalOrder}
          loadSurgicalOrderDocument={loadSurgicalOrderDocument}
        />
      </>
    )
  }
)

export default SecretaryOrderDetails
