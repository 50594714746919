import React, { HTMLAttributes, useState } from 'react'
import Menu from 'presentation/hospital/components/Menu'
import Header from 'presentation/hospital/components/Header'
import * as S from './styles'

type Props = {
  hideButton?: boolean
  headerButtonTitle?: string
  onClickHeaderButton?: () => void
  onHospitalChange?: () => void
  disableScroll?: boolean
} & HTMLAttributes<HTMLDivElement>

const Container = ({
  children,
  hideButton,
  headerButtonTitle,
  onClickHeaderButton,
  onHospitalChange,
  disableScroll = false
}: Props) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false)

  const handleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  return (
    <>
      <Header
        hideButton={hideButton}
        headerButtonTitle={headerButtonTitle}
        onClickHeaderButton={onClickHeaderButton}
        onHospitalChange={onHospitalChange}
        onClickMenuIcon={handleMenu}
      />
      <div style={{ display: 'flex', flex: '1', position: 'relative' }}>
        <Menu menuOpen={menuOpen} />
        <S.Content disableScroll={disableScroll}>{children}</S.Content>
      </div>
    </>
  )
}

export default Container
