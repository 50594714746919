import { useServices } from 'presentation/hooks/use-services'
import { useStores } from 'presentation/hooks/use-stores'
import ProfilePic from 'presentation/shared/components/ProfilePic'
import React, { useCallback } from 'react'
import { formatProfilePicLink } from './format-profile-pic-link'

export default function UpdateProfilePic() {
  const { user_id, photoKey } =
    useStores().currentAccount.getCurrentAccount().user
  const userServices = useServices().user
  const updateProfilePic = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target

      if (files) {
        const formData = new FormData()
        formData.append('file', files[0])
        await userServices.updateProfilePic({
          form: formData,
          file: files[0]
        })
      }
    },
    []
  )

  return (
    <ProfilePic
      upload={updateProfilePic}
      src={photoKey && formatProfilePicLink(photoKey, user_id)}
      size="xl"
    />
  )
}
