import React, { useState, useEffect } from 'react'

import { useLocation } from 'react-router-dom'
import SurgicalOrderResumeLayout from 'presentation/secretary/layouts/SurgicalOrderResume'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import { LoadSurgicalOrder } from 'domain/usecases/surgical-order/load-surgical-order'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { LoadSurgicalOrderDocument } from 'domain/usecases/surgical-order/load-surgical-order-document'
import { UpdateSurgicalOrderRegenerateGuides } from 'domain/usecases/surgical-order/update-surgical-order-regenerate-guides'
import { LoadHealthInsurancePlans } from 'domain/usecases/health-insurance/load-health-insurance-plans'
type SurgicalLocation = {
  id: number
}

type Props = {
  loadOrder: LoadSurgicalOrder
  loadSurgicalOrderDocument?: LoadSurgicalOrderDocument
  updateSurgicalOrderRegenerateGuides?: UpdateSurgicalOrderRegenerateGuides
  loadHealthInsurance?: LoadHealthInsurancePlans
} & WithLoadingProps
export const SurgicalOrderResume = WithLoading(
  ({
    loadOrder,
    setIsLoading,
    loadSurgicalOrderDocument,
    updateSurgicalOrderRegenerateGuides,
    loadHealthInsurance
  }: Props) => {
    const [order, setOrder] = useState({} as SurgicalOrderModel)

    const location = useLocation<SurgicalLocation>()

    async function loadData() {
      setIsLoading(true)
      try {
        const loadSurgicalOrder = await loadOrder.load(
          Number(location.state.id),
          [
            'doctor{name, doctor_id crm}',
            'patient{birthday, gender, company, name, cpf, email, lastName, phone, landlinePhone, maritalStatus,' +
              '}',
            'hospital{name, hospital_id}',
            'surgical_order_id',
            'createdAt',
            'expectedDate',
            'procedure {isMain,procedure_code,code,description,quantity,doctor_name}',
            'healthInsurance {healthInsuranceCode, healthInsuranceName, healthPlanCode, healthPlanName, company, validThru, healthCard, healthSubPlanName, healthSubPlanCode}',
            'hospitalizationMode',
            'hospitalizationType',
            'cid {code, description}',
            'clinicalHistory',
            'laterality',
            'surgicalCenter',
            'room',
            'patientHospitalized',
            'allergy',
            'opme {solicitations {description, quantity}, providers}',
            'documents {document_id, type, name, documentUri}',
            'status {status }',
            'expectedDuration',
            'hospitalizationAvgTimeInDays',
            'patientHospitalized',
            'requestedUtiDiaries',
            'laterality',
            'preliminaryScintigraphyTime',
            'xrayOfPieceTime',
            'medicalTeam {name, type, crm, phone}',
            'anesthetistTeam {name, anesthesiaType, crm, phone}',
            'cme{description, quantity}',
            'equipment{specification, name}',
            'patientOriginId',
            'patientClassificationTypeId',
            'agendaClassificationId',
            'patientOriginDescription',
            'patientClassificationTypeDescription',
            'agendaClassificationDescription'
          ]
        )

        setOrder(loadSurgicalOrder)
      } catch (e) {
        return
      } finally {
        setIsLoading(false)
      }
    }

    useEffect(() => {
      loadData()
    }, [])

    if (order.patient)
      return (
        <SurgicalOrderResumeLayout
          patient={order?.patient}
          surgicalOrder={order}
          loadSurgicalOrderDocument={loadSurgicalOrderDocument}
          updateSurgicalOrderRegenerateGuides={
            updateSurgicalOrderRegenerateGuides
          }
          loadHealthInsurance={loadHealthInsurance}
        />
      )
    else return <></>
  }
)
