import styled from 'styled-components'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 401px auto;
  gap: 20px;
  padding: 1rem;

  flex: 1;
`

export const ContainerOptions = styled.div`
  padding: 48px 40px;
  border-radius: 1.1rem;
  background: ${({ theme }) => theme.colors.white};

  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .profile-info-div {
    margin: 0 auto;
    margin-bottom: 40px;
  }
`

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  strong {
    font-weight: 600;
    font-size: ${({ theme }) => theme.font.sizes.medium};
    color: ${({ theme }) => theme.colors.black};
  }
  label {
    font-weight: 600;
    font-size: ${({ theme }) => theme.font.sizes.small};
    color: ${({ theme }) => theme.colors.lightGray};
  }
`
export const DivForm = styled.div`
  padding: 48px 40px;
  border-radius: 1.1rem;
  background: ${({ theme }) => theme.colors.white};
`
