import React, { ReactNode } from 'react'
import { ReactComponent as DoctorIcon } from 'presentation/assets/icons/doctor.svg'
import { ReactComponent as CollaboratorIcon } from 'presentation/assets/icons/collaborators.svg'
import { ReactComponent as ScheduleIcon } from 'presentation/assets/icons/schedule.svg'
import { ReactComponent as DashboardIcon } from 'presentation/assets/icons/dashboard.svg'
import { ReactComponent as FactoriteIcon } from 'presentation/assets/icons/favorite.svg'
import { ReactComponent as WhatsappIcon } from 'presentation/assets/icons/whatsapp-icon.svg'

type OptionsType = {
  link: string
  label: string
  icon: ReactNode | string
  target?: string
}

type ProfilesType = {
  admin: OptionsType[]
  secretary: OptionsType[]
  patient: OptionsType[]
  doctor: OptionsType[]
  crmo: OptionsType[]
  hospitalization: OptionsType[]
  pre_scheduling: OptionsType[]
  scheduling: OptionsType[]
  opme: OptionsType[]
  guide_center: OptionsType[]
  budget: OptionsType[]
  triage: OptionsType[]
  RECEPTIONIST: OptionsType[]
}

const hospitalMenuItems = [
  {
    link: '/home',
    label: 'Dashboard',
    icon: <DashboardIcon />
  },
  {
    link: '/favoritos',
    label: 'Proc. Favoritados',
    icon: <FactoriteIcon />
  },
  {
    link: '/pacientes',
    label: 'Pacientes',
    icon: <CollaboratorIcon />
  },
  {
    link: '/medicos',
    label: 'Médicos',
    icon: <DoctorIcon />
  }
]

const profiles: ProfilesType = {
  admin: [
    {
      link: '/home',
      label: 'Dashboard',
      icon: <DashboardIcon />
    },
    {
      link: '/pacientes',
      label: 'Pacientes',
      icon: <CollaboratorIcon />
    },
    {
      link: '/medicos',
      label: 'Médicos',
      icon: <DoctorIcon />
    },
    {
      link: '/colaboradores',
      label: 'Colaboradores',
      icon: <CollaboratorIcon />
    },
    {
      link: 'https://wa.me/5511974508365',
      label: 'Suporte',
      target: '_blank',
      icon: <WhatsappIcon />
    }
  ],
  secretary: [
    {
      link: '/home',
      label: 'Dashboard',
      icon: <DashboardIcon />
    },
    {
      link: '/favoritos',
      label: 'Proc. Favoritados',
      icon: <FactoriteIcon />
    },
    {
      link: '/pedidos',
      label: 'Pedidos',
      icon: <ScheduleIcon />
    }
  ],
  crmo: [...hospitalMenuItems],
  RECEPTIONIST: [...hospitalMenuItems],
  hospitalization: [...hospitalMenuItems],
  pre_scheduling: [...hospitalMenuItems],
  scheduling: [...hospitalMenuItems],
  opme: [...hospitalMenuItems],
  guide_center: [...hospitalMenuItems],
  budget: [...hospitalMenuItems],
  triage: [...hospitalMenuItems],
  patient: [],
  doctor: []
}

export default profiles
