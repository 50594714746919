import React, { useEffect, useState } from 'react'

import { LoadSecretary } from 'domain/usecases/secretary/load-secretary'
import { LoadHospitals } from 'domain/usecases/hospital/load-hospitals'
import { Hospital } from 'domain/entities/hospital-model'
import { Doctor } from 'domain/entities/doctor-model'
import { LoadPatientByCpf } from 'domain/usecases/patient/load-patient-by-cpf'
import { LoadCid } from 'domain/usecases/surgical-order/load-cid'
import { LoadTuss } from 'domain/usecases/surgical-order/load-tuss'
import { Cid } from 'domain/entities/cid'
import { Tuss } from 'domain/entities/tuss'
import { InvitePatient } from 'domain/usecases/patient/invite-patient'
import { RegisterSurgicalOrder } from 'domain/usecases/surgical-order/register-surgical-order'
import { AddFavorite } from 'domain/usecases/surgical-order/add-favorite'
import HospitalAddSurgicalOrderLayout from 'presentation/hospital/layouts/AddSurgicalOrder'
import { LoadSurgeryCenter } from 'domain/usecases/surgery-center/load-surgery-center'
import { SearchDoctors } from 'domain/usecases/doctor/search-doctors'
import { createAddSurgicalOrderFunctions } from './load-surgical-order'
import { SurgeryCenter } from 'domain/entities/surgery-center-model'
import { useStores } from 'presentation/hooks/use-stores'
import { Profile } from 'common/enum/profile'
import { AddPatient } from 'domain/usecases/patient/add-patient'
import { UploadPatientDocument } from 'domain/usecases/patient/upload-patient-document'
import { LoadSurgeries } from 'domain/usecases/surgery/load-surgeries'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import {
  HealthInsurancePlans,
  LoadHealthInsurancePlans,
  Plans,
  SubPlan
} from 'domain/usecases/health-insurance/load-health-insurance-plans'
import { toast } from 'react-toastify'
import { SearchPatient } from 'domain/usecases/patient/search-patient'
import { UploadSurgicalOrderDocument } from 'domain/usecases/surgical-order/upload-surgical-order-document'

type Props = {
  loadSecretary: LoadSecretary
  loadHospitals: LoadHospitals
  loadPatient: LoadPatientByCpf
  loadInsurances: LoadHealthInsurancePlans
  invitePatient: InvitePatient
  loadCid: LoadCid
  loadTuss: LoadTuss
  addOrder: RegisterSurgicalOrder
  addFavorite: AddFavorite
  loadSurgeryCenters: LoadSurgeryCenter
  loadDoctors?: SearchDoctors
  addPatient: AddPatient
  uploadDocument?: UploadPatientDocument
  loadSurgeries: LoadSurgeries
  searchPatient: SearchPatient
  uploadSurgicalOrderDocument?: UploadSurgicalOrderDocument
} & WithLoadingProps

const HospitalAddSurgicalOrder = WithLoading(
  ({
    loadHospitals,
    loadPatient,
    loadInsurances,
    loadCid,
    loadTuss,
    invitePatient,
    addOrder,
    addFavorite,
    loadSurgeryCenters,
    loadDoctors,
    loadSecretary,
    addPatient,
    uploadDocument,
    loadSurgeries,
    setIsLoading,
    searchPatient,
    uploadSurgicalOrderDocument
  }: Props) => {
    const user = useStores().currentAccount.getCurrentAccount()
    const [hospitals, setHospitals] = useState([] as Hospital[])
    const [doctors, setDoctors] = useState([] as Doctor[])
    const [insurances, setInsurances] = useState([] as HealthInsurancePlans[])
    const [plans, setPlans] = useState<Plans[]>([])
    const [subPlans, setSubPlans] = useState<SubPlan[]>([])
    const [cid, setCid] = useState([] as Cid[])
    const [tuss, setTuss] = useState([] as Tuss[])
    const [surgeryCenters, setSurgeryCenters] = useState<SurgeryCenter[]>([])

    const loadHealthInsurances = async (hospital_id: number) => {
      // setIsLoading(true)
      try {
        const insurances = await loadInsurances.load({
          hospital_id: hospital_id,
          params: [
            'code',
            'description',
            'ansRegister',
            'plans {code, description, subPlan{code, description}}'
          ]
        })

        setInsurances(insurances)
      } catch (e: any) {
        toast.error(e.message)
      } finally {
        // setIsLoading(false)
      }
    }

    const loadHealthPlans = (insurance_id: number): void => {
      try {
        setIsLoading(true)
        const healthPlans = insurances.find((insurance) => {
          return insurance.code === insurance_id
        })

        setPlans(healthPlans?.plans ?? [])
      } catch (e: any) {
        toast.error(e.message)
      } finally {
        setIsLoading(false)
      }
    }

    const loadHealthSubPlans = (plan_id: number): void => {
      try {
        setIsLoading(true)
        const healthPlans = plans.find((insurance) => {
          return insurance.code === plan_id
        })

        setSubPlans(healthPlans?.subPlan ?? [])
      } catch (e: any) {
        toast.error(e.message)
      } finally {
        setIsLoading(false)
      }
    }

    const loadTussTable = async (
      pageNumber: number,
      pageSize: number,
      name?: string,
      code?: string
    ) => {
      try {
        const tuss = await loadTuss.load({
          pagination: { pageNumber, pageSize },
          name,
          code,
          fields: [
            'data{description',
            'tuss_id, pro_fat_id}',
            'pageInfo{totalItems, itemsPerPage}'
          ]
        })
        setTuss(tuss.data)
        return tuss
      } catch (e) {
        return
      }
    }

    const loadSurgeriesTable = async (
      pageNumber: number,
      pageSize: number,
      name?: string
    ) => {
      try {
        const tuss = await loadSurgeries.load({
          pagination: { pageNumber, pageSize },
          name: name,
          fields: [
            'data{description',
            'tuss_id, surgery_id, pro_fat_id}',
            'pageInfo{totalItems, itemsPerPage}'
          ]
        })
        setTuss(tuss.data)

        return tuss
      } catch (e) {
        return
      }
    }

    const loadCidTable = async (
      pageNumber: number,
      pageSize: number,
      query?: string
    ) => {
      try {
        const cids = await loadCid.load({
          pagination: { pageNumber, pageSize },
          query,
          fields: [
            'data{description',
            'cid_id}',
            'pageInfo{totalItems, itemsPerPage}'
          ]
        })
        setCid(cids.data)
        return cids
      } catch (e) {
        return
      }
    }

    const loadDoctor = async (name = '') => {
      let doctors: Doctor[] | undefined = []

      if (user.user.role === Profile.SECRETARY) {
        doctors = await loadSecretary
          .load(['doctors{name, doctor_id, crm, crmUf}'])
          .then((res) => res.doctors)
      } else {
        doctors = await loadDoctors
          ?.load({
            doctorName: name,
            params: ['data{', 'name', 'crm', 'crmUf', 'doctor_id}'],
            pagination: { pageNumber: 1, pageSize: 10 }
          })
          .then((res) => res.data)
      }
      setDoctors(doctors ?? [])
    }

    useEffect(() => {
      async function loadData() {
        setIsLoading(true)
        setHospitals(
          await loadHospitals.load(['name', 'hospital_id', 'nickname'])
        )
        setIsLoading(false)
      }
      loadData()
    }, [])

    const loadPatientByCpf = async (cpf: string) => {
      setIsLoading(true)
      return await loadPatient
        .load(cpf, [
          'gender',
          'name',
          'cpf',
          'phone',
          'landlinePhone',
          'email',
          'birthday',
          'patient_id',
          'company',
          'maritalStatus',
          'healthInsurance {healthInsuranceCode, healthInsuranceName, health_insurance_id, healthCard',
          'healthPlanCode, healthPlanName, validThru, company, ansRegister}'
        ])
        .then((res) => {
          if (res.healthInsurance?.healthInsuranceCode) {
            loadHealthPlans(res.healthInsurance.healthInsuranceCode)
          }
          if (res.healthInsurance?.healthSubPlanCode) {
            loadHealthSubPlans(res.healthInsurance.healthPlanCode ?? 0)
          }
          return res
        })
        .finally(() => setIsLoading(false))
    }

    const loadSurgeryCentersByHospital = async (hospital_id: number) => {
      setSurgeryCenters(
        await loadSurgeryCenters.load({
          hospital_id,
          fields: ['codeCenter', 'description', 'hospital_id']
        })
      )
    }

    createAddSurgicalOrderFunctions({
      loadHealthInsurances,
      loadTuss: loadTussTable,
      loadCid: loadCidTable,
      loadHealthPlans,
      loadPatientByCpf,
      loadSurgeryCenter: loadSurgeryCentersByHospital,
      loadSurgeries: loadSurgeriesTable,
      loadHealthSubPlans,
      loadDoctor
    })

    return (
      <HospitalAddSurgicalOrderLayout
        doctors={doctors}
        hospitals={hospitals}
        insurances={insurances}
        surgeryCenters={surgeryCenters}
        cids={cid}
        tussList={tuss}
        invitePatient={invitePatient}
        addOrder={addOrder}
        addFavorite={addFavorite}
        plans={plans}
        subPlans={subPlans}
        addPatient={addPatient}
        uploadDocument={uploadDocument}
        searchPatient={searchPatient}
        uploadSurgicalOrderDocument={uploadSurgicalOrderDocument}
      />
    )
  }
)

export default HospitalAddSurgicalOrder
