import React, { useEffect, useState } from 'react'

import moment from 'moment'
import { useFormik } from 'formik'
import * as yup from 'yup'

import Modal from 'presentation/hospital/components/Modal'
import SuccesModal from 'presentation/shared/components/Modal'

import Heading from 'presentation/shared/components/Heading'
import Button from 'presentation/shared/components/Button'
import TextArea from 'presentation/shared/components/TextArea'
import SupportText from 'presentation/shared/components/SupportText'
import {
  SurgicalPendency,
  SurgicalPendencyStatus
} from 'domain/entities/surgical-pendency'
import DocumentsArea from 'presentation/shared/components/DocumentsArea'
import { DocumentItem } from 'presentation/shared/components/DocumentsArea/DocumentsAreaModal'
import { secretaryPendenciesDocumentList } from 'presentation/shared/pages/SurgeryDetails/documents'

import * as S from './styles'
import { toast } from 'react-toastify'
import { AnswerSurgicalPendency } from 'domain/usecases/surgical-pendency/answer-surgical-pendency'
import { UploadSurgicalPendencyDocument } from 'domain/usecases/surgical-pendency/upload-surgical-pendency-document'
import { LoadSurgicalPendencyDocument } from 'domain/usecases/surgical-pendency/load-surgical-pendency-document'

export type SurgicalPendencyModal = {
  crmResponsible: string
  patientName: string
  procedures: any[]
  surgeonReturn?: string
  validateDate: string
  pendency?: SurgicalPendency
}

type Props = {
  surgicalPendecy: SurgicalPendencyModal
  close: (dataChanged: boolean) => void
  loadSurgicalPendencyDocument?: LoadSurgicalPendencyDocument
  answerSurgicalPendency: AnswerSurgicalPendency
  uploadSurgicalPendencyDocument: UploadSurgicalPendencyDocument
}

const PendencyModal = ({
  surgicalPendecy,
  close,
  loadSurgicalPendencyDocument,
  answerSurgicalPendency,
  uploadSurgicalPendencyDocument
}: Props) => {
  const [documents, setDocuments] = React.useState([] as DocumentItem[])
  const [documentsChanged, setDocumentsChanged] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false)

  const { submitForm, values, handleChange, handleBlur, setFieldValue } =
    useFormik<PendencyModalFormValues>({
      initialValues: {
        answer: surgicalPendecy.pendency?.answer ?? '',
        documents:
          surgicalPendecy.pendency?.documents.map((document) => {
            return {
              file: new File([''], ''),
              type: document.type,
              document_id: document.document_id
            }
          }) ?? []
      },
      validationSchema,
      validateOnMount: true,
      onSubmit: async (values) => {
        const surgicalPendencyId =
          surgicalPendecy.pendency?.surgical_pendency_id
        try {
          if (surgicalPendencyId) {
            if (documentsChanged) {
              await documents.forEach((document) => {
                if (document.file) {
                  const form = new FormData()
                  form.append('file', document.file)
                  form.append(
                    'surgical_pendency_id',
                    surgicalPendencyId.toString()
                  )
                  form.append('type', document.type)
                  const uploadedFile = uploadSurgicalPendencyDocument.upload({
                    type: document.type,
                    file: document.file,
                    form: form
                  })
                  toast.promise(uploadedFile, {
                    pending: `Fazendo o envio do arquivo ${document.file.name}`,
                    success: `Arquivo ${document.file.name} enviado com sucesso`,
                    error: `Falha ao enviar o arquivo ${document.file.name}`
                  })
                }
              })
            }
            await answerSurgicalPendency.answer({
              surgical_pendency_id: surgicalPendencyId,
              answer: values.answer
            })
            setShowSuccessModal(true)
            setDocumentsChanged(false)
          }
        } catch (err: any) {
          toast.error(err.message)
        }
      }
    })

  const handleChangeDocuments = (documents: DocumentItem[]) => {
    setFieldValue('documents', documents)
    setDocuments(documents)
    setDocumentsChanged(true)
  }

  const handleDownloadDocumentFile = async (
    document_id: number
  ): Promise<File> => {
    if (surgicalPendecy.pendency?.surgical_order_id) {
      const surgicalOrderDocument = await loadSurgicalPendencyDocument?.load(
        Number(surgicalPendecy.pendency?.surgical_pendency_id),
        document_id
      )
      if (surgicalOrderDocument) {
        return new File([surgicalOrderDocument?.data], '', {
          type: surgicalOrderDocument.contentType
        })
      }
    }
    return new File([''], '')
  }

  const handleSuccesSendPendency = () => {
    close(true)
  }

  useEffect(() => {
    function fomatDocuments() {
      if (
        surgicalPendecy.pendency?.documents &&
        surgicalPendecy.pendency?.documents.length > 0
      ) {
        const documents = surgicalPendecy.pendency?.documents.map(
          (document, idx) => {
            return {
              file: new File([''], `Documento ${idx + 1}`),
              type: document.type,
              document_id: document.document_id
            }
          }
        )
        setDocuments(documents)
      }
    }
    fomatDocuments()
  }, [surgicalPendecy])
  return (
    <Modal close={() => close(false)}>
      <S.Wrapper>
        <Heading as="h1" color="primary">
          Pendência Cirúrgica
        </Heading>
        <S.Content>
          <S.Header>
            {surgicalPendecy.crmResponsible && (
              <S.Item>
                <span>Responsável CRMO:</span>
                <p>{surgicalPendecy.crmResponsible}</p>
              </S.Item>
            )}
            <S.Item>
              <span>Pendência gerada:</span>
              <p>
                {surgicalPendecy.pendency?.createdAt
                  ? moment(surgicalPendecy.pendency?.createdAt).format(
                      'DD/MM/YYYY'
                    )
                  : '-'}
              </p>
            </S.Item>
          </S.Header>
          <S.Complement>
            <h2>{surgicalPendecy.patientName}</h2>
            {surgicalPendecy.procedures.map((procedure) => (
              <h3 key={procedure.procedure_code}>{procedure.description}</h3>
            ))}
            <div>
              {surgicalPendecy.surgeonReturn && (
                <S.Item>
                  <span>Retorno Cirurgião:</span>
                  <p>{surgicalPendecy.surgeonReturn}</p>
                </S.Item>
              )}
              {surgicalPendecy.validateDate && (
                <S.Item>
                  <span>Data da validação:</span>
                  <p>{surgicalPendecy.validateDate}</p>
                </S.Item>
              )}
            </div>
          </S.Complement>
          <S.InputsContainer>
            <SupportText color="primary">Pendência</SupportText>
            <TextArea
              rows={3}
              bgColor="white"
              name="observation"
              id="answer"
              value={surgicalPendecy.pendency?.observation || ''}
              disabled
            />
            <SupportText color="primary">Retorno*</SupportText>
            <TextArea
              rows={5}
              placeholder="Retorno da pendência"
              bgColor="white"
              name="answer"
              id="answer"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.answer || ''}
              required
              disabled={
                surgicalPendecy?.pendency?.status ===
                SurgicalPendencyStatus.RESOLVED
              }
            />
          </S.InputsContainer>
          <S.Documents>
            <DocumentsArea
              defaultDocuments={documents}
              handleChangeDocuments={(documents: DocumentItem[]) =>
                handleChangeDocuments(documents)
              }
              defaultDocumentsListType={secretaryPendenciesDocumentList}
              handleDownloadDocument={handleDownloadDocumentFile}
            />
          </S.Documents>
        </S.Content>
        <S.Buttons>
          <Button variant="outlined" type="button" onClick={() => close(false)}>
            Cancelar
          </Button>
          <Button
            type="button"
            onClick={submitForm}
            disabled={
              !values.answer ||
              surgicalPendecy.pendency?.status ===
                SurgicalPendencyStatus.RESOLVED
            }
          >
            Enviar
          </Button>
        </S.Buttons>
        <SuccesModal
          show={showSuccessModal}
          close={() => handleSuccesSendPendency()}
          title="Resolução de pendência enviada com sucesso!"
        />
      </S.Wrapper>
    </Modal>
  )
}

export default PendencyModal

const validationSchema = yup.object().shape({
  answer: yup.string().required()
})

type PendencyModalFormValues = {
  answer: string
  documents: DocumentItem[]
}
