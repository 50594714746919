import React, { useEffect, useMemo, useState } from 'react'

import { toast } from 'react-toastify'

import Heading from 'presentation/shared/components/Heading'
import SupportText from 'presentation/shared/components/SupportText'
import SelectField from 'presentation/shared/components/SelectField'
import * as S from './styles'
import Button from 'presentation/shared/components/Button'
import { Hospital } from 'domain/entities/hospital-model'
import { Doctor } from 'domain/entities/doctor-model'
import { SurgeryCenter } from 'domain/entities/surgery-center-model'
import Chip from 'presentation/shared/components/Chip'
import { useFormikContext } from 'formik'
import { AddSurgicalOrderFormValues } from '../index'
import { useAddSurgicalOrderFunctions } from 'presentation/hospital/pages/AddSurgicalOrder/load-surgical-order'
import { ReactComponent as SearchIcon } from 'presentation/assets/icons/search.svg'
import AutoComplete from 'presentation/shared/components/AutoComplete'
import Modal from 'presentation/hospital/components/Modal'
import { ReactComponent as CheckIcon } from 'presentation/assets/icons/big-check.svg'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { Backspace } from '@material-ui/icons'

type Props = {
  goNext?: () => void
  hospitals: Hospital[]
  doctors: Doctor[]
  goBack?: () => void
  surgeryCenters: SurgeryCenter[]
  updateOrder?: boolean
} & WithLoadingProps

const SelectDoctorAndHospital = WithLoading(
  ({
    goNext,
    goBack,
    hospitals,
    doctors,
    surgeryCenters,
    updateOrder = false,
    setIsLoading
  }: Props) => {
    const { setFieldValue, values } =
      useFormikContext<AddSurgicalOrderFormValues>()
    const context = useAddSurgicalOrderFunctions()
    const [doctorQuery, setDoctorQuery] = useState<string>()
    const [showModal, setShowModal] = useState<boolean>(false)

    const isValid = useMemo(() => {
      return values.doctor && values.hospital && values.surgery_center
    }, [values.hospital, values.doctor, values.surgery_center])

    async function selectDoctor(doctor: Doctor) {
      if (doctor) {
        await setFieldValue('doctor', doctor)
        const placeholder = `${doctor.crmUf} - ${doctor.crm} - ${doctor.name}`
        setDoctorQuery(placeholder)
      } else {
        await setFieldValue('doctor', undefined)
        setDoctorQuery(undefined)
      }
    }

    async function selectHospital(id: string) {
      const hospital = hospitals.find(
        (hospital) => hospital.hospital_id == Number(id)
      )
      try {
        setIsLoading(true)
        if (hospital) {
          await setFieldValue('hospital', hospital)
          await setFieldValue('surgery_center', undefined)
          await context?.loadSurgeryCenter(hospital.hospital_id ?? 0)
        } else {
          await setFieldValue('hospital', undefined)
        }
      } catch (err) {
        toast.error('Não foi possível selecionar a unidade')
      } finally {
        setIsLoading(false)
      }
      await context?.loadHealthInsurances(hospital?.hospital_id ?? 0)
    }

    async function selectSurgeryCenter(surgeryCenter: SurgeryCenter) {
      if (surgeryCenter) {
        await setFieldValue('surgery_center', surgeryCenter)
      } else {
        await setFieldValue('surgery_center', undefined)
      }
    }

    useEffect(() => {
      selectHospital(String(values.hospital?.hospital_id))
    }, [values.hospital?.hospital_id])

    return (
      <S.Wrapper>
        {!updateOrder && (
          <>
            <Heading size="xlarge" color="primary" as="h1">
              Novo pedido cirúrgico
            </Heading>
          </>
        )}

        {!updateOrder ? (
          <SupportText style={{ marginBottom: '3.125em' }}>
            Selecione o médico, unidade e qual sala será realizado o
            procedimento
          </SupportText>
        ) : (
          <SupportText
            style={{ marginBottom: '1em', fontSize: '18px', fontWeight: 700 }}
          >
            Dados do médico e unidade
          </SupportText>
        )}
        <S.Inputs>
          <S.InputButtonContainer>
            <Heading size="medium" color="primary" as="p">
              Médico
            </Heading>
            <AutoComplete
              label="Selecione o médico"
              labelColor="lightGray"
              bgColor="mainBg"
              suggestions={doctors.map((doctor) => ({
                label: `${doctor.crmUf} - ${doctor.crm} - ${doctor.name}`,
                value: doctor
              }))}
              name="doctor"
              placeholder="Busca por nome ou CRM"
              value={doctorQuery || values.doctor?.name || ''}
              onSuggestionClick={selectDoctor}
              disabled={!!values.doctor?.doctor_id}
              onType={(val) => context?.loadDoctor?.(val)}
              onInputChange={setDoctorQuery}
              icon={<SearchIcon />}
              iconPosition="right"
              iconLocale="inside"
              iconMargin="44px"
              required
              data-testid="select-doctor-autocomplete"
            />
            {values.doctor?.doctor_id && !updateOrder && (
              <Button
                backgroundColor="lightGray"
                onClick={() => {
                  setFieldValue('doctor', undefined)
                  setDoctorQuery('')
                }}
                title="Limpar médico selecionado"
              >
                <Backspace style={{ width: '30px', height: '24px' }} />
              </Button>
            )}
          </S.InputButtonContainer>

          <S.InputButtonContainer>
            <Heading size="medium" color="primary" as="p">
              Unidade
            </Heading>
            <SelectField
              style={{ marginBottom: '1em' }}
              label="Selecione a unidade e a sala"
              labelColor="lightGray"
              bgColor="mainBg"
              name="hospital"
              items={hospitals?.map((hospital) => ({
                label: hospital.nickname,
                value: hospital.hospital_id
              }))}
              onInputChange={selectHospital}
              data-testid="hospital-select"
              value={values.hospital?.hospital_id ?? ''}
              required
            />

            <S.SurgeryCenterList>
              {surgeryCenters.map((center) => (
                <Chip
                  key={center.codeCenter}
                  dataTestId={center.codeCenter}
                  label={center.description}
                  customValue={center}
                  name="surgery_center"
                  onCheck={() => selectSurgeryCenter(center)}
                  checked={
                    Number(values.surgery_center?.codeCenter) ===
                    center.codeCenter
                  }
                  data-testid={`surgery-center-chip-${values.hospital?.hospital_id}`}
                  disabled={values.hospital?.hospital_id !== center.hospital_id}
                />
              ))}

              {values.hospital?.hospital_id > 0 &&
                surgeryCenters.length === 0 && (
                  <S.NoAvailableSurgeryCenter>
                    Sem centros cirurgícos para a unidade escolhida
                  </S.NoAvailableSurgeryCenter>
                )}
            </S.SurgeryCenterList>
          </S.InputButtonContainer>
        </S.Inputs>
        {!updateOrder && (
          <S.Buttons>
            <Button
              type="button"
              variant="outlined"
              backgroundColor="white"
              onClick={goBack}
              data-testid={`select-doctor-next-button`}
            >
              Cancelar
            </Button>
            <Button
              disabled={!isValid}
              type="button"
              onClick={() => setShowModal(true)}
              data-testid={`select-doctor-next-button`}
            >
              Próximo
            </Button>
          </S.Buttons>
        )}
        {showModal && (
          <Modal close={() => setShowModal(false)}>
            <S.ModalWrapper>
              <CheckIcon />
              <p
                style={{
                  fontSize: '1.3rem',
                  textAlign: 'center'
                }}
              >
                Confirma seleção de unidade e centro cirúrgico do pedido?
              </p>
              <p
                style={{
                  margin: '1.6rem 0'
                }}
              >
                Unidade: <strong>{values.hospital.nickname}</strong> <br />
                Centro cirúrgico:{' '}
                <strong>{values.surgery_center.description}</strong> <br />
              </p>
              <S.ModalButtons>
                <Button
                  type="button"
                  variant="outlined"
                  onClick={() => setShowModal(false)}
                  data-testid={`cancel-modal-button`}
                >
                  Cancelar
                </Button>
                <Button
                  type="button"
                  data-testid={`confirm-modal-button`}
                  onClick={goNext}
                >
                  Confirmar
                </Button>
              </S.ModalButtons>
            </S.ModalWrapper>
          </Modal>
        )}
      </S.Wrapper>
    )
  }
)
export default SelectDoctorAndHospital
