import React from 'react'

import Container from 'presentation/hospital/components/Container'
import Heading from 'presentation/shared/components/Heading'
import FavoritesTable from 'presentation/secretary/components/Favorites/FavoritesTable'

import * as S from './styles'

const FavoritesLayout = () => {
  return (
    <Container>
      <Heading size="huge" color="primary" style={{ marginBottom: '48px' }}>
        Procedimentos Favoritados
      </Heading>
      <S.Wrapper>
        <FavoritesTable />
      </S.Wrapper>
    </Container>
  )
}

export default FavoritesLayout
