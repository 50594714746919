import React from 'react'

import SecretaryAddSurgicalOrderForm from 'presentation/secretary/components/Forms/AddSurgicalOrder'
import Container from 'presentation/hospital/components/Container'
import * as S from './styles'
import { Hospital } from 'domain/entities/hospital-model'
import { Doctor } from 'domain/entities/doctor-model'
import { Cid } from 'domain/entities/cid'
import { Tuss } from 'domain/entities/tuss'
import { InvitePatient } from 'domain/usecases/patient/invite-patient'
import { RegisterSurgicalOrder } from 'domain/usecases/surgical-order/register-surgical-order'
import { AddFavorite } from 'domain/usecases/surgical-order/add-favorite'
import { SurgeryCenter } from 'domain/entities/surgery-center-model'
import { AddPatient } from 'domain/usecases/patient/add-patient'
import { UploadPatientDocument } from 'domain/usecases/patient/upload-patient-document'
import {
  HealthInsurancePlans,
  Plans,
  SubPlan
} from 'domain/usecases/health-insurance/load-health-insurance-plans'
import { SearchPatient } from 'domain/usecases/patient/search-patient'
import { UploadSurgicalOrderDocument } from 'domain/usecases/surgical-order/upload-surgical-order-document'

type Props = {
  hospitals: Hospital[]
  doctors: Doctor[]
  insurances: HealthInsurancePlans[]
  surgeryCenters: SurgeryCenter[]
  cids: Cid[]
  tussList: Tuss[]
  plans: Plans[]
  subPlans: SubPlan[]
  invitePatient: InvitePatient
  addOrder: RegisterSurgicalOrder
  addFavorite: AddFavorite
  addPatient: AddPatient
  uploadDocument?: UploadPatientDocument
  searchPatient: SearchPatient
  uploadSurgicalOrderDocument?: UploadSurgicalOrderDocument
}

export default function HospitalAddSurgicalOrderLayout({
  hospitals,
  doctors,
  insurances,
  cids,
  tussList,
  invitePatient,
  addOrder,
  addFavorite,
  surgeryCenters,
  plans,
  addPatient,
  uploadDocument,
  subPlans,
  searchPatient,
  uploadSurgicalOrderDocument
}: Props) {
  return (
    <Container hideButton={true}>
      <S.Wrapper>
        <SecretaryAddSurgicalOrderForm
          doctors={doctors}
          hospitals={hospitals}
          insurances={insurances}
          cids={cids}
          tussList={tussList}
          invitePatient={invitePatient}
          addOrder={addOrder}
          addFavorite={addFavorite}
          surgeryCenter={surgeryCenters}
          plans={plans}
          subPlans={subPlans}
          addPatient={addPatient}
          uploadDocument={uploadDocument}
          searchPatient={searchPatient}
          uploadSurgicalOrderDocument={uploadSurgicalOrderDocument}
        />
      </S.Wrapper>
    </Container>
  )
}
