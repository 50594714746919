import React from 'react'
import { ChangePassword } from 'domain/usecases/authentication/change-password'
import { GetPasswordRecoveryCode } from 'domain/usecases/authentication/get-password-recovery-code'
import {
  GetPasswordCodeFormValues,
  SetPasswordFormValues
} from 'presentation/hospital/components/Forms/PasswordRecovery'
import SecretaryPasswordRecoveryLayout from 'presentation/secretary/layouts/PasswordRecovery'
import { useParams } from 'react-router'

type SecretaryPasswordRecoveryParams = {
  type: 'email' | 'phone'
}

type SecretaryPasswordRecoveryProps = {
  getCodeUseCase: GetPasswordRecoveryCode
  changePasswordUseCase: ChangePassword
}

export default function SecretaryPasswordRecovery({
  changePasswordUseCase,
  getCodeUseCase
}: SecretaryPasswordRecoveryProps) {
  const { type } = useParams<SecretaryPasswordRecoveryParams>()

  const setInitialValues: GetPasswordCodeFormValues = {
    type: type,
    field: '',
    role: 'SECRETARY'
  }

  return (
    <SecretaryPasswordRecoveryLayout
      getCodeUseCase={getCodeUseCase}
      setPasswordUseCase={changePasswordUseCase}
      getPasswordCodeInitialValues={setInitialValues}
      setPasswordInitialValues={{} as SetPasswordFormValues}
      type={type}
    />
  )
}
