import React from 'react'

import * as S from './styles'
import Container from 'presentation/hospital/components/Container'
import { ReactComponent as CheckedList } from 'presentation/assets/icons/total-orders.svg'
// import DonutChart from 'presentation/shared/components/Charts/Donut'
import Table from 'presentation/shared/components/Table'
import { Doctor } from 'domain/entities/doctor-model'
import { useHistory } from 'react-router'
import { SurgicalPendency } from 'domain/entities/surgical-pendency'
import PendencyCard from 'presentation/secretary/components/PendencyCard'
import { RegisteredOrdersCount } from 'domain/usecases/secretary/load-secretary-registered-orders-count'
import { LoadSecretaryScheduleSurgicalOrdersStatus } from 'domain/usecases/secretary/load-secretary-schedule-surgical-orders'
import { Tooltip } from 'presentation/shared/components/Tooltip'
import Heading from 'presentation/shared/components/Heading'

export type SecretaryHomeLayoutProps = {
  scheduled?: SchedulesCount
  unscheduled?: SchedulesCount
  total?: number
  totalDoctor?: number
  totalSecretary?: number
  surgicalOrderList?: SecretarySurgicalOrderListType[]
  chartData?: Doctor[]
  pendencies?: SurgicalPendency[]
  assistancePendencies?: number
  authorizationPendencies?: number
  registeredOrders?: RegisteredOrdersCount[]
  refetchData: () => void
}

export type SecretarySurgicalOrderListType = {
  surgical_order_id?: string
  status?: string
  hospital?: string
  createdAt?: string
  patient?: string
  insurance?: string
  surgery?: string
  doctor?: string
  createdBy?: string
}

export default function SecretaryHomeLayout({
  scheduled,
  unscheduled,
  total,
  totalDoctor,
  totalSecretary,
  surgicalOrderList,
  assistancePendencies,
  authorizationPendencies,
  registeredOrders,
  refetchData
}: SecretaryHomeLayoutProps) {
  const today = new Date()
  const month = today.toLocaleString('pt-br', {
    month: 'long'
  })
  const year = today.toLocaleString('pt-br', {
    year: 'numeric'
  })

  const history = useHistory()

  const goToSchedulePage = (
    orderStatus: LoadSecretaryScheduleSurgicalOrdersStatus
  ) => {
    history.push('/pedidos/agendamentos', {
      params: orderStatus
    })
  }

  return (
    <S.Wrapper>
      <Container onHospitalChange={refetchData}>
        <S.DataOverview>
          <S.RegisteredOrdersTotal>
            <S.CardSubtitle>
              <abbr>
                {month} {year}
              </abbr>
              <label>Pedidos cadastrados neste mês</label>
            </S.CardSubtitle>
            <div className="icon-total">
              <CheckedList />
              <S.CardNumber>{total}</S.CardNumber>
            </div>
          </S.RegisteredOrdersTotal>
          <div
            style={{
              width: '100%'
            }}
          >
            <S.RegisteredOrders>
              <S.CardSubtitle>
                <abbr>
                  {month} {year}
                </abbr>
                <label>Secretária</label>
              </S.CardSubtitle>
              <S.CardNumber>{totalSecretary}</S.CardNumber>
            </S.RegisteredOrders>
            <S.RegisteredOrders>
              <S.CardSubtitle>
                <abbr>
                  {month} {year}
                </abbr>
                <label>Médico</label>
              </S.CardSubtitle>
              <S.CardNumber>{totalDoctor}</S.CardNumber>
            </S.RegisteredOrders>
          </div>

          {/* <S.ChartContainer>
            <S.CardSubtitle>
              <label>Pedidos Cadastrados | Médicos</label>
            </S.CardSubtitle>
            <DonutChart
              labels={registeredOrders?.map((label) => label.doctorName)}
              series={registeredOrders?.map((serie) => serie.count)}
            />
          </S.ChartContainer> */}
        </S.DataOverview>
        <S.PendencyContainer>
          <PendencyCard
            type="authorization"
            quantity={authorizationPendencies}
          />
          <PendencyCard type="assistance" quantity={assistancePendencies} />
          <Heading color="primary">
            Ultimos pedidos cirúrgicos cadastrados
          </Heading>
          <S.TableContainer>
            <Table
              title="Pedidos cadastrados"
              columns={columns}
              data={surgicalOrderList}
              onClick={(data) =>
                history.push('/pedido/detalhes', { id: data[0] })
              }
              rowsPerPage={10}
              rowsPerPageOptions={[10]}
              paginateServerSide={false}
              autoPaginate={true}
            />
          </S.TableContainer>
        </S.PendencyContainer>
      </Container>
    </S.Wrapper>
  )
}

type SchedulesCount = {
  total?: number
  approved?: number
  disapproved?: number
}

const columns = [
  {
    name: 'surgical_order_id',
    label: 'Nº aviso',
    options: { filter: false, sort: false }
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      filter: true,
      sort: false,
      //eslint-disable-next-line
      customBodyRender: (value: string) => (
        <S.StatusContainer>
          <p>{value}</p>
        </S.StatusContainer>
      )
    }
  },
  {
    name: 'hospital',
    label: 'Unidade',
    options: { filter: true, sort: false }
  },
  {
    name: 'createdAt',
    label: 'Dt. de criação',
    options: { filter: true, sort: false }
  },
  {
    name: 'patient',
    label: 'Paciente',
    options: { filter: false, sort: false }
  },
  {
    name: 'insurance',
    label: 'Convênio',
    options: { filter: true, sort: false }
  },
  {
    name: 'surgery',
    label: 'Cirurgia',
    options: { filter: true, sort: false }
  },
  {
    name: 'doctor',
    label: 'Médico',
    options: { filter: true, sort: false }
  },
  {
    name: 'createdBy',
    label: 'Responsável',
    options: {
      filter: true,
      sort: false,
      // eslint-disable-next-line react/display-name
      customBodyRender: (value: string) => {
        const [name, role] = value.split('*')
        return (
          <Tooltip text={name?.trim()}>
            <p>{role?.trim()}</p>
          </Tooltip>
        )
      }
    }
  }
]
