import React, { useEffect, useState } from 'react'

import { useFormik } from 'formik'
import * as yup from 'yup'

import * as S from './styles'
import TextField from '../../../shared/components/TextField'
import Button from '../../../shared/components/Button'
import Table from '../../../shared/components/Table'
import { resetForm } from '../../../utils/form-utils'
import { isEqual } from 'lodash'
import { Profile } from 'common/enum/profile'
import AutoComplete from 'presentation/shared/components/AutoComplete'
import { ReactComponent as SearchIcon } from 'presentation/assets/icons/search.svg'
import { toast } from 'react-toastify'
import { Backspace } from '@material-ui/icons'
import { useServices } from 'presentation/hooks/use-services'

type Props = {
  selectedOpme: any[]
  setSelectedOpme: (opme: any) => void
  handleChange: (field: string[]) => any
  onlyVisualization?: boolean
  defaultProviders?: string[]
  favoriteProviders?: string[]
  profile?: Profile
}

export const OpmeTable = ({
  selectedOpme,
  setSelectedOpme,
  handleChange,
  onlyVisualization,
  defaultProviders,
  favoriteProviders,
  profile
}: Props) => {
  const surgicalOrderService = useServices().surgicalOrder
  const [opmeQuery, setOpmeQuery] = useState<string>()
  const [opmesList, setOpmesList] = useState([] as any)
  const [opme, setOpme] = useState({} as any)
  const getInitialProviders = () => {
    const providersArr =
      favoriteProviders! && favoriteProviders.length > 0
        ? favoriteProviders
        : defaultProviders
          ? defaultProviders
          : ['', '', '']
    return providersArr.length < 3
      ? [
        ...providersArr,
        ...Array.from<string>({ length: 3 - providersArr.length }).fill('')
      ]
      : providersArr
  }

  const [providers, setProviders] = useState<string[]>(getInitialProviders())

  const formik = useFormik({
    initialValues: {
      quantity: 0,
      description: '',
      opme_id: undefined
    },
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setSelectedOpme([
        ...selectedOpme,
        {
          description: values.description,
          quantity: Number(values.quantity),
          opme_id: values.opme_id
        }
      ])
      setOpmeQuery('')
      setOpme({})
      resetForm(formik)
    }
  })

  const onDeleteRow = (index: number[]) => {
    setSelectedOpme(
      selectedOpme.filter(
        (procedure, procedureIndex) => !index.includes(procedureIndex)
      )
    )
  }

  const handleProvidersChange = (val: string, index: number) => {
    const newProviders = [...providers]
    newProviders[index] = val
    setProviders(newProviders)
  }

  const removeProvider = (index: number) => {
    const newProviders = [...providers]
    newProviders.splice(index, 1)
    setProviders(newProviders)
  }

  useEffect(() => {
    handleChange(providers)
  }, [providers])

  const addOpme = (opme: any) => {
    if (selectedOpme.find((val) => val.opme_id === opme.id)) {
      toast.error('OPME já adicionado')
    } else {
      formik.setFieldValue('description', opme.description)
      formik.setFieldValue('opme_id', opme.id)
      setOpme(opme)
      setOpmeQuery(opme.description)
    }
  }

  const loadOpmes = async (description: string) => {
    try {
      const opmes = await surgicalOrderService.findAllOpmes({
        pagination: {
          pageNumber: 1,
          pageSize: 5
        },
        query: description
      })
      setOpmesList(opmes.data)
    } catch {
      toast.error('Erro ao carregar OPMEs')
    }
  }

  return (
    <S.Wrapper>
      {!onlyVisualization && (
        <>
          <S.Inputs>
            <S.InputButtonContainer>
              <AutoComplete
                label="OPME"
                labelColor="gray"
                placeholder="OPME"
                value={opmeQuery}
                onInputChange={setOpmeQuery}
                onSuggestionClick={addOpme}
                style={{ width: '100%' }}
                onType={(val) => loadOpmes(val)}
                suggestions={opmesList?.map((opme) => ({
                  label: opme.description,
                  value: opme
                }))}
                disabled={!!opme?.id}
                bgColor="mainBg"
                icon={<SearchIcon />}
                iconPosition="right"
                iconLocale="inside"
                required
                data-testid={`opme-description-autocomplete`}
              />
              {!!opme?.id && (
                <Button
                  backgroundColor="lightGray"
                  onClick={() => {
                    setOpme(undefined)
                    setOpmeQuery('')
                  }}
                  title="Limpar procedimento selecionado"
                >
                  <Backspace style={{ width: '30px', height: '24px' }} />
                </Button>
              )}
            </S.InputButtonContainer>
            <TextField
              label="Descrição"
              labelColor="gray"
              value={opme?.description || ''}
              placeholder="Nome do material"
              style={{ width: '120%', marginLeft: '12px' }}
              disabled
              data-testid={`tuss-description-disabled-input`}
            />
            <S.QuantityContainer>
              <S.Title>Quantidade</S.Title>
              <TextField
                onInputChange={formik.handleChange('quantity')}
                value={formik.values.quantity}
                type="number"
                bgColor="mainBg"
                data-testid={`opme-quantity-input`}
              />
            </S.QuantityContainer>

            <Button
              type="button"
              onClick={formik.submitForm}
              disabled={!formik.isValid}
              data-testid={`add-opme-button`}
            >
              Adicionar
            </Button>
          </S.Inputs>
        </>
      )}
      <Table
        checkbox={!onlyVisualization}
        data={selectedOpme}
        columns={opmeColumns}
        rowsDelete={onDeleteRow}
        pagination={false}
      />
      <S.Providers>
        {providers.map((provider, index) => (
          <>
            <div style={{ position: 'relative' }}>
              <TextField
                key={index + 1}
                bgColor="mainBg"
                label={'Fornecedor ' + (index + 1)}
                onInputChange={(val) => handleProvidersChange(val, index)}
                disabled={onlyVisualization}
                value={providers[index]}
                required={profile !== 'RECEPTIONIST' ? index === 0 : false}
                data-testid={`provider-${index}-input`}
              />
              {!onlyVisualization && index > 2 && (
                <S.RemoveProviderIcon
                  data-testid={`provider-${index}-delete-icon`}
                  onClick={() => removeProvider(index)}
                />
              )}
            </div>
          </>
        ))}
      </S.Providers>
      {!onlyVisualization && (
        <S.AddProviderWrapper>
          <Button
            onClick={() => setProviders([...providers, ''])}
            data-testid={`add-provider-button`}
            variant="outlined"
          >
            <S.AddProviderIcon />
            <p>Adicionar fornecedor</p>
          </Button>
        </S.AddProviderWrapper>
      )}
    </S.Wrapper>
  )
}

export const opmeColumns = [
  {
    label: 'Descrição',
    name: 'description',
    options: {
      sort: false
    }
  },
  {
    label: 'Quantidade',
    name: 'quantity',
    options: {
      sort: false
    }
  }
]

const validationSchema = yup.object().shape({
  quantity: yup.number().min(1),
  description: yup.string().required()
})

function dataCompare(prevData: Props, nextData: Props) {
  const prev = {
    opmes: prevData.selectedOpme
  }

  const next = {
    opmes: nextData.selectedOpme
  }

  return isEqual(prev, next)
}

export default React.memo(OpmeTable, dataCompare)
