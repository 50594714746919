import styled, { DefaultTheme } from 'styled-components'
import { lighten } from 'polished'

export const Wrapper = styled.div<{ isOpen: boolean }>`
  position: relative;
  width: 100%;

  button {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-left: auto;
  }
`

export const Suggestions = styled.ul<{
  bgColor?: keyof DefaultTheme['colors']
}>`
  position: absolute;
  max-height: 130px;
  overflow-y: auto;
  top: 100%;
  width: 100%;
  font-variant-numeric: tabular-nums;
  border-radius: 0 0 10px 10px;
  z-index: 2;
  background-color: ${({ theme, bgColor }) =>
    bgColor ? theme.colors[bgColor] : theme.colors.mainBg};

  li {
    font-size: ${({ theme }) => theme.font.sizes.medium};
    color: ${({ theme }) => theme.colors.gray};
    display: inline-block; /* Force the width to be respected. See also: inline-block */
    float: left; /* So it won't be 1-per-line. Not needed for inline-block */
    text-align: left;
    width: 100%;
    padding: 5px 15px;
    cursor: pointer;
    position: relative;
    transition: background-color 80ms;
    &:hover {
      background-color: ${({ theme }) => lighten(0.1, theme.colors.mainBg)};
    }

    img {
      height: 50px;
      display: block;
      margin: auto;
      margin-top: -7px;
      margin-bottom: -4px;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${({ theme, bgColor }) =>
      bgColor ? theme.colors[bgColor] : theme.colors.mainBg}};
  }
`
