type Props = {
  dateCheck?: string
  dateLimit?: string
}

export const alertInsuranceCardValidity = ({
  dateCheck,
  dateLimit
}: Props): string => {
  let message = ''
  const dateToCompare = dateLimit ? new Date(dateLimit) : new Date()
  const dateNow = handleFormatDate({ dateCheck })
  if (dateCheck && new Date(dateNow) < dateToCompare) {
    message = 'Atenção, data de validade expirada ou Inválida'
  }
  return message
}

const handleFormatDate = ({ dateCheck }: Props) => {
  let date = ''
  if (dateCheck && dateCheck.length >= 10) {
    const [day, month, year] = dateCheck.split('/')
    date = `${month}/${day}/${year}`
  }
  return date
}
