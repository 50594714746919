import React from 'react'
import { ReactComponent as SearchIcon } from 'presentation/assets/icons/search.svg'
import TextField from 'presentation/shared/components/TextField'
import * as S from './styles'
import { useFormik } from 'formik'
import { cleanObject } from 'common/utils/cleanObject'
import Button from 'presentation/shared/components/Button'

import { LoadSecretaryScheduleSurgicalOrdersSearch } from 'domain/usecases/secretary/load-secretary-schedule-surgical-orders'

type FilterProps = {
  filterSubmit: (filter: LoadSecretaryScheduleSurgicalOrdersSearch) => void
}

export default function FilterScheduleSurgicalOrders({
  filterSubmit
}: FilterProps) {
  const { handleChange, values, handleSubmit } = useFormik({
    initialValues: {} as LoadSecretaryScheduleSurgicalOrdersSearch,
    onSubmit: async (values) => {
      filterSubmit(cleanObject(values))
    }
  })

  return (
    <S.FiltersContainer onSubmit={handleSubmit}>
      <TextField
        name="patientName"
        id="patientName"
        bgColor="mainBg"
        placeholder="Paciente"
        iconPosition="left"
        icon={<SearchIcon />}
        iconMargin="50px"
        iconLocale="inside"
        onChange={handleChange}
        value={values.patientName || ''}
      />
      <TextField
        name="doctorName"
        id="doctorName"
        bgColor="mainBg"
        placeholder="Médico"
        iconPosition="left"
        iconMargin="50px"
        icon={<SearchIcon />}
        iconLocale="inside"
        onChange={handleChange}
        value={values.doctorName || ''}
      />
      <TextField
        name="specialty"
        id="specialty"
        bgColor="mainBg"
        placeholder="Especialidade"
        iconPosition="left"
        icon={<SearchIcon />}
        iconMargin="50px"
        iconLocale="inside"
        onChange={handleChange}
        value={values.specialty || ''}
      />
      <Button fullWidth type="submit">
        Buscar
      </Button>
    </S.FiltersContainer>
  )
}
