export interface ButtonNavItem {
  label: string
  path: string
  profile: string[]
  isAdmin?: boolean
}

export const buttonNavItems: ButtonNavItem[] = [
  {
    label: 'Adicionar paciente',
    path: '/paciente/save',
    profile: ['admin', 'crmo']
  },
  {
    label: 'Adicionar colaborador',
    path: '/colaboradores/save',
    profile: ['admin']
  },
  {
    label: 'Adicionar médico',
    path: '/medicos/save',
    profile: ['admin', 'crmo']
  },
  {
    label: 'Adicionar administrador',
    path: '/colaboradores/save',
    isAdmin: true,
    profile: ['admin']
  }
]
