export const cleanObject = <T = any>(values: T): T => {
  const newObject = values
  if (newObject) {
    Object.keys(newObject).forEach((key) => {
      const field = newObject[key as keyof typeof newObject]
      if (!field && String(field) !== 'false')
        delete newObject[key as keyof typeof newObject]
    })
  }
  return newObject
}
