import React, { useEffect, useState } from 'react'

import { toast } from 'react-toastify'
import moment from 'moment-timezone'
import { useHistory } from 'react-router-dom'

import TextField from 'presentation/shared/components/TextField'
import Button from 'presentation/shared/components/Button'
import { ReactComponent as SearchIcon } from 'presentation/assets/icons/search.svg'
import Table from 'presentation/shared/components/Table'
import { ReactComponent as DeleteIcon } from 'presentation/assets/icons/trash-gray.svg'
import Modal from 'presentation/shared/components/Modal'
import Heading from 'presentation/shared/components/Heading'
import { ReactComponent as CheckIcon } from 'presentation/assets/icons/check.svg'
import { useServices } from 'presentation/hooks/use-services'
import { LoadFavoriteSurgicalOrders } from 'domain/usecases/surgical-order/load-favorite-surgical-orders'
import { FavoriteSurgicalOrder } from 'domain/entities/favorite-surgical-order-model'
import normalizeText from 'common/utils/getNormalizedText'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import * as S from './styles'
import { SurgicalOrderProcedureModel } from 'domain/entities/surgical-order-procedure-model'
import { SurgicalOrderOpmeModel } from 'domain/entities/surgical-order-opme-model'
import { SurgicalOrderCidModel } from 'domain/entities/surgical-order-cid-model'
import { User } from 'domain/entities/user-model'

const FavoritesTable = WithLoading(({ setIsLoading }: WithLoadingProps) => {
  const [searchFavorite, setSearchFavorite] = useState('')
  const [favorites, setFavorites] = useState<Favorite[]>([])
  const [favoritesInitial, setFavoritesInitial] = useState<Favorite[]>([])
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [actualFavorite, setActualFavorite] = useState<Favorite>()
  const [selectedFavorite, setSelectedFavorite] = useState<SelectedFavorite[]>(
    []
  )

  const history = useHistory()

  const service = useServices().surgicalOrder

  const handleFilterFavorites = async () => {
    try {
      if (!searchFavorite) {
        await loadFavorites()
        return
      }
      setIsLoading(true)
      const filteredFavorites = favoritesInitial.filter(
        (favorite) =>
          normalizeText(favorite.name).includes(
            normalizeText(searchFavorite)
          ) ||
          favorite.procedure.includes(searchFavorite) ||
          favorite.procedure.includes(searchFavorite.toUpperCase()) ||
          favorite.cid.includes(searchFavorite) ||
          favorite.cid.includes(searchFavorite.toUpperCase())
      )
      setFavorites(filteredFavorites)
    } catch (err) {
      toast.error('Falha ao buscar favoritos')
    } finally {
      setIsLoading(false)
    }
  }

  const getTableFormattedData = () => {
    return favorites.map((favorite) => ({
      date: favorite.date,
      name: favorite.name,
      procedure: favorite.procedure,
      cid: favorite.cid,
      link_patient: (
        <S.LinkPatientButton onClick={() => handleLinkPatient(favorite)}>
          Criar Pedido Cirúrgico
        </S.LinkPatientButton>
      ),
      delete: (
        <S.DeleteButton onClick={() => handleDelete(favorite)}>
          <DeleteIcon />
        </S.DeleteButton>
      )
    }))
  }

  const handleLinkPatient = async (favorite: Favorite) => {
    selectedFavorite.forEach((selected) => {
      if (favorite.name === selected.name) {
        makeLocalStorageAdapter().set('favorite', selected)
      }
    })
    history.push('/pedido/novo')
  }

  const handleDelete = async (favorite: Favorite) => {
    setActualFavorite(favorite)
    setShowDeleteModal(true)
  }

  const handleRemoveActualFavorite = async () => {
    try {
      if (actualFavorite) {
        setShowDeleteModal(false)
        setIsLoading(true)
        await service.deleteFavoritedSurgicalOrder({
          data: {
            favorite_surgical_order_id: actualFavorite?.id
          }
        })
        await loadFavorites()
      }
    } catch (err: any) {
      toast.error(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSearchFavorite = (search: string) => {
    if (search === '') {
      setFavorites(favoritesInitial)
    }
    setSearchFavorite(search)
  }

  const loadFavorites = async () => {
    try {
      setIsLoading(true)
      const favorites = await service.loadFavoriteSurgicalOrders([
        'favorite_surgical_order_id',
        'name',
        'procedure {isMain,  description, procedure_code, doctor_name, quantity, isMain, pro_fat_id, surgery_id}',
        'opme {solicitations {description, quantity, opme_id}, providers}',
        'cid {code, description}',
        'createdAt'
      ])
      setFavoritesInitial(getFormattedFavorites(favorites))
      setFavorites(getFormattedFavorites(favorites))
      setSelectedFavorite(favorites)
    } catch (err: any) {
      toast.error(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  const getFormattedFavorites = (
    favorites: LoadFavoriteSurgicalOrders.Model
  ): Favorite[] => {
    return favorites.map((favorite) => {
      const procedure = getMainProcedure(favorite)
      const cid = getCid(favorite)
      return {
        id: favorite.favorite_surgical_order_id,
        date: moment(favorite.createdAt).format('DD/MM/YYYY'),
        name: favorite.name,
        procedure: procedure ? procedure.description : '',
        procedure_code: procedure ? procedure.procedure_code : '',
        doctor_name: procedure ? procedure.doctor_name : '',
        quantity: procedure ? procedure.quantity : '',
        isMain: procedure ? procedure.isMain : '',
        cid_id: favorite.cid.length > 0 ? favorite.cid[0].code : '',
        pro_fat_id: procedure ? procedure.pro_fat_id : '',
        surgery_id: procedure ? procedure.surgery_id : '',
        cid: cid ? cid.description : '',
        cid_code: cid ? cid.code : ''
      }
    })
  }

  const getMainProcedure = (favorite: FavoriteSurgicalOrder) => {
    return favorite.procedure.find((procedure) => procedure.isMain)
  }

  const getCid = (favorite: FavoriteSurgicalOrder) => {
    return favorite.cid.length > 0 ? favorite.cid[0] : undefined
  }

  useEffect(() => {
    loadFavorites()
  }, [])

  return (
    <S.Wrapper>
      <S.FiltersContainer>
        <S.FiltersInput>
          <TextField
            name="searchFavorite"
            id="searchFavorite"
            bgColor="mainBg"
            placeholder="Nome do favorito, procedimento ou CID."
            iconPosition="left"
            icon={<SearchIcon />}
            iconMargin="50px"
            iconLocale="inside"
            onChange={(e) => handleSearchFavorite(e.target.value)}
            value={searchFavorite}
            data-testid="input-search-favorite"
          />
        </S.FiltersInput>
        <S.FiltersButton>
          <Button
            data-testid="btn-search"
            type="button"
            onClick={() => handleFilterFavorites()}
          >
            Buscar
          </Button>
        </S.FiltersButton>
      </S.FiltersContainer>
      <S.FavoritesTableContainer>
        <Table
          columns={columns}
          data={getTableFormattedData()}
          pagination={false}
          noItemsDescription={'Nenhum favorito para exibir'}
        />
      </S.FavoritesTableContainer>
      <Modal
        show={showDeleteModal}
        close={() => setShowDeleteModal(false)}
        preventAutomateClose
        style={{
          width: 400
        }}
      >
        <S.DeleteModalWrapper>
          <CheckIcon />
          <Heading as="h1">Deseja excluir favorito?</Heading>
          <div>
            <Button
              variant="outlined"
              type="button"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancelar
            </Button>
            <Button type="button" onClick={() => handleRemoveActualFavorite()}>
              Sim
            </Button>
          </div>
        </S.DeleteModalWrapper>
      </Modal>
    </S.Wrapper>
  )
})

type Favorite = {
  id: number
  date: string
  name: string
  procedure: string
  procedure_code: string
  cid: string
  pro_fat_id: string
  surgery_id: string | number
  cid_code: string
}

type SelectedFavorite = {
  favorite_surgical_order_id: number
  name: string
  procedure: SurgicalOrderProcedureModel[]
  opme: SurgicalOrderOpmeModel
  cid: SurgicalOrderCidModel[]
  createdAt: Date
  updatedAt: Date
  user: User
}

const columns = [
  {
    name: 'date',
    label: 'Data',
    options: { filter: false, sort: false }
  },
  {
    name: 'name',
    label: 'Nome',
    options: { filter: false, sort: false }
  },
  {
    name: 'procedure',
    label: 'Procedimento',
    options: { filter: false, sort: false }
  },
  {
    name: 'cid',
    label: 'CID',
    options: { filter: false, sort: false }
  },
  {
    name: 'link_patient',
    label: ' ',
    options: { filter: false, sort: false }
  },
  {
    name: 'delete',
    label: ' ',
    options: { filter: false, sort: false }
  }
]

export default FavoritesTable
