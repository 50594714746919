import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'
import { ToastContainer } from 'react-toastify'
import 'reflect-metadata/Reflect.js'
import GlobalStyles from 'presentation/styles/global'
import theme from 'presentation/styles/theme'
import SecretaryRouter from 'main/routes/secretary-routes'

import 'react-toastify/dist/ReactToastify.min.css'

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <SecretaryRouter />
      <ToastContainer />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
