import styled from 'styled-components'

export const Wrapper = styled.div`
  height: auto;
  width: 900px;
  max-width: 80vw;
  padding: 42px 70px 40px 70px;
  display: flex;
  flex-direction: column;
`

export const InputsContainer = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 20px;

  button {
    width: auto;
    padding-left: 80px;
    padding-right: 80px;
  }
  @media (max-width: 770px) {
    flex-direction: column-reverse;
  }
`
