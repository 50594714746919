import { PendencyType } from 'domain/entities/surgical-pendency'
import { useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import { AnswerPendencyForm, PendencyDocument } from '..'
import DocumentsList from './DocumentsList'
import DocumentsUpload from './DocumentsUpload'
import * as S from './styles'

type PendencyDocumentsProps = {
  changeMode: () => void
  type: PendencyType
  changeTitle: (newTitle?: string) => void
}

export default function PendencyDocuments({
  changeMode,
  type,
  changeTitle
}: PendencyDocumentsProps) {
  const { values } = useFormikContext<AnswerPendencyForm>()
  const [newDocuments, setNewDocuments] = useState<PendencyDocument[]>(
    values.documents
  )
  const [selectedDocumentLabel, setSelectedDocumentLabel] = useState<string>()
  useEffect(() => {
    setNewDocuments(values.documents)
  }, [values.documents])

  const defaultDocuments = newDocuments.filter(
    (newDocument) => newDocument.label === selectedDocumentLabel
  )

  const onSave = (documentsAdded: PendencyDocument[], label: string) => {
    const labels = [
      ...documentsAdded.map((documentAdded) => documentAdded.label),
      label
    ]
    const filteredNewDocuments = newDocuments.filter(
      (newDocument) => !labels.includes(newDocument.label)
    )
    setNewDocuments([...filteredNewDocuments, ...documentsAdded])
  }

  return (
    <S.DocumentsContainer>
      {selectedDocumentLabel ? (
        <DocumentsUpload
          selectedDocumentLabel={selectedDocumentLabel}
          defaultDocuments={defaultDocuments}
          onGoBack={() => {
            setSelectedDocumentLabel(undefined)
            changeTitle(undefined)
          }}
          type={type}
          onSave={onSave}
        />
      ) : (
        <DocumentsList
          documents={newDocuments}
          changeMode={changeMode}
          setSelectedDocumentLabel={setSelectedDocumentLabel}
          type={type}
          changeTitle={changeTitle}
        />
      )}
    </S.DocumentsContainer>
  )
}
