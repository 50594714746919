import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  h1 {
    line-height: normal;
    margin-bottom: 0;
  }

  button {
    align-self: flex-end;
  }
`

export const Inputs = styled.div`
  display: flex;
  align-items: end;
  margin-bottom: 12px;

  div:last-child {
    margin: 0;
  }
`

export const Buttons = styled.div`
  display: flex;

  justify-content: flex-end;
  button:last-child {
    margin-left: 10px;
  }
`

export const AddPatient = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 500px;

  & div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
    font-size: ${({ theme }) => theme.font.sizes.small};
    & svg {
      width: 14px;
      height: 14px;
      margin-right: 4px;

      & path {
        fill: ${({ theme }) => theme.colors.primary};
        stroke: ${({ theme }) => theme.colors.primary};
      }
    }
  }

  h5 {
    margin-bottom: 15px;
  }
`

export const SearchInputWrapper = styled.div`
  display: flex;
  height: fit-content;
  position: relative;
  width: 50%;

  > div {
    margin-left: 1rem;
  }

  button {
    width: 10rem;
    margin-left: 1rem;
    border-radius: ${({ theme }) => theme.border.radius.xsmall};

    span {
      height: 30px;
    }

    svg {
      height: 16px;
      width: 16px;

      path {
        height: 16px;
        width: 16px;
        fill: white;
        opacity: 1;
      }

      #white {
        fill: none;
      }
    }
  }
`

export const NotFoundPatientMessage = styled.div`
  display: flex;
`

export const SectionWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const ChipsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  div {
    margin: 0.2rem;
  }
`
