import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-top: 24px;

  div {
    h6 {
      color: ${({ theme }) => theme.colors.primary};
      font-weight: ${({ theme }) => theme.font.bold};
      font-size: ${({ theme }) => theme.font.sizes.medium};
      display: inline;
    }
    span {
      color: ${({ theme }) => theme.colors.gray};
      font-weight: ${({ theme }) => theme.font.normal};
      font-size: ${({ theme }) => theme.font.sizes.medium};
    }
  }
`

export const DataOverview = styled.div`
  display: grid;
  grid-template-columns: 0.7fr 1fr;
  gap: 2px 40px;
`

export const ProceduresWrapper = styled.div`
  width: 100%;
  & + & {
    margin-top: 12px;
  }
`

export const ProvidersWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px 40px;
  margin-top: 12px;
  margin-bottom: 20px;
`
export const DocumentsContainer = styled.div``

export const HeadingWrapper = styled(DataOverview)`
  grid-template-columns: 0.7fr 0fr;
  padding-right: 30px;
`
export const Edit = styled.p`
  align-self: center;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.font.semiBold};
  font-size: ${({ theme }) => theme.font.sizes.medium};
  text-decoration: underline ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`
