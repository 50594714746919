import styled from 'styled-components'

export const Wrapper = styled.header`
  height: 90px;
  grid-area: header;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: ${({ theme }) => theme.colors?.white};
  padding-right: 51px;
  flex-shrink: 0;
`

export const Content = styled.div`
  padding-left: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  h5 {
    color: ${({ theme }) => theme.colors?.primary};
    font-size: ${({ theme }) => theme.font?.sizes.medium};
    font-weight: 600;
  }

  select {
    margin: 0;
    color: ${({ theme }) => theme.colors?.primary};
    font-weight: ${({ theme }) => theme.font?.bold};
  }
`

export const UserWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 32px;
`

export const LogoWrapper = styled.div`
  width: 266px;
  cursor: pointer;
`

export const UserInfo = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 24px;

  strong {
    font-weight: 600;
    font-size: ${({ theme }) => theme.font?.sizes.medium};
    color: ${({ theme }) => theme.colors?.primary};
  }

  label {
    font-weight: 600;
    font-size: ${({ theme }) => theme.font?.sizes.small};
    color: ${({ theme }) => theme.colors?.lightGray};
  }
`

export const NewSurgicalOrder = styled.div`
  background: ${({ theme }) => theme.colors.secondary};
  border-radius: 8px;
  height: 48px;
  padding: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  margin-left: 1rem;
  margin-left: auto;

  span {
    font-weight: 600;
    font-size: 18px;
    color: white;
  }
`

export const HeaderButton = styled.div`
  background: ${({ theme }) => theme.colors.secondary};
  border-radius: 8px;
  height: 48px;
  padding: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-width: 235px;
  margin-left: auto;

  span {
    font-weight: 600;
    font-size: 18px;
    color: white;
  }
`

export const AddButton = styled.div`
  background: ${({ theme }) => theme.colors.secondary};
  border-radius: 10px;
  height: 37px;
  width: 37px;
  padding: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 1rem;

  span {
    font-weight: 600;
    font-size: 18px;
    color: white;
  }
`

export const Options = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`

export const IconWrapper = styled.div`
  min-width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    cursor: pointer;
    font-size: 32px;
    color: #a0a49f;
  }
`
