import { ListItem } from 'presentation/shared/components/List'
import CheckIcon from 'presentation/assets/icons/big-check.svg'
import ArrowIcon from 'presentation/assets/icons/arrow-right.svg'
import { PendencyDocument } from '../../..'
import { PendencyType } from 'domain/entities/surgical-pendency'
import { DocumentsType, DocumentType } from 'domain/entities/document-types'

export const adaptDocumentToList = (
  documentType: DocumentType,
  defaultDocuments: PendencyDocument[],
  onClick: (label: string) => void
): ListItem => {
  return {
    title: documentType.label,
    click: () => onClick(documentType.label),
    icon: defaultDocuments.some(
      (document) => document.label === documentType.label
    )
      ? CheckIcon
      : ArrowIcon
  }
}

export const getItemsAdapted = (
  type: PendencyType,
  defaultDocuments: PendencyDocument[],
  onClick: (label: string) => void
): ListItem[] => {
  const initialDocuments = DocumentsType.getPendenciesDocuments()[type]
  const initialDocumentsAdapted = (initialDocuments || []).map(
    (initialDocument) =>
      adaptDocumentToList(initialDocument, defaultDocuments, onClick)
  )
  defaultDocuments.forEach((document) => {
    const alreadyAdapted = initialDocumentsAdapted.find(
      (initialDocument) => initialDocument.title === document.label
    )
    if (!alreadyAdapted) {
      initialDocumentsAdapted.push(
        adaptDocumentToList(
          {
            ...DocumentsType.examReportDocumentType,
            label: document.label,
            type: [document.type]
          },
          defaultDocuments,
          onClick
        )
      )
    }
  })
  return initialDocumentsAdapted
}
