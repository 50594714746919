import { PaginationModel } from 'domain/entities/pagination-model'
import {
  PendencyType,
  SurgicalPendency
} from 'domain/entities/surgical-pendency'
import { LoadSecretaryPendencies } from 'domain/usecases/secretary/load-secretary-pendencies'
import { useServices } from 'presentation/hooks/use-services'
import TypedPendenciesLayout from 'presentation/secretary/layouts/TypedPendencies'
import { typedPendenciesFields } from 'presentation/secretary/utils/TypedPendenciesTable/typedPendenciesFields'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Pagination } from 'service/protocols/api/api-client'

const PENDENCIES_PER_PAGE_DEFAULT = 10
const CURRENT_PAGE_DEFAULT = 1
const TOTAL_ITEMS_DEFAULT = 0
const TOTAL_PAGES_DEFAULT = 0

export const SecretaryTypedPendencies = () => {
  const [loadingPendencies, setLoadingPendencies] = useState<boolean>(false)
  const [pendencies, setPendencies] = useState<SurgicalPendency[]>([])
  const [pagination, setPagination] = useState<PaginationModel>({
    currentPage: CURRENT_PAGE_DEFAULT,
    itemsPerPage: PENDENCIES_PER_PAGE_DEFAULT,
    totalItems: TOTAL_ITEMS_DEFAULT,
    totalPages: TOTAL_PAGES_DEFAULT
  })

  const { type } = useParams<{ type: 'assistencial' | 'autorizacao' }>()

  const secretaryService = useServices().secretary

  async function loadData(
    page: number,
    filter?: LoadSecretaryPendencies.Params['filters'],
    itemsPerPage?: number
  ) {
    setLoadingPendencies(true)
    try {
      const newPagination: Pagination = {
        pageNumber: page,
        pageSize: pagination.itemsPerPage
      }
      if (itemsPerPage) {
        if (itemsPerPage !== pagination.itemsPerPage) {
          newPagination.pageNumber = 1
          newPagination.pageSize = itemsPerPage
        }
      }
      const pendencies = await secretaryService.loadSecretaryPendencies({
        fields: typedPendenciesFields,
        filters: {
          ...filter,
          type: langType[type].enum
        },
        pagination: newPagination
      })

      setPendencies(pendencies.data)
      setPagination(pendencies.pageInfo)
    } catch (err: any) {
      toast.error(err.message)
    } finally {
      setLoadingPendencies(false)
    }
  }

  useEffect(() => {
    loadData(1)
  }, [])

  return (
    <TypedPendenciesLayout
      pendencies={pendencies}
      pagination={pagination}
      refreshPendencies={loadData}
      type={langType[type].enum}
      loadingPendencies={loadingPendencies}
    />
  )
}

const langType: {
  [name in string]: {
    enum: PendencyType
  }
} = {
  autorizacao: {
    enum: PendencyType.AUTHORIZATION
  },
  assistencial: {
    enum: PendencyType.ASSISTANCE
  }
}
