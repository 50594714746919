import styled from 'styled-components'

export const Wrapper = styled.div``

export const FiltersContainer = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 0.3fr;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
  margin-bottom: 20px;
  border-radius: 6px;
  padding: 40px 30px;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-gap: 12px;
  }
`

export const FiltersInput = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;
  place-content: center;

  div:first-child {
    grid-column: span 2;
  }
`

export const FiltersButton = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  padding: 0 1rem;

  button {
    margin-left: 12px;
  }

  @media (max-width: 1024px) {
    padding: 0;
    justify-content: flex-end;

    button {
      margin-left: 0;
    }
  }
`

export const FavoritesTableContainer = styled.div``

export const LinkPatientButton = styled.button`
  background: none;
  border-radius: 40px;
  padding: 10px;
  border: ${({ theme }) => '2px solid' + theme.colors.primary};
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 500;
  font-size: ${({ theme }) => theme.font.sizes.small};
  cursor: pointer;
  transition: background-color 0.5s ease, color 0.5s ease;

  :hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: #fff;
  }
`

export const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: 0;
  cursor: pointer;

  svg {
    height: 24px;
    width: 24px;
  }
`

export const DeleteModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 210px;
  width: 100%;

  img {
    height: 54px;
    width: 54px;
  }

  h1 {
    margin: 24px 0;
  }

  div {
    display: flex;
    justify-content: space-between;
    width: 100%;

    button {
      width: 48%;
    }
  }
`
