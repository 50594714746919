import React, { useEffect, useState } from 'react'

import { useHistory, useLocation } from 'react-router-dom'
import moment from 'moment-timezone'
import { toast } from 'react-toastify'

import Container from 'presentation/hospital/components/Container'
import ActualPage from 'presentation/shared/components/ActualPage'
import { TimelineStatusItem } from 'presentation/shared/components/NewTimeline'
import { PatientDocument } from 'common/enum/patient-document'
import { ItemText } from 'presentation/shared/components/List/styles'
import { ListItem } from 'presentation/shared/components/List'
import AlertIcon from 'presentation/assets/icons/alert.svg'
import ArrowIcon from 'presentation/assets/icons/arrow-right.svg'
import DownloadIcon from 'presentation/assets/icons/download.svg'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import { WithLoading } from 'presentation/shared/components/HOCs/WithLoading'
import { useServices } from 'presentation/hooks/use-services'

import { OverviewPanel } from 'presentation/secretary/components/SurgicalOrderDetails/OverviewPanel'
import { TimelineStatus } from 'presentation/secretary/components/SurgicalOrderDetails/TimelineStatus'
import { DocumentsPanel } from 'presentation/secretary/components/SurgicalOrderDetails/DocumentsPanel'
import { ContactPanel } from 'presentation/secretary/components/SurgicalOrderDetails/ContactPanel'
import { timelineStatus } from './contants'

import * as S from './styles'
import { ListFile } from 'presentation/shared/components/UploadSection/UploadListFiles/UploadListFile'
import { getThumbnail } from 'presentation/shared/components/UploadSection/Functions'
import { UploadSurgicalDocumentsModal } from 'presentation/secretary/components/UploadSurgicalDocumentsModal'
import downloadFileFromBlob from 'common/utils/downloadFileFromBlob'
import { getDocumentsLabelLocationByEnum } from 'presentation/utils/document-types-location'
import { useStores } from 'presentation/hooks/use-stores'

type SurgicalLocation = {
  id: number
}

export type DocumentItem = {
  document_id?: number
  type: PatientDocument
  file: File
  data: Blob
  contentType: string
  fileThumb: string
}

const CAN_UPLOAD = true
const ONLY_DOWNLOAD = true

export type SurgicalDocumentListFile = ListFile<{ document_id?: number }>

type ShowModalType = {
  initialValues: SurgicalDocumentListFile[]
  type: PatientDocument
}

export const SurgicalOrderDetailsLayout = WithLoading(({ setIsLoading }) => {
  const surgicalStatusStore = useStores().surgicalstatus
  const [order, setOrder] = useState<SurgicalOrderModel>()
  const [surgicalStatus, setSurgicalStatus] = useState([])
  const [showUploadModal, setShowUploadModal] = useState<ShowModalType>()

  const history = useHistory()
  const location = useLocation<SurgicalLocation>()

  const surgicalOrderService = useServices().surgicalOrder

  const getStatusIconByType = (type: PatientDocument, canUpload?: boolean) => {
    const alreadyUploaded = order?.documents?.find(
      (document) => type === (document.type as PatientDocument)
    )
    if (alreadyUploaded) {
      return DownloadIcon
    }
    if (canUpload) {
      return ArrowIcon
    }
    return AlertIcon
  }

  const getDocumentsByType = (type: PatientDocument) => {
    const documents = order?.documents?.filter(
      (document) => type === (document.type as PatientDocument)
    )
    return documents ?? []
  }

  const getDocumentsFileByType = async (
    type: PatientDocument
  ): Promise<SurgicalDocumentListFile[]> => {
    try {
      setIsLoading(true)
      const documents = getDocumentsByType(type)
      if (order?.surgical_order_id && documents) {
        const documentsFile: SurgicalDocumentListFile[] = await Promise.all(
          documents.map(async (document, index) => {
            const surgicalOrderDocument =
              await surgicalOrderService.loadSurgicalOrderDocument({
                surgical_order_id: Number(order?.surgical_order_id),
                document_id: document.document_id
              })
            const file = new File([surgicalOrderDocument.data], document.name, {
              type: surgicalOrderDocument.contentType
            })
            const thumbnail = await getThumbnail(file)
            const listFile: SurgicalDocumentListFile = {
              file,
              identifier: {
                document_id: document.document_id
              },
              page: index + 1,
              thumbnail,
              fileCan: {
                canDownload: true,
                canUpdate: false,
                canDelete: false,
                canPreview: true
              }
            }
            return listFile
          })
        )
        return documentsFile
      }
    } catch (err) {
      toast.error('Falha ao recuperar os arquivos')
    } finally {
      setIsLoading(false)
    }
    return []
  }

  const getExpectedDate = (): string => {
    if (order?.surgeryDate)
      return moment(order?.surgeryDate).utc().format('DD/MM/YYYY')
    else if (order?.expectedDate.length)
      return moment(order?.expectedDate[0]).utc().format('DD/MM/YYYY')
    else return ''
  }

  useEffect(() => {
    const integrationStatus = surgicalStatusStore.getStatus()
    const allStatus = [
      {
        internalStatus: 'Revisão'
      },
      ...integrationStatus
    ]

    const status = allStatus.map((status) => {
      const foundStatus = order?.status?.find(
        (stat) => stat.status === status.internalStatus
      )
      if (foundStatus) {
        return foundStatus
      }
      return {
        status: status.internalStatus,
        isConcluded: false,
        isActive: false
      }
    })
    setSurgicalStatus(status)
  }, [order])

  const downloadFiles = (
    files: SurgicalDocumentListFile[],
    type: PatientDocument
  ) => {
    files.forEach(({ file }) => {
      const name = getDocumentsLabelLocationByEnum(type)
      downloadFileFromBlob(file, file.type, name)
    })
  }

  const handleShowDocumentsModal = async (
    type: PatientDocument,
    onlyDownload?: boolean
  ) => {
    const initialValues = await getDocumentsFileByType(type)
    if (onlyDownload) {
      downloadFiles(initialValues, type)
      return
    }
    setShowUploadModal({
      initialValues,
      type
    })
  }

  async function loadData() {
    setIsLoading(true)
    try {
      const loadedSurgicalOrder = await surgicalOrderService.loadSurgicalOrder(
        Number(location.state.id),
        [
          'patient {name}',
          'hospital {name, hospital_id}',
          'surgical_order_id',
          'expectedDate',
          'surgeryDate',
          'hospitalizationType',
          'documents {document_id, type, name, documentUri}',
          'procedure {isMain,procedure_code,code,description,quantity,doctor_name}',
          'status {status, isConcluded, updatedAt, isActive}'
        ]
      )
      setOrder(loadedSurgicalOrder)
    } catch (e) {
      return
    } finally {
      setIsLoading(false)
    }
  }

  const documentItems: ListItem[] = [
    {
      title: <ItemText>Planejamento cirúrgico</ItemText>,
      icon: getStatusIconByType(PatientDocument.GUIDE_SURGICAL_PLANNING),
      click: () =>
        handleShowDocumentsModal(
          PatientDocument.GUIDE_SURGICAL_PLANNING,
          ONLY_DOWNLOAD
        )
    },
    {
      title: <ItemText>Carteira de identidade</ItemText>,
      icon: getStatusIconByType(PatientDocument.IDENTITY_CARD, CAN_UPLOAD),
      click: () => handleShowDocumentsModal(PatientDocument.IDENTITY_CARD)
    },
    {
      title: <ItemText>Carteira de convênio</ItemText>,
      icon: getStatusIconByType(PatientDocument.HEALTH_CARD, CAN_UPLOAD),
      click: () => handleShowDocumentsModal(PatientDocument.HEALTH_CARD)
    },
    {
      title: <ItemText>Laudos dos exames</ItemText>,
      icon: getStatusIconByType(PatientDocument.EXAM_REPORT, CAN_UPLOAD),
      click: () => handleShowDocumentsModal(PatientDocument.EXAM_REPORT)
    },
    {
      title: <ItemText>Avaliação pré-anestésica</ItemText>,
      icon: getStatusIconByType(
        PatientDocument.PRE_ANESTHETIC_EVALUATION,
        CAN_UPLOAD
      ),
      click: () =>
        handleShowDocumentsModal(PatientDocument.PRE_ANESTHETIC_EVALUATION)
    },
    {
      title: <ItemText>Relatório médico</ItemText>,
      icon: getStatusIconByType(PatientDocument.MEDICAL_REPORT, CAN_UPLOAD),
      click: () => handleShowDocumentsModal(PatientDocument.MEDICAL_REPORT)
    },
    {
      title: <ItemText>Consent. cirúrgico</ItemText>,
      icon: getStatusIconByType(PatientDocument.SURGICAL_CONSENT),
      click: () =>
        handleShowDocumentsModal(
          PatientDocument.SURGICAL_CONSENT,
          ONLY_DOWNLOAD
        )
    },
    {
      title: <ItemText>Consent. anestésico</ItemText>,
      icon: getStatusIconByType(PatientDocument.ANESTHETIC_CONSENT),
      click: () =>
        handleShowDocumentsModal(
          PatientDocument.ANESTHETIC_CONSENT,
          ONLY_DOWNLOAD
        )
    }
  ]

  useEffect(() => {
    loadData()
  }, [])

  return (
    <Container>
      <S.Wrapper>
        <ActualPage
          text="Voltar"
          onClick={history.goBack}
          data-testid="btn-go-back"
        />
        <S.Content>
          <OverviewPanel
            surgicalOrder={order}
            getExpectedDate={getExpectedDate}
            data-testid="card-overview"
          />
          <S.Details>
            <TimelineStatus
              timelineItems={surgicalStatus}
              data-testid="card-timeline-status"
            />
            <DocumentsPanel
              documentItems={documentItems}
              data-testid="card-documents"
            />
          </S.Details>
        </S.Content>
      </S.Wrapper>
      {showUploadModal && order?.surgical_order_id && (
        <UploadSurgicalDocumentsModal
          initialValues={showUploadModal.initialValues}
          type={showUploadModal.type}
          onClose={() => setShowUploadModal(undefined)}
          refreshData={loadData}
          surgical_order_id={order.surgical_order_id}
        />
      )}
    </Container>
  )
})
