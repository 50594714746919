import styled from 'styled-components'

export const FiltersContainer = styled.form`
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  margin-bottom: 20px;
  border-radius: 6px;
  padding: 30px 20px;
  gap: 16px;

  button {
    margin-top: 12px;
  }

  @media (max-width: 1400px) {
    grid-template-columns: 1fr;
    grid-gap: 12px;
  }
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`

export const FiltersInput = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 0.7fr 0.3fr;
  height: 100%;
  place-content: center;
  gap: 16px;

  margin-top: -10px;

  @media (max-width: 1850px) {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
  button {
    margin-top: 0;
  }
`
