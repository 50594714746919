import React from 'react'
import { ReactComponent as SearchIcon } from 'presentation/assets/icons/search.svg'
import TextField from 'presentation/shared/components/TextField'
import * as S from './styles'
import { useFormik } from 'formik'
import { cleanObject } from 'common/utils/cleanObject'
import Button from 'presentation/shared/components/Button'
import { LoadSecretarySurgicalOrdersSearch } from 'domain/usecases/secretary/load-secretary-surgical-order'
import { chipsFilters } from './Constants'
import Chip from 'presentation/shared/components/Chip'

type FilterProps = {
  filterSubmit: (filter: LoadSecretarySurgicalOrdersSearch) => void
  onCleanFilter: () => void
}

export default function FilterSurgicalOrders({
  filterSubmit,
  onCleanFilter
}: FilterProps) {
  const { handleChange, values, handleSubmit, resetForm } = useFormik({
    initialValues: {} as LoadSecretarySurgicalOrdersSearch,
    onSubmit: async (values) => {
      filterSubmit(cleanObject(values))
    }
  })

  return (
    <S.FiltersContainer onSubmit={handleSubmit}>
      <S.FiltersInput>
        <TextField
          name="patientName"
          id="patientName"
          bgColor="mainBg"
          placeholder="Busca por pacientes"
          iconPosition="left"
          icon={<SearchIcon />}
          iconMargin="50px"
          iconLocale="inside"
          onChange={handleChange}
          value={values.patientName || ''}
        />
        <TextField
          name="doctorName"
          id="doctorName"
          bgColor="mainBg"
          placeholder="Busca por médico"
          iconPosition="left"
          iconMargin="50px"
          icon={<SearchIcon />}
          iconLocale="inside"
          onChange={handleChange}
          value={values.doctorName || ''}
        />
        <TextField
          name="specialty"
          id="specialty"
          bgColor="mainBg"
          placeholder="Especialidade"
          onChange={handleChange}
          icon={<SearchIcon />}
          iconPosition="left"
          iconMargin="50px"
          iconLocale="inside"
          value={values.specialty || ''}
        />
        <TextField
          name="healthInsuranceName"
          id="healthInsuranceName"
          bgColor="mainBg"
          placeholder="Convênio"
          icon={<SearchIcon />}
          iconPosition="left"
          iconMargin="50px"
          iconLocale="inside"
          onChange={handleChange}
          value={values.healthInsuranceName || ''}
        />
      </S.FiltersInput>

      <S.FiltersButton>
        <Button
          fullWidth
          type="button"
          onClick={() => {
            resetForm({
              values: {}
            })
            onCleanFilter()
          }}
          variant="outlined"
        >
          Limpar
        </Button>
        <Button fullWidth type="submit">
          Buscar
        </Button>
      </S.FiltersButton>
    </S.FiltersContainer>
  )
}
