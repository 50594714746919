import React from 'react'
import { useFormik } from 'formik'
import Heading from 'presentation/shared/components/Heading'
import * as S from './styles'
import ServiceOverview, {
  ServiceOverviewProps
} from 'presentation/shared/components/ServiceOverview'
import TextArea from 'presentation/shared/components/TextArea'
import Checkbox from 'presentation/shared/components/Checkbox'
import Button from 'presentation/shared/components/Button'
import SupportText from 'presentation/shared/components/SupportText'
import Modal from 'presentation/shared/components/Modal'
import { useState } from 'react'
import { useServices } from 'presentation/hooks/use-services'
import { ConcludeSurgicalOrder } from 'domain/usecases/surgical-order/conclude-surgical-order'
import { toast } from 'react-toastify'
import TextField from 'presentation/shared/components/TextField'
import { phoneMask } from 'presentation/utils/masks'

import * as yup from 'yup'

type Props = {
  surgery?: ServiceOverviewProps
  surgicalOrderId?: number
  close: () => void
  onSuccess?: () => void
}

export default function ChangeOrderStatusModal({
  surgery,
  surgicalOrderId,
  close,
  onSuccess
}: Props) {
  const [handleModal, setHandleModal] = useState<boolean>(false)
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const surgicalOrderService = useServices().surgicalOrder
  const defaultValue = {
    email: false,
    sms: false
  }
  const formik = useFormik({
    initialValues: {
      notifyPatientBy: defaultValue,
      notifySecretariesBy: defaultValue,
      notifyDoctorBy: defaultValue,
      phone: {
        doctor: surgery?.doctorPhone || '',
        patient: surgery?.patientPhone || '',
        secretary: ''
      },
      email: {
        doctor: surgery?.doctorEmail || '',
        patient: surgery?.patientEmail || '',
        secretary: ''
      }
    } as ConcludeSurgicalOrder.Params['data'],
    validationSchema,
    validateOnMount: true,
    onSubmit: async (values) => {
      try {
        setIsSubmitted(true)
        await surgicalOrderService.concludeSurgicalOrder({
          data: {
            ...values,
            phone: {
              doctor: values.phone.doctor.replace(/\D+/g, ''),
              patient: values.phone.patient.replace(/\D+/g, ''),
              secretary: values.phone.secretary.replace(/\D+/g, '')
            },
            surgical_order_id: Number(surgicalOrderId) ?? 0
          }
        })
        await onSuccess?.()
        setHandleModal(true)
      } catch (error) {
        toast.error(error?.message)
        setIsSubmitted(false)
      }
    }
  })

  const onCheck = (value: boolean, field: string) => {
    formik.setFieldValue(field, value)
  }

  return (
    <S.Wrapper>
      <S.Container onSubmit={formik.handleSubmit}>
        <Heading size="huge" color="primary">
          Autorizar Pedido
        </Heading>
        <SupportText style={{ marginBottom: '20px' }} color="lightGray">
          Notificar médico e paciente quanto a finalização do pedido
        </SupportText>
        <ServiceOverview {...surgery} />
        <S.CheckboxWrapper>
          <p>Notificar:</p>
          <S.Persona>
            <S.PersonaLabel>Paciente:</S.PersonaLabel>
            <Checkbox
              label="E-mail"
              name="sendPatientEmail"
              labelFor="sendPatientEmail"
              onCheck={(status) => onCheck(status, 'notifyPatientBy.email')}
            />
            <Checkbox
              label="SMS"
              name="sendPatientSms"
              labelFor="sendPatientSms"
              onCheck={(status) => onCheck(status, 'notifyPatientBy.sms')}
            />
            <TextField
              placeholder="E-mail"
              error={
                formik.touched.email?.patient && formik.errors.email?.patient
              }
              name="email.patient"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              defaultValue={surgery?.patientEmail}
            />
            <TextField
              placeholder="Telefone"
              error={
                formik.touched.phone?.patient && formik.errors.phone?.patient
              }
              name="phone.patient"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              mask={phoneMask}
              defaultValue={surgery?.patientPhone || '+55'}
            />
          </S.Persona>
          <S.Persona>
            <S.PersonaLabel>Médico:</S.PersonaLabel>
            <Checkbox
              label="E-mail"
              name="sendDoctorEmail"
              labelFor="sendDoctorEmail"
              onCheck={(status) => onCheck(status, 'notifyDoctorBy.email')}
            />
            <Checkbox
              label="SMS"
              name="sendDoctorSms"
              labelFor="sendDoctorSms"
              onCheck={(status) => onCheck(status, 'notifyDoctorBy.sms')}
            />
            <TextField
              placeholder="E-mail"
              error={
                formik.touched.email?.doctor && formik.errors.email?.doctor
              }
              name="email.doctor"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              defaultValue={surgery?.doctorEmail}
            />
            <TextField
              placeholder="Telefone"
              error={
                formik.touched.phone?.doctor && formik.errors.phone?.doctor
              }
              name="phone.doctor"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              mask={phoneMask}
              defaultValue={surgery?.doctorPhone || '+55'}
            />
          </S.Persona>
          <S.Persona>
            <S.PersonaLabel>Secretária:</S.PersonaLabel>
            <Checkbox
              label="E-mail"
              name="sendSecretaryEmail"
              labelFor="sendSecretaryEmail"
              onCheck={(status) => onCheck(status, 'notifySecretariesBy.email')}
            />
            <Checkbox
              label="SMS"
              name="sendSecretarySms"
              labelFor="sendSecretarySms"
              onCheck={(status) => onCheck(status, 'notifySecretariesBy.sms')}
            />
            <TextField
              placeholder="E-mail"
              error={
                formik.touched.email?.secretary &&
                formik.errors.email?.secretary
              }
              name="email.secretary"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <TextField
              placeholder="Telefone"
              error={
                formik.touched.phone?.secretary &&
                formik.errors.phone?.secretary
              }
              name="phone.secretary"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              mask={phoneMask}
              defaultValue={'+55'}
            />
          </S.Persona>
        </S.CheckboxWrapper>
        <TextArea
          label="Observação"
          placeholder="Observações"
          bgColor="lightestGray"
          rows={4}
          style={{ marginTop: '30px', marginBottom: '10px' }}
          onInputChange={formik.handleChange('observation')}
          required
        />
        <S.Footer>
          <Button
            style={{ alignSelf: 'flex-end' }}
            type="button"
            onClick={close}
          >
            Cancelar
          </Button>
          <Button
            style={{ alignSelf: 'flex-end' }}
            type="submit"
            disabled={isSubmitted || !formik.isValid}
          >
            Autorizar pedido
          </Button>
        </S.Footer>
      </S.Container>
      <Modal
        title="Pedido finalizado com sucesso!"
        close={close}
        show={handleModal}
      />
    </S.Wrapper>
  )
}

const validationSchema = yup.object().shape({
  observation: yup.string().required(),
  email: yup.object().shape({
    doctor: yup.string().email().optional(),
    patient: yup.string().email().optional(),
    secretary: yup.string().email().optional()
  }),
  phone: yup.object().shape({
    doctor: yup
      .string()
      .test('masked-phone-validation', 'Telefone inválido', function (val) {
        return !!(
          val?.match(/^(?:\+)[0-9]{2}\s?[0-9]{2}\s?[0-9]{9}$/) ||
          val?.match(/^[0-9]{13}$/) ||
          !val
        )
      })
      .optional(),
    patient: yup
      .string()
      .test('masked-phone-validation', 'Telefone inválido', function (val) {
        return !!(
          val?.match(/^(?:\+)[0-9]{2}\s?[0-9]{2}\s?[0-9]{9}$/) ||
          val?.match(/^[0-9]{13}$/) ||
          !val
        )
      })
      .optional(),
    secretary: yup
      .string()
      .test('masked-phone-validation', 'Telefone inválido', function (val) {
        return !!(
          val?.match(/^(?:\+)[0-9]{2}\s?[0-9]{2}\s?[0-9]{9}$/) ||
          val?.match(/^[0-9]{13}$/) ||
          !val
        )
      })
      .optional()
  })
})
