import React from 'react'

import Heading from 'presentation/shared/components/Heading'
import SupportText from 'presentation/shared/components/SupportText'
import List, { ListItem } from 'presentation/shared/components/List'

import * as S from './styles'

type DocumentsPanelProps = {
  documentItems: ListItem[]
}

export const DocumentsPanel = ({ documentItems }: DocumentsPanelProps) => {
  return (
    <S.Wrapper>
      <Heading size="large" color="primary" as="h2">
        Documentos
      </Heading>
      <SupportText>Faça a gestão dos documentos dos procedimentos</SupportText>
      <S.DocumentsList data-testid="list-documents">
        <List items={documentItems} size={'small'} />
      </S.DocumentsList>
    </S.Wrapper>
  )
}
