import React, { useState } from 'react'
import { useFormik } from 'formik'
import Modal from 'presentation/shared/components/Modal'
import Button from 'presentation/shared/components/Button'
import TextField from 'presentation/shared/components/TextField'
import Heading from 'presentation/shared/components/Heading'
import * as S from './styles'
import { PatientHealthInsurance } from 'domain/entities/patient-model'
import moment from 'moment-timezone'
import { HealthInsurancePlans } from 'domain/usecases/health-insurance/load-health-insurance-plans'
import SelectField from 'presentation/shared/components/SelectField'
import { dateMask } from 'presentation/utils/masks'

type Props = {
  showModal(show: boolean): void
  insuranceData: PatientHealthInsurance
  changeInsuranceData: (data: ModifiedInsuranceData) => void
  insurances?: HealthInsurancePlans[]
}

export default function EditInsuranceModal({
  showModal,
  insuranceData,
  insurances,
  changeInsuranceData
}: Props) {
  const [modalVisible] = useState(true)
  const validThru = insuranceData.validThru
    ? moment(insuranceData.validThru).utc(true).format('DD/MM/YYYY')
    : ''

  const formik = useFormik({
    initialValues: {
      company: insuranceData.company,
      healthCard: insuranceData.healthCard,
      healthInsuranceCode: insuranceData.healthInsuranceCode,
      healthInsuranceName: insuranceData.healthInsuranceName,
      healthPlanCode: insuranceData.healthPlanCode,
      healthPlanName: insuranceData.healthPlanName,
      healthSubPlanName: insuranceData.healthSubPlanName,
      healthSubPlanCode: insuranceData.healthSubPlanCode,
      validThru: insuranceData.validThru,
      ansRegister: insuranceData.ansRegister
    },
    onSubmit: async () => null
  })

  const getInsuranceSelected = () => {
    return (insurances || []).find(
      (insurance) => insurance.code === formik.values.healthInsuranceCode
    )
  }

  const plans = getInsuranceSelected()?.plans || []

  const getSelectedPlan = () => {
    return plans.find((plan) => plan.code === formik.values.healthPlanCode)
  }

  const subPlans = getSelectedPlan()?.subPlan || []

  const handleInsuranceChange = (id: string) => {
    const insurance = insurances?.find((val) => val.code.toString() === id)
    formik.setFieldValue('healthInsuranceCode', insurance?.code)
    formik.setFieldValue('healthInsuranceName', insurance?.description)
    formik.setFieldValue('ansRegister', insurance?.ansRegister)
    formik.setFieldValue('healthPlanName', undefined)
    formik.setFieldValue('healthPlanCode', undefined)
    formik.setFieldValue('healthSubPlanCode', undefined)
    formik.setFieldValue('healthSubPlanName', undefined)
  }

  const handlePlanChange = (code: number) => {
    const plan = plans.find((val) => val.code === code)
    formik.setFieldValue('healthPlanName', plan?.description)
    formik.setFieldValue('healthPlanCode', plan?.code)
    formik.setFieldValue('healthSubPlanCode', undefined)
    formik.setFieldValue('healthSubPlanName', undefined)
  }

  const handleSubPlanChange = (subplanCode: number) => {
    const subplan = subPlans.find((val) => val.code === subplanCode)
    formik.setFieldValue('healthSubPlanCode', subplan?.code)
    formik.setFieldValue('healthSubPlanName', subplan?.description)
  }

  const handleSubmit = () => {
    formik.submitForm()
    changeInsuranceData({
      ...formik.values,
      validThru: moment(formik.values.validThru, 'DD/MM/YYYY').toISOString()
    })
  }

  const validForm = () => {
    if (!formik.values.healthInsuranceCode) return false
    if (!formik.values.healthPlanCode) return false
    if (subPlans.length > 0 && !formik.values.healthSubPlanCode) return false
    return true
  }

  return (
    <Modal show={modalVisible} preventAutomateClose style={{ width: '50%' }}>
      <S.Container>
        <Heading
          style={{ textAlign: 'left' }}
          size="large"
          color="gray"
          as="h1"
        >
          Dados do Convênio
        </Heading>
        <S.Wrapper>
          <S.InputsContainer>
            <SelectField
              label="Convênio"
              bgColor="mainBg"
              value={formik.values.healthInsuranceCode || ''}
              onInputChange={(id) => handleInsuranceChange(id)}
              items={insurances?.map((insurance) => ({
                label: insurance.description,
                value: insurance.code
              }))}
              required
            />
            <SelectField
              key={'healthPlanName'}
              label="Plano"
              bgColor="mainBg"
              value={formik.values.healthPlanCode || ''}
              onInputChange={(id) => handlePlanChange(+id)}
              items={plans?.map((plan) => ({
                label: plan.description,
                value: plan.code
              }))}
              required
            />
            <SelectField
              key={'healthSubPlanName'}
              label="Sub Plano"
              bgColor="mainBg"
              value={formik.values.healthSubPlanCode || ''}
              onInputChange={(subplan) => handleSubPlanChange(+subplan)}
              items={subPlans?.map((subPlan) => ({
                label: subPlan.description,
                value: subPlan.code
              }))}
              required={subPlans.length > 0}
              disabled={subPlans.length === 0}
            />
          </S.InputsContainer>
          <S.InputsContainer>
            <TextField
              key={'healthCard'}
              bgColor="mainBg"
              label={'Número da carteira'}
              name={'healthCard'}
              defaultValue={insuranceData.healthCard}
              onChange={formik.handleChange}
              required
            />
            <TextField
              key={'validThru'}
              mask={dateMask}
              bgColor="mainBg"
              placeholder="dd/mm/aaaa"
              label={'Validade da carteira'}
              name={'validThru'}
              defaultValue={validThru}
              onChange={formik.handleChange}
            />
          </S.InputsContainer>
          <S.InputsContainer>
            <TextField
              key={'company'}
              bgColor="mainBg"
              label={'Empresa (titular do plano)'}
              name={'company'}
              defaultValue={insuranceData.company}
              onChange={formik.handleChange}
            />
          </S.InputsContainer>
          <S.ActionsContainer>
            <Button
              style={{ width: '30%', marginRight: 20 }}
              variant="outlined"
              onClick={() => showModal(false)}
            >
              Cancelar
            </Button>
            <Button
              style={{ width: '30%' }}
              onClick={() => handleSubmit()}
              type="submit"
              disabled={!validForm()}
            >
              <S.ButtonTitle>Salvar</S.ButtonTitle>
            </Button>
          </S.ActionsContainer>
        </S.Wrapper>
      </S.Container>
    </Modal>
  )
}

export type ModifiedInsuranceData = {
  healthInsuranceName: string
  healthInsuranceCode: number
  healthPlanName?: string
  healthPlanCode?: number
  healthSubPlanName?: string
  healthSubPlanCode?: number
  healthCard: string
  validThru: string
  company?: string
  ansRegister?: string
}
