import styled, { css } from 'styled-components'

export const Wrapper = styled.form`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    div.swiper-slide {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    h1 {
      margin-top: 40px;
      text-align: center;
      font-weight: 700;

      & strong {
        color: ${theme.colors.primary};
      }
    }

    p {
      margin-top: 20px;
      margin-bottom: 40px;
      text-align: center;
      width: 434px;
    }
  `}
`

export const Inputs = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 327px);
  grid-template-rows: repeat(2, 1fr);
  gap: 20px 80px;
  padding-bottom: 40px;
`
